import { useEffect } from 'react'

import {
  cartVirtualPageViewPush,
  homeVirtualPageViewPush,
  otherVirtualPageViewPush,
  searchVirtualPageViewPush,
} from '../../dataLayer/pushing'

export const useDataLayer = (pathname) => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      if (['/', '/home/investidor', '/home/incorporador'].includes(pathname)) {
        homeVirtualPageViewPush(pathname)
      } else if (pathname === '/search') {
        searchVirtualPageViewPush(pathname)
      } else if (pathname.includes('/apartment/payment/')) {
        cartVirtualPageViewPush(pathname)
      } else if (!pathname.includes('/apartment/')) {
        otherVirtualPageViewPush('other', pathname)
      }
    }
  }, [pathname])
}
