import * as dateFns from 'date-fns'
import moment from 'moment'
import qs from 'qs'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import dateUtils from 'src/utils/date'

import useCreateBooking from '../../hooks/booking/use-create-booking'
import { useCoupon } from '../../hooks/coupon'
import usePropertyPrice from '../../hooks/properties/price'
import dateValidation from './date-validation'
import { PropertyDetailContext } from './property-detail.context'

const BookingContext = createContext({
  dates: {
    from: '',
    to: '',
    range: '',
    setFrom: () => {},
    setTo: () => {},
    setRange: () => {},
  },
  focus: {
    fromFocus: false,
    toFocus: false,
    setFromFocus: () => {},
    setToFocus: () => {},
  },
  price: {
    daily: {
      amount: 0,
      currency: 'BRL',
      precision: 2,
    },
    monthly: {
      amount: 0,
      currency: 'BRL',
      precision: 2,
    },
    total: {
      amount: 0,
      currency: 'BRL',
      precision: 2,
      fees: [],
    },
  },
  coupon: {
    content: {},
    validate: () => {},
    delete: () => {},
    loading: false,
  },
  guests: 1,
  setGuests: () => {},
  months: null,
  bookingType: 'subscription',
  setBookingType: () => {},
  staysOnly: false,
  isShortStay: false,
  isSubscription: false,
  handleRedirectParams: () => {},
  createBooking: async () => {},
  updateUserDocument: false,
  setUpdateUserDocument: () => {},
  bookingCreationLoading: false,
  bookingErrors: {},
  paramsError: {},
  isNightUnavailable: false,
  isSubscriptionUnavailable: false,
})

const BookingProvider = ({ children }) => {
  const { propertyId } = useParams()
  const { search } = useLocation()
  const navigate = useNavigate()
  const [updateUserDocument, setUpdateUserDocument] = useState(false)

  const {
    property,
    subscriptionCalendarProps,
    shortStayCalendarProps,
    acceptType,
  } = useContext(PropertyDetailContext)

  const searchParams = useMemo(() => {
    return qs.parse(search, { ignoreQueryPrefix: true })
  }, [search])

  const [bookingType, setBookingType] = useState('subscription')
  const [guests, setGuests] = useState(1)
  const [from, setFrom] = useState(null)
  const [fromFocus, setFromFocus] = useState(null)
  const [to, setTo] = useState(null)
  const [toFocus, setToFocus] = useState(null)
  const [range, setRange] = useState([null, null])
  const [paramsError, setParamsError] = useState({})

  const isSubscription = React.useMemo(() => {
    if (!property?.stayTypes) return

    const canBeSubscription = property?.stayTypes.some(
      (stayType) => stayType.type === 'SUBSCRIPTION'
    )

    if (!canBeSubscription) return

    if (!searchParams?.to || !searchParams?.from) return canBeSubscription

    return (
      dateFns.differenceInDays(
        dateUtils.create(searchParams?.to),
        dateUtils.create(searchParams?.from)
      ) >= 30
    )
  }, [property?.stayTypes, searchParams?.to, searchParams?.from])

  const { coupon, validateCoupon, deleteCoupon, couponLoading } = useCoupon({
    from: searchParams?.from || null,
    to: searchParams?.to || null,
    range: searchParams?.range || null,
    guests,
    propertyId,
    isSubscription,
  })

  const setSubscriptionDates = useCallback(
    ({ dateFrom, dateTo }) => {
      if (dateFrom && dateTo) {
        setFrom(moment(dateFrom))
        setTo(moment(dateTo))
        setRange([null, null])
        setParamsError({})
      }
    },
    [setParamsError, setFrom, setTo, setRange]
  )

  const setShortStayDates = useCallback(
    ({ dateRange }) => {
      if (Array.isArray(dateRange) && dateRange[0] && dateRange[1]) {
        setRange([moment(dateRange[0]), moment(dateRange[1])])
        setFrom(null)
        setTo(null)
        setParamsError({})
      }
    },
    [setParamsError, setFrom, setTo, setRange]
  )

  const setType = useCallback(
    (type) => {
      if (type === 'subscription' && acceptType.includes('SUBSCRIPTION')) {
        setBookingType(type)
      }
      if (type === 'subscription' && !acceptType.includes('SUBSCRIPTION')) {
        setBookingType('shortStay')
      }
      if (type === 'shortStay' && acceptType.includes('SHORT_STAY')) {
        setBookingType(type)
      }
      if (type === 'shortStay' && !acceptType.includes('SHORT_STAY')) {
        setBookingType('subscription')
      }
    },
    [acceptType]
  )

  const setErrorParams = useCallback(
    ({ dateValid, type }) => {
      if (!Object.keys(dateValid).some((key) => dateValidation[key])) {
        setParamsError({})

        if (!dateValid.rangeHasMinimumNights && type === 'shortStay')
          setParamsError({
            error: 'MINIMUM_STAY_NOT_REACHED',
            requestCode: 400,
          })

        if (!dateValid.dontHasBlockBetweenDates)
          setParamsError({ error: 'Conflict', requestCode: 409 })

        if (!dateValid.fromIsAfterAdvanceDays)
          setParamsError({
            error: 'ADVANCE_DAYS_NOT_REACHED',
            requestCode: 400,
          })
      }
    },
    [setParamsError]
  )

  useEffect(() => {
    const params = qs.parse(search, { ignoreQueryPrefix: true })

    // set guests and booking types
    setGuests(params?.guests ? Number(params.guests) : 1)
    setType(params?.bookingType)

    const validationDates = dateValidation({
      from: params?.from,
      to: params?.to,
      range: params?.range,
      minimumNights: shortStayCalendarProps.minStay,
      blockedDates: property?.busyDates,
      advanceDaysShortStay: shortStayCalendarProps.blockAfterNextAvailableDate,
      advanceDaysSubscription:
        subscriptionCalendarProps.blockAfterNextAvailableDate,
    })

    if (params?.bookingType === 'subscription' && property?.aliasId) {
      setSubscriptionDates({ dateFrom: params?.from, dateTo: params?.to })
      setErrorParams({
        dateValid: validationDates.subscription,
        type: 'subscription',
      })
    }

    if (params?.bookingType === 'shortStay' && property?.aliasId) {
      setShortStayDates({
        dateRange: params?.range,
      })
      setErrorParams({
        dateValid: validationDates.shortStay,
        type: 'shortStay',
      })
    }
  }, [
    search,
    property,
    shortStayCalendarProps,
    subscriptionCalendarProps,
    setShortStayDates,
    setSubscriptionDates,
    setErrorParams,
  ])

  const isShortStay = useMemo(() => {
    return bookingType === 'shortStay'
  }, [bookingType])

  const handleRedirectParams = useCallback(
    (overwriteProps = {}) => {
      const actualParams = qs.parse(search, { ignoreQueryPrefix: true })

      return navigate(
        `/apartment/${propertyId}?${qs.stringify(
          { ...actualParams, ...overwriteProps },
          { ignoreQueryPrefix: true }
        )}`
      )
    },
    [search]
  )

  const { price } = usePropertyPrice({
    from: searchParams?.from || null,
    to: searchParams?.to || null,
    range: searchParams?.range || null,
    guests,
    propertyId,
    bookingType: searchParams?.bookingType,
  })

  const {
    create: createBooking,
    loading: bookingCreationLoading,
    bookingErrors,
  } = useCreateBooking({
    from: searchParams?.from || null,
    to: searchParams?.to || null,
    range: searchParams?.range || null,
    guests,
    propertyId,
    bookingType: searchParams?.bookingType,
    coupon,
  })

  const isNightUnavailable =
    price.daily.grossAmount.amount === 0 ||
    price.daily.grossAmount.amount === undefined

  const isSubscriptionUnavailable =
    price.monthly.grossAmount.amount === 0 ||
    price.monthly.grossAmount.amount === undefined

  const staysOnly = property.staysOnly ?? false

  return (
    <BookingContext.Provider
      value={{
        staysOnly,
        dates: { from, to, range, setRange, setFrom, setTo },
        focus: { toFocus, setToFocus, fromFocus, setFromFocus },
        guests,
        setGuests,
        bookingType,
        setBookingType,
        price,
        coupon: {
          content: coupon,
          validate: validateCoupon,
          delete: deleteCoupon,
          loading: couponLoading,
        },
        isShortStay,
        isSubscription,
        handleRedirectParams,
        createBooking,
        bookingCreationLoading,
        bookingErrors,
        paramsError,
        isNightUnavailable,
        isSubscriptionUnavailable,
        updateUserDocument,
        setUpdateUserDocument,
      }}
    >
      {children}
    </BookingContext.Provider>
  )
}

export { BookingContext, BookingProvider }
