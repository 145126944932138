import * as c from '@chakra-ui/react'
import React from 'react'
import {
  PropertyCardContext,
  PropertyCardProvider,
} from 'src/components/cards/property/property-card.context'
import { convertToMoney } from 'src/utils/monetary'

import { SearchContext } from '../search-context'
import { search_events } from '../search-machine'

const UnavailablePrice = () => {
  const { property } = React.useContext(PropertyCardContext)

  const isSubscriptionUnavailable =
    property.price?.monthly?.grossAmount.amount === 0

  if (isSubscriptionUnavailable)
    return (
      <c.Box>
        <c.Text
          color="black.400"
          fontSize={14}
          fontWeight={700}
          textDecoration="line-through"
        >
          Indisponível
        </c.Text>
      </c.Box>
    )

  return (
    <c.Box>
      <c.Text color="black.400" fontSize={12} fontWeight={500}>
        {`${convertToMoney(property?.price?.monthly?.netAmount)} Aluguel`}
      </c.Text>

      <c.Text color="black.500" fontSize={14} fontWeight={700}>
        {`${convertToMoney(property?.price?.monthly?.grossAmount)} /mês`}
      </c.Text>
    </c.Box>
  )
}

const Card = () => {
  const { send } = React.useContext(SearchContext)
  const { property, redirectLink } = React.useContext(PropertyCardContext)

  const onRedirect = () => {
    window.open(redirectLink, '_blank')

    return send(search_events.open)
  }

  return (
    <c.Flex
      onClick={onRedirect}
      align="center"
      bg="black.0"
      border="1px"
      borderStyle="solid"
      borderColor="black.200"
      borderRadius="8px"
      transition="all 0.3s"
      cursor="pointer"
      _hover={{
        borderColor: 'primary.500',
      }}
    >
      <c.Image
        src={`${process.env.REACT_APP_CDN_IMAGE}/160x132/${property?.mainPhoto}`}
        alt={property?.aliasName}
        borderTopLeftRadius="8px"
        borderBottomLeftRadius="8px"
        objectFit="cover"
        objectPosition="center"
        minH="132px"
        maxH="123px"
        minW="160px"
        maxW="160px"
      />
      <c.Flex
        p={4}
        minH="132px"
        w="fit-content"
        direction="column"
        justify="space-between"
      >
        <c.Box>
          <c.Text
            color="black.500"
            fontSize={14}
            fontWeight={500}
            noOfLines={1}
          >
            {property?.aliasName}
          </c.Text>
          <c.Text
            color="black.400"
            fontSize={12}
            fontWeight={500}
            noOfLines={1}
          >
            {`${property?.address?.neighborhood?.name}, ${property?.address?.city?.name}`}
          </c.Text>
        </c.Box>
        <UnavailablePrice />
      </c.Flex>
    </c.Flex>
  )
}

const HighlightCard = (props: any) => (
  <PropertyCardProvider {...props}>
    <Card />
  </PropertyCardProvider>
)

export default HighlightCard
