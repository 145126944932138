export const ArrowRightIcon = (props: any) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33339 15.8332C8.17504 15.8332 8.01999 15.788 7.88639 15.703C7.75279 15.618 7.64617 15.4967 7.57902 15.3533C7.51187 15.2099 7.48696 15.0504 7.50721 14.8933C7.52747 14.7363 7.59205 14.5882 7.69339 14.4666L11.4226 9.99074L7.82672 5.52241C7.68815 5.35013 7.62368 5.12985 7.64752 4.91005C7.67135 4.69024 7.78152 4.4889 7.9538 4.35032C8.12608 4.21175 8.34636 4.14729 8.56617 4.17112C8.78597 4.19495 8.98731 4.30513 9.12589 4.47741L13.1492 9.47741C13.2699 9.62738 13.3349 9.8145 13.3332 10.007C13.3315 10.1994 13.2633 10.3854 13.1401 10.5332L8.97339 15.5332C8.89522 15.6271 8.79737 15.7026 8.68677 15.7545C8.57617 15.8063 8.45553 15.8332 8.33339 15.8332Z"
      fill="currentColor"
    />
  </svg>
)
