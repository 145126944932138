import { Box, Flex, Paragraph, retriever } from '@retriever-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CheckMarkIcon } from 'src/assets/icons/checkmarker.icon'
import { CloseIcon } from 'src/assets/icons/close.icon'
import { PropertyDetailContext } from 'src/pages/property-detail/property-detail.context'

interface BillItemProps {
  haveBill: boolean
  text: string
}

interface BillListProps {
  text: string
  bills: BillItemProps[]
}

export type Flags =
  | 'WATER'
  | 'LIGHT'
  | 'TV'
  | 'INTERNET'
  | 'KITCHEN_UTILITIES'
  | 'TROUSSEAU'
  | 'FURNITURE'

export const BillItem = ({ haveBill, text }: BillItemProps) => {
  return (
    <Flex maxW="fit-content" alignItems="center" width="fit-content" gap="4px">
      {haveBill ? (
        <CheckMarkIcon height={16} width={16} />
      ) : (
        <CloseIcon color="gray" height={10} width={10} />
      )}

      <retriever.p
        fontWeight={500}
        whiteSpace="nowrap"
        width="fit-content"
        textDecoration={haveBill ? 'none' : 'line-through'}
        color={haveBill ? 'black.400' : 'black.300'}
        fontSize={14}
      >
        {text}
      </retriever.p>
    </Flex>
  )
}
const BillList = ({ bills, text }: BillListProps) => {
  return (
    <Box mTop="14px">
      <Paragraph fontSize="extraSmall" color="black.400" fontWeight="bold">
        {text}
      </Paragraph>
      <Flex flexWrap="wrap" mTop="4px" gap="14px">
        {bills.map((bill) => (
          <BillItem haveBill={bill.haveBill} text={bill.text} key={bill.text} />
        ))}
      </Flex>
    </Box>
  )
}

const Bills = () => {
  const { property } = React.useContext(PropertyDetailContext)
  const { t } = useTranslation('property-detail/booking-resume')
  let flags = property.flags as Flags[]

  if (!property.flags) {
    flags = []
  }

  const living = ['WATER', 'LIGHT', 'TV', 'INTERNET']
  const facilities = ['KITCHEN_UTILITIES', 'TROUSSEAU', 'FURNITURE']

  const livingBills = living.map((bill) => ({
    haveBill: flags.includes(bill as any),
    text: t(`property-detail/booking-resume:BILLS_FLAGS.${bill}`),
  }))

  const facilitiesBills = facilities.map((bill) => ({
    haveBill: flags.includes(bill as any),
    text: t(`property-detail/booking-resume:BILLS_FLAGS.${bill}`),
  }))

  return (
    <Box mTop="16px">
      <BillList
        text={t('property-detail/booking-resume:LIVING')}
        bills={livingBills}
      />
      <BillList
        text={t('property-detail/booking-resume:FACILITIES')}
        bills={facilitiesBills}
      />
    </Box>
  )
}

export default Bills
