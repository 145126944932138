import * as c from '@chakra-ui/react'
import React from 'react'
import { InfoIcon } from 'src/assets/icons/info.icon'
import { usePackHousi } from 'src/pages/property-detail/details/pack-housi'
import { ListBillItem } from 'src/pages/property-detail/details/pack-housi/pack-housi-modal'
import { PropertyDetailContext } from 'src/pages/property-detail/property-detail.context'

import Fees from '../fees'
import { RentPrice } from '../subscription'
import TotalSubscription from '../total/total-subscription'
import { PackHousiValues } from '.'

const PackHousiModal = () => {
  const { property } = React.useContext(PropertyDetailContext)
  const { facilitiesBills, livingBills } = usePackHousi({ property: property })

  const { isOpen, onOpen, onClose } = c.useDisclosure()

  const bills = [...facilitiesBills, ...livingBills].sort(
    (a, b) => Number(b.haveBill) - Number(a.haveBill)
  )

  const hasPackHousi = bills.filter((x) => x.haveBill)

  if (hasPackHousi.length <= 0) return null

  const showWarning = bills.map((x) => x.haveBill)

  return (
    <>
      <c.Flex
        as={c.Button}
        onClick={onOpen}
        px={0}
        mt={2}
        height="44px"
        fontSize={14}
        fontWeight={700}
        bg="transparent"
        color="primary.500"
        gap={2}
        _hover={{
          background: 'transparent',
        }}
      >
        Entenda os valores
        <InfoIcon height={14} width={14} color="var(--primary-color)" />
      </c.Flex>

      <c.Modal isOpen={isOpen} onClose={onClose}>
        <c.ModalOverlay />

        <c.ModalContent py={10} maxW="375px">
          <c.Flex px={5} align="center" justify="space-between">
            <c.Text fontSize={20} fontWeight={700} color="black.500">
              Detalhes dos preços
            </c.Text>
            <c.ModalCloseButton />
          </c.Flex>

          <c.ModalBody>
            <c.Divider mt={3} />

            <c.Flex direction="column" gap={2} mb={6}>
              <RentPrice isModal />
              <Fees isModal />
              <PackHousiValues isModal />
            </c.Flex>

            <c.Box
              p={3}
              border="1px"
              borderColor="black.200"
              borderRadius="8px"
            >
              <c.Heading fontSize={14} mb={3} color="black.500">
                No
                <c.Box as="span" fontWeight={700}>
                  {` pacote de moradia `}
                </c.Box>
                dessa unidade, estão inclusas as taxas de:
              </c.Heading>

              <c.Flex direction="column" gap={2}>
                {bills?.map((bill, key) => (
                  <ListBillItem
                    key={key}
                    text={bill.text}
                    haveBill={bill.haveBill}
                  />
                ))}
              </c.Flex>

              {showWarning.includes(false) && (
                <c.Text mt={5} fontSize={12} fontWeight={500} color="black.500">
                  * Itens não inclusos no pacote de moradia devem ser combinados
                  diretamente com o proprietário/ operador da unidade.
                </c.Text>
              )}
            </c.Box>

            <c.Divider my={6} />
            <TotalSubscription />
          </c.ModalBody>

          <c.ModalFooter mt={10}>
            <c.Button
              onClick={onClose}
              h="44px"
              w="100%"
              bg="black.500"
              color="black.0"
              fontWeight={700}
              fontSize={16}
              py={2.5}
              transition="all 0.2s"
              _hover={{ bg: 'black.600' }}
            >
              Ok, entendi
            </c.Button>
          </c.ModalFooter>
        </c.ModalContent>
      </c.Modal>
    </>
  )
}

export default PackHousiModal
