import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import NotFoundIcon from './icons/not-found.icon'
import { Header } from './reservation-code'

const ReservationNotFound = () => {
  const { t } = useTranslation('ota-page')
  const navigate = useNavigate()

  return (
    <Flex direction="column" gap="24px" w="full">
      <Box
        w="full"
        mt={['none', '40px']}
        py={['none', '40px']}
        bg={['none', 'black.0']}
        borderRadius={['none', '12px']}
      >
        <Header
          title={t('step-2.title')}
          back={() => navigate('/vincular/reserva')}
        />
        <Box mx="24px" px="20px" borderRadius="12px" py="40px" bg="black.0">
          <NotFoundIcon />
          <Heading w="full" mt="16px" fontWeight="bold" fontSize="24px">
            {t('not-found.title')}
          </Heading>
          <Text mt="24px" fontWeight="medium" fontSize="16px">
            {t('not-found.desc.0').split('\b')[0]}
            <Text as="span" fontWeight="bold" fontSize="16px">
              {t('not-found.desc.0').split('\b')[1]}
            </Text>
          </Text>
          <Text mt="24px" fontWeight="medium" fontSize="16px">
            {t('not-found.desc.1')}
          </Text>
          <Flex direction="column" gap="8px">
            <Button
              onClick={() => navigate('/vincular/reserva')}
              colorScheme="primary"
              bg="primary.500"
              mt="60px"
            >
              {t('not-found.try-again')}
            </Button>
            <Button variant="ghost" onClick={() => navigate('/')}>
              {t('not-found.support')}
            </Button>
          </Flex>
        </Box>
      </Box>
      <Button variant="ghost" onClick={() => navigate('/vincular/reserva')}>
        {t('step-1.back')}
      </Button>
    </Flex>
  )
}

export default ReservationNotFound
