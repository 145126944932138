import { css } from 'styled-components'

const applyToIcon = (
  solid = '--primary-color',
  hover = '--white-color',
  disabled = '--primary-color-light-2'
) => css`
  svg,
  path {
    fill: var(${solid}) !important;
  }

  :hover {
    svg,
    path {
      fill: var(${hover}) !important;
    }
  }

  :disabled {
    svg,
    path {
      fill: var(${disabled}) !important;
    }
  }
`

const primary = css`
  background: #fff;
  border-color: var(--primary-color);
  color: var(--primary-color);

  .loading-svg {
    circle {
      stroke: var(--primary-color);
    }
  }

  :hover {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;

    .loading-svg {
      circle {
        stroke: #fff;
      }
    }
  }

  :disabled {
    background: #fff;
    border-color: var(--primary-color-light-2);
    color: var(--primary-color-light-2);
  }

  ${(p) => p.applyToIcon && applyToIcon()}
`

const secondary = css`
  background: #fff;
  border-color: var(--secondary-color);
  color: var(--secondary-color);

  .loading-svg {
    circle {
      stroke: var(--secondary-color);
    }
  }

  :hover {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #fff;

    .loading-svg {
      circle {
        stroke: #fff;
      }
    }
  }

  :disabled {
    background: var(--secondary-color-light-2);
    border-color: var(--secondary-color-light-2);
    color: var(--secondary-color-light-2);
  }

  ${(p) =>
    p.applyToIcon &&
    applyToIcon(
      '--secondary-color',
      '--white-color',
      '--secondary-color-light-2'
    )}
`

const black = css`
  background: #fff;
  border-color: var(--black-color);
  color: var(--black-color);

  .loading-svg {
    circle {
      stroke: var(--black-color);
    }
  }

  :hover {
    background: #f5f5f5;
    border-color: var(--black-color);

    .loading-svg {
      circle {
        stroke: #fff;
      }
    }
  }

  :disabled {
    background: #fff;
    border-color: var(--gray-color-2);
  }

  ${(p) =>
    p.applyToIcon &&
    applyToIcon('--black-color', '--black-color', '--secondary-color-2')}
`

const danger = css`
  background: #fff;
  border-color: var(--danger-color);
  color: var(--danger-color);

  .loading-svg {
    circle {
      stroke: var(--black-color);
    }
  }

  :hover {
    background: #f5f5f5;
    border-color: var(--danger-color);

    .loading-svg {
      circle {
        stroke: #fff;
      }
    }
  }

  :disabled {
    background: #ef8099;
    border-color: var(--gray-color-2);
  }

  ${(p) =>
    p.applyToIcon &&
    applyToIcon('--black-color', '--black-color', '--secondary-color-2')}
`

export const outlineStyles = { primary, secondary, black, danger }
