import React from 'react'

const FavHeart = ({ color = '#6C6C6C' }) => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3167 18C10.1853 17.9998 10.0553 17.9738 9.93397 17.9233C9.81266 17.8729 9.70248 17.799 9.60972 17.706L1.84372 9.926C0.864964 8.94335 0.31543 7.61293 0.31543 6.226C0.31543 4.83907 0.864964 3.50865 1.84372 2.526C2.82467 1.54786 4.15343 0.998573 5.53872 0.998573C6.92401 0.998573 8.25278 1.54786 9.23372 2.526L10.3167 3.614L11.4017 2.529C12.3825 1.55061 13.7114 1.00116 15.0967 1.00116C16.4821 1.00116 17.8109 1.55061 18.7917 2.529C19.7702 3.51178 20.3196 4.84216 20.3196 6.229C20.3196 7.61585 19.7702 8.94622 18.7917 9.929L11.0267 17.707C10.9335 17.8002 10.8228 17.8741 10.701 17.9244C10.5791 17.9747 10.4485 18.0004 10.3167 18ZM5.53872 3C5.11533 2.99822 4.69579 3.08047 4.30441 3.242C3.91304 3.40353 3.55761 3.64112 3.25872 3.941C2.65411 4.54838 2.31468 5.3705 2.31468 6.2275C2.31468 7.08451 2.65411 7.90663 3.25872 8.514L10.3167 15.585L17.3777 8.514C17.678 8.21452 17.9163 7.85883 18.0791 7.46724C18.2419 7.07565 18.326 6.65583 18.3266 6.23174C18.3271 5.80766 18.2442 5.38762 18.0824 4.9956C17.9206 4.60358 17.6832 4.24727 17.3837 3.947C17.0842 3.64673 16.7286 3.40839 16.337 3.24559C15.9454 3.08278 15.5256 2.9987 15.1015 2.99814C14.245 2.99702 13.4231 3.33617 12.8167 3.941L11.0267 5.736C10.8358 5.91771 10.5823 6.01905 10.3187 6.01905C10.0551 6.01905 9.80166 5.91771 9.61072 5.736L7.81672 3.94C7.51799 3.64054 7.16285 3.40327 6.77184 3.24192C6.38083 3.08057 5.96171 2.99835 5.53872 3Z"
        fill={color}
      />
    </svg>
  )
}

export default FavHeart
