export type GeoType = 'city' | 'neighborhood' | 'street'
export type ResultType = { coordinates: number[]; type: GeoType }
type QueryType = { address: string; placeId: string }

const streetTypes = ['street_address']
const cityTypes = ['locality']
const neighTypes = [
  'route',
  'sublocality_level_1',
  'sublocality_level_2',
  'sublocality_level_3',
  'sublocality_level_4',
  'sublocality_level_5',
  'postal_code',
  'town_square',
  'point_of_interest',
  'establishment',
]

const getLocationType = (types: string[]): GeoType => {
  const isStreet = types.some((type) => streetTypes.includes(type))
  const isNeight = types.some((type) => neighTypes.includes(type))
  const isCity = types.some((type) => cityTypes.includes(type))

  switch (true) {
    case isStreet:
      return 'street'
    case isNeight:
      return 'neighborhood'
    case isCity:
      return 'city'
    default:
      return 'city'
  }
}

const addressOrPlaceId = async (placeId: string): Promise<boolean> =>
  new Promise((resolve, reject) => {
    try {
      new window.google.maps.Geocoder().geocode({ placeId }, (_, status) => {
        return resolve(status === 'OK')
      })
    } catch (e) {
      reject(e)
    }
  })

const getCoordinates = (playload: Partial<QueryType>): Promise<ResultType> =>
  new Promise((resolve, reject) => {
    try {
      new window.google.maps.Geocoder().geocode(playload, (data) => {
        if (!Array.isArray(data)) throw new Error('not array')
        const { geometry, types } = data[0]

        return resolve({
          coordinates: [geometry.location.lat(), geometry.location.lng()],
          type: getLocationType(types),
        } as ResultType)
      })
    } catch (e) {
      reject(e)
    }
  })

const googleGeocoder = async ({ address, placeId }: QueryType) => {
  const isPlaceId = await addressOrPlaceId(placeId)

  if (isPlaceId) {
    return await getCoordinates({ placeId })
  }

  return await getCoordinates({ address })
}

const useGeocorder = () => {
  const getGeocorderByPlaceId = async (payload: QueryType) => {
    const result = await googleGeocoder(payload)

    return result as ResultType
  }

  return { getGeocorderByPlaceId }
}

export default useGeocorder
