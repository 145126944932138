import { Box, Flex } from '@retriever-ui/layout'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowWidth } from 'src/store/context/window.context'

import CreditCard from '../../components/icons/credit-card.icon'
import FavHeart from '../../components/icons/fav-heart.icon'
import Location from '../../components/icons/location.icon'
import OutIcon from '../../components/icons/out.icon'
import PersonInfo from '../../components/icons/person-info.icon'
import SafetyLocker from '../../components/icons/safety-locker.icon'
import Avatar from '../../pages/avatar/avatar-icon'
import MenuItem from './menu-item'

type Props = {
  children?: ReactNode
}

const Menu = ({ children }: Props) => {
  const { t } = useTranslation('profile')

  const { windowWidth } = useWindowWidth()

  const isMobile = windowWidth < 720

  const isMenuPage = window.location.pathname === '/profile'

  const menuItems = [
    {
      title: t(`profile:MY_RESERVATIONS`),
      Icon: Location,
      path: '/profile/reservations',
    },
    {
      title: t('profile:FAVORITES'),
      Icon: FavHeart,
      path: '/profile/favorites',
    },
    {
      title: t(`profile:PERSONAL_INFO`),
      Icon: PersonInfo,
      path: '/profile/personal-info',
    },
    {
      title: t(`profile:PAYMENT`),
      Icon: CreditCard,
      path: '/profile/payment' || '/profile/payment/new-card',
    },
    {
      title: t(`profile:SECURITY`),
      Icon: SafetyLocker,
      path: '/profile/password',
    },
  ]

  if (isMobile && !isMenuPage)
    return (
      <Box
        backgroundColor="black.0"
        flex="1"
        width="100%"
        height="100%"
        minH="80vh"
        paddingBottom="60px"
        boxShadow="0px 4px 16px -5px #0000001a"
      >
        {children}
      </Box>
    )

  return (
    <Flex
      backgroundColor={['initial', 'black.100']}
      flexDirection={['column', 'row']}
    >
      <Box p={['60px 20px', '60px 0 0 84px']}>
        <Avatar />
        <Flex flexDirection="column" gap="2px">
          {menuItems.map(({ title, Icon, path }) => (
            <MenuItem
              title={title}
              Icon={Icon}
              path={path}
              key={path}
              active={true}
            />
          ))}

          <Box mTop="62px">
            <MenuItem
              title={t(`profile:SIGN_OUT`)}
              Icon={OutIcon}
              path="/logout"
              active={false}
              isLogout={true}
            />
          </Box>
        </Flex>
      </Box>
      <Flex
        backgroundColor="black.0"
        flex="1"
        width="100%"
        height="100%"
        minH={['0', '100vh']}
        paddingBottom="60px"
        boxShadow="0px 4px 16px -5px #0000001a"
      >
        {children}
      </Flex>
    </Flex>
  )
}

export default React.memo(Menu)
