import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'

import { getApartmentPrice } from '../../requests/apartments'

const usePropertyPrice = ({
  from = null,
  to = null,
  range = [null, null],
  propertyId,
  guests = 1,
  bookingType = 'subscription',
}) => {
  const initialPrice = { amount: 0, currency: 'BRL', precision: 2 }

  const [loading, setLoading] = useState(false)
  const [price, setPrice] = useState({
    daily: {
      netAmount: initialPrice,
      grossAmount: initialPrice,
      feesAmount: initialPrice,
    },
    monthly: {
      netAmount: initialPrice,
      grossAmount: initialPrice,
      feesAmount: initialPrice,
      fees: [],
    },
    total: {
      fessAmount: initialPrice,
      totalNetAmount: initialPrice,
      totalGrossAmount: initialPrice,
    },
  })

  const getDateObject = useCallback(() => {
    if (bookingType === 'subscription' && from && to) {
      return {
        from: moment(from).format('YYYY-MM-DD'),
        to: moment(to).format('YYYY-MM-DD'),
      }
    }

    if (
      bookingType === 'shortStay' &&
      Array.isArray(range) &&
      range[0] &&
      range[1]
    ) {
      return {
        from: moment(range[0]).format('YYYY-MM-DD'),
        to: moment(range[1]).format('YYYY-MM-DD'),
        isSubscription: false,
      }
    }

    return {
      from: moment().format('YYYY-MM-DD'),
      to: moment().add(1, 'day').format('YYYY-MM-DD'),
      isSubscription: true,
    }
  }, [bookingType, from, to, range])

  const effect = useCallback(async () => {
    if (propertyId) {
      setLoading(true)

      try {
        const paramsDate = getDateObject()

        if (paramsDate.from && paramsDate.to) {
          const { data } = await getApartmentPrice({
            id: propertyId,
            data: { ...paramsDate, guests },
          })

          setPrice(data)
          return data
        }

        return null
      } catch (e) {
        return Promise.reject(e)
      } finally {
        setLoading(false)
      }
    }

    return null
  }, [getDateObject, guests, propertyId])

  useEffect(() => {
    effect()
  }, [effect])

  return { price, priceLoading: loading }
}

export default usePropertyPrice
