export const BookingServiceIcon = (props: any) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4166 9.99995H14.9166C14.8333 9.99995 14.8333 9.91662 14.8333 9.83329V8.91662C14.8333 8.83328 14.9166 8.74995 14.9166 8.74995H15.8333C16.4166 8.74995 16.9166 8.24995 16.9166 7.66662V5.74996C16.9166 5.16663 16.4166 4.66663 15.8333 4.66663H12.6666C12.0833 4.66663 11.5833 5.16663 11.5833 5.74996V7.66662C11.5833 8.24995 12.0833 8.74995 12.6666 8.74995H13.5833C13.6666 8.74995 13.6666 8.83328 13.6666 8.91662V9.83329C13.6666 9.91662 13.5833 9.99995 13.5833 9.99995H10.0833C9.99998 9.99995 9.99998 9.91663 9.99998 9.91663C9.91665 9.16663 9.49998 8.41663 8.99998 7.8333C8.33331 7.16663 7.41665 6.74996 6.41665 6.74996C5.41665 6.74996 4.49998 7.16663 3.83331 7.8333C3.24998 8.41663 2.91665 9.08329 2.83331 9.91663C2.83331 9.99996 2.74998 9.99995 2.74998 9.99995C1.66665 9.99995 0.833313 10.9166 0.833313 12V16.75C0.833313 17.8333 1.74998 18.75 2.83331 18.75H16.6666C17.75 18.75 18.6666 17.8333 18.6666 16.75V12C18.4166 10.8333 17.5 9.99995 16.4166 9.99995ZM12.75 7.66662H12.6666V7.58329V5.91663C12.6666 5.83329 12.75 5.83329 12.75 5.83329H15.6666C15.75 5.83329 15.75 5.91662 15.75 5.99995V7.66662V7.74996H15.6666L12.75 7.66662ZM4.58331 8.74995C4.99998 8.33329 5.66665 8.08328 6.24998 8.08328C6.91665 8.08328 7.49998 8.33329 7.91665 8.74995C8.24998 9.08329 8.41665 9.41662 8.58331 9.83329V9.91663L8.49998 9.99995H4.08331C4.08331 9.99995 3.99998 9.99996 3.99998 9.91663V9.83329C4.08331 9.41662 4.33331 8.99995 4.58331 8.74995ZM17.3333 16.75C17.3333 17.25 16.9166 17.6666 16.4166 17.6666H2.58331C2.08331 17.6666 1.66665 17.25 1.66665 16.75V12C1.66665 11.5 2.08331 11.0833 2.58331 11.0833H16.4166C16.9166 11.0833 17.3333 11.5 17.3333 12V16.75Z"
      fill="currentColor"
    />
    <path
      d="M5.16666 5.58329C5.49999 5.83329 5.91666 5.91663 6.33332 5.91663C6.91666 5.91663 7.41666 5.66663 7.83332 5.24996C8.24999 4.8333 8.49999 4.33329 8.49999 3.74996C8.49999 3.33329 8.33332 2.91663 8.16666 2.5833C7.91666 2.24996 7.58332 1.99996 7.16666 1.83329C6.91666 1.74996 6.66666 1.66663 6.33332 1.66663C6.16666 1.66663 6.08332 1.66663 5.91666 1.66663C5.49999 1.74996 5.08332 1.99996 4.83332 2.24996C4.49999 2.49996 4.33332 2.91663 4.24999 3.33329C4.16666 3.74996 4.16666 4.16663 4.33332 4.58329C4.49999 5.08329 4.74999 5.41662 5.16666 5.58329ZM5.49999 3.49996C5.58332 3.33329 5.66666 3.16662 5.83332 3.08329C6.16666 2.83329 6.66666 2.91663 6.91666 3.16663C7.08332 3.3333 7.16666 3.5833 7.16666 3.8333C7.16666 3.99996 7.08332 4.16662 6.99999 4.33329C6.91666 4.49996 6.74999 4.5833 6.58332 4.66663C6.49999 4.74996 6.33332 4.74996 6.24999 4.74996C6.16666 4.74996 6.16666 4.74996 6.08332 4.74996C5.91666 4.74996 5.74999 4.66663 5.58332 4.49997C5.49999 4.41663 5.41666 4.24997 5.33332 4.0833C5.41666 3.8333 5.41666 3.66662 5.49999 3.49996Z"
      fill="currentColor"
    />
  </svg>
)
