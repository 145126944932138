const AppspaceIcon = (props: any) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 17.5H5.00001C4.33697 17.5 3.70109 17.2366 3.23224 16.7678C2.7634 16.2989 2.50001 15.6631 2.50001 15V7.35751C2.49901 7.02895 2.56324 6.70346 2.68898 6.39991C2.81472 6.09636 2.99947 5.82079 3.23251 5.58918L5.58918 3.23251C5.82067 2.99953 6.09609 2.81482 6.3995 2.68908C6.70291 2.56334 7.02825 2.49907 7.35668 2.50001H12.6425C12.9709 2.49907 13.2963 2.56334 13.5997 2.68908C13.9031 2.81482 14.1785 2.99953 14.41 3.23251L16.7667 5.58918C16.9999 5.82072 17.1848 6.09626 17.3107 6.39981C17.4365 6.70336 17.5009 7.02889 17.5 7.35751V15C17.5 15.6631 17.2366 16.2989 16.7678 16.7678C16.2989 17.2366 15.6631 17.5 15 17.5ZM4.16668 7.50001V15C4.16668 15.221 4.25448 15.433 4.41076 15.5893C4.56704 15.7455 4.779 15.8333 5.00001 15.8333H15C15.221 15.8333 15.433 15.7455 15.5893 15.5893C15.7455 15.433 15.8333 15.221 15.8333 15V7.50001H4.16668ZM7.35668 4.16668C7.13588 4.16772 6.92432 4.2554 6.76751 4.41084L5.34501 5.83334H14.655L13.2325 4.41084C13.0757 4.2554 12.8641 4.16772 12.6433 4.16668H7.35668ZM10 13.3333C9.11596 13.3333 8.26811 12.9822 7.64299 12.357C7.01787 11.7319 6.66668 10.8841 6.66668 10C6.66668 9.779 6.75448 9.56704 6.91076 9.41075C7.06704 9.25447 7.279 9.16668 7.50001 9.16668C7.72103 9.16668 7.93299 9.25447 8.08927 9.41075C8.24555 9.56704 8.33334 9.779 8.33334 10C8.33334 10.442 8.50894 10.866 8.8215 11.1785C9.13406 11.4911 9.55798 11.6667 10 11.6667C10.442 11.6667 10.866 11.4911 11.1785 11.1785C11.4911 10.866 11.6667 10.442 11.6667 10C11.6667 9.779 11.7545 9.56704 11.9108 9.41075C12.067 9.25447 12.279 9.16668 12.5 9.16668C12.721 9.16668 12.933 9.25447 13.0893 9.41075C13.2455 9.56704 13.3333 9.779 13.3333 10C13.3333 10.8841 12.9822 11.7319 12.357 12.357C11.7319 12.9822 10.8841 13.3333 10 13.3333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AppspaceIcon
