import * as c from '@chakra-ui/react'

import LandingPageItem from './landing-page-item'
import { NavbarItemsConfig } from './navbar-item.config'

export function LandingPages() {
  const { navbarItemsData } = NavbarItemsConfig()

  return (
    <c.Flex alignItems="center">
      {navbarItemsData.map((item) => (
        <LandingPageItem key={item.id} item={item} />
      ))}
    </c.Flex>
  )
}
