import { retriever } from '@retriever-ui/react'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import React from 'react'

import { useSearch } from './search-context'

interface WrapperProps {
  children: React.ReactNode
}

const variants: Variants = {
  initial: {
    maxWidth: 500,
    transition: {
      duration: 0.2,
    },
  },
  animate: {
    maxWidth: 900,
    transition: {
      duration: 0.3,
    },
  },
}

const Wrapper = ({ children }: WrapperProps) => {
  const { valid, controls, handleOpen } = useSearch()

  React.useEffect(() => {
    if (valid.open || valid.closing) {
      document.body.style.overflowY = 'hidden'
    }
    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [valid.open, valid.closing])

  if (valid.closed) {
    return (
      <retriever.div
        role="button"
        radius={32}
        pLeft={20}
        pRight={8}
        pY={2}
        onClick={handleOpen}
        height="56px"
        width={['100%', '436px']}
        bg="black.0"
        shadow="0px 4px 16px -5px black.800/2"
      >
        {children}
      </retriever.div>
    )
  }

  return (
    <retriever.div pos="relative" w="100%" maxW={900} mX="auto">
      <AnimatePresence>
        {!valid.closed && (
          <motion.div
            exit="initial"
            initial="initial"
            variants={variants}
            animate={controls.closing}
          >
            <retriever.div
              pos="relative"
              bg="black.0"
              w="100%"
              shadow={['initial', '0px 4px 16px -5px black.800/2']}
              maxW={900}
              pTop={[40, 40]}
              pBottom={[20, 40]}
              pX={[20, 40]}
              radius={['0px 0px 8px 8px', 8]}
              zIndex={1}
            >
              {children}
            </retriever.div>
          </motion.div>
        )}
      </AnimatePresence>
    </retriever.div>
  )
}

export default Wrapper
