import React from 'react'
import { useSelector } from 'react-redux'
import { LikeHearthIcon } from 'src/assets/icons/like-hearth.icon'
import { useUserFavorite } from 'src/hooks/user/favorites'

import { PropertyCardContext } from '../property-card.context'
import { ButtonLike } from '../styled'

const LikeButton = () => {
  const { property, isLiked, isPartner } = React.useContext(PropertyCardContext)
  const { logged } = useSelector((state: any) => state.user)

  const { insertFavorite, deleteFavorite } = useUserFavorite({
    reload: false,
  })
  if (!logged) return null

  return (
    <ButtonLike
      buttonType="outline"
      buttonTheme={isPartner ? 'secondary' : 'primary'}
      active={isLiked}
      onClick={async (e) => {
        e.stopPropagation()
        if (!isLiked) {
          await insertFavorite({ aliasId: property?.aliasId })
        } else await deleteFavorite({ aliasId: property?.aliasId })
      }}
    >
      <LikeHearthIcon
        width="20"
        height="16"
        active={isLiked}
        fillColor="var(--primary-color)"
        stroke={isLiked ? 'var(--primary-color)' : 'var(--black-color)'}
      />
    </ButtonLike>
  )
}

export default LikeButton
