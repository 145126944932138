import { FooterItem } from 'src/components/footer/footer.interface'
import FacebookIcon from 'src/components/footer/icons/facebook.icon'
import InstagramIcon from 'src/components/footer/icons/instagram.icon'
import LinkedinIcon from 'src/components/footer/icons/linkedin.icon'

export function SocialNetworkConfig() {
  const socialNetworkItems: FooterItem[] = [
    {
      icon: <FacebookIcon />,
      path: 'https://www.facebook.com/housioficial/',
      isExternal: true,
    },
    {
      icon: <LinkedinIcon />,
      path: 'https://www.linkedin.com/company/housioficial/',
      isExternal: true,
    },
    {
      icon: <InstagramIcon />,
      path: 'https://www.instagram.com/housioficial/',
      isExternal: true,
    },
  ]

  return socialNetworkItems
}
