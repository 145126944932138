export const BuildingIcon = (props: any) => (
  <svg
    {...props}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7667 2.9C19.2667 2.3 18.4667 2 17.6667 2H7.66666C6.86666 2 6.06666 2.3 5.56666 2.9C4.96666 3.4 4.66666 4.2 4.66666 5V19C4.66666 19.8 4.96666 20.6 5.56666 21.1C6.06666 21.7 6.86666 22 7.66666 22H17.6667C18.4667 22 19.2667 21.7 19.7667 21.1C20.3667 20.5 20.6667 19.8 20.6667 19V5C20.6667 4.2 20.3667 3.4 19.7667 2.9ZM18.6667 19C18.6667 19.3 18.5667 19.5 18.3667 19.7C18.1667 19.9 17.9667 20 17.6667 20H7.66666C7.36666 20 7.16666 19.9 6.96666 19.7C6.76666 19.5 6.66666 19.3 6.66666 19V5C6.66666 4.7 6.76666 4.5 6.96666 4.3C7.16666 4.1 7.36666 4 7.66666 4H17.6667C17.9667 4 18.1667 4.1 18.3667 4.3C18.5667 4.5 18.6667 4.7 18.6667 5V19Z"
      fill="currentColor"
    />
    <path
      d="M14.1667 19.9999C14.1667 20.3999 13.9667 20.7999 13.7667 21.0999C13.4667 21.3999 13.0667 21.4999 12.6667 21.4999C12.3667 21.4999 12.0667 21.3999 11.8667 21.2999C11.6667 21.0999 11.4667 20.8999 11.2667 20.5999C11.1667 20.2999 11.1667 19.9999 11.1667 19.6999C11.2667 19.3999 11.3667 19.0999 11.5667 18.8999C11.7667 18.6999 12.0667 18.5999 12.3667 18.4999C12.6667 18.3999 12.9667 18.4999 13.2667 18.5999C13.5667 18.6999 13.7667 18.8999 13.9667 19.0999C14.0667 19.3999 14.1667 19.6999 14.1667 19.9999Z"
      fill="currentColor"
    />
    <path
      d="M12.0667 6.8999C12.0667 7.1999 11.9667 7.3999 11.7667 7.5999C11.5667 7.7999 11.2667 7.8999 11.0667 7.8999H9.06665C8.76665 7.8999 8.56665 7.7999 8.36665 7.5999C8.16665 7.4999 8.06665 7.1999 8.06665 6.8999C8.06665 6.5999 8.16665 6.3999 8.36665 6.1999C8.56665 5.9999 8.76665 5.8999 9.06665 5.8999H11.0667C11.3667 5.8999 11.5667 5.9999 11.7667 6.1999C11.9667 6.3999 12.0667 6.6999 12.0667 6.8999Z"
      fill="currentColor"
    />
    <path
      d="M17.2667 6.8999C17.2667 7.1999 17.1667 7.3999 16.9667 7.5999C16.7667 7.7999 16.4667 7.8999 16.2667 7.8999H14.2667C13.9667 7.8999 13.7667 7.7999 13.5667 7.5999C13.3667 7.4999 13.2667 7.1999 13.2667 6.8999C13.2667 6.5999 13.3667 6.3999 13.5667 6.1999C13.7667 5.9999 13.9667 5.8999 14.2667 5.8999H16.2667C16.5667 5.8999 16.7667 5.9999 16.9667 6.1999C17.1667 6.3999 17.2667 6.6999 17.2667 6.8999Z"
      fill="currentColor"
    />
    <path
      d="M12.0667 10.6001C12.0667 10.9001 11.9667 11.1001 11.7667 11.3001C11.5667 11.5001 11.2667 11.6001 11.0667 11.6001H9.06665C8.76665 11.6001 8.56665 11.5001 8.36665 11.3001C8.16665 11.1001 8.06665 10.9001 8.06665 10.6001C8.06665 10.3001 8.16665 10.1001 8.36665 9.9001C8.56665 9.7001 8.76665 9.6001 9.06665 9.6001H11.0667C11.3667 9.6001 11.5667 9.7001 11.7667 9.9001C11.9667 10.1001 12.0667 10.4001 12.0667 10.6001Z"
      fill="currentColor"
    />
    <path
      d="M17.2667 10.6001C17.2667 10.9001 17.1667 11.1001 16.9667 11.3001C16.7667 11.5001 16.4667 11.6001 16.2667 11.6001H14.2667C13.9667 11.6001 13.7667 11.5001 13.5667 11.3001C13.3667 11.1001 13.2667 10.9001 13.2667 10.6001C13.2667 10.3001 13.3667 10.1001 13.5667 9.9001C13.7667 9.7001 13.9667 9.6001 14.2667 9.6001H16.2667C16.5667 9.6001 16.7667 9.7001 16.9667 9.9001C17.1667 10.1001 17.2667 10.4001 17.2667 10.6001Z"
      fill="currentColor"
    />
    <path
      d="M12.0667 14.3C12.0667 14.6 11.9667 14.8 11.7667 15C11.5667 15.2 11.2667 15.3 11.0667 15.3H9.06665C8.76665 15.3 8.56665 15.2 8.36665 15C8.16665 14.8 8.06665 14.6 8.06665 14.3C8.06665 14 8.16665 13.8 8.36665 13.6C8.56665 13.4 8.76665 13.3 9.06665 13.3H11.0667C11.3667 13.3 11.5667 13.4 11.7667 13.6C11.9667 13.8 12.0667 14 12.0667 14.3Z"
      fill="currentColor"
    />
    <path
      d="M17.3667 14.3C17.3667 14.6 17.2667 14.8 17.0667 15C16.8667 15.2 16.5667 15.3 16.3667 15.3H14.3667C14.0667 15.3 13.8667 15.2 13.6667 15C13.4667 14.8 13.3667 14.6 13.3667 14.3C13.3667 14 13.4667 13.8 13.6667 13.6C13.8667 13.4 14.0667 13.3 14.3667 13.3H16.3667C16.6667 13.3 16.8667 13.4 17.0667 13.6C17.1667 13.8 17.3667 14 17.3667 14.3Z"
      fill="currentColor"
    />
  </svg>
)
