import React, { createContext, useContext, useEffect, useState } from 'react'

export const WindowContext = createContext({
  windowWidth: window.innerWidth,
  setWindowWidth: () => {},
  windowHeight: undefined,
  setWindowHeight: () => {},
})

export const WindowProvider = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  return (
    <WindowContext.Provider
      value={{ windowWidth, setWindowWidth, windowHeight, setWindowHeight }}
    >
      {children}
    </WindowContext.Provider>
  )
}

export const useWindowWidth = () => {
  const { windowWidth, setWindowWidth, setWindowHeight, windowHeight } =
    useContext(WindowContext)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(document.body.clientWidth)
      setWindowHeight(window.innerHeight)
    })
  }, [setWindowWidth])

  return { windowWidth, windowHeight }
}
