import React from 'react'
import * as ReactRouter from 'react-router'

export type PreviousType = { pathname?: string; search?: string; full?: string }

export const useNavigate = () => {
  const navigate = ReactRouter.useNavigate()

  const handleNavigate = (to: string) => {
    const path = window.location.pathname + window.location.search

    navigate(to, {
      state: {
        pathname: window.location.pathname,
        search: window.location.search,
        full: path,
      },
    })
  }

  return handleNavigate
}

export const useHistory = () => {
  const navigate = ReactRouter.useNavigate()
  const location = ReactRouter.useLocation()

  const handleBack = React.useCallback(() => {
    const path = window.location.pathname + window.location.search

    navigate(-1 as any, {
      state: {
        pathname: window.location.pathname,
        search: window.location.search,
        full: path,
      },
    })
  }, [location.state, window.location])

  return handleBack
}
