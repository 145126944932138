export const MonitorIcon = (props: any) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1667 17.5H5.83335C5.61234 17.5 5.40038 17.4122 5.2441 17.2559C5.08782 17.0996 5.00002 16.8877 5.00002 16.6667C5.00002 16.4457 5.08782 16.2337 5.2441 16.0774C5.40038 15.9211 5.61234 15.8333 5.83335 15.8333H9.16669V14.1667H4.16669C3.50365 14.1667 2.86776 13.9033 2.39892 13.4344C1.93008 12.9656 1.66669 12.3297 1.66669 11.6667V5C1.66669 4.33696 1.93008 3.70107 2.39892 3.23223C2.86776 2.76339 3.50365 2.5 4.16669 2.5H15.8334C16.4964 2.5 17.1323 2.76339 17.6011 3.23223C18.07 3.70107 18.3334 4.33696 18.3334 5V11.6667C18.3334 12.3297 18.07 12.9656 17.6011 13.4344C17.1323 13.9033 16.4964 14.1667 15.8334 14.1667H10.8334V15.8333H14.1667C14.3877 15.8333 14.5997 15.9211 14.7559 16.0774C14.9122 16.2337 15 16.4457 15 16.6667C15 16.8877 14.9122 17.0996 14.7559 17.2559C14.5997 17.4122 14.3877 17.5 14.1667 17.5ZM4.16669 4.16667C3.94567 4.16667 3.73371 4.25446 3.57743 4.41074C3.42115 4.56702 3.33335 4.77899 3.33335 5V11.6667C3.33335 11.8877 3.42115 12.0996 3.57743 12.2559C3.73371 12.4122 3.94567 12.5 4.16669 12.5H15.8334C16.0544 12.5 16.2663 12.4122 16.4226 12.2559C16.5789 12.0996 16.6667 11.8877 16.6667 11.6667V5C16.6667 4.77899 16.5789 4.56702 16.4226 4.41074C16.2663 4.25446 16.0544 4.16667 15.8334 4.16667H4.16669Z"
      fill="currentColor"
    />
  </svg>
)
