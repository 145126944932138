import React from 'react'
import { TFunction } from 'react-i18next'
import { Outlet, RouteObject, useLocation, useNavigate } from 'react-router-dom'
import { RequireSeo } from 'src/main/routes/custom'

import Layout from './components/layout'

const ForgotPassword = React.lazy(() => import('./forgot-password'))
const PasswordSuccess = React.lazy(() => import('./password-success'))
const SignIn = React.lazy(() => import('./sign-in'))
const SignUp = React.lazy(() => import('./sign-up'))

const Auth = () => {
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (location.pathname === '/auth') {
      return navigate('/auth/signin')
    }
  }, [location.pathname])

  return (
    <React.Suspense fallback="">
      <Layout>
        <Outlet />
      </Layout>
    </React.Suspense>
  )
}

const authRoutes = (t: TFunction): RouteObject => ({
  path: 'auth',
  element: <Auth />,
  children: [
    {
      path: 'signin',
      element: (
        <RequireSeo
          title={t('titles:auth.signin.SEO_TITLE')}
          description={t('titles:auth.signin.SEO_DESCRIPTION')}
        >
          <SignIn />
        </RequireSeo>
      ),
    },
    {
      path: 'signin/forgot_password',
      element: (
        <RequireSeo
          title={t('titles:auth.forgot_password.SEO_TITLE')}
          description={t('titles:auth.forgot_password.SEO_DESCRIPTION')}
        >
          <ForgotPassword />
        </RequireSeo>
      ),
    },
    {
      path: 'signin/forgot_password/success',
      element: (
        <RequireSeo
          title={t('titles:auth.forgot_password.SEO_TITLE')}
          description={t('titles:auth.forgot_password.SEO_DESCRIPTION')}
        >
          <PasswordSuccess />
        </RequireSeo>
      ),
    },
    {
      path: 'signup',
      element: (
        <RequireSeo
          title={t('titles:auth.signup.SEO_TITLE')}
          description={t('titles:auth.signup.SEO_DESCRIPTION')}
        >
          <SignUp />
        </RequireSeo>
      ),
    },
  ],
})

export default authRoutes
