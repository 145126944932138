export const MenuDrawerIcon = (props: any) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.632 12C2.46438 11.9996 2.30373 11.9329 2.18526 11.8143C2.0668 11.6957 2.00018 11.535 2 11.3673V11.3007C2 11.1329 2.06652 10.9719 2.18499 10.8531C2.30345 10.7342 2.4642 10.6672 2.632 10.6667H13.3653C13.5331 10.6672 13.6938 10.7341 13.8123 10.8528C13.9309 10.9715 13.9976 11.1323 13.998 11.3V11.3667C13.9976 11.5344 13.9309 11.6951 13.8123 11.8136C13.6937 11.9322 13.533 11.999 13.3653 11.9993L2.632 12ZM2.632 8.66667C2.46443 8.66614 2.30388 8.59929 2.18546 8.48074C2.06703 8.36219 2.00035 8.20157 2 8.034V7.96733C1.99982 7.79948 2.06629 7.63842 2.18479 7.51954C2.3033 7.40066 2.46415 7.33369 2.632 7.33333H13.3653C13.5334 7.33316 13.6946 7.39969 13.8136 7.51832C13.9326 7.63695 13.9996 7.79797 14 7.966V8.03267C13.9996 8.20041 13.9329 8.3612 13.8143 8.47988C13.6958 8.59856 13.5351 8.66547 13.3673 8.666L2.632 8.66667ZM2.632 5.33333C2.46438 5.33298 2.30373 5.26619 2.18526 5.1476C2.0668 5.02901 2.00018 4.86829 2 4.70067V4.634C1.99982 4.46615 2.06629 4.30508 2.18479 4.18621C2.3033 4.06733 2.46415 4.00035 2.632 4H13.3653C13.5334 3.99982 13.6946 4.06636 13.8136 4.18499C13.9326 4.30362 13.9996 4.46464 14 4.63267V4.69933C14 4.86725 13.9334 5.02831 13.8148 5.14717C13.6962 5.26603 13.5353 5.33298 13.3673 5.33333H2.632Z"
      fill="currentColor"
    />
  </svg>
)
