import { createApiRequest } from '../../store/api'

export const verifyCouponValid = async ({ params, onSuccess, onFail }) => {
  return createApiRequest({
    url: '/reservations/coupons',
    params,
    onSuccess,
    onFail,
  })
}
