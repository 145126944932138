import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getPropertyById } from '../../requests/apartments'

export const useApartmentDetail = ({ apartmentId }) => {
  const navigate = useNavigate()
  const [apartment, setApartment] = useState({ salesType: [] })
  const [blockedDates, setBlockedDates] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchApartment = useCallback(async () => {
    if (!apartmentId) return
    try {
      setLoading(true)

      const { data } = await getPropertyById({
        id: apartmentId,
      })

      setApartment(data)
      setBlockedDates(() => data.busyDates)
      return Promise.resolve(data)
    } catch (e) {
      if (e?.response?.status === 404) navigate('/404')
      return Promise.reject(e)
    } finally {
      setLoading(false)
    }
  }, [apartmentId])

  useEffect(() => {
    fetchApartment().then()
  }, [fetchApartment])

  return { apartment, detailLoading: loading, blockedDates }
}
