import * as c from '@chakra-ui/react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import HelpSection from 'src/components/navbar/desktop/items/help'
import ButtonLogout from 'src/components/navbar/desktop/items/menu-profile/components/button-logout'
import Language from 'src/components/navbar/desktop/items/menu-profile/components/language'
import MyProfile from 'src/components/navbar/desktop/items/menu-profile/content/my-profile'
import PersonasSection from 'src/components/navbar/mobile/items/components/not-logged-links/personas-section'
import { useAuth } from 'src/pages/auth/auth-context'

import Header from './header'

interface Props {
  onToggle: () => void
  onClose: () => void
  isOpen: boolean
}

const MenuDrawer = ({ onClose, isOpen }: Props) => {
  const { isAuth } = useAuth()
  const { pathname } = useLocation()

  useEffect(() => {
    onClose()
  }, [pathname])

  return (
    <c.Drawer
      variant="menu"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="full"
    >
      <c.DrawerOverlay zIndex={88} />
      <c.DrawerContent zIndex={88}>
        <c.Flex
          bg="black.0"
          direction="column"
          height="100%"
          overflowY="auto"
          pb="l10"
        >
          <Header />
          <c.Flex direction="column" pt="m7" px="s5" pb="m8" gap="s6">
            <c.Flex direction="column">
              <MyProfile isLogged={isAuth} />
              <Language />
              <ButtonLogout isLogged={isAuth} />
            </c.Flex>
            <HelpSection isLogged={isAuth} />
            <PersonasSection isLogged={isAuth} />
          </c.Flex>
        </c.Flex>
      </c.DrawerContent>
    </c.Drawer>
  )
}

export default MenuDrawer
