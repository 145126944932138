import { useCallback } from 'react'

import { TermsInterface } from './interfaces'

const googleAutocomplete = async (text: string) =>
  new Promise((resolve, reject) => {
    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input: text,
          componentRestrictions: { country: 'br' },
          language: 'pt',
        },
        resolve
      )
    } catch (e) {
      reject(e)
    }
  })

const formatAddress = (terms: Array<TermsInterface> = []) => {
  const size = terms.length

  if (size < 2) return `${terms[0].value}`

  if (size < 4) return `${terms[0].value} - ${terms[1].value}`

  return `${terms[0].value}, ${terms[1].value} - ${terms[2].value}`
}

export const useMapAutocomplete = () => {
  const getPlacesQuery = useCallback(async (text: string) => {
    const result = (await googleAutocomplete(
      text
    )) as google.maps.places.AutocompletePrediction[]

    return result.map((predict) => ({
      ...predict,
      address: formatAddress(predict.terms),
      placeId: predict.place_id,
    }))
  }, [])

  const getDescriptionAndLocation = useCallback(
    async (text) => {
      const result = await getPlacesQuery(text)

      return result
    },
    [getPlacesQuery]
  )

  return { getPlacesQuery, getDescriptionAndLocation }
}
