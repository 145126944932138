interface PinIconProps {
  color?: string
  width?: number
  height?: number
}

export const PinIcon = ({
  color = '#FA2F93',
  width = 16,
  height = 21,
}: PinIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 24"
      color={color}
    >
      <path
        d="M10.0004 0C4.69695 0 0.399902 4.31562 0.399902 9.63942C0.399902 14.6533 6.06214 20.8526 8.68969 23.4595C9.41606 24.1802 10.5847 24.1802 11.311 23.4595C13.9383 20.8525 19.5999 14.6532 19.5999 9.63942C19.5999 4.31562 15.3013 0 10.0004 0ZM10.0005 15.3815C6.89379 15.3815 4.38046 12.8309 4.42414 9.70215C4.46623 6.68684 6.91699 4.22659 9.91985 4.18397C13.0359 4.13974 15.5755 6.66263 15.5755 9.78246C15.5755 12.8744 13.0796 15.3815 10.0005 15.3815Z"
        fill={color}
      />
    </svg>
  )
}
