import React, { useCallback, useState } from 'react'
import { UserInterface } from 'src/interfaces/user/user.interface'
import { updateUserProfile } from 'src/requests/users'
import { getUserAvatar, updateUserAvatar } from 'src/requests/users/avatar'

export const useProfile = () => {
  const [data, setData] = React.useState({} as UserInterface)

  const [profileLoading, setProfileLoading] = useState(false)

  const [updateLoading, setUpdateLoading] = React.useState(false)

  const [fetchLoading, setFetchLoading] = React.useState(false)

  const updateProfile = async (values) => {
    setProfileLoading(true)
    try {
      const result = await updateUserProfile({ data: values })

      return setData((oldData) => {
        return { ...oldData, ...result.data }
      })
    } catch (e) {
      return Promise.reject(e)
    } finally {
      setProfileLoading(false)
    }
  }

  const fetchAvatar = async () => {
    setFetchLoading(true)
    try {
      const { data } = await getUserAvatar()

      return setData(data)
    } catch (e) {
      return Promise.reject(e)
    } finally {
      setFetchLoading(false)
    }
  }

  const updateAvatar = useCallback(
    async ({ value }) => {
      setUpdateLoading(true)
      try {
        const result = await updateUserAvatar({
          photo: value,
        })

        return setData((oldData) => ({ ...oldData, ...result.data }))
      } catch (e) {
        return Promise.reject(e)
      } finally {
        await fetchAvatar()
        setUpdateLoading(false)
      }
    },
    [fetchAvatar]
  )

  React.useEffect(() => {
    fetchAvatar().then()
  }, [])

  return {
    data,
    profileLoading,
    updateProfile,
    updateAvatar,
    updateLoading,
    fetchLoading,
  }
}
