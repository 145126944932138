const BracketRight = () => {
  return (
    <svg
      width="10"
      height="125"
      viewBox="0 0 10 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.34817 124.5H0.84817V124V122.924V122.424H1.34817H2.99851C5.43832 122.424 7.42359 120.438 7.42359 117.999V7.00171C7.42359 4.5619 5.43789 2.57663 2.99851 2.57663H1.34817H0.84817V2.07663V1.00022V0.500217H1.34817H2.99851C6.58413 0.500217 9.5 3.41609 9.5 7.00171V117.998C9.5 121.584 6.58362 124.5 2.99851 124.5H1.34817Z"
        fill="#FA2F93"
        stroke="#FA2F93"
      />
    </svg>
  )
}

export default BracketRight
