import qs from 'qs'
import React, { createContext, FC, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUserFavorite } from '../../../hooks/user/favorites'
import PropertyInterface from '../../../interfaces/property/property.interface'
import {
  ReservationType,
  ReservationTypes,
} from '../../../interfaces/reservation/reservation.type'

interface PropertyCardContextInterface {
  property: PropertyInterface
  linkParams: string
  clickRedirect: () => void
  infoOpened: boolean
  setInfoOpened: (state: boolean) => void
  isLiked: boolean
  isPartner: boolean
  hasSubscriptionPrice: boolean
  searchByType: ReservationType
  redirectLink: string
}

const PropertyCardContext = createContext<PropertyCardContextInterface>(
  {} as PropertyCardContextInterface
)

const PropertyCardProvider: FC<{
  property: PropertyInterface
  from?: string
  to?: string
  bookingType?: ReservationType
  redirectWithBlank?: boolean
}> = ({
  property,
  from,
  to,
  bookingType = ReservationTypes.SUBSCRIPTION,
  redirectWithBlank = true,
  children,
}) => {
  const [infoOpened, setInfoOpened] = useState<boolean>(false)

  const { favoritesIds } = useUserFavorite({ reload: false })
  const navigate = useNavigate()

  const isLiked = useMemo(
    () => favoritesIds.includes(property?.aliasId),
    [favoritesIds, property]
  )

  const isPartner = useMemo(
    () => property?.partnerId !== 'HOUSI',
    [property?.partnerId, property?.aliasId, property]
  )

  const partnerId = isPartner ? 'marketplace' : property?.partnerId

  const linkParams = useMemo(() => {
    if (from) {
      return qs.stringify({
        from,
        to,
        partnerId,
      })
    }
    return qs.stringify({
      partnerId,
    })
  }, [property?.aliasId, from, to])

  const redirectLink = property?.building?.aliasId
    ? `/building/${property?.building?.aliasId}/apartment/${property?.aliasId}?${linkParams}`
    : `/apartment/${property?.aliasId}?${linkParams}`

  const clickRedirect = useCallback(() => {
    if (!redirectWithBlank) {
      return navigate(redirectLink)
    }

    return window.open(redirectLink, '_blank')
  }, [linkParams, property?.aliasId, redirectWithBlank])

  const hasSubscriptionPrice = useMemo(
    () => property?.stayTypes?.some((s) => s.type === 'SUBSCRIPTION') || false,
    [property?.stayTypes]
  )

  const output = React.useMemo(
    () => ({
      property,
      isLiked,
      isPartner,
      infoOpened,
      linkParams,
      setInfoOpened,
      clickRedirect,
      hasSubscriptionPrice,
      searchByType: bookingType,
      redirectLink,
    }),
    [
      property,
      isLiked,
      isPartner,
      infoOpened,
      linkParams,
      setInfoOpened,
      clickRedirect,
      hasSubscriptionPrice,
      bookingType,
      redirectLink,
    ]
  )

  return (
    <PropertyCardContext.Provider value={output}>
      {children}
    </PropertyCardContext.Provider>
  )
}

export { PropertyCardContext, PropertyCardProvider }
