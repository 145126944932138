import * as c from '@chakra-ui/react'
import * as dateFns from 'date-fns'
import React from 'react'
import { ArrowIcon } from 'src/assets/icons/arrow.icon'

import { PickerContext } from '../picker-context'
import { picker_events } from '../picker-machine'

const styles = {
  minW: '25px',
  maxW: '25px',
  minH: '25px',
  maxH: '25px',
  p: 1,
  borderRadius: '8px',
  backgroundColor: 'black.0',
  transition: 'all 0.2s ease-in-out',
  _hover: {
    backgroundColor: 'black.100',
  },
}

const Actions = () => {
  const pickerState = React.useContext(PickerContext)

  const isSameMonth = dateFns.isSameMonth(pickerState.date(false), new Date())

  const handleNext = () => pickerState.send(picker_events.next)
  const handlePrev = () => pickerState.send(picker_events.prev)

  return (
    <c.Flex
      pos="absolute"
      zIndex={1}
      top="135px"
      px={5}
      minW="100%"
      maxW="100%"
      minH={pickerState.valid.month ? '36px' : '24px'}
      maxH={pickerState.valid.month ? '36px' : '24px'}
      align="center"
      justify="space-between"
    >
      {!isSameMonth ? (
        <c.Button onClick={handlePrev} {...styles}>
          <ArrowIcon width={8} height={12} color="gray" direction="left" />
        </c.Button>
      ) : (
        <div />
      )}
      <c.Button onClick={handleNext} {...styles}>
        <ArrowIcon width={8} height={12} color="gray" direction="right" />
      </c.Button>
    </c.Flex>
  )
}

export default Actions
