export const PinIcon = (props: any) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 18.3332C9.80088 18.333 9.60843 18.2614 9.4575 18.1316C8.31402 17.1235 7.25828 16.0201 6.30167 14.8332C5.50934 13.8674 4.82317 12.8191 4.255 11.7066C3.68915 10.6459 3.37386 9.4697 3.33333 8.26823C3.33333 6.50012 4.03571 4.80443 5.28595 3.55418C6.5362 2.30394 8.23189 1.60156 10 1.60156C11.7681 1.60156 13.4638 2.30394 14.714 3.55418C15.9643 4.80443 16.6667 6.50012 16.6667 8.26823C16.6258 9.46885 16.3105 10.6442 15.745 11.7041C15.177 12.8175 14.4908 13.8666 13.6983 14.8332C12.7418 16.0209 11.6861 17.1252 10.5425 18.1341C10.3912 18.263 10.1988 18.3337 10 18.3332ZM10 3.33323C8.68299 3.32613 7.41694 3.84171 6.47959 4.76688C5.54224 5.69205 5.01013 6.95124 5 8.26823C5 9.59406 5.66667 11.1791 6.97667 12.9791C7.88359 14.1959 8.89523 15.3311 10 16.3716C11.1049 15.3312 12.1166 14.196 13.0233 12.9791C14.3333 11.1791 15 9.59406 15 8.26823C14.9899 6.95124 14.4578 5.69205 13.5204 4.76688C12.5831 3.84171 11.317 3.32613 10 3.33323ZM10 10.8332C9.42314 10.8332 8.85923 10.6622 8.37959 10.3417C7.89994 10.0212 7.52611 9.56567 7.30535 9.03272C7.0846 8.49977 7.02684 7.91333 7.13938 7.34755C7.25192 6.78177 7.5297 6.26207 7.9376 5.85417C8.34551 5.44626 8.86521 5.16848 9.43099 5.05594C9.99676 4.9434 10.5832 5.00116 11.1162 5.22191C11.6491 5.44267 12.1046 5.81651 12.4251 6.29615C12.7456 6.77579 12.9167 7.3397 12.9167 7.91656C12.9167 8.69011 12.6094 9.43198 12.0624 9.97896C11.5154 10.5259 10.7735 10.8332 10 10.8332ZM10 6.66656C9.75277 6.66656 9.5111 6.73987 9.30554 6.87723C9.09998 7.01458 8.93976 7.2098 8.84515 7.43821C8.75054 7.66662 8.72579 7.91795 8.77402 8.16043C8.82225 8.4029 8.9413 8.62563 9.11612 8.80045C9.29093 8.97526 9.51366 9.09431 9.75614 9.14254C9.99861 9.19078 10.2499 9.16602 10.4784 9.07141C10.7068 8.9768 10.902 8.81659 11.0393 8.61103C11.1767 8.40546 11.25 8.16379 11.25 7.91656C11.25 7.58504 11.1183 7.2671 10.8839 7.03268C10.6495 6.79826 10.3315 6.66656 10 6.66656Z"
      fill="currentColor"
    />
  </svg>
)
