import React, { useCallback } from 'react'
import { UserInterface } from 'src/interfaces/user/user.interface'
import { updateUserPassword } from 'src/requests/users'

export const usePassword = () => {
  const [passwordLoading, setPasswordLoading] = React.useState(false)
  const [data, setData] = React.useState({} as UserInterface)

  const updatePassword = useCallback(async (values) => {
    setPasswordLoading(true)
    try {
      const result = await updateUserPassword({ data: values })
      return setData((oldData) => ({ ...oldData, ...result.data }))
    } catch (e) {
      return Promise.reject(e)
    } finally {
      setPasswordLoading(false)
    }
  }, [])

  return { updatePassword, passwordLoading, data }
}
