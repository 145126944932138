import React from 'react'

export const LikeHearthIcon = ({
  active = false,
  stroke = '#FA2F93',
  fillColor = '#FA2F93',
  width = '18px',
  height = '15px',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2616 1.96368L10.2632 1.96205C10.9861 1.23909 11.9673 0.833496 12.9846 0.833496C14.0108 0.833496 14.9918 1.23945 15.7144 1.96205C16.437 2.68465 16.843 3.66568 16.843 4.69183C16.843 5.70837 16.4379 6.69038 15.7133 7.42268C15.7131 7.42293 15.7129 7.42317 15.7126 7.42341L9.23942 13.9049C9.23937 13.905 9.23933 13.905 9.23928 13.9051C9.21741 13.9269 9.18483 13.95 9.13994 13.9694C9.07581 13.9934 9.03147 14.0002 9.0013 14.0002C8.96882 14.0002 8.9258 13.9923 8.87694 13.972C8.83013 13.9525 8.80501 13.9328 8.78962 13.9157L8.78093 13.906L8.77175 13.8968L2.29675 7.4135L2.29652 7.41328C1.57393 6.69068 1.16797 5.70965 1.16797 4.6835C1.16797 3.66746 1.57266 2.68592 2.29652 1.95373C3.0199 1.23868 4.00124 0.833496 5.01797 0.833496C6.04359 0.833496 7.02486 1.23909 7.74789 1.95386L8.64612 2.86042L8.99804 3.2156L9.35322 2.86367L10.2616 1.96368Z"
      fill={active ? fillColor : 'transparent'}
      stroke={stroke}
    />
  </svg>
)
