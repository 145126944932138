import propTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { LoadingSvg } from '../../assets/images/loading'
import { patternBorderRadius } from '../../assets/styles/common-css'
import LabelButton from './label-button'
import { outlineStyles } from './outline-button'
import { solidStyles } from './solid-button'
import {
  actionButton,
  block,
  blockTheme,
  btnLarge,
  btnSmall,
  customPadding,
  getColor,
  rounded,
} from './themes'

const Btn = styled.button.attrs((props) => ({
  type: props.type || 'button',
}))`
  ${patternBorderRadius};
  ${customPadding};
  ${(props) => props.sm && btnSmall};
  ${(props) => props.lg && btnLarge};
  ${(props) => props.rounded && rounded};
  ${block};

  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  min-height: 45px;
  font-weight: 500;
  width: auto;
  touch-action: none;
  transition: all 0.2s;
  user-select: none;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;

  :focus {
    outline: 0;
  }

  ${(p) => getColor(p, p.theme.colors)};
  ${(p) => p.actionButton && actionButton};
`

const CustomBtn = styled.button.attrs((p) => ({ type: p.type || 'button' }))`
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  width: auto;
  transition: all 0.2s;
  user-select: none;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(p) => (p.rounded ? 60 : 4)}px;
  padding: ${(p) => (p.small ? '4px 12px' : '12px 20px')};
  border-width: 1px;
  border-style: solid;

  ${blockTheme};

  :focus {
    outline: 0;
  }

  :disabled {
    cursor: not-allowed;
  }

  svg,
  path {
    transition: all 0.2s;
    margin: 0 4px;
  }

  ${(p) => p.buttonType === 'solid' && solidStyles[p.buttonTheme]}
  ${(p) => p.buttonType === 'outline' && outlineStyles[p.buttonTheme]}
`

export const Button = ({ isLoading = false, children, ...props }) => {
  return (
    <Btn {...props}>
      {children}
      {isLoading && <LoadingSvg />}
    </Btn>
  )
}

export const CustomButton = ({
  isLoading = false,
  buttonType = 'solid',
  buttonTheme = 'primary',
  applyToIcon = true,
  small = false,
  children,
  ...props
}) => {
  return (
    <CustomBtn
      small={small}
      buttonType={buttonType}
      buttonTheme={buttonTheme}
      applyToIcon={applyToIcon}
      {...props}
    >
      {children}
      {isLoading && <LoadingSvg />}
    </CustomBtn>
  )
}

Button.propTypes = {
  type: propTypes.oneOf(['button', 'submit']),
  isLoading: propTypes.bool,
  sm: propTypes.bool,
  block: propTypes.bool,
  blockMd: propTypes.bool,
  actionButton: propTypes.bool,
  rounded: propTypes.bool,
  white: propTypes.bool,
  success: propTypes.bool,
  danger: propTypes.bool,
  info: propTypes.bool,
  secondary: propTypes.bool,
  primary: propTypes.bool,
  textBlack: propTypes.bool,
  textSuccess: propTypes.bool,
  textDanger: propTypes.bool,
  textInfo: propTypes.bool,
  textSecondary: propTypes.bool,
  textPrimary: propTypes.bool,
  textWhite: propTypes.bool,
}

CustomButton.propTypes = {
  type: propTypes.oneOf(['button', 'submit']),
  isLoading: propTypes.bool,
  block: propTypes.bool,
  blockMd: propTypes.bool,
  buttonType: propTypes.oneOf(['solid', 'outline']),
  buttonTheme: propTypes.oneOf(['primary', 'secondary', 'black', 'danger']),
  onClick: propTypes.func,
  disabled: propTypes.bool,
  rounded: propTypes.bool,
  applyToIcon: propTypes.bool,
}

export { LabelButton }
