import { Box, Flex, Text } from '@chakra-ui/react'
import Dinero from 'dinero.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CONDO_REGEX, IPTU_REGEX } from 'src/constants'
import { convertToMoney } from 'src/utils/monetary'

import { PropertyCardContext } from '../../../../property-card.context'
import HousiPackModal from '../pack-modal'

const Subscription = () => {
  const { t } = useTranslation('card-apartment')

  const { property } = React.useContext(PropertyCardContext)

  const iptu = property?.price?.monthly?.fees.find(
    (fee: any) => IPTU_REGEX.test(fee.name) || IPTU_REGEX.test(fee.name.pt_BR)
  )

  const condo = property?.price?.monthly?.fees.find(
    (fee: any) => CONDO_REGEX.test(fee.name) || CONDO_REGEX.test(fee.name.pt_BR)
  )

  const condoPlusIptu = property?.price?.monthly?.fees.reduce(
    (acc, current) => {
      const isCondo =
        CONDO_REGEX.test(current.name as any) ||
        CONDO_REGEX.test((current.name as any).pt_BR)

      const isIptu =
        IPTU_REGEX.test(current.name as any) ||
        IPTU_REGEX.test((current.name as any).pt_BR)

      if (!isCondo && !isIptu) return acc

      return {
        name: 'Condo+Iptu',
        amount: Dinero(current.amount).add(Dinero(acc.amount)).toObject(),
      }
    },
    { name: 'Condo+Iptu', amount: { amount: 0, currency: 'BRL', precision: 2 } }
  )

  const packHousi = Dinero(property?.price?.monthly?.grossAmount)
    .subtract(Dinero(property?.price?.monthly?.netAmount))
    .subtract(Dinero(condoPlusIptu?.amount))
    .toObject()

  return (
    <>
      <Flex justifyContent="space-between">
        <Text fontSize="14px">{t('card-apartment:RENT')}</Text>
        <Text fontSize="14px">
          {convertToMoney(property?.price?.monthly?.netAmount)}
        </Text>
      </Flex>
      <Flex justifyContent="space-between">
        <Text fontSize="14px">{t('card-apartment:PACKAGE_ITEMS.0')}</Text>
        <Text fontSize="14px">{convertToMoney(condo?.amount)}</Text>
      </Flex>
      <Flex justifyContent="space-between">
        <Text fontSize="14px">{t('card-apartment:PACKAGE_ITEMS.1')}</Text>
        <Text fontSize="14px">{convertToMoney(iptu?.amount)}</Text>
      </Flex>
      <Flex justifyContent="space-between">
        <Flex alignItems="baseline" gap="5px">
          <Text fontSize="14px">{t('card-apartment:PACKAGE_TITLE')}</Text>
          <HousiPackModal
            rent={property?.price?.monthly?.netAmount}
            condo={condo?.amount}
            iptu={iptu?.amount}
            housiPack={packHousi}
            total={property?.price?.monthly?.grossAmount}
          />
        </Flex>
        <Text fontSize="14px">{convertToMoney(packHousi)}</Text>
      </Flex>
      <Box
        width="100%"
        height="1px"
        margin="4px 0px"
        backgroundColor="black.200"
      />
      <Flex justifyContent="space-between">
        <Text fontSize="14px" fontWeight="bold">
          {t('card-apartment:TOTAL_RENT')}
        </Text>
        <Text fontSize="14px" fontWeight="bold">
          {convertToMoney(property?.price?.monthly?.grossAmount)}
          {t('card-apartment:MONTH')}
        </Text>
      </Flex>
    </>
  )
}

export default Subscription
