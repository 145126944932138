import React from 'react'

const PetIcon = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0953 9.28147C12.2272 7.941 10.759 7.13672 9.15046 7.13672C7.55466 7.13672 6.07376 7.941 5.21841 9.29424L3.13749 12.5241C2.7928 13.0603 2.62683 13.6859 2.6779 14.3242C2.7162 14.9625 2.95876 15.5625 3.38005 16.0477C3.80134 16.5328 4.3503 16.8647 4.97585 17.0051C5.6014 17.1456 6.23972 17.0817 6.82697 16.8264L6.86527 16.8136C8.34617 16.1753 10.0313 16.1881 11.4995 16.8264C11.8825 16.9924 12.2782 17.0817 12.6867 17.0817C12.9038 17.0817 13.1336 17.0562 13.3506 17.0051C13.9762 16.8647 14.5251 16.5328 14.9464 16.0477C15.3677 15.5625 15.6102 14.9625 15.6485 14.3242C15.6868 13.6859 15.5337 13.0603 15.189 12.5241L13.0953 9.28147ZM14.0783 15.32C13.5549 15.9328 12.6867 16.137 11.9463 15.8051C11.0654 15.4093 10.1079 15.2178 9.16322 15.2178C8.21851 15.2178 7.26103 15.4093 6.38015 15.8051L6.35462 15.8179C5.6014 16.1243 4.75882 15.9328 4.2354 15.32C3.71198 14.7072 3.64814 13.8263 4.0822 13.1369L6.16312 9.89426C6.81421 8.87295 7.93765 8.26016 9.15046 8.26016C10.3633 8.26016 11.4867 8.87295 12.1378 9.89426L14.2187 13.1369C14.6655 13.8263 14.6017 14.6944 14.0783 15.32Z"
        fill="#A3A3A3"
      />
      <path
        d="M3.44359 9.77864C4.05638 9.53608 4.52873 9.03819 4.7713 8.37434C5.00109 7.73602 4.97556 7.0211 4.72023 6.35725C4.4649 5.69339 3.99255 5.14444 3.40529 4.82528C2.77974 4.48059 2.09035 4.41676 1.47757 4.65932C0.239228 5.13167 -0.335259 6.67641 0.200929 8.08071C0.634986 9.20415 1.64353 9.91907 2.66484 9.91907C2.93294 9.91907 3.18826 9.868 3.44359 9.77864ZM1.24777 7.68495C0.928613 6.84237 1.20947 5.96149 1.87333 5.70616C1.98822 5.65509 2.11589 5.64233 2.24355 5.64233C2.44781 5.64233 2.65208 5.69339 2.84357 5.80829C3.20103 5.99979 3.48189 6.34448 3.64785 6.753C3.82658 7.1743 3.83935 7.60835 3.69892 7.99135C3.57126 8.3488 3.34146 8.60413 3.03507 8.71903C2.37122 8.97436 1.56693 8.51477 1.24777 7.68495Z"
        fill="#A3A3A3"
      />
      <path
        d="M7.2485 6.42149C8.80599 6.42149 10.0699 4.97889 10.0699 3.20436C10.0699 1.4426 8.80599 0 7.2485 0C5.691 0 4.42712 1.4426 4.42712 3.21713C4.42712 4.97889 5.691 6.42149 7.2485 6.42149ZM7.2485 1.12344C8.18044 1.12344 8.94643 2.05539 8.94643 3.21713C8.94643 4.3661 8.18044 5.29805 7.2485 5.29805C6.31655 5.29805 5.55057 4.3661 5.55057 3.21713C5.55057 2.05539 6.31655 1.12344 7.2485 1.12344Z"
        fill="#A3A3A3"
      />
      <path
        d="M12.6094 7.30248C12.852 7.37907 13.0946 7.41737 13.3371 7.41737C14.4733 7.41737 15.5712 6.58756 15.9925 5.32369C16.2351 4.596 16.2223 3.81725 15.9415 3.1534C15.6606 2.45125 15.1244 1.94059 14.4606 1.7108C13.784 1.481 13.0563 1.58313 12.418 1.97889C11.7924 2.36188 11.32 2.9619 11.0775 3.70235C10.5668 5.23432 11.2562 6.85565 12.6094 7.30248ZM12.1499 4.04705C12.3031 3.57469 12.6094 3.17893 13.0052 2.93637C13.3627 2.71934 13.7584 2.65551 14.1159 2.78318C14.4733 2.89807 14.7542 3.17893 14.9074 3.57469C15.0734 3.99598 15.0861 4.49387 14.9329 4.96623C14.6138 5.92371 13.7329 6.48543 12.9669 6.2301C12.2009 5.98754 11.8307 5.00453 12.1499 4.04705Z"
        fill="#A3A3A3"
      />
      <path
        d="M19.2099 7.14927C18.1376 6.35775 16.529 6.70245 15.6353 7.91525C14.7417 9.12806 14.8821 10.7622 15.9417 11.5537C16.3375 11.8473 16.7971 11.975 17.2694 11.975C18.0865 11.975 18.9418 11.5537 19.5163 10.7877C20.41 9.57488 20.2695 7.94079 19.2099 7.14927ZM18.6099 10.1238C18.0737 10.8388 17.1801 11.0813 16.6056 10.66C16.0311 10.2387 15.9928 9.30679 16.529 8.59187C16.8865 8.11951 17.3971 7.85142 17.8695 7.85142C18.112 7.85142 18.3418 7.91525 18.5333 8.05568C19.1078 8.47697 19.1461 9.40892 18.6099 10.1238Z"
        fill="#A3A3A3"
      />
    </svg>
  )
}

export default PetIcon
