import { TFunction } from 'i18next'
import React, { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

import { RequireSeo } from './custom'
import * as Components from './route-components'

const apartmentsRoutes = (t: TFunction): RouteObject[] => [
  {
    path: 'apartment/:propertyId',
    element: (
      <RequireSeo>
        <Suspense fallback="">
          <Components.PropertyDetailComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: 'apartment/payment/:id',
    element: (
      <RequireSeo
        title={t('titles:apartments-payment-update.SEO_TITLE')}
        description={t('titles:apartments-payment-update.SEO_DESCRIPTION')}
      >
        <Suspense fallback="loading...">
          <Components.PayComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: 'building/:buildingId/apartment/:propertyId',
    element: (
      <RequireSeo>
        <Suspense fallback="">
          <Components.PropertyDetailComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
]

export default apartmentsRoutes
