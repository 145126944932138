import * as c from '@chakra-ui/react'
import { NavbarConfigItem } from 'src/components/navbar/desktop/items/landing-pages/navbar-item.config'

import Button from './button'

interface SectionInterface {
  item: NavbarConfigItem
}

const Section = ({ item }: SectionInterface) => {
  return (
    <c.Box>
      <c.Text fontSize="t-sm.0" fontWeight="bold" color="black.300" mb="s4">
        {item.title}
      </c.Text>

      {item?.options.map((subItem, i) => (
        <Button
          key={subItem.id}
          label={subItem.label}
          path={subItem.path}
          Icon={subItem.Icon}
          lastDivider={i === item.options.length - 1}
        />
      ))}
    </c.Box>
  )
}

export default Section
