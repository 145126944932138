import { Button } from '@chakra-ui/react'
import { Box, Flex, Grid, retriever } from '@retriever-ui/react'
import { TFunction } from 'i18next'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { notify } from 'src/components/alert/toast'
import useToRedirect from 'src/hooks/use-to-redirect'
import {
  FieldText,
  FieldTextPassword,
} from 'src/pages/profile-v2/components/field-text'
import ArrowLeft from 'src/pages/profile-v2/components/icons/arrow-left.icon'
import { validate } from 'src/utils/validate'
import * as yup from 'yup'

import { useAuth } from '../auth-context'
import GoogleAndFacebookButtons from '../components/google-facebook-login'
import { HousiLogoIcon } from '../icons/housi-logo.icon'

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('formMessages:EMAIL'))
      .required(t('formMessages:REQUIRED')),
    password: yup
      .string()
      .required(t('formMessages:REQUIRED'))
      .min(6, t('formMessages:SIZE_MINIMUM', { number: 6 })),
  })

export const SignInForm = ({ onFinish }: { onFinish?: () => void }) => {
  const { redirect, redirect_to } = useToRedirect()
  const { t } = useTranslation(['profile', 'login', 'formMessages'])

  const { handleLogin } = useAuth()

  const initialValue = {
    email: '',
    password: '',
  }

  const onSubmit = async (values: typeof initialValue) => {
    try {
      await handleLogin(values)

      notify({ text: 'Conectado com sucesso!' }).success()

      if (onFinish) {
        return onFinish()
      }
      return redirect()
    } catch (e) {
      notify({ text: t('login:LOGIN_ERROR') }).error()
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValue}
      validate={validate(validationSchema(t))}
      render={({ handleSubmit, submitting, valid }) => (
        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Flex direction="column" width="100%" gap="12px" alignItems="end">
            <FieldText
              name="email"
              label="Email"
              placeholder="Email"
              type="email"
            />
            <FieldTextPassword
              name="password"
              placeholder="******"
              label={t('login:PASSWORD')}
              type="password"
            />
            <Link
              to={`/auth/signin/forgot_password?=redirect_to=${redirect_to}`}
              style={{ textDecoration: 'none' }}
            >
              <retriever.p
                color="primary.500"
                fontWeight="bold"
                fontSize="14px"
                cursor="pointer"
                mBottom="38px"
              >
                {t('login:FORGOT')}
              </retriever.p>
            </Link>
            <Button
              colorScheme="primary"
              bg="primary.500"
              color="black.0"
              minH="48px"
              disabled={submitting || !valid}
              isLoading={submitting}
              type="submit"
              isFullWidth
            >
              {t('login:ENTER')}
            </Button>
          </Flex>
        </form>
      )}
    />
  )
}

const SignIn = () => {
  const { t } = useTranslation(['login', 'formMessages'])
  const { redirect_to, redirect } = useToRedirect()
  const navigate = useNavigate()

  return (
    <Box w="100%">
      <Flex alignItems="center" direction="column" gap="24px">
        <Box as="span" display={['none', 'block']}>
          <HousiLogoIcon />
        </Box>
        <Grid
          alignItems="center"
          justifyItems="center"
          gridTemplateColumns="40px 1fr 40px"
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            maxW={40}
            h={40}
            role="button"
            cursor="pointer"
            onClick={redirect}
          >
            <ArrowLeft />
          </Flex>
          <retriever.p
            m="0"
            fontSize="20px"
            color="black.500"
            fontWeight="bold"
          >
            {t('login:ENTER')}
          </retriever.p>
        </Grid>

        <Box height="1px" width="100%" backgroundColor="black.200" />
        <retriever.p
          alignSelf="flex-start"
          color="black.500"
          style={{ fontWeight: 500 }}
        >
          {t('login:INSERT_DATA')}
        </retriever.p>
        <SignInForm />
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          gap="24px"
        >
          <retriever.p
            color="primary.500"
            fontWeight="bold"
            fontSize="14px"
            cursor="pointer"
            mTop="8px"
            mBottom="16px"
            onClick={() => navigate(`/auth/signup?redirect_to=${redirect_to}`)}
          >
            {t('login:REGISTER')}
          </retriever.p>

          <GoogleAndFacebookButtons />
        </Flex>
      </Flex>
    </Box>
  )
}

export default SignIn
