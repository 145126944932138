import useMessages from 'src/hooks/messages/use-messages'

import { useSearch } from '../search-v2/search-context'
import PrimaryFooter from './components/primary-footer'
import SecondaryFooter from './components/secondary-footer'

const Footer = () => {
  const { value } = useMessages('footer')
  const { valid } = useSearch()

  // loadmore function on search page will emit a true value to dont render this component.
  if (value?.data) return null

  if (valid.open) return null

  return (
    <>
      <PrimaryFooter />
      <SecondaryFooter />
    </>
  )
}

export default Footer
