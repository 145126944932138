import qs from 'qs'
import { useLocation } from 'react-router-dom'

type ParamsType = {
  [key: string]: string
}

const useToRedirect = () => {
  const location = useLocation()

  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as ParamsType

  const redirect_to = params.redirect_to ?? '/'

  const redirect = () => (window.location.href = redirect_to)

  return {
    redirect,
    redirect_to,
  }
}

export default useToRedirect
