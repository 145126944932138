interface EllipseIconProps {
  fill: string
}

export const EllipseIcon = ({ fill }: EllipseIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="6"
    viewBox="0 0 6 6"
    fill="none"
  >
    <circle cx="3" cy="3" r="3" fill={fill} />
  </svg>
)
