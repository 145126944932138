import { TFunction } from 'i18next'
import { RouteObject } from 'react-router-dom'
import ApartmentPreview from 'src/components/cards/property/search/apartment-preview'
import SearchMap from 'src/pages/search'
import SearchMobile from 'src/pages/search-mobile'

import { RequireSeo } from './custom'

const searchRoutes = (t: TFunction): RouteObject[] => [
  {
    path: '/search',
    element: (
      <RequireSeo
        title={t('titles:search.SEO_TITLE')}
        description={t('titles:search.SEO_DESCRIPTION')}
      >
        <SearchMobile />
      </RequireSeo>
    ),
  },
  {
    path: '/search/properties',
    element: (
      <RequireSeo
        title={t('titles:properties-search.SEO_TITLE')}
        description={t('titles:properties-search.SEO_DESCRIPTION')}
      >
        <SearchMap />
      </RequireSeo>
    ),
    children: [
      {
        path: 'preview/:aliasId',
        element: <ApartmentPreview />,
      },
    ],
  },
]

export default searchRoutes
