export const dataLayerReport = (report) => {
  try {
    window.dataLayerReports = window.dataLayerReports || []
    window.dataLayerReports.push(report)
    return true
  } catch (exception) {
    window.console.error(`Error logging dataLayer reports: ${exception}`)
  }
  return false
}

dataLayerReport('This structure stores the data layer related occurrences')
