import * as c from '@chakra-ui/react'
import React from 'react'
import { CloseIcon } from 'src/assets/icons/close.icon'
import SearchIcon from 'src/components/navbar/icons/search.icon'

import { truncateString } from '../../../autocomplete/options/item'
import { SearchContext } from '../../../search-context'
import { search_events } from '../../../search-machine'
import { StepsContext } from '../../../steps/steps-context'

const Tag = () => {
  const { context, send } = React.useContext(SearchContext)
  const { prev } = React.useContext(StepsContext)

  const onClear = () => {
    send(search_events.set_coords, {
      coords: undefined,
      searchTerm: { value: '', label: '' },
    })
    prev()
  }

  return (
    <c.Flex
      minH="45px"
      minW="100%"
      px={4}
      py={2}
      borderRadius="8px"
      border="1px solid #A3A3A3"
      justify="space-between"
      alignSelf="center"
      align="center"
      gap={3}
    >
      <c.Flex align="center" gap={2}>
        <SearchIcon width="15px" height="15px" color="#A3A3A3" />

        <c.Text color="black.500" fontSize={14} fontWeight={500} noOfLines={1}>
          {truncateString(context.searchTerm.label, 32)}
        </c.Text>
      </c.Flex>

      <c.Box
        as={c.Button}
        onClick={onClear}
        p={0}
        minW="12px"
        maxW="12px"
        maxH="20px"
        bg="transparent"
        border="none"
      >
        <CloseIcon color="gray" height={10} width={10} />
      </c.Box>
    </c.Flex>
  )
}

export default Tag
