import { extendTheme } from '@chakra-ui/react'
import { lightTheme as retrieverTheme } from '@retriever-ui/react'

import { Button } from './components/button'
import { Container } from './components/container'
import { Drawer } from './components/drawer'
import { Heading } from './components/heading'
import { Link } from './components/link'
import { Menu } from './components/menu'
import { Modal } from './components/modal'
import { Radio } from './components/radio'
import { Tabs } from './components/tabs'
import { Text } from './components/text'
import { Tooltip } from './components/tooltip'
import { foundations } from './foundations'
import { Tag } from './tag'

const theme = extendTheme({
  ...foundations,
  components: {
    Button,
    Container,
    Drawer,
    Heading,
    Modal,
    Menu,
    Radio,
    Text,
    Tabs,
    Tag,
    Tooltip,
    Link,
  },
  styles: {
    global: {
      body: {
        background: 'black.100',
      },
      '.gm-ui-hover-effect': {
        display: 'none',
        visibility: 'hidden',
      },
      '.scrollbar-hidden': {
        '::-webkit-scrollbar': {
          maxWidth: '5px',
          minWidth: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#e2e2e2',
          borderRadius: '8px',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#c4c4c4',
          borderRadius: '8px',
        },
      },
    },
  },

  // retriever suport
  font: retrieverTheme.font,
  breakpoints: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  },
  layers: {
    base: 10,
    header: 15,
    menu: 25,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
  config: {
    cssVarPrefix: 'housi',
  },
  breakpoint(breakpoint: string) {
    const size = (this as any).breakpoints[breakpoint]

    return `@media screen and (min-width: ${size})`
  },
})

export default theme
