import * as c from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CalendarIcon } from 'src/assets/icons/calendar.icon'
import { CloseIcon } from 'src/assets/icons/close.icon'
import dateUtils from 'src/utils/date'

import { SearchContext } from '../../search-context'
import { search_events } from '../../search-machine'
import { FieldsContext } from './fields-context'

export const FieldStart = ({ onClick }) => {
  const { t } = useTranslation('components/search')
  const { context, send } = React.useContext(SearchContext)
  const { valid } = React.useContext(FieldsContext)

  const value = context.start
    ? dateUtils.format(context.start, 'dd/MM/yyyy')
    : t('components/search:dates.placeholder')

  return (
    <c.Box minW={['50%', '190px']} maxW={['50%', '190px']}>
      <c.Text
        color="black.500"
        fontSize={14}
        fontWeight={valid.start ? 700 : 500}
      >
        {t('components/search:dates.start')}
      </c.Text>

      <c.Flex
        minH="48px"
        maxH="48px"
        borderTopLeftRadius="8px"
        borderBottomLeftRadius="8px"
        borderStyle="solid"
        borderWidth="1px"
        borderColor="#474747"
        cursor="pointer"
        align="center"
        overflow="hidden"
        px={3}
        mt="7px"
        minW={['100%', '190px']}
        background="black.0"
        onClick={onClick}
      >
        <c.Text color="black.500" fontSize={16} fontWeight={500} w="100%">
          {value}
        </c.Text>
        {context.start ? (
          <c.Box
            as="span"
            onClick={() => {
              send(search_events.set_date_start, { date: undefined })
              send(search_events.set_date_end, { date: undefined })
            }}
          >
            <CloseIcon height={12} />
          </c.Box>
        ) : (
          <CalendarIcon />
        )}
      </c.Flex>
    </c.Box>
  )
}
export const FieldEnd = ({ onClick }) => {
  const { t } = useTranslation(['components/search', 'property-detail/booking'])
  const { context, send } = React.useContext(SearchContext)
  const { valid } = React.useContext(FieldsContext)

  const value = context.end
    ? dateUtils.format(context.end, 'dd/MM/yyyy')
    : t('property-detail/booking:DATE_EXIT')

  const weight = context.start && context.end ? 500 : 700

  return (
    <c.Box minW={['50%', '190px']} maxW={['50%', '190px']}>
      <c.Text
        color={context.start ? 'black.500' : 'black.300'}
        fontSize={14}
        fontWeight={valid.end ? weight : 500}
      >
        {t('components/search:dates.end')}
      </c.Text>

      <c.Flex
        background={context.start ? 'black.0' : '#f5f5f5'}
        borderColor={context.start ? '#474747' : '#e0e0e0'}
        minH="48px"
        maxH="48px"
        borderTopRightRadius="8px"
        borderBottomRightRadius="8px"
        borderStyle="solid"
        borderWidth="1px"
        cursor="pointer"
        align="center"
        overflow="hidden"
        px={3}
        mt="7px"
        minW={['100%', '190px']}
        onClick={onClick}
      >
        <c.Text
          color={context.start ? 'black.500' : 'black.300'}
          fontSize={16}
          fontWeight={500}
          w="100%"
        >
          {value}
        </c.Text>
        {context.end ? (
          <c.Box
            as="span"
            onClick={() => {
              send(search_events.set_date_end, { date: undefined })
            }}
          >
            <CloseIcon width={12} />
          </c.Box>
        ) : (
          <CalendarIcon color={context.start ? '#FA2F93' : '#a3a3a3'} />
        )}
      </c.Flex>
    </c.Box>
  )
}

//
