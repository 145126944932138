import { useMachine } from '@xstate/react'
import React from 'react'
import { EventData } from 'xstate'

import pickerMachine, {
  ContextType,
  picker_events,
  picker_states,
} from './picker-machine'

type SendEvent = (event: picker_events, payload?: EventData | undefined) => void

type Valid = {
  [key in picker_states]: boolean
}

interface PickerContextProps {
  states: picker_states
  send: SendEvent
  context: ContextType
  valid: Valid
  date: (isNext: boolean) => Date
}

interface PickerProviderProps {
  children?: React.ReactNode
}

export const PickerContext = React.createContext({} as PickerContextProps)

const PickerProvider = ({ children }: PickerProviderProps) => {
  const [current, send] = useMachine(pickerMachine)

  const states = current.value as picker_states
  const context = current.context

  const valid: Valid = {
    dates: current.matches(picker_states.dates),
    month: current.matches(picker_states.month),
    closed: current.matches(picker_states.closed),
  }

  const date = (isNext: boolean) => (isNext ? context.next : context.current)

  return (
    <PickerContext.Provider value={{ states, send, context, valid, date }}>
      {children}
    </PickerContext.Provider>
  )
}

export default PickerProvider
