import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { convertToMoney } from 'src/utils/monetary'
import { __, match } from 'ts-pattern'

import { PropertyCardContext } from '../../property-card.context'
import PriceNight from './night'
import PriceRent from './rent'

const PriceUnavailable = () => {
  return (
    <Flex gap="8px">
      <Text
        m="0"
        color="black.300"
        textDecoration="line-through"
        fontSize={14}
        fontWeight="bold"
      >
        {convertToMoney({ amount: 0, currency: 'BRL', precision: 2 })}
      </Text>
      <Text m="0" fontSize={14}>
        Indisponível
      </Text>
    </Flex>
  )
}

const PropertyPrice = ({ isCardMap = false }) => {
  {
    const { property, searchByType, hasSubscriptionPrice } =
      React.useContext(PropertyCardContext)

    const price = property?.price
    const hasPrice = !!property?.price
    const isNightUnavailable = price?.daily?.grossAmount.amount === 0
    const isSubscriptionUnavailable = price?.monthly?.grossAmount.amount === 0

    const isSubscription =
      searchByType === 'subscription' && hasSubscriptionPrice

    const nightElement = match({ isNightUnavailable, hasPrice })
      .with({ hasPrice: false, isNightUnavailable: __ }, () => (
        <PriceUnavailable />
      ))
      .with({ hasPrice: true, isNightUnavailable: true }, () => (
        <PriceUnavailable />
      ))
      .with({ hasPrice: true, isNightUnavailable: false }, () => <PriceNight />)
      .exhaustive()

    const subscriptionElement = match({ isSubscriptionUnavailable, hasPrice })
      .with({ hasPrice: false, isSubscriptionUnavailable: __ }, () => (
        <PriceUnavailable />
      ))
      .with({ hasPrice: true, isSubscriptionUnavailable: true }, () => (
        <PriceUnavailable />
      ))
      .with({ hasPrice: true, isSubscriptionUnavailable: false }, () => (
        <PriceRent isCardMap={isCardMap} />
      ))
      .exhaustive()

    const priceElement = match(isSubscription)
      .with(false, () => nightElement)
      .with(true, () => subscriptionElement)
      .exhaustive()

    return priceElement
  }
}

export default PropertyPrice
