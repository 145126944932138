export const SearchIcon = (props: any) => (
  <svg
    {...props}
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9852 12C11.8976 12.0002 11.8109 11.983 11.73 11.9495C11.6491 11.916 11.5756 11.8668 11.5139 11.8047L9.24719 9.54134C8.31683 10.2717 7.16796 10.668 5.98519 10.6667C4.93036 10.6667 3.89921 10.3539 3.02215 9.76784C2.14509 9.18181 1.4615 8.34885 1.05783 7.37431C0.654166 6.39978 0.548549 5.32742 0.754336 4.29285C0.960124 3.25829 1.46807 2.30798 2.21396 1.5621C2.95984 0.81622 3.91014 0.308269 4.94471 0.102481C5.97927 -0.103307 7.05163 0.00231083 8.02617 0.405978C9.00071 0.809646 9.83366 1.49323 10.4197 2.37029C11.0057 3.24736 11.3185 4.2785 11.3185 5.33334C11.3209 6.51771 10.9245 7.66841 10.1932 8.6L12.4565 10.8667C12.5481 10.9601 12.6102 11.0785 12.635 11.207C12.6598 11.3355 12.6463 11.4685 12.596 11.5894C12.5457 11.7102 12.461 11.8136 12.3524 11.8866C12.2438 11.9596 12.1161 11.9991 11.9852 12ZM5.98519 1.33334C5.19407 1.33334 4.42071 1.56793 3.76291 2.00746C3.10511 2.44698 2.59242 3.0717 2.28967 3.8026C1.98692 4.53351 1.90771 5.33777 2.06205 6.1137C2.21639 6.88962 2.59735 7.60235 3.15676 8.16176C3.71617 8.72117 4.42891 9.10214 5.20483 9.25648C5.98075 9.41082 6.78502 9.3316 7.51592 9.02885C8.24683 8.7261 8.87154 8.21341 9.31107 7.55562C9.75059 6.89782 9.98519 6.12446 9.98519 5.33334C9.98396 4.27285 9.56213 3.25615 8.81225 2.50627C8.06238 1.75639 7.04568 1.33457 5.98519 1.33334Z"
      fill="currentColor"
    />
  </svg>
)
