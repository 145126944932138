import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  createUserFavorites,
  deleteUserFavorites,
  getUserFavorites,
} from '../../requests/users'
import { UsersActions } from '../../store/redux/users.reducer'

export function useUserFavorite({ reload = true }) {
  const [loading, setLoading] = useState(false)
  const [insertLoading, setInsertLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [favoritesData, setFavoritesData] = useState([])
  const [reloadFavorites, setReloadFavorites] = useState(reload)

  const dispatch = useDispatch()
  const { setFavorites } = bindActionCreators(UsersActions, dispatch)
  const { favorites, id: userId } = useSelector((state) => state.user)

  const fetchFavorites = async () => {
    if (reloadFavorites) {
      setLoading(true)
      const { data } = await getUserFavorites()

      setLoading(false)
      setFavoritesData(data.items)
      setFavorites(data.items.map((f) => f.externalCode))
      setReloadFavorites(false)
    }
  }

  const funcInsert = async ({
    aliasId,
    callbackError = () => {},
    callbackSuccess = () => {},
  }) => {
    try {
      setFavorites(favorites.concat(aliasId))

      await createUserFavorites({ aliasId })

      setInsertLoading(true)
      callbackSuccess(aliasId)
    } catch (e) {
      setInsertLoading(false)
      callbackError(aliasId)
    } finally {
      setInsertLoading(false)
    }
  }

  const funcDelete = async ({
    aliasId,
    callbackSuccess = () => {},
    callbackError = () => {},
  }) => {
    setDeleteLoading(true)

    try {
      setFavorites(favorites.filter((i) => i !== aliasId))

      await deleteUserFavorites({
        aliasId,
      })

      if (reload) {
        setFavoritesData(
          favoritesData.filter((f) => f.externalCode !== aliasId)
        )
      }

      setDeleteLoading(false)
      callbackSuccess(aliasId)
    } catch (e) {
      callbackError(aliasId)
      callbackError()
    } finally {
      setInsertLoading(false)
    }
  }

  const insertFavorite = useCallback(funcInsert, [favorites, userId])

  const deleteFavorite = useCallback(funcDelete, [favorites, userId])

  useEffect(fetchFavorites, [reloadFavorites])

  return {
    favoritesFetchLoading: loading,
    favoriteDeleteLoading: deleteLoading,
    favoriteInsertLoading: insertLoading,
    reloadFavorites: () => setReloadFavorites(true),
    insertFavorite,
    deleteFavorite,
    favorites: favoritesData,
    favoritesIds: favorites,
  }
}
