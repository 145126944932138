import styled from 'styled-components'

export const CarouselItems = styled.div`
  border-radius: 8px;
  display: flex;
  flex: 1;
  position: relative;
`

export const CarouselWrapper = styled.div`
  position: relative;
  div {
    .slide {
      background-color: transparent;
      min-width: 100%;
      max-width: 100%;
    }
    .carousel,
    .slide {
      ${({ customStylesMobile }) => ({ ...customStylesMobile })};
    }
    .control-dots {
      ${({ customStyleControlIndicators }) => ({
        ...customStyleControlIndicators,
      })};
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    div {
      .carousel,
      .slide {
        ${({ customStylesDesktop }) => ({ ...customStylesDesktop })};
      }
    }
  }
`

export const ImageItem = styled.img`
  box-sizing: border-box;
  object-fit: cover;
  object-position: center;
`

export const ButtonStyle = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  max-height: 24px;
  max-width: 24px;
  min-height: 24px;
  min-width: 24px;
  padding: 0 4px;
`

export const ButtonArrows = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  max-height: 100%;
  max-width: 32px;
  min-height: 100%;
  min-width: 32px;
  outline: none;
  position: absolute;
  transition: 0.2s;

  z-index: 1;
  :active,
  :hover {
    ${ButtonStyle} {
      transform: scale(1.1);
      transition: 0.2s;
      svg path {
        fill: ${({ theme }) => theme.colors.primary};
        transition: 0.2s;
      }
    }
  }
`

export const DotsIndicator = styled.div`
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary : theme.colors.white};
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: inline-block;
  margin-right: 8px;
  max-height: 8px;
  max-width: 8px;
  min-height: 8px;
  min-width: 8px;

  :last-of-type {
    margin-right: 0;
  }
`
