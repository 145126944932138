import { DineroObject } from 'dinero.js'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getOta, importReservation } from 'src/requests/ota'

import { useModalLogin } from './login.context'

interface Props {
  children: React.ReactNode
}

type OtaType = {
  partnerCode: string
  aliasId: string
  status: string
  property: { externalId: string }
  checkIn: { date: string; time: string }
  checkOut: { date: string; time: string }
  guests: { adults: number }
  user: {
    staysId: string
    firstName: string
    lastName: string
    name: string
    email: string
    phone: string
  }
  cost: {
    total: DineroObject
    grossAmount: DineroObject
    netAmount: DineroObject
  }
  staysId: string
  partner: { _id: string; name: string }
}

type ContextType = {
  data?: OtaType
  loading: boolean
  validReservation: (partnerCode: string) => Promise<void>
  handleSubmit: () => Promise<void>
}

const context = React.createContext({} as ContextType)

export const useOta = () => React.useContext(context)

const OtaProvider = ({ children }: Props) => {
  const navigate = useNavigate()
  const params = useParams()
  const { onClose } = useModalLogin()
  const [loading, setLoading] = React.useState(false)
  const [isRedirected, setRedirecting] = React.useState(false)
  const [data, setData] = React.useState<OtaType>()

  const validReservation = React.useCallback(async (partnerCode: string) => {
    setLoading(true)
    try {
      const result = await getOta(partnerCode.toUpperCase())

      setData(result.data as OtaType)
      setRedirecting(true)

      navigate(`/vincular/reserva/${partnerCode.toUpperCase()}/codigo`)
    } catch (e) {
      navigate(`/vincular/reserva/nao-encontrada`)
    } finally {
      setLoading(false)
    }
  }, [])

  const getOtaData = React.useCallback(async () => {
    if (isRedirected) return
    if (!params.partnerCode) return

    setLoading(true)
    try {
      const result = await getOta(String(params.partnerCode).toUpperCase())
      setData(result.data as OtaType)
    } catch (e) {
      navigate(`/vincular/reserva/nao-encontrada`)
    } finally {
      setLoading(false)
    }
  }, [params.partnerCode])

  const handleSubmit = React.useCallback(async () => {
    try {
      await importReservation(data?.partnerCode!)
      navigate(`/vincular/reserva/${data?.partnerCode}/sucesso`)
    } catch (e) {
      navigate(`/vincular/reserva/nao-encontrada`)
    } finally {
      onClose()
    }
  }, [data])

  React.useEffect(() => {
    getOtaData()
  }, [])

  return (
    <context.Provider value={{ validReservation, loading, data, handleSubmit }}>
      {children}
    </context.Provider>
  )
}

export default OtaProvider
