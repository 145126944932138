import React from 'react'

export const CloseIcon = ({
  width = 18,
  height = 18,
  color = '#474747',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.41457 7.00008L12.7076 2.70708C12.8031 2.61483 12.8793 2.50449 12.9317 2.38249C12.9841 2.26048 13.0117 2.12926 13.0128 1.99648C13.014 1.8637 12.9887 1.73202 12.9384 1.60913C12.8881 1.48623 12.8139 1.37458 12.72 1.28069C12.6261 1.18679 12.5144 1.11254 12.3915 1.06226C12.2686 1.01198 12.1369 0.986677 12.0042 0.987831C11.8714 0.988985 11.7402 1.01657 11.6182 1.06898C11.4962 1.12139 11.3858 1.19757 11.2936 1.29308L7.00057 5.58608L2.70757 1.29308C2.51897 1.11092 2.26637 1.01013 2.00417 1.01241C1.74197 1.01469 1.49116 1.11985 1.30575 1.30526C1.12034 1.49067 1.01517 1.74148 1.0129 2.00368C1.01062 2.26588 1.11141 2.51848 1.29357 2.70708L5.58657 7.00008L1.29357 11.2931C1.19806 11.3853 1.12188 11.4957 1.06947 11.6177C1.01706 11.7397 0.989473 11.8709 0.988319 12.0037C0.987165 12.1365 1.01247 12.2681 1.06275 12.391C1.11303 12.5139 1.18728 12.6256 1.28117 12.7195C1.37507 12.8134 1.48672 12.8876 1.60962 12.9379C1.73251 12.9882 1.86419 13.0135 1.99697 13.0123C2.12975 13.0112 2.26097 12.9836 2.38297 12.9312C2.50498 12.8788 2.61532 12.8026 2.70757 12.7071L7.00057 8.41408L11.2936 12.7071C11.4822 12.8892 11.7348 12.99 11.997 12.9878C12.2592 12.9855 12.51 12.8803 12.6954 12.6949C12.8808 12.5095 12.986 12.2587 12.9882 11.9965C12.9905 11.7343 12.8897 11.4817 12.7076 11.2931L8.41457 7.00008Z"
      fill={color}
    />
  </svg>
)
