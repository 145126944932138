export const MenuDrawerMobileIcon = (props: any) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="6" width="18" height="2" rx="0.5" fill="currentColor" />
    <rect x="3" y="11" width="18" height="2" rx="0.5" fill="currentColor" />
    <rect x="10" y="16" width="11" height="2" rx="0.5" fill="currentColor" />
  </svg>
)
