import { createMachine } from 'xstate'

export const enum fields_states {
  start = 'start',
  end = 'end',
  idle = 'idle',
}

export const enum fields_events {
  to_start = 'to_start',
  blur = 'blur',
  to_end = 'to_end',
}

export interface ContextType {}

export interface EventsType {
  type: fields_events
}

export interface StatesType {
  value: fields_states
  context: ContextType
}

const fieldsMachine = createMachine<ContextType, EventsType, StatesType>({
  id: 'fields-machine',
  context: {},
  initial: fields_states.idle,
  states: {
    [fields_states.idle]: {
      on: {
        [fields_events.to_start]: { target: fields_states.start },
        [fields_events.to_end]: { target: fields_states.end },
      },
    },
    [fields_states.start]: {
      on: {
        [fields_events.to_end]: { target: fields_states.end },
        [fields_events.blur]: { target: fields_states.idle },
      },
    },
    [fields_states.end]: {
      on: {
        [fields_events.to_start]: { target: fields_states.start },
        [fields_events.blur]: { target: fields_states.idle },
      },
    },
  },
})
export default fieldsMachine
