export const LeftBracket = () => (
  <svg
    width="10"
    height="46"
    viewBox="0 0 10 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.65183 45.4998H9.15183V44.9998V43.9238V43.4238H8.65183H7.00149C4.56168 43.4238 2.57641 41.4381 2.57641 38.9987V7.00174C2.57641 4.56192 4.56211 2.57666 7.00149 2.57666H8.65183H9.15183V2.07666V1.00024V0.500244H8.65183H7.00149C3.41587 0.500244 0.5 3.41611 0.5 7.00174V38.9983C0.5 42.5839 3.41638 45.4998 7.00149 45.4998H8.65183Z"
      fill="#D2D4D7"
      stroke="#D2D4D7"
    />
  </svg>
)
