import * as c from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PickerContext } from '../picker-context'
import { picker_events } from '../picker-machine'

interface SwitchItemProps {
  isActive: boolean
  children: React.ReactNode
  onClick: () => void
}

const SwitchItem = ({ isActive, children, onClick }: SwitchItemProps) => {
  return (
    <c.Button
      onClick={onClick}
      color={isActive ? 'black.500' : 'black.400'}
      fontSize={14}
      fontWeight={isActive ? 700 : 500}
      whiteSpace="nowrap"
      px={3}
      minH="30px"
      maxH="30px"
      minW="56px"
      maxW="fit-content"
      bg={isActive ? '#fff' : 'transparent'}
      border="none"
      borderRadius="20px"
      transition="all 0.2s"
      _hover={{
        backgroundColor: 'black.100',
      }}
    >
      {children}
    </c.Button>
  )
}

const Switch = () => {
  const { t } = useTranslation('components/search')
  const pickerState = React.useContext(PickerContext)

  const handleMonth = () => pickerState.send(picker_events.to_month)

  const handleDay = () => pickerState.send(picker_events.to_dates)

  return (
    <c.Flex
      p={0.5}
      minH="35px"
      maxH="35px"
      maxW="fit-content"
      bg="black.200"
      borderRadius="60px"
      align="center"
      justify="space-between"
      gap={1}
    >
      <SwitchItem isActive={pickerState.valid.dates} onClick={handleDay}>
        {t('components/search:dates.model.short')}
      </SwitchItem>
      <SwitchItem onClick={handleMonth} isActive={pickerState.valid.month}>
        {t('components/search:dates.model.subscribe')}
      </SwitchItem>
    </c.Flex>
  )
}

const DateOrMonth = () => {
  const { t } = useTranslation('components/search')

  return (
    <c.Flex
      pos="relative"
      zIndex={1}
      minW={['100%', '154px']}
      maxW={['100%', '154px']}
      direction={['row', 'column']}
      align={['center', 'flex-end']}
      gap={4}
    >
      <c.Text
        color="black.500"
        fontSize={14}
        fontWeight={500}
        textAlign={['start', 'end']}
        w={['100%', 'fit-content']}
      >
        {t('components/search:dates.model.title')}
      </c.Text>
      <Switch />
    </c.Flex>
  )
}

export default DateOrMonth
