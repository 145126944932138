import { Box, Flex, GlobalStyles } from '@retriever-ui/react'
import React from 'react'

interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Flex w="100%" pY={40} alignItems="center" justifyContent="center">
        <Flex
          radius={16}
          bg="black.0"
          alignItems="center"
          justifyContent="center"
          maxW={360}
          w="100%"
          h="fit-content"
          p={20}
        >
          <Box w="100%">{children}</Box>
        </Flex>
      </Flex>
      <GlobalStyles
        styles={(theme) => ({
          body: {
            background: theme.colors['black.200'],
          },
        })}
      />
    </>
  )
}

export default Layout
