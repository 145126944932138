import { Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useMaps } from '../map/context'

const OrderDropdown = () => {
  const { t } = useTranslation('apartment-search')
  const map = useMaps()

  const links = [
    {
      label: t('apartment-search:ORDER.options.0'),
      onClick: () =>
        map.updateFilters({
          sortingType: 'DISTANCE',
          sortingOrder: undefined,
        }),
      type: 'DISTANCE',
    },
    {
      label: t('apartment-search:ORDER.options.2'),
      onClick: () =>
        map.updateFilters({
          sortingType: 'PRICE',
          sortingOrder: 'DESC',
        }),
      type: 'DESC',
    },
    {
      label: t('apartment-search:ORDER.options.1'),
      onClick: () =>
        map.updateFilters({
          sortingType: 'PRICE',
          sortingOrder: 'ASC',
        }),
      type: 'ASC',
    },
  ]

  const matchLink = () => {
    if (map.context?.filters?.sortingOrder === 'DESC') return links[1].label
    if (map.context?.filters?.sortingOrder === 'ASC') return links[2].label

    return links[0].label
  }

  return (
    <Menu isLazy>
      <MenuButton
        width="130px"
        height="37px"
        backgroundColor="black.0"
        borderRadius="8px"
        padding="8px 12px"
        justifyContent="center"
        boxShadow="0px 4px 8px -5px rgba(0, 0, 0, 0.1)"
        fontSize="14px"
        color="black.500"
        fontWeight={500}
      >
        {matchLink()}
      </MenuButton>
      <MenuList padding="10px">
        {links.map(({ label, onClick, type }) => {
          return (
            <>
              <MenuItem
                key={type}
                onClick={onClick}
                _focus={{ backgroundColor: 'primary.50', borderRadius: '4px' }}
              >
                <Text fontSize="14px" color="black.400">
                  {label}
                </Text>
              </MenuItem>
            </>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default OrderDropdown
