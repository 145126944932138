import {
  Box,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'src/components/typograph'
import { useMaps } from 'src/pages/search/map/context'
import { convertToMoney } from 'src/utils/monetary'

import * as S from './styled'

const PriceField = ({ setValue }) => {
  const { t } = useTranslation('search-filter-form')

  const map = useMaps()

  const minPrice = Number(map.context?.filters?.minPrice ?? 100) / 100
  const maxPrice = Number(map.context?.filters?.maxPrice ?? 1500000) / 100
  const [sliderValue, setSliderValue] = useState([minPrice, maxPrice])

  return (
    <S.ContentField>
      <Box marginBottom="24px">
        <Typography title variant="extraSmall" weight="bold">
          {t('search-filter-form:VALUE')}
        </Typography>
      </Box>
      <RangeSlider
        aria-label={['min', 'max']}
        colorScheme="primary"
        defaultValue={[minPrice, maxPrice]}
        max={15000}
        min={1}
        onChange={(val) => {
          setSliderValue(val)
          setValue('minPrice', val[0] * 100)
          setValue('maxPrice', val[1] * 100)
        }}
        marginBottom="16px"
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        <RangeSliderThumb index={0} bg="#FA2F93" />
        <RangeSliderThumb index={1} bg="#FA2F93" />
      </RangeSlider>
      <Flex gap="16px" alignItems="center">
        <Box
          width="100%"
          height="37"
          border="1px solid #474747"
          backgroundColor="#F5F5F5"
          borderRadius="8"
          padding="8px 16px"
        >
          <Text fontSize="14px">
            {convertToMoney({
              amount: sliderValue[0] * 100,
              precision: 2,
              currency: 'BRL',
            })}
          </Text>
        </Box>
        <Text fontSize="12px">até</Text>
        <Box
          width="100%"
          height="37"
          border="1px solid #474747"
          backgroundColor="#F5F5F5"
          borderRadius="8"
          padding="8px 16px"
        >
          <Text fontSize="14px">
            {convertToMoney({
              amount: sliderValue[1] * 100,
              precision: 2,
              currency: 'BRL',
            })}
          </Text>
        </Box>
      </Flex>
    </S.ContentField>
  )
}

export default PriceField
