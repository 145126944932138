import React from 'react'

const handleDirection = (direction) => {
  return direction == 'top'
    ? '90deg'
    : direction == 'bottom'
    ? '-90deg'
    : direction == 'right'
    ? '180deg'
    : '0'
}
export const ArrowIndicateIcon = ({
  color = 'black',
  direction = 'bottom',
  width = '16',
  height = '14',
  style = {},
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: `rotate(${handleDirection(direction)})`,
      ...style,
    }}
    {...props}
  >
    <path
      d="M15 6.00012H3.135L6.768 1.64012C6.93027 1.43561 7.00612 1.17576 6.97937 0.916076C6.95262 0.65639 6.82537 0.417462 6.62482 0.250336C6.42427 0.0832089 6.16631 0.00113538 5.90606 0.0216489C5.6458 0.0421624 5.40389 0.163636 5.232 0.36012L0.232 6.36012C0.197888 6.40857 0.168424 6.46013 0.144 6.51412C0.117244 6.55361 0.0935102 6.59506 0.073 6.63812C0.0260624 6.75311 0.00129383 6.87593 0 7.00012H0C0.00147668 7.1229 0.0258962 7.24432 0.072 7.35812C0.0925101 7.40118 0.116244 7.44263 0.143 7.48212C0.167424 7.53611 0.196888 7.58767 0.231 7.63612L5.231 13.6361C5.40289 13.8326 5.6448 13.9541 5.90506 13.9746C6.16531 13.9951 6.42327 13.913 6.62382 13.7459C6.82437 13.5788 6.95162 13.3398 6.97837 13.0802C7.00512 12.8205 6.92927 12.5606 6.767 12.3561L3.135 8.00012H15C15.2652 8.00012 15.5196 7.89476 15.7071 7.70723C15.8946 7.51969 16 7.26534 16 7.00012C16 6.7349 15.8946 6.48055 15.7071 6.29301C15.5196 6.10548 15.2652 6.00012 15 6.00012Z"
      fill={`var(--${color}-color)`}
    />
  </svg>
)
