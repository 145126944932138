import * as c from '@chakra-ui/react'
import React from 'react'
import { ArrowIcon } from 'src/assets/icons/arrow.icon'

import { StepsContext } from '../../../steps/steps-context'
import Tag from './tag'

const Header = () => {
  const { prev } = React.useContext(StepsContext)

  return (
    <c.Flex direction="column">
      <c.Grid
        gridTemplateColumns="20px 1fr"
        justifyItems="center"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <c.Box
          as={c.Button}
          onClick={() => prev()}
          p={0}
          minW="12px"
          maxW="12px"
          maxH="20px"
          bg="transparent"
          border="none"
        >
          <ArrowIcon color="gray" direction="left" height={14} width={14} />
        </c.Box>
        <Tag />
      </c.Grid>
    </c.Flex>
  )
}

export default Header
