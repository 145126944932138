import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators: NewsActions } = createActions({
  setNews: ['news'],
})

const INITIAL_STATE = {
  news: [],
}

const setNews = (state = INITIAL_STATE, action) => ({
  ...state,
  news: action.news,
})

export default createReducer(INITIAL_STATE, {
  [Types.SET_NEWS]: setNews,
})
