import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  PinInput,
  PinInputField,
  Text,
} from '@chakra-ui/react'
import { FORM_ERROR } from 'final-form'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { TFunction, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { notify } from 'src/components/alert/toast'
import ShadowSkeleton from 'src/components/skeleton'
import { sendSms, verifySmsCode } from 'src/requests/ota'
import { validate } from 'src/utils/validate'
import * as yup from 'yup'

import { useAuth } from '../auth/auth-context'
import { useOta } from './context'
import { useModalLogin } from './login.context'
import { Header } from './reservation-code'
import { formatPhone } from './utils'

const validationScheme = (t: TFunction) =>
  yup.object().shape({
    code: yup.string().min(6).max(6).required(t('formMessages:REQUIRED')),
  })

const VerifySmsCode = () => {
  const { t } = useTranslation('ota-page')
  const navigate = useNavigate()
  const { isAuth } = useAuth()
  const { data, loading } = useOta()
  const { onOpen } = useModalLogin()

  const handleSubmit = async (values: { code: string }) => {
    try {
      try {
        await verifySmsCode(values.code.toUpperCase())
      } catch (e) {
        return { [FORM_ERROR]: 'Código inválido.' }
      }

      if (!isAuth) return onOpen()
    } catch (e) {
      return { [FORM_ERROR]: 'Código inválido.' }
    }
  }

  React.useEffect(() => {
    if (data?.user?.phone) {
      sendSms(data?.user.phone!).then()
    }
  }, [data?.user?.phone])

  return (
    <Form validate={validate(validationScheme(t))} onSubmit={handleSubmit}>
      {({
        handleSubmit,
        submitting,
        submitError,
        hasValidationErrors,
        dirty,
      }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap="24px" w="full">
            <Box
              w="full"
              pt={['none', '40px']}
              mt={['none', '40px']}
              bg={['none', 'black.0']}
              borderRadius={['none', '12px']}
            >
              <Header
                title={t('step-2.title')}
                back={() => navigate('/vincular')}
              />
              <Box
                bg="black.0"
                mt={['20px', '0px']}
                mx="24px"
                px="16px"
                py="20px"
                borderRadius="8px"
              >
                <Heading fontSize="20px" as="h2" fontWeight="bold">
                  {t('verify.title')}
                </Heading>
                <Text mt="16px" fontWeight="medium" fontSize="16px">
                  {t('verify.desc.0')}
                </Text>
                <ShadowSkeleton w="100%" maxW={200} isLoading={loading}>
                  <Text
                    mt="16px"
                    color="#2D70D2"
                    fontWeight="medium"
                    fontSize="16px"
                  >
                    {data?.user?.phone && formatPhone(data?.user?.phone)}
                  </Text>
                </ShadowSkeleton>
                <Text mt="16px" fontWeight="medium" fontSize="16px">
                  {t('verify.desc.1')}
                </Text>
              </Box>
            </Box>
            <Flex mt={['none', '20px']} justify="center" gap="8px">
              <Field name="code">
                {({ input, meta }) => (
                  <FormControl
                    w="fit"
                    isInvalid={(meta.error && meta.touched) || submitError}
                  >
                    <PinInput {...input} type="alphanumeric">
                      <PinInputField
                        mx="4px"
                        textTransform="uppercase"
                        bg="black.0"
                      />
                      <PinInputField
                        mx="4px"
                        textTransform="uppercase"
                        bg="black.0"
                      />
                      <PinInputField
                        mx="4px"
                        textTransform="uppercase"
                        bg="black.0"
                      />
                      <PinInputField
                        mx="4px"
                        textTransform="uppercase"
                        bg="black.0"
                      />
                      <PinInputField
                        mx="4px"
                        textTransform="uppercase"
                        bg="black.0"
                      />
                      <PinInputField
                        mx="4px"
                        textTransform="uppercase"
                        bg="black.0"
                      />
                    </PinInput>
                    <FormErrorMessage fontSize="13px">
                      {meta.error || submitError}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Button
              mx="24px"
              type="submit"
              isLoading={submitting}
              disabled={submitting || !dirty || hasValidationErrors}
              colorScheme="primary"
              bg="primary.500"
            >
              {t('step-2.button')}
            </Button>
            <Button
              mx="24px"
              variant="link"
              onClick={async () => {
                await sendSms(data?.user.phone!)
                notify({
                  text: 'Código de confimação enviado com sucesso!!',
                }).success()
              }}
            >
              {t('verify.resend')}
            </Button>
          </Flex>
        </form>
      )}
    </Form>
  )
}

export default VerifySmsCode
