import { useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { DisabledResetScroll } from 'src/store/context/scroll.context'

import { useHideSearchBar } from '../../store/context/navbar.context'
import HeaderSeo from './header-seo'
import MapsProvider from './map/context'
import Desktop from './map/desktop'
import Mobile from './map/mobile'

const SearchMap = () => {
  useHideSearchBar({ mobile: true, desktop: false })
  const [isMobile] = useMediaQuery(['(max-width: 578px)'])

  return (
    <DisabledResetScroll>
      <MapsProvider>
        <HeaderSeo />
        {isMobile ? <Mobile /> : <Desktop />}
      </MapsProvider>
    </DisabledResetScroll>
  )
}

export default SearchMap
