import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { Waypoint } from 'react-waypoint'
import CardMobileMap, {
  CardLoading,
} from 'src/components/cards/property/search/card-mobile-map'

import { useMaps } from '../../context'
import Head from './head'

const List = () => {
  const { context, valid, onLoadMore } = useMaps()

  return (
    <>
      <Flex mt="24px" w="100%" overflowX="auto" gap={3}>
        {context.cards.map((property) => (
          <CardMobileMap key={property._id} property={property} />
        ))}
        {valid.requesting && <CardLoading />}
        <Waypoint horizontal onEnter={onLoadMore} />
      </Flex>
    </>
  )
}

const Mobile = ({ map, buildingId, label, isFlagship, isPartner }) => {
  return (
    <Box
      position="sticky"
      zIndex={99}
      w="100%"
      bottom="80px"
      borderTopRadius="8px"
      shadow="lg"
      h="fit-content"
    >
      <Box w="100%" px="20px" pt="20px" pb="32px" bg="black.50">
        <Head
          map={map}
          label={label}
          isPartner={isPartner}
          buildingId={buildingId}
          isFlagship={isFlagship}
        />

        <React.Suspense fallback={<CardLoading />}>
          <List />
        </React.Suspense>
      </Box>
    </Box>
  )
}

export default Mobile
