const ArrowRight = (props: any) => {
  return (
    <svg
      {...props}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 18.9999C10.31 18.9998 10.1239 18.9456 9.9636 18.8436C9.80328 18.7416 9.67534 18.5961 9.59476 18.424C9.51417 18.2519 9.48428 18.0604 9.50859 17.872C9.5329 17.6835 9.61039 17.5059 9.732 17.3599L14.207 11.9889L9.892 6.62689C9.72571 6.42015 9.64835 6.15583 9.67695 5.89206C9.70555 5.62829 9.83776 5.38668 10.0445 5.22039C10.2512 5.0541 10.5156 4.97674 10.7793 5.00534C11.0431 5.03394 11.2847 5.16615 11.451 5.37289L16.279 11.3729C16.4238 11.5529 16.5018 11.7774 16.4998 12.0084C16.4978 12.2393 16.4159 12.4625 16.268 12.6399L11.268 18.6399C11.1742 18.7525 11.0568 18.8431 10.9241 18.9053C10.7913 18.9676 10.6466 18.9998 10.5 18.9999Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowRight
