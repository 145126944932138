import React from 'react'

const ArrowLeft = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.829 19.001C13.6793 19.0012 13.5316 18.9677 13.3966 18.9032C13.2616 18.8386 13.1428 18.7446 13.049 18.628L8.22097 12.628C8.07595 12.448 7.99779 12.2232 7.99979 11.992C8.0018 11.7609 8.08385 11.5375 8.23197 11.36L13.232 5.36C13.3149 5.25555 13.4178 5.16872 13.5347 5.10464C13.6516 5.04055 13.7802 5.0005 13.9129 4.98683C14.0455 4.97317 14.1795 4.98617 14.3071 5.02507C14.4346 5.06397 14.5531 5.12798 14.6555 5.21335C14.758 5.29871 14.8423 5.4037 14.9035 5.52213C14.9648 5.64057 15.0018 5.77006 15.0122 5.90299C15.0227 6.03592 15.0065 6.1696 14.9646 6.29617C14.9226 6.42274 14.8558 6.53965 14.768 6.64L10.293 12.011L14.608 17.373C14.7265 17.5199 14.8011 17.6974 14.823 17.8849C14.8449 18.0724 14.8133 18.2623 14.7318 18.4326C14.6503 18.6029 14.5223 18.7467 14.3625 18.8473C14.2027 18.9479 14.0178 19.0012 13.829 19.001Z"
        fill="#474747"
      />
    </svg>
  )
}

export default ArrowLeft
