import { ComponentStyleConfig } from '@chakra-ui/react'

export const Menu: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    navbar: {
      button: {
        bg: 'black.0',
        borderRadius: '6px',
      },
      list: {
        border: 'none',
        py: 's3',
        px: 's2',
        minW: '345px',
        borderLeftRadius: '12px',
        borderRightBottomRadius: '12px',
        boxShadow: '0px 10px 10px -5px #0000000A, 0px 20px 25px -5px #0000000D',
      },
      item: {
        color: 'black.500',
        alignItems: 'start',
        fontSize: 't-sm',
        fontWeight: 'medium',
        py: 's4',
        px: 's2',
        display: 'flex',
        gap: 's2',
        _hover: {
          boxShadow: 'none',
          bg: 'black.100',
        },
        _focus: {
          bg: 'black.0',
          shadow: 'none',
        },
        _active: {
          border: '1px solid',
          borderColor: 'primary.500',
        },
      },
    },
  },
  defaultProps: {},
}
