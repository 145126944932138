import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ArrowLeft from '../profile-v2/components/icons/arrow-left.icon'
import { useOta } from './context'

interface HeaderProps {
  title: string
  back: () => void
  withoutButton?: boolean
  withArrow?: boolean
}

export const Header = ({
  title,
  back,
  withoutButton,
  withArrow,
}: HeaderProps) => {
  const [isMobile] = useMediaQuery(['(max-width: 575px)'])
  if (!isMobile && !withArrow)
    return (
      <Heading w="full" fontWeight="bold" textAlign="center" fontSize="20px">
        {title}
      </Heading>
    )
  return (
    <Grid
      w="full"
      bg="black.0"
      gap="8px"
      py="16px"
      px="20px"
      alignItems="center"
      justifyItems="center"
      templateColumns="40px 1fr 40px"
    >
      <GridItem>
        {!withoutButton && (
          <IconButton
            variant="ghost"
            onClick={back}
            aria-label="voltar"
            icon={<ArrowLeft />}
          />
        )}
      </GridItem>
      <GridItem>
        <Heading
          w="fit-content"
          fontWeight="bold"
          textAlign="center"
          fontSize="20px"
        >
          {title}
        </Heading>
      </GridItem>
      <GridItem>
        <div />
      </GridItem>
    </Grid>
  )
}

const ReservationCode = () => {
  const { t } = useTranslation('ota-page')
  const navigate = useNavigate()
  const [reservationCode, setReservationCode] = React.useState('')
  const { loading, validReservation } = useOta()

  return (
    <Flex gap="24px" w="full" direction="column">
      <Box
        w="full"
        mt={['none', '40px']}
        pt={['none', '40px']}
        bg={['none', 'black.0']}
        borderRadius={['none', '12px']}
      >
        <Header title={t('step-2.title')} back={() => navigate('/vincular')} />
        <Box
          mt={['20px', '0px']}
          bg="black.0"
          mx="24px"
          px="16px"
          py="20px"
          borderRadius="8px"
        >
          <Heading fontSize="20px" as="h2" fontWeight="bold">
            {t('step-2.code')}
          </Heading>
          <Text mt="16px" fontWeight="medium" fontSize="16px">
            {t('step-2.desc')}
          </Text>

          <Text mt="40px" fontWeight="medium" fontSize="16px">
            {t('step-2.exemple.0')}
            <Text as="span" mt="16px" fontWeight="bold" fontSize="16px">
              {t('step-2.exemple.1')}
            </Text>
          </Text>
          <Input
            onChange={(e) => setReservationCode(e.target.value)}
            mt="16px"
            bg="black.100"
            placeholder={t('step-2.input')}
          />
        </Box>
      </Box>
      <Button
        mx="24px"
        isLoading={loading}
        onClick={async () => await validReservation(reservationCode)}
        disabled={reservationCode.length < 5 || loading}
        colorScheme="primary"
        bg="primary.500"
      >
        {t('step-2.button')}
      </Button>
    </Flex>
  )
}

export default ReservationCode
