import { useMediaQuery } from '@chakra-ui/react'
import { InfoWindow, Marker } from '@react-google-maps/api'
import React from 'react'
import useMessages from 'src/hooks/messages/use-messages'

import { lazyZoom } from '../../utils'
import { useMaps } from '../context'
import { ListingMapType } from '../mobile'
import Head from './card/head'

interface PinType {
  map?: google.maps.Map
  clusterer: any
  lat: number
  lng: number
  label: string
  buildingId: string
  isPartner: boolean
  isFlagship: boolean
}

const Pin = ({
  clusterer,
  lat,
  lng,
  map,
  label,
  buildingId,
  isPartner,
  isFlagship,
}: PinType) => {
  const { context, onZoomPin } = useMaps()
  const [isMobile] = useMediaQuery(['(max-width: 578px)'])

  const { emit } = useMessages<ListingMapType | boolean>('map:marker')

  const icon = React.useMemo(
    () => ({
      url: isFlagship
        ? `https://housi-images.s3.amazonaws.com/site/pins/pin_rosa.svg`
        : `https://housi-images.s3.amazonaws.com/site/pins/pin_preto.svg`,
      scaledSize: new google.maps.Size(90, 90),
      origin: new google.maps.Point(0, -5), // origin
      textSize: 14,
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
    }),
    []
  )

  const handleOpen = React.useCallback(async () => {
    emit('maps', { category: 'zoom-to-pin', data: true })

    map?.panTo({ lat, lng })

    emit('map:listing', {
      category: 'open-marker',
      data: { isPartner, buildingId, lat, lng, map: map!, label, isFlagship },
    })

    await lazyZoom(map!, 22)

    onZoomPin(buildingId, { lat, lng })
  }, [lat, lng, isPartner, buildingId, lat, lng, map, label, isFlagship])

  return (
    <Marker
      clusterer={clusterer}
      position={{ lat, lng }}
      options={
        {
          label: {
            isFlagship,
            color: '#474747',
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: 'Montserrat',
            text: label,
            className: 'marker-pin',
          },
          icon: icon,
        } as any
      }
      onClick={handleOpen}
    >
      {!isMobile && context.activeCard === `${lat}${lng}` && (
        <InfoWindow position={{ lng, lat }} options={{ maxWidth: 375 }}>
          <Head
            map={map}
            label={label}
            isPartner={isPartner}
            buildingId={buildingId}
            isFlagship={isFlagship}
          />
        </InfoWindow>
      )}
    </Marker>
  )
}

export default Pin
