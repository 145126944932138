import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import OrderDropdown from './order-dropdown'

const Order = () => {
  const { t } = useTranslation('apartment-search')
  return (
    <Flex paddingTop="16px" gap="12px" alignItems="center">
      <Text fontSize="14px">{t('apartment-search:ORDER.order')}</Text>
      <OrderDropdown />
    </Flex>
  )
}

export default Order
