export const ChevronDownIcon = (props: any) => (
  <svg
    {...props}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00001 10.8334C7.82321 10.8333 7.65367 10.7631 7.52867 10.638L4.86201 7.97136C4.74057 7.84563 4.67337 7.67722 4.67489 7.50243C4.67641 7.32763 4.74652 7.16042 4.87013 7.03681C4.99373 6.91321 5.16094 6.8431 5.33574 6.84158C5.51054 6.84006 5.67894 6.90726 5.80467 7.02869L8.00467 9.22869L10.2033 7.11203C10.3306 6.9887 10.5016 6.92096 10.6787 6.92371C10.8559 6.92646 11.0247 6.99948 11.148 7.12669C11.2713 7.25391 11.3391 7.42491 11.3363 7.60206C11.3336 7.77922 11.2606 7.94803 11.1333 8.07136L8.46667 10.646C8.34154 10.767 8.17405 10.8342 8.00001 10.8334Z"
      fill="currentColor"
    />
  </svg>
)
