import { ComponentStyleConfig } from '@chakra-ui/react'

export const Link: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'bold',
    _hover: {
      textDecoration: 'none',
    },
  },
  sizes: {},
  variants: {
    primary: {
      color: 'primary.500',
    },
    unstyled: {},
  },
  defaultProps: {
    variant: 'primary',
  },
}
