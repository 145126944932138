import { Container } from '@chakra-ui/react'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { useHideSearchBar } from 'src/store/context/navbar.context'

import AuthModal from './auth'
import OtaProvider from './context'
import ModalLoginProvider from './login.context'

const OtaLayout = () => {
  useHideSearchBar({})

  return (
    <ModalLoginProvider>
      <OtaProvider>
        <Container px="none" mb="40px" maxW="395px">
          <Outlet />
          <AuthModal />
        </Container>
      </OtaProvider>
    </ModalLoginProvider>
  )
}

export default OtaLayout
