import * as c from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SearchIcon } from 'src/assets/icons/search.icon'

import { PickerContext } from '../../../dates/picker/picker-context'
import { picker_events } from '../../../dates/picker/picker-machine'
import { SearchContext } from '../../../search-context'
import { StepsContext } from '../../../steps/steps-context'

const buttonStyle = {
  fontSize: 16,
  fontWeight: 700,
  px: 4,
  py: 2.5,
  minH: '44px',
  maxH: '44px',
  transition: 'all 0.2s ease-in-out',
}

const FinishButton = () => {
  const { t } = useTranslation('components/search')
  const { handleSearch, context } = React.useContext(SearchContext)
  const pickerState = React.useContext(PickerContext)

  const disabled = !(context.coords && context.start && context.end)

  const onSearch = () => {
    handleSearch()
    pickerState.send(picker_events.close)
  }

  if (disabled)
    return (
      <c.Flex
        as={c.Button}
        {...buttonStyle}
        isDisabled
        color="black.0"
        minW="168px"
        bg="primary.500"
        align="center"
        gap={2}
        _hover={{
          background: 'primary.600',
        }}
      >
        <SearchIcon color="white" width={18} height={18} />
        {t('components/search:submit')}
      </c.Flex>
    )

  return (
    <c.Flex
      as={c.Button}
      {...buttonStyle}
      onClick={onSearch}
      color="black.0"
      minW="168px"
      bg="primary.500"
      align="center"
      gap={2}
      _hover={{
        background: 'primary.600',
      }}
    >
      <SearchIcon color="white" width={18} height={18} />
      {t('components/search:submit')}
    </c.Flex>
  )
}

const Footer = () => {
  const { t } = useTranslation('components/search')
  const { prev } = React.useContext(StepsContext)
  const { context } = React.useContext(SearchContext)

  if (!context.end) return null

  return (
    <c.Flex
      pos="fixed"
      bottom={0}
      pt={3}
      pb={10}
      px={5}
      minH="96px"
      minW="100%"
      bg="black.0"
      borderRadius="8px 8px 0px 0px"
      align="center"
      justify="space-between"
      shadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
    >
      <c.Button
        {...buttonStyle}
        onClick={prev}
        color="black.500"
        bg="black.0"
        _hover={{
          background: 'black.100',
        }}
      >
        {t('components/search:back')}
      </c.Button>

      <FinishButton />
    </c.Flex>
  )
}

export default Footer
