export const SearchIcon = (props: any) => (
  <svg
    {...props}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3333 21C20.202 21.0003 20.0719 20.9746 19.9505 20.9243C19.8292 20.874 19.719 20.8001 19.6263 20.707L16.2263 17.312C14.8308 18.4075 13.1075 19.002 11.3333 19C9.75108 19 8.20436 18.5308 6.88877 17.6518C5.57318 16.7727 4.5478 15.5233 3.9423 14.0615C3.33679 12.5997 3.17837 10.9911 3.48705 9.43928C3.79573 7.88743 4.55766 6.46197 5.67648 5.34315C6.7953 4.22433 8.22076 3.4624 9.77261 3.15372C11.3245 2.84504 12.933 3.00347 14.3948 3.60897C15.8566 4.21447 17.106 5.23985 17.9851 6.55544C18.8641 7.87103 19.3333 9.41775 19.3333 11C19.337 12.7766 18.7424 14.5026 17.6453 15.9L21.0403 19.3C21.1778 19.4402 21.2709 19.6178 21.3081 19.8105C21.3453 20.0033 21.3249 20.2028 21.2495 20.384C21.1742 20.5653 21.0471 20.7204 20.8841 20.8299C20.7212 20.9395 20.5297 20.9986 20.3333 21ZM11.3333 5C10.1466 5 8.98661 5.3519 7.99991 6.01119C7.01322 6.67047 6.24418 7.60755 5.79005 8.7039C5.33593 9.80026 5.21711 11.0067 5.44862 12.1705C5.68013 13.3344 6.25158 14.4035 7.09069 15.2426C7.92981 16.0818 8.9989 16.6532 10.1628 16.8847C11.3267 17.1162 12.5331 16.9974 13.6294 16.5433C14.7258 16.0892 15.6629 15.3201 16.3221 14.3334C16.9814 13.3467 17.3333 12.1867 17.3333 11C17.3315 9.40927 16.6987 7.88423 15.5739 6.75941C14.4491 5.63459 12.9241 5.00186 11.3333 5Z"
      fill="currentcolor"
    />
  </svg>
)
