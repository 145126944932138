import * as c from '@chakra-ui/react'
import React from 'react'
import { FieldsContext } from 'src/components/search-v2/dates/fields/fields-context'
import { fields_events } from 'src/components/search-v2/dates/fields/fields-machine'
import { useWindowWidth } from 'src/store/context/window.context'

import { FieldEnd, FieldStart } from '../../../dates/fields/field'
import DateOrMonth from '../../../dates/picker/atomic/date-or-month'
import Week from '../../../dates/picker/atomic/week'
import DatePickerMobile from '../../../dates/picker/mobile'
import { PickerContext } from '../../../dates/picker/picker-context'
import { picker_events } from '../../../dates/picker/picker-machine'
import Wrapper from '../../../wrapper'
import Footer from './footer'
import Header from './header'

const StepDate = () => {
  const pickerState = React.useContext(PickerContext)
  const fieldsState = React.useContext(FieldsContext)
  const { windowWidth } = useWindowWidth()

  React.useEffect(() => {
    pickerState.send(picker_events.to_dates)
    fieldsState.send(fields_events.to_start)
  }, [])

  if (windowWidth > 768) return null

  const toStart = () => fieldsState.send(fields_events.to_start)
  const toEnd = () => fieldsState.send(fields_events.to_end)

  return (
    <div>
      <Wrapper>
        <Header />
        <c.Flex mt={10} zIndex={0} pos="relative">
          <FieldStart onClick={toStart} />
          <FieldEnd onClick={toEnd} />
        </c.Flex>
        <c.Flex mt={5}>
          <DateOrMonth />
        </c.Flex>
      </Wrapper>
      <c.Flex bg="black.50" align="center" justify="center" px={6}>
        <Week />
      </c.Flex>
      <DatePickerMobile />
      <Footer />
    </div>
  )
}

export default StepDate
