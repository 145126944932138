import * as c from '@chakra-ui/react'
import { Link } from 'react-router-dom'

interface NavLinkProps {
  path: string
  label: string
  Icon: (props: any) => JSX.Element
  isExternal: boolean
}

const NavLink = ({ path, label, Icon, isExternal }: NavLinkProps) => {
  return (
    <>
      {isExternal && (
        <c.Flex
          as={c.Link}
          href={path}
          direction="column"
          alignItems="center"
          justify="center"
          gap="s1"
        >
          <c.Icon as={Icon} color="primary.500" w="1.5rem" h="1.5rem" />
          <c.Text fontSize="t-sm.0" fontWeight="medium" color="black.400">
            {label}
          </c.Text>
        </c.Flex>
      )}

      {!isExternal && (
        <c.Flex
          as={Link}
          to={path}
          direction="column"
          alignItems="center"
          justify="center"
          gap="s1"
        >
          <c.Icon as={Icon} color="primary.500" w="1.5rem" h="1.5rem" />
          <c.Text fontSize="t-sm.0" fontWeight="medium" color="black.400">
            {label}
          </c.Text>
        </c.Flex>
      )}
    </>
  )
}

export default NavLink
