import React from 'react'

const BrazilFlagIcon = () => {
  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 15C26 17.7614 24.6297 20 21.9375 20H4.0625C1.37028 20 0 17.7614 0 15V5C0 2.23864 1.37028 0 4.0625 0H21.9375C24.6297 0 26 2.23864 26 5V15Z"
        fill="#128807"
      />
      <path
        d="M23.2132 10.3593L13.061 18.3411L2.90839 10.3593L13.061 2.37744L23.2132 10.3593Z"
        fill="#F9CB38"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0664 11.6501C16.6041 13.2824 15.1575 14.5251 13.5195 14.6939C11.331 14.9214 9.41633 13.4762 8.95077 11.2419C8.81302 10.5854 8.83059 9.90389 9.00195 9.25593C9.06573 9.18417 9.15389 9.16686 9.24164 9.1542C9.88173 9.06186 10.5288 9.0326 11.1742 9.06682C11.8628 9.10734 12.5457 9.20654 13.2095 9.40155C13.8908 9.60205 14.5505 9.86376 15.1733 10.2116C15.642 10.4703 16.0896 10.7682 16.5119 11.1022C16.6549 11.2175 16.8007 11.3306 16.938 11.4534C16.9994 11.5083 17.0473 11.5674 17.066 11.6501"
        fill="#002776"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.23126 8.59517C9.75573 7.37739 10.6401 6.56653 11.8625 6.18579C14.3244 5.41882 16.8501 7.10936 17.2161 9.74584C17.273 10.1532 17.2754 10.5618 17.2153 10.9699C17.1531 10.994 17.1068 10.9598 17.0642 10.9231C16.618 10.5443 16.142 10.2051 15.6411 9.90919C15.2554 9.68177 14.8546 9.48316 14.4418 9.31487C14.3687 9.2849 14.2769 9.26928 14.2517 9.16755C14.2781 9.11859 14.3114 9.07595 14.3512 9.03628C14.3655 9.02108 14.3862 9.00631 14.3671 8.98098C14.3606 8.97296 14.3472 8.97296 14.3358 8.97423C14.3053 8.9776 14.3094 8.99955 14.3106 9.02277C14.313 9.09833 14.2708 9.14687 14.2151 9.1857C14.1164 9.20892 14.0218 9.1781 13.9417 9.13378C13.7126 9.00884 13.4591 8.96621 13.2166 8.89656C12.8384 8.78976 12.45 8.7294 12.0624 8.66651C11.7531 8.61519 11.4417 8.57829 11.1293 8.55592C10.8449 8.53523 10.5552 8.53819 10.2688 8.55845C9.95642 8.58124 9.6432 8.60404 9.33242 8.64287C9.31293 8.64583 9.29304 8.6429 9.2751 8.63444C9.25717 8.62599 9.24195 8.61236 9.23126 8.59517Z"
        fill="#002776"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2549 9.1414C14.2533 9.19289 14.2976 9.18614 14.3244 9.19711C15.3735 9.62115 16.3491 10.2199 17.2128 10.97C17.1879 11.2015 17.1386 11.4296 17.0657 11.65C16.5575 11.2118 16.0371 10.7944 15.4615 10.4533C13.6419 9.37651 11.6935 8.94976 9.61066 9.17854C9.40753 9.20007 9.20441 9.22877 9.00128 9.25452C9.05369 9.02616 9.12031 8.80202 9.22959 8.59435C10.7502 8.38118 12.2444 8.50233 13.7105 8.97382C13.8925 9.03249 14.066 9.12704 14.2602 9.14477L14.2549 9.14013"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1486 11.8619C16.026 11.8484 16.1592 12.0042 16.0354 12.0008C15.9275 11.9991 15.9218 11.9096 15.8689 11.8598C15.9096 11.8159 15.9817 11.8071 16.0045 11.7387C16.0199 11.8109 16.0855 11.8261 16.1486 11.8619Z"
        fill="#D9DFEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5353 12.6428C11.4201 12.6567 11.5166 12.7867 11.4201 12.7888C11.3228 12.7909 11.2727 12.7162 11.2532 12.639C11.2463 12.617 11.3399 12.568 11.3859 12.5317C11.3916 12.6081 11.4681 12.6048 11.5353 12.6428Z"
        fill="#E9ECF3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0568 12.4078C12.8968 12.515 13.0259 12.2727 12.9318 12.2964C12.9624 12.2457 13.0487 12.2575 13.0544 12.1799C13.0951 12.2238 13.1297 12.2744 13.1944 12.2837C13.102 12.2706 13.2026 12.4834 13.0568 12.4078Z"
        fill="#EDEFF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75837 9.56349C9.7201 9.63905 9.6912 9.72347 9.57517 9.67282C9.51573 9.64707 9.57924 9.56265 9.508 9.55463L9.6399 9.43433C9.64886 9.45417 9.65944 9.47316 9.6684 9.493C9.67898 9.54323 9.77547 9.47654 9.75837 9.56349Z"
        fill="#F0F2F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1271 11.3975C10.0665 11.4174 10.1101 11.5828 9.97977 11.5106C9.96675 11.5039 9.90975 11.5364 9.92033 11.5174C9.95453 11.4482 9.87799 11.4144 9.8898 11.3591C9.96105 11.3912 9.95697 11.2561 10.0298 11.2924C10.0351 11.2966 10.0351 11.3135 10.0388 11.3245C10.0465 11.3768 10.1451 11.3114 10.1271 11.3975Z"
        fill="#ECEFF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48756 11.8863C9.36258 11.9315 9.45581 11.7749 9.38416 11.7762C9.41754 11.747 9.45499 11.6926 9.48838 11.6947C9.52828 11.6968 9.56492 11.7479 9.60156 11.777C9.547 11.796 9.60441 11.9492 9.48756 11.8863Z"
        fill="#D9DFEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3082 13.3802C14.1759 13.4426 14.3046 13.2582 14.2162 13.2822C14.2073 13.2117 14.3176 13.2544 14.3037 13.1801L14.423 13.2793C14.3746 13.3021 14.423 13.4456 14.3082 13.3802Z"
        fill="#D8DEEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1532 13.2784C15.0437 13.3257 15.1109 13.1729 15.0518 13.1577C15.0917 13.1311 15.1337 13.1028 15.1727 13.0754C15.176 13.1379 15.2586 13.1151 15.2676 13.1704C15.2061 13.1742 15.27 13.3118 15.1532 13.2784Z"
        fill="#DCE1EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9129 12.7103C15.7883 12.7757 15.8803 12.6039 15.8091 12.6111C15.8233 12.5562 15.9052 12.5769 15.91 12.5136L16.0269 12.6044C15.9556 12.6023 16.0432 12.7833 15.9129 12.7103Z"
        fill="#DCE1EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8807 11.9224C10.7504 11.9988 10.8355 11.8278 10.7753 11.8245C10.7814 11.7616 10.8734 11.7886 10.8775 11.7219C10.8897 11.7835 10.9686 11.7658 10.9857 11.8211C10.908 11.8093 11.0187 12.003 10.8807 11.9224Z"
        fill="#D4DAE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4072 10.6401C11.3335 10.635 11.428 10.5147 11.3376 10.5223C11.3531 10.4548 11.4227 10.4687 11.4609 10.4337C11.4805 10.4763 11.5359 10.486 11.5387 10.5472C11.5452 10.6591 11.4495 10.6013 11.4072 10.6401Z"
        fill="#F2F4F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5484 11.5314C13.4357 11.5985 13.4951 11.4486 13.443 11.4322C13.4784 11.4018 13.5126 11.371 13.5484 11.3397C13.5509 11.4022 13.6661 11.3883 13.6335 11.4364C13.614 11.4651 13.6771 11.5964 13.5484 11.5314Z"
        fill="#E6E9F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0448 11.1554C12.9162 11.1689 13.0489 11.0478 12.9655 11.0507C12.998 11.019 13.0294 10.965 13.066 10.9616C13.0978 10.9583 13.1344 11.0119 13.169 11.0414C13.1238 11.0866 13.1307 11.2031 13.0448 11.1554Z"
        fill="#EAEDF3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1617 12.1403C16.1882 12.2129 16.2403 12.2226 16.281 12.2484C16.2395 12.2969 16.2232 12.3712 16.1369 12.3712C16.0486 12.3721 16.1084 12.3024 16.0885 12.2699C16.0392 12.1889 16.2212 12.2572 16.1617 12.1403Z"
        fill="#E4E8F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2416 13.611C15.2106 13.6587 15.2318 13.7042 15.1496 13.7059C15.0555 13.7076 15.0857 13.6363 15.0816 13.6143C15.0739 13.578 15.1276 13.5434 15.1585 13.5506C15.1854 13.5557 15.2375 13.5713 15.2416 13.611Z"
        fill="#CED5E4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8525 12.2588C10.7853 12.2668 10.8411 12.358 10.756 12.355C10.6783 12.3525 10.6897 12.298 10.6811 12.2651C10.6677 12.2191 10.7332 12.2204 10.7479 12.1858C10.7788 12.2081 10.8102 12.2305 10.8525 12.2588Z"
        fill="#DAE0EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0596 12.7406C15.0885 12.7182 15.1174 12.6975 15.148 12.6764C15.1504 12.7232 15.2477 12.6962 15.2151 12.7562C15.2017 12.7802 15.2347 12.8461 15.1504 12.8402C15.0747 12.8347 15.0926 12.7798 15.0596 12.7406Z"
        fill="#D8DEE9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7513 12.8853C14.7366 12.9684 14.691 12.9819 14.6409 12.9917C14.5558 13.0064 14.6198 12.9402 14.6059 12.9148C14.5701 12.8515 14.6605 12.8638 14.671 12.8224C14.6947 12.8545 14.7488 12.8536 14.7513 12.8853Z"
        fill="#CBD2E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2754 10.1481C14.2469 10.162 14.2848 10.2325 14.2237 10.2122C14.1773 10.1958 14.1203 10.173 14.1244 10.1139C14.126 10.0911 14.1813 10.0721 14.2123 10.0522C14.2176 10.0949 14.3055 10.0809 14.2754 10.1481Z"
        fill="#D1D8E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5746 11.9995C16.498 12.0307 16.5326 11.9653 16.5192 11.9463C16.4679 11.8741 16.5693 11.8847 16.5746 11.8416C16.6059 11.8631 16.667 11.8876 16.6646 11.9062C16.6628 11.9303 16.6527 11.953 16.6362 11.9701C16.6197 11.9872 16.5978 11.9976 16.5746 11.9995Z"
        fill="#DADFEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6465 11.3555C12.6465 11.4045 12.7418 11.3851 12.7096 11.4463C12.6966 11.4712 12.7243 11.5379 12.6583 11.5239C12.6083 11.5134 12.5578 11.483 12.5562 11.4315C12.5562 11.4074 12.614 11.3817 12.6465 11.3555Z"
        fill="#CAD2E2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9461 12.8827C13.9087 12.8646 13.907 12.8135 13.9095 12.789C13.9136 12.7565 13.9555 12.7126 13.9913 12.7303C14.0133 12.7413 14.0756 12.735 14.0605 12.7941C14.0467 12.8506 13.9921 12.8587 13.9461 12.8827Z"
        fill="#D0D7E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5186 12.8084C15.5202 12.808 15.5487 12.767 15.5808 12.7662C15.604 12.7653 15.6277 12.8151 15.6525 12.843C15.6191 12.8527 15.6476 12.9165 15.5817 12.9156C15.5222 12.9143 15.5222 12.8865 15.5186 12.8084Z"
        fill="#EFF2F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9046 11.7394C12.8936 11.785 12.8936 11.8133 12.8545 11.8125C12.8146 11.8116 12.7951 11.7964 12.7959 11.7542C12.7959 11.7209 12.8171 11.6976 12.8411 11.6997C12.8688 11.7019 12.9083 11.7099 12.9046 11.7394Z"
        fill="#CCD4E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4139 11.0826C10.4334 11.1045 10.4558 11.1218 10.4644 11.1442C10.4717 11.1628 10.4603 11.191 10.4351 11.194C10.407 11.1965 10.3768 11.1957 10.3679 11.1594C10.3597 11.1239 10.3895 11.1113 10.4139 11.0826Z"
        fill="#E0E5EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1265 11.3977C10.1155 11.3492 10.043 11.38 10.0381 11.3242C10.0536 11.3665 10.1745 11.2723 10.1265 11.3977Z"
        fill="#7085B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75782 9.56332C9.74886 9.5114 9.67192 9.55235 9.66785 9.49283C9.68942 9.52829 9.79853 9.44429 9.75782 9.56332Z"
        fill="#5C75A7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2632 9.14587C14.2709 9.11379 14.2958 9.06144 14.286 9.05174C14.1533 8.92932 14.3304 8.90864 14.3568 8.88374C14.4053 8.83519 14.3975 9.03992 14.5254 8.91033C14.4566 9.06862 14.3646 9.11421 14.2587 9.14122L14.2632 9.14587Z"
        fill="#E7EAF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3476 9.01465C10.2181 9.00157 10.3333 8.82682 10.3052 8.72551C10.2702 8.59466 10.3622 8.59635 10.4518 8.59593C10.5906 8.59508 10.6692 8.65586 10.6692 8.80782C10.6688 8.95725 10.602 9.01634 10.3476 9.01465Z"
        fill="#15A24A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2841 9.85811C15.2556 9.94844 15.1502 10.005 15.1322 10.1164C15.1306 10.1312 15.0899 10.1283 15.0822 10.0852C15.0496 9.88344 15.048 9.88386 14.9043 9.99192C14.8929 10.0008 14.8729 10.0088 14.8619 10.005C14.8407 9.9953 14.8322 9.97335 14.8448 9.94844C14.8925 9.8522 14.9372 9.75343 14.9906 9.66014C15.0264 9.59852 15.07 9.65001 15.105 9.66774C15.1819 9.707 15.2768 9.72937 15.2841 9.85811Z"
        fill="#13A248"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6479 10.1867C15.573 10.2179 15.5368 10.1592 15.4887 10.1381C15.4562 10.1238 15.4256 10.1103 15.4036 10.1478C15.3796 10.1875 15.4036 10.2103 15.4366 10.228C15.4619 10.2411 15.4887 10.2512 15.5075 10.2719C15.5404 10.3078 15.6438 10.2981 15.6019 10.3745C15.5689 10.4353 15.5136 10.3644 15.4769 10.342C15.4138 10.3019 15.2888 10.28 15.3023 10.2103C15.323 10.0984 15.3845 9.97688 15.4663 9.90006C15.5319 9.83843 15.6044 9.95493 15.678 9.98405C15.7098 9.9963 15.7639 10.0073 15.7546 10.0592C15.7456 10.1128 15.7074 10.0816 15.678 10.0744C15.6092 10.0579 15.5425 9.9372 15.4883 10.0208C15.4558 10.0706 15.5897 10.0913 15.632 10.1461C15.6386 10.1542 15.6402 10.1651 15.6479 10.1867Z"
        fill="#11A147"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5231 10.7832C16.5353 10.8819 16.4966 10.9491 16.3969 10.9322C16.2935 10.9144 16.2149 10.849 16.197 10.7317C16.1953 10.7199 16.2092 10.6958 16.2206 10.6924C16.2271 10.6902 16.2341 10.6895 16.241 10.6903C16.2478 10.6912 16.2544 10.6936 16.2603 10.6974C16.2661 10.7012 16.2711 10.7062 16.2749 10.7122C16.2786 10.7182 16.2811 10.725 16.2821 10.7321C16.2951 10.8127 16.3358 10.8486 16.4176 10.8503C16.4335 10.7182 16.2035 10.6578 16.3358 10.5206C16.4022 10.4518 16.4832 10.5088 16.541 10.5641C16.5943 10.6143 16.6595 10.6696 16.5622 10.7414C16.5483 10.7338 16.5296 10.7215 16.5194 10.73C16.4986 10.7443 16.519 10.765 16.5231 10.7832Z"
        fill="#11A147"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7168 9.52651C13.6684 9.49823 13.6708 9.47122 13.6786 9.44167C13.694 9.37877 13.6627 9.34585 13.6073 9.32812C13.552 9.30955 13.5092 9.31208 13.4941 9.38384C13.4889 9.41592 13.4726 9.43829 13.4388 9.42647C13.3997 9.4117 13.4192 9.37877 13.4266 9.35471C13.442 9.29942 13.4559 9.2437 13.4803 9.19094C13.5023 9.13817 13.4868 9.04151 13.567 9.04784C13.6521 9.05417 13.7441 9.07528 13.8088 9.1483C13.8378 9.18038 13.8496 9.23568 13.8259 9.25974C13.7571 9.33319 13.7628 9.43702 13.7168 9.52651Z"
        fill="#0FA046"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2103 8.98886C11.0788 9.04036 10.975 9.01039 10.8736 9.0125C10.8276 9.01377 10.8235 8.97789 10.8235 8.94159C10.8268 8.90149 10.8268 8.86097 10.8268 8.82087C10.8317 8.59841 10.9078 8.54143 11.1187 8.60348C11.1423 8.61023 11.1647 8.61445 11.1663 8.64189C11.1663 8.67988 11.1399 8.68664 11.1073 8.68579C11.1028 8.68495 11.0975 8.68368 11.0918 8.68452C11.0287 8.70056 10.9156 8.6267 10.9135 8.72462C10.9111 8.79976 11.0344 8.74657 11.0975 8.7719C11.1065 8.7757 11.1163 8.78414 11.1301 8.79469C11.0902 8.86941 11.0238 8.82762 10.9726 8.83648C10.942 8.84155 10.9021 8.82762 10.8976 8.87912C10.8919 8.9399 10.9327 8.93694 10.9726 8.94117C11.0405 8.94961 11.1142 8.92302 11.2103 8.98886Z"
        fill="#0B9F43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.132 9.0212C10.0611 9.00516 10.0709 8.96295 10.0587 8.9334C10.0375 8.87895 9.99559 8.87895 9.94958 8.88275C9.89707 8.88782 9.86857 8.90892 9.87305 8.96928C9.8763 8.99883 9.8877 9.04653 9.83478 9.03977C9.78918 9.03387 9.80221 8.98786 9.79977 8.95662C9.79569 8.90554 9.79081 8.8532 9.79569 8.80255C9.80221 8.74599 9.7501 8.65946 9.82867 8.6392C9.90969 8.61725 10.0033 8.60163 10.0876 8.64342C10.1242 8.66157 10.1531 8.70589 10.1397 8.74177C10.1088 8.8321 10.1332 8.9237 10.132 9.0212Z"
        fill="#079D3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.27313 8.87755C9.28616 8.76316 9.33461 8.67029 9.45552 8.67452C9.57155 8.67831 9.63343 8.76189 9.63343 8.88219C9.63343 9.00165 9.57969 9.08396 9.45715 9.08312C9.33257 9.08227 9.29023 8.98856 9.27313 8.87755Z"
        fill="#15A24A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0079 11.0219C16.9965 11.1354 16.9424 11.2211 16.8174 11.2325C16.7022 11.2422 16.6411 11.1738 16.6321 11.0616C16.622 10.948 16.7294 10.8395 16.839 10.8404C16.935 10.8412 17.0055 10.9134 17.0079 11.0219Z"
        fill="#11A147"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7272 9.7007C14.8192 9.77752 14.7077 9.82818 14.7036 9.89276C14.627 9.85603 14.5314 9.87081 14.4695 9.80116C14.4035 9.72687 14.4157 9.64287 14.4589 9.56098C14.5065 9.47445 14.5798 9.43731 14.6698 9.47023C14.752 9.50062 14.8135 9.55803 14.7736 9.66609C14.7105 9.67791 14.6588 9.5918 14.57 9.64962C14.6291 9.67791 14.6999 9.63865 14.7272 9.7007Z"
        fill="#0C9F43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5175 8.83136C12.4637 8.89678 12.4242 8.87146 12.3872 8.8723C12.3514 8.8723 12.3131 8.84149 12.2687 8.8799C12.3086 8.94743 12.4051 8.90396 12.4426 8.97107C12.4373 8.98078 12.4348 8.99387 12.4279 9.00062C12.3742 9.04452 12.2883 8.93815 12.2549 9.02595C12.2243 9.1015 12.3045 9.07533 12.3391 9.088C12.3782 9.10404 12.4267 9.09813 12.4739 9.153C12.3644 9.20281 12.2337 9.17959 12.1885 9.12472C12.1173 9.03819 12.1673 8.88454 12.2215 8.80772C12.2854 8.71696 12.4022 8.80561 12.5175 8.83136Z"
        fill="#19A44D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2096 9.19822C13.1172 9.18809 13.0122 9.12055 12.9857 9.27842C12.9849 9.28349 12.9527 9.29066 12.9429 9.28391C12.9319 9.27758 12.9258 9.25394 12.9279 9.24043C12.9519 9.14039 12.9641 9.03318 13.0069 8.94327C13.0565 8.84196 13.1408 8.92765 13.2104 8.93736C13.2935 8.94876 13.3395 9.0011 13.3305 9.09185C13.3224 9.16994 13.2654 9.19105 13.2096 9.19822Z"
        fill="#0D9F44"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3091 9.44026C14.3001 9.56731 14.2399 9.65511 14.1173 9.65891C14.0078 9.66313 13.9464 9.58335 13.9488 9.47023C13.9521 9.34782 14.0095 9.25917 14.1369 9.25538C14.2537 9.25115 14.2989 9.33262 14.3091 9.44026Z"
        fill="#0FA046"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5695 8.9816C11.5561 9.01157 11.5451 9.0542 11.5064 9.04449C11.4392 9.02803 11.4694 8.96092 11.4531 8.91702C11.4034 8.86679 11.4164 8.93601 11.401 8.94868C11.3953 8.97991 11.4091 9.02761 11.3684 9.03394C11.3183 9.04027 11.3301 8.99047 11.3314 8.96092C11.3354 8.89929 11.3362 8.83471 11.3525 8.77561C11.3668 8.72496 11.3151 8.63167 11.4124 8.63083C11.4983 8.63041 11.4795 8.72116 11.4905 8.77561C11.5019 8.83808 11.508 8.86468 11.5573 8.80263C11.6082 8.7887 11.6188 8.82711 11.6306 8.86426C11.6371 8.91829 11.6139 8.95585 11.5695 8.9816Z"
        fill="#0EA044"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8965 10.5321C15.9266 10.5321 15.9572 10.5734 15.9865 10.538C16.0089 10.5114 15.9889 10.4852 15.969 10.4637C15.9543 10.4472 15.9388 10.4316 15.925 10.4139C15.8835 10.362 15.8253 10.3122 15.8827 10.237C15.9348 10.1682 15.9954 10.1851 16.0557 10.2286C16.0667 10.2505 16.063 10.2716 16.0516 10.2915C16.0072 10.3637 16.0899 10.4076 16.0883 10.4692C16.1009 10.527 16.109 10.5853 16.0475 10.6186C15.971 10.6608 15.912 10.622 15.8558 10.5713C15.8497 10.538 15.8615 10.5215 15.8965 10.5321Z"
        fill="#10A146"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7268 9.70045C14.6955 9.72324 14.6323 9.64937 14.6344 9.70509C14.6385 9.80386 14.5908 9.795 14.5464 9.76587C14.4866 9.72746 14.4833 9.66161 14.5212 9.60083C14.5578 9.54131 14.6095 9.5067 14.6722 9.55524C14.7105 9.58437 14.7394 9.62827 14.7724 9.66583L14.7268 9.70045Z"
        fill="#ECF7F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5964 8.88174C11.5845 8.85568 11.5714 8.83018 11.5573 8.80534C11.5716 8.76777 11.5846 8.72978 11.6017 8.69348C11.6172 8.65929 11.644 8.6403 11.6819 8.65296C11.7242 8.66773 11.721 8.7015 11.7185 8.73696C11.7096 8.84038 11.7014 8.94337 11.6937 9.04678C11.6343 9.08604 11.6192 9.05016 11.6094 8.99698C11.6021 8.95856 11.6212 8.9172 11.5964 8.88174Z"
        fill="#17A34C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0892 10.4689C16.0526 10.4301 16.0135 10.3933 15.9793 10.352C15.9394 10.303 15.9549 10.273 16.0143 10.265C16.1002 10.2849 16.1527 10.3279 16.1275 10.4301C16.1157 10.4427 16.1026 10.4562 16.0892 10.4689Z"
        fill="#D7EFE0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1273 10.4301C16.0878 10.3769 16.0773 10.3009 16.0142 10.265L16.0565 10.2283C16.1249 10.2785 16.2499 10.3072 16.1273 10.4301Z"
        fill="#2BAB5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5226 10.7833C16.5173 10.78 16.5104 10.7783 16.5079 10.7736C16.4753 10.7112 16.3675 10.655 16.4041 10.5997C16.4501 10.533 16.4981 10.612 16.5234 10.6689C16.5348 10.693 16.5486 10.7166 16.5617 10.7415C16.5482 10.7555 16.5348 10.7694 16.5226 10.7833Z"
        fill="#E6F5EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8968 10.5321C15.8838 10.5452 15.8712 10.5587 15.8561 10.5718C15.839 10.5591 15.8154 10.5503 15.8044 10.5321C15.7837 10.4912 15.7242 10.4367 15.793 10.4046C15.8508 10.3759 15.8508 10.4523 15.8696 10.4895C15.8773 10.5051 15.8871 10.5182 15.8968 10.5321Z"
        fill="#29AA59"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5962 8.88159C11.6557 8.90565 11.6243 8.95715 11.6252 8.99556C11.6284 9.04832 11.6463 9.06605 11.694 9.04748C11.5946 9.1471 11.6072 9.01413 11.569 8.98459C11.5791 8.94955 11.5869 8.91536 11.5962 8.88159Z"
        fill="#DDF1E4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.399 8.94949V8.81357C11.456 8.83214 11.4577 8.87308 11.4519 8.91783C11.4345 8.92838 11.4163 8.93851 11.399 8.94949Z"
        fill="#6FC690"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.387 8.80988C10.3927 8.74826 10.3642 8.66805 10.4655 8.67481C10.5396 8.67945 10.5934 8.71026 10.5974 8.79806C10.6015 8.89262 10.5482 8.93103 10.4639 8.93736C10.3568 8.94622 10.4004 8.85885 10.387 8.80988Z"
        fill="#F5FBF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0715 9.73846C15.1139 9.76252 15.1786 9.76716 15.1925 9.83217C15.1994 9.86594 15.1713 9.88831 15.1424 9.8786C15.0886 9.86172 15.0174 9.86087 15.0101 9.78236C15.008 9.75956 15.0316 9.73086 15.0715 9.73846Z"
        fill="#EFF8F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7548 9.21924C13.7438 9.26314 13.7141 9.27833 13.6799 9.26525C13.6339 9.24794 13.5598 9.26694 13.559 9.18336C13.5573 9.13988 13.5858 9.1268 13.6241 9.13988C13.6746 9.15677 13.7357 9.15466 13.7548 9.21924Z"
        fill="#DEF2E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.95925 8.69986C9.99915 8.70492 10.0623 8.68298 10.0582 8.75347C10.0541 8.8151 9.99386 8.80539 9.9507 8.80623C9.9161 8.8075 9.86643 8.82607 9.86317 8.76149C9.86073 8.68466 9.91854 8.70872 9.95925 8.69986Z"
        fill="#F3FAF5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.44547 8.7587C9.51915 8.76376 9.55579 8.80935 9.55864 8.88111C9.56272 8.94527 9.53218 9.00014 9.46867 9.00605C9.39539 9.01323 9.36241 8.9554 9.35834 8.88491C9.35427 8.8199 9.3787 8.77263 9.44547 8.7587Z"
        fill="#F3FAF6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9269 11.0364C16.9163 11.1065 16.8756 11.1559 16.797 11.1559C16.7339 11.155 16.7103 11.1073 16.7168 11.0495C16.7229 10.971 16.7681 10.9204 16.845 10.9216C16.9057 10.9233 16.9293 10.9685 16.9269 11.0364Z"
        fill="#F9FCFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1137 8.99072C13.1549 9.01689 13.2375 8.98988 13.2493 9.0743C13.253 9.10638 13.2188 9.12284 13.1915 9.11651C13.1382 9.10427 13.0637 9.12411 13.0572 9.03504C13.0559 9.00761 13.0677 8.97806 13.1137 8.99072Z"
        fill="#E7F5EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2273 9.45016C14.2195 9.52318 14.1849 9.57426 14.1137 9.57426C14.0436 9.5751 14.0188 9.52445 14.0221 9.45775C14.0278 9.38431 14.0681 9.33534 14.1369 9.33239C14.2053 9.32986 14.2273 9.38642 14.2273 9.45016Z"
        fill="#F5FBF7"
      />
    </svg>
  )
}

export default BrazilFlagIcon
