import * as c from '@chakra-ui/react'
import { Container } from '@retriever-ui/react'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Highlights from '../highlights'
import { SearchContext } from '../search-context'
import Suggestions from '../suggestions'
import { buildings, cities } from './lists'

const Thematic = React.lazy(() => import('./thematic'))

const variants: Variants = {
  initial: {
    opacity: 0,
    y: -100,
  },
  exit: {
    opacity: 0,
    y: -100,
    transition: {
      duration: 0.2,
    },
  },
  animate: {
    opacity: 1,
    y: 0,

    transition: {
      duration: 0.2,
    },
  },
}

const HotContent = () => {
  const { t } = useTranslation('components/search')
  const { valid, controls } = React.useContext(SearchContext)

  return (
    <AnimatePresence initial={false}>
      {valid.open && (
        <motion.div
          exit="exit"
          initial={valid.closing ? 'exit' : 'initial'}
          animate={controls.opening}
          variants={variants}
        >
          <Container
            d={['flex', 'grid']}
            pos="relative"
            zIndex={0}
            gridTemplateColumns="442px 440px"
            gap={16}
            flexDirection="column"
            mTop={[24, 38]}
            pBottom={80}
            pX={[20, '0px']}
            maxW={900}
          >
            <c.Flex
              direction="column"
              gap={[4, 3]}
              minW={['100%', '442px']}
              maxW={['100%', '442px']}
            >
              <Suggestions
                title={t('components/search:hots.cities')}
                list={cities ?? []}
              />
              <Suggestions
                title={t('components/search:hots.buildings')}
                list={buildings ?? []}
              />
              <React.Suspense fallback="">
                <Thematic />
              </React.Suspense>
            </c.Flex>
            <React.Suspense fallback="">
              <Highlights />
            </React.Suspense>
          </Container>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default HotContent
