import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PropertyCardContext } from '../../../property-card.context'
import AreaIcon from '../icons/area.icon'
import BathroomIcon from '../icons/bathroom.icon'
import BedIcon from '../icons/bed.icon'
import ParkingIcon from '../icons/parking.icon'
import PetIcon from '../icons/pet.icon'

const AptItems = () => {
  const { property } = React.useContext(PropertyCardContext)
  const { t } = useTranslation('card-apartment')
  const apartmentItems = [
    {
      icon: <AreaIcon />,
      text: `${property?.area} m²`,
    },
    {
      icon: <BedIcon />,
      text: `${property?.bedrooms} ${t('card-apartment:BED')}`,
    },
    {
      icon: <ParkingIcon />,
      text: `${property?.parkingSpaces ?? 0} ${t(
        'card-apartment:PARKING_SPACE'
      )}`,
    },
    {
      icon: <BathroomIcon />,
      text: `${property?.bathrooms} ${t('card-apartment:BATHROOM')}`,
    },
    {
      icon: <PetIcon />,
      text:
        property?.petFriendly === true
          ? t('card-apartment:PET_ALLOWED')
          : t('card-apartment:PET_NOT_ALLOWED'),
    },
  ]
  return (
    <Flex gap="20px" flexWrap="wrap" width={['90%', null, '75%']}>
      {apartmentItems.map(({ icon, text }) => {
        return (
          <Flex key={text} gap="5px" alignItems="center">
            <Box>{icon}</Box>
            <Text fontSize="14px" color="black.500">
              {text}
            </Text>
          </Flex>
        )
      })}
    </Flex>
  )
}

export default AptItems
