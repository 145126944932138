import React, { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'
import OffersList from 'src/pages/landing-pages/housi-offer/list'

import { RequireSeo } from './custom'
import * as Components from './route-components'

const offersRoutes = (): RouteObject[] => [
  {
    path: '/lista-de-ofertas',
    element: (
      <RequireSeo title="Lista de ofertas Housi">
        <Suspense fallback="">
          <OffersList />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/housi-ofertas/:aliasId',
    element: (
      <RequireSeo title="Housi ofertas">
        <Suspense fallback="">
          <Components.HousiOfferComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/housi-ofertas',
    element: (
      <RequireSeo title="Housi ofertas">
        <Suspense fallback="">
          <Components.HousiOfferComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
]

export default offersRoutes
