/* eslint-disable unused-imports/no-unused-vars */
import { assign, createMachine } from 'xstate'

export const enum date_states {
  idle = 'idle',
  disabled = 'disabled',
  hovered = 'hovered',
  focused = 'focused',
}

export const enum date_events {
  focus = 'focus',
  blur = 'blur',
  hover = 'hover',
}
export type ContextType = {
  hoverDate?: Date
}

export interface EventsType {
  type: date_events
}

export interface StatesType {
  value: date_states
  context: ContextType
}

const dateMachine = createMachine<ContextType, EventsType, StatesType>(
  {
    id: 'date-machine',
    initial: date_states.idle,
    context: {
      hoverDate: undefined,
    },
    states: {
      [date_states.disabled]: {},
      [date_states.idle]: {
        on: {
          [date_events.focus]: {
            target: date_states.focused,
          },
          [date_events.hover]: {
            target: date_states.hovered,
            actions: 'hover',
          },
        },
      },
      [date_states.focused]: {
        on: {
          [date_events.blur]: {
            target: date_states.idle,
          },
        },
      },
      [date_states.hovered]: {
        on: {
          [date_events.focus]: {
            target: date_states.focused,
          },
          [date_events.blur]: {
            target: date_states.idle,
            actions: 'blur',
          },
        },
      },
    },
  },
  {
    actions: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      blur: assign((_) => ({
        hoverDate: undefined,
      })),
      hover: assign((_, event: any) => ({
        hoverDate: event.hoverDate,
      })),
    },
  }
)

export default dateMachine
