export const BriefcaseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.8327 17.5H4.16602C3.50297 17.5 2.86709 17.2366 2.39825 16.7678C1.92941 16.2989 1.66602 15.663 1.66602 15L1.66602 8.33333C1.66602 7.67029 1.92941 7.03441 2.39825 6.56557C2.86709 6.09673 3.50297 5.83333 4.16602 5.83333H6.66602V4.58333C6.66602 4.0308 6.88551 3.5009 7.27621 3.11019C7.66691 2.71949 8.19682 2.5 8.74935 2.5L11.2493 2.5C11.8019 2.5 12.3318 2.71949 12.7225 3.11019C13.1132 3.5009 13.3327 4.0308 13.3327 4.58333V5.83333H15.8327C16.4957 5.83333 17.1316 6.09673 17.6005 6.56557C18.0693 7.03441 18.3327 7.67029 18.3327 8.33333V15C18.3327 15.663 18.0693 16.2989 17.6005 16.7678C17.1316 17.2366 16.4957 17.5 15.8327 17.5ZM14.166 7.5V15.8333H15.8327C16.0537 15.8333 16.2657 15.7455 16.4219 15.5893C16.5782 15.433 16.666 15.221 16.666 15V8.33333C16.666 8.11232 16.5782 7.90036 16.4219 7.74408C16.2657 7.5878 16.0537 7.5 15.8327 7.5H14.166ZM7.49935 7.5V15.8333H12.4993V7.5H7.49935ZM4.16602 7.5C3.945 7.5 3.73304 7.5878 3.57676 7.74408C3.42048 7.90036 3.33268 8.11232 3.33268 8.33333V15C3.33268 15.221 3.42048 15.433 3.57676 15.5893C3.73304 15.7455 3.945 15.8333 4.16602 15.8333H5.83268V7.5H4.16602ZM8.74935 4.16667C8.63884 4.16667 8.53286 4.21057 8.45472 4.28871C8.37658 4.36685 8.33268 4.47283 8.33268 4.58333V5.83333H11.666V4.58333C11.666 4.47283 11.6221 4.36685 11.544 4.28871C11.4658 4.21057 11.3599 4.16667 11.2493 4.16667H8.74935Z"
      fill="#6C6C6C"
    />
  </svg>
)
