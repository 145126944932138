import * as c from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import HousiLogoIcon from '../../icons/housi-logo-footer.icon'
import DownloadApps from './download-apps'
import Links from './links'

export default function PrimaryFooter() {
  const [isMobile] = c.useMediaQuery('(max-width: 768px)')

  return (
    <c.Box w="100%" bg="black.0">
      <c.Container
        as={c.Flex}
        pt={['m5', 'l4']}
        pb={['m5', 'l2']}
        px={['m5', '0']}
        w="100%"
        maxW="1200px"
        direction={['column', 'row']}
        justify={['start', 'space-between']}
        gap="m5"
      >
        <c.Flex direction={['column', 'row']} gap="l3">
          <c.Box as={Link} to="/">
            <HousiLogoIcon />
          </c.Box>
          {isMobile ? <DownloadApps /> : <Links />}
        </c.Flex>
        {isMobile ? <Links /> : <DownloadApps />}
      </c.Container>
    </c.Box>
  )
}
