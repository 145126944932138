import * as c from '@chakra-ui/react'
import { ArrowRightIcon } from 'src/assets/icons/nav/arrow-right.icon'
interface ItemInterface {
  label: string
  onClick: () => void
  Icon: (props: any) => JSX.Element
}

const Item = ({ label, onClick, Icon }: ItemInterface) => {
  return (
    <>
      <c.Button
        onClick={onClick}
        variant="outline"
        gap="0px"
        px="0px"
        py="16px"
        border="none"
        height="50px"
        fontWeight="medium"
        fontSize={['t-md.1', 't-md.0']}
        alignItems="center"
        leftIcon={
          <c.Icon as={Icon} w="1.25rem" h="1.25rem" color="black.400" />
        }
        _hover={{ bg: 'none' }}
      >
        {label}

        <c.Icon
          ml="auto"
          as={ArrowRightIcon}
          color="black.300"
          w="1.25rem"
          h="1.25rem"
        />
      </c.Button>
      <c.Divider h="1px" w="full" />
    </>
  )
}

export default Item
