import { Button, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ArrowPink from 'src/pages/landing-pages/housi-offer/content/arrow-pink.icon'

import { PropertyCardContext } from '../../../property-card.context'

const AptReviewFooter = ({ onClose }) => {
  const { t } = useTranslation('card-apartment')
  const { clickRedirect } = React.useContext(PropertyCardContext)
  return (
    <>
      <Button
        borderColor="primary.500"
        color="primary.500"
        variant="outline"
        rightIcon={<ArrowPink />}
        onClick={clickRedirect}
      >
        {t('card-apartment:REDIRECT')}
      </Button>
      <Text
        fontWeight="bold"
        textAlign="center"
        onClick={onClose}
        cursor="pointer"
        marginBottom="20px"
        role="button"
      >
        {t('card-apartment:BACK')}
      </Text>
    </>
  )
}

export default AptReviewFooter
