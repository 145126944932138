import * as c from '@chakra-ui/react'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import React from 'react'
import { SearchIcon } from 'src/assets/icons/search.icon'

import { SearchContext } from '../search-context'

const Button = () => {
  const searchState = React.useContext(SearchContext)

  const variants: Variants = {
    initial: {
      y: 0,
      alignSelf: 'center',
      transition: {
        duration: 0.3,
      },
      position: 'relative',
    },
    animate: {
      y: searchState.valid.closing ? 0 : 'calc(50% + 40px)',
      alignSelf: searchState.valid.closing ? 'center' : 'flex-end',
      transition: {
        duration: 0.3,
      },
    },
  }

  const disabled =
    !(
      searchState.context.coords?.lat &&
      searchState.context.coords?.long &&
      searchState.context.start &&
      searchState.context.end
    ) && searchState.valid.open

  return (
    <AnimatePresence>
      <motion.div
        variants={variants}
        initial="initial"
        exit="initial"
        animate={
          searchState.valid.closed || searchState.valid.closing
            ? 'initial'
            : 'animate'
        }
      >
        <c.Button
          position="absolute"
          top={-5}
          left={-10}
          border="none"
          color="black.0"
          bg="primary.500"
          py={2}
          px={3}
          minW="93px"
          maxW="93px"
          minH="36px"
          maxH="36px"
          borderRadius="20px"
          display="flex"
          gap={1}
          alignItems="center"
          _hover={
            disabled
              ? {}
              : {
                  background: 'primary.600',
                }
          }
          onClick={searchState.handleSearch}
          disabled={disabled}
          _disabled={{
            bg: 'primary.100',
            cursor: 'no-drop',
          }}
        >
          <SearchIcon color="white" />
          <c.Text color="black.0" fontSize={12} fontWeight={700}>
            Buscar
          </c.Text>
        </c.Button>
      </motion.div>
    </AnimatePresence>
  )
}
export default Button
