import * as c from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import AppStoreIcon from 'src/components/footer/icons/app-store.icon'
import GooglePlayIcon from 'src/components/footer/icons/google-play.icon'

import BracketLeft from '../../../icons/bracket-left.icon'
import BracketRight from '../../../icons/bracket-right.icon'

interface ContainerProps {
  children: JSX.Element
}

const Container = ({ children }: ContainerProps) => {
  const { t } = useTranslation('footer')

  return (
    <c.Flex gap="s2">
      <c.Icon as={BracketLeft} />
      <c.Flex direction="column" justify={['center', 'start']} gap="s2">
        <c.Text color="black.600" fontSize="t-md.0" fontWeight="bold">
          {t('footer:DOWNLOAD')}
        </c.Text>
        {children}
      </c.Flex>
      <c.Icon as={BracketRight} />
    </c.Flex>
  )
}

export default function DownloadApps() {
  const [isMobile] = c.useMediaQuery('(max-width: 768px)')
  const isMacOrIOS = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)

  if (!isMobile)
    return (
      <Container>
        <>
          <c.Link
            isExternal
            href="https://apps.apple.com/br/app/housi/id1472319764"
          >
            <AppStoreIcon />
          </c.Link>
          <c.Link
            isExternal
            href="https://play.google.com/store/apps/details?id=br.com.xapps.housiapp"
          >
            <GooglePlayIcon />
          </c.Link>
        </>
      </Container>
    )

  if (isMacOrIOS)
    return (
      <Container>
        <c.Link
          isExternal
          href="https://apps.apple.com/br/app/housi/id1472319764"
        >
          <AppStoreIcon />
        </c.Link>
      </Container>
    )

  return (
    <Container>
      <c.Link
        isExternal
        href="https://play.google.com/store/apps/details?id=br.com.xapps.housiapp"
      >
        <GooglePlayIcon />
      </c.Link>
    </Container>
  )
}
