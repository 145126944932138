import { retriever } from '@retriever-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import StepsProvider, { StepsContext } from './steps-context'

interface StepChildProps {
  children?: React.ReactNode
  step: number
}

interface StepsProps {
  children?: React.ReactNode
  steps: number[]
}

export const StepChild = ({ children, step }: StepChildProps) => {
  const { active } = React.useContext(StepsContext)

  const isComeBack = step === 1

  return (
    <AnimatePresence initial={false}>
      <>
        {step === active && (
          <motion.div
            initial={{ opacity: 0, x: isComeBack ? 100 : -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: isComeBack ? 100 : -100 }}
            transition={{ duration: 0.3 }}
          >
            <retriever.div>{children}</retriever.div>
          </motion.div>
        )}
      </>
    </AnimatePresence>
  )
}

const Steps = ({ children, steps }: StepsProps) => {
  return <StepsProvider steps={steps}>{children}</StepsProvider>
}

export default Steps
