import qs from 'qs'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const HeaderSeo = () => {
  const { t } = useTranslation('search-page')
  const location = useLocation()
  const { term } = qs.parse(location.search, { ignoreQueryPrefix: true })

  return useMemo(
    () => (
      <Helmet>
        <title>{t('search-page:SEO_TITLE', { term })}</title>
        <meta
          name="description"
          content={t('search-page:SEO_DESCRIPTION', { term })}
        />
        <meta
          property="og:title"
          content={t('search-page:SEO_TITLE', { term })}
        />
        <meta
          property="og:description"
          content={t('search-page:SEO_DESCRIPTION', { term })}
        />
      </Helmet>
    ),
    []
  )
}

export default HeaderSeo
