export const LoginIcon = (props: any) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1667 16.6666C13.9457 16.6666 13.7337 16.5788 13.5774 16.4225C13.4211 16.2662 13.3333 16.0543 13.3333 15.8333C13.3333 15.6122 13.4211 15.4003 13.5774 15.244C13.7337 15.0877 13.9457 14.9999 14.1667 14.9999H15V4.99992H14.1667C13.9457 4.99992 13.7337 4.91212 13.5774 4.75584C13.4211 4.59956 13.3333 4.3876 13.3333 4.16659C13.3333 3.94557 13.4211 3.73361 13.5774 3.57733C13.7337 3.42105 13.9457 3.33325 14.1667 3.33325H15.8333C16.0543 3.33325 16.2663 3.42105 16.4226 3.57733C16.5789 3.73361 16.6667 3.94557 16.6667 4.16659V15.8333C16.6667 16.0543 16.5789 16.2662 16.4226 16.4225C16.2663 16.5788 16.0543 16.6666 15.8333 16.6666H14.1667ZM8.8425 14.0158C8.75288 13.9528 8.67654 13.8728 8.61785 13.7804C8.55916 13.6879 8.51927 13.5848 8.50047 13.4769C8.48166 13.369 8.4843 13.2585 8.50824 13.1516C8.53219 13.0447 8.57696 12.9436 8.64 12.8541L10.0717 10.8183C10.0481 10.8246 10.0242 10.8296 10 10.8333H3.33333C3.11232 10.8333 2.90036 10.7455 2.74408 10.5892C2.5878 10.4329 2.5 10.2209 2.5 9.99992C2.5 9.7789 2.5878 9.56694 2.74408 9.41066C2.90036 9.25438 3.11232 9.16658 3.33333 9.16658H10L8.5 7.16659C8.36739 6.98977 8.31045 6.76753 8.34171 6.54873C8.37296 6.32994 8.48986 6.13253 8.66667 5.99992C8.84348 5.86731 9.06573 5.81037 9.28452 5.84163C9.50331 5.87288 9.70073 5.98977 9.83333 6.16659L12.3333 9.49992C12.4387 9.64058 12.4968 9.81094 12.4995 9.98665C12.5022 10.1624 12.4493 10.3344 12.3483 10.4783L10 13.8133C9.87291 13.9938 9.67938 14.1165 9.46189 14.1545C9.2444 14.1924 9.02074 14.1425 8.84 14.0158H8.8425Z"
      fill="currentColor"
    />
  </svg>
)
