export const CreditCardIcon = (props: any) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8333 15.8334H4.16667C3.50363 15.8334 2.86774 15.57 2.3989 15.1012C1.93006 14.6323 1.66667 13.9965 1.66667 13.3334V6.66675C1.66667 6.00371 1.93006 5.36782 2.3989 4.89898C2.86774 4.43014 3.50363 4.16675 4.16667 4.16675H15.8333C16.4964 4.16675 17.1323 4.43014 17.6011 4.89898C18.0699 5.36782 18.3333 6.00371 18.3333 6.66675V13.3334C18.3333 13.9965 18.0699 14.6323 17.6011 15.1012C17.1323 15.57 16.4964 15.8334 15.8333 15.8334ZM3.33333 9.16675V13.3334C3.33333 13.5544 3.42113 13.7664 3.57741 13.9227C3.73369 14.0789 3.94565 14.1667 4.16667 14.1667H15.8333C16.0543 14.1667 16.2663 14.0789 16.4226 13.9227C16.5789 13.7664 16.6667 13.5544 16.6667 13.3334V9.16675H3.33333ZM4.16667 5.83341C3.94565 5.83341 3.73369 5.92121 3.57741 6.07749C3.42113 6.23377 3.33333 6.44573 3.33333 6.66675V7.50008H16.6667V6.66675C16.6667 6.44573 16.5789 6.23377 16.4226 6.07749C16.2663 5.92121 16.0543 5.83341 15.8333 5.83341H4.16667ZM14.1667 12.5001H12.5C12.279 12.5001 12.067 12.4123 11.9107 12.256C11.7545 12.0997 11.6667 11.8878 11.6667 11.6667C11.6667 11.4457 11.7545 11.2338 11.9107 11.0775C12.067 10.9212 12.279 10.8334 12.5 10.8334H14.1667C14.3877 10.8334 14.5996 10.9212 14.7559 11.0775C14.9122 11.2338 15 11.4457 15 11.6667C15 11.8878 14.9122 12.0997 14.7559 12.256C14.5996 12.4123 14.3877 12.5001 14.1667 12.5001ZM9.16667 12.5001H5.83333C5.61232 12.5001 5.40036 12.4123 5.24408 12.256C5.0878 12.0997 5 11.8878 5 11.6667C5 11.4457 5.0878 11.2338 5.24408 11.0775C5.40036 10.9212 5.61232 10.8334 5.83333 10.8334H9.16667C9.38768 10.8334 9.59964 10.9212 9.75592 11.0775C9.9122 11.2338 10 11.4457 10 11.6667C10 11.8878 9.9122 12.0997 9.75592 12.256C9.59964 12.4123 9.38768 12.5001 9.16667 12.5001Z"
      fill="currentColor"
    />
  </svg>
)
