import { useMemo } from 'react'

import { useWindowWidth } from '../../../store/context/window.context'

export const useCommonCard = ({
  property,
  image: { width, height, widthDesktop, heightDesktop } = {
    width: 400,
    height: 300,
    widthDesktop: 400,
    heightDesktop: 250,
  },
}) => {
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
  const filterWebp = isMac ? '/filters:format(jpg)' : '/filters:format(webp)'

  const { windowWidth } = useWindowWidth()

  const imageSize = useMemo(
    () =>
      windowWidth < 576
        ? `/${width}x${height}`
        : `/${widthDesktop}x${heightDesktop}`,
    [windowWidth]
  )

  const gallery = useMemo(() => {
    const name = property?.aliasName
    const buildingName = property?.building?.aliasName

    const base = [
      { path: property?.mainPhoto },
      ...property?.gallery
        .filter((p) => p.path !== property?.mainPhoto)
        .slice(0, 4),
    ]

    return base.map((p, i) => ({
      src: `${process.env.REACT_APP_CDN_IMAGE}${imageSize}${filterWebp}/${p.path}`,
      alt: `${name} - ${buildingName} ${i}`,
    }))
  }, [property?.mainPhoto, property?.gallery, imageSize])

  return {
    gallery,
  }
}
