import qs from 'qs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { notify } from 'src/components/alert/toast'
import useToRedirect from 'src/hooks/use-to-redirect'
import { UserInterface } from 'src/interfaces/user/user.interface'
import {
  createUser,
  doFacebookLogin,
  doGoogleLogin,
  doUserLogin,
  getUserDocument,
  getUserProfile,
  postResetPassword,
  refreshToken,
} from 'src/requests/users'
import { UsersActions } from 'src/store/redux/users.reducer'

type ContextType = {
  isAuth: boolean
  isLoading: boolean
  user?: UserInterface
  handleLogin: (args: LoginType) => Promise<void>
  handleLoginGoogle: (data: any) => Promise<void>
  handleLoginFacebook: (data: any) => Promise<void>
  handleResetPassword: (data: any) => Promise<void>
  handleCreateAccount: (data: any) => Promise<void>
  handleLogout: () => void
  handleRefreshToken: () => Promise<void>
}

interface AuthProviderProps {
  children: React.ReactNode
}
type LoginType = { email: string; password: string }

export const AuthContext = React.createContext({} as ContextType)

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { t } = useTranslation(['login', 'formMessages'])
  const navigate = useNavigate()
  const { redirect } = useToRedirect()
  const [isLoading, setIsLoading] = React.useState(false)

  const dispatch = useDispatch()
  const user = useSelector((store: any) => store.user) as UserInterface

  const {
    setUser,
    setUserFields,
    setDocs,
    setFavorites,
    setUserBySocialMedia,
    logout,
  } = bindActionCreators(UsersActions, dispatch)

  const handleLogin = React.useCallback(
    async ({ email, password }: LoginType) => {
      setIsLoading(true)
      try {
        const { data: user } = await doUserLogin({
          data: { email, password },
        } as any)
        setUser(user)
        setFavorites(user.favorites)

        const { data: userProfile } = await getUserProfile()
        setUserFields(userProfile)

        const { data: document } = await getUserDocument()
        setDocs(document)
      } catch (e) {
        throw new Error(e)
      } finally {
        setIsLoading(true)
      }
    },
    []
  )

  const handleCreateAccount = React.useCallback(async (data: any) => {
    try {
      await createUser({ data })

      await handleLogin({ email: data.email, password: data.password })
      redirect()
    } catch (e) {
      if (e.response.data.statusCode === 409)
        return notify({ text: t('login:EMAIL_ALREADY') }).error() as any

      return notify({ text: t('login:ERROR') }).error() as any
    }
  }, [])

  const handleLoginGoogle = React.useCallback(async (data: any) => {
    const result = await doGoogleLogin({ data })
    setUserBySocialMedia({ ...result.data }, 'google')
  }, [])

  const handleLoginFacebook = React.useCallback(async (data: any) => {
    const result = await doFacebookLogin({ data })

    setUserBySocialMedia({ ...result.data }, 'facebook')
  }, [])

  const handleResetPassword = React.useCallback(async (data: any) => {
    await postResetPassword({ data })
  }, [])

  const handleRefreshToken = React.useCallback(async () => {
    localStorage.getItem('refreshToken')
    try {
      const response = await refreshToken({
        data: { refreshToken: user.refreshToken },
        onSuccess: () => {},
        onFail: () => {},
      })

      localStorage.setItem('userToken', response.data.accessToken)
      localStorage.setItem('refreshToken', response.data.refreshToken)
    } catch (e) {
      const uri = qs.stringify({
        redirect_to: `${window.location.href}`,
      })
      navigate(`/auth/signin?${uri}`)
    }
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        handleLoginGoogle,
        handleLogin,
        handleLoginFacebook,
        handleResetPassword,
        handleCreateAccount,
        handleLogout: logout,
        handleRefreshToken,
        isAuth: !!user.userToken,
        isLoading,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => React.useContext(AuthContext)

export default AuthProvider
