import React from 'react'

export const LoadingSvg = ({ color = '#fff', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="loading-svg"
    style={{
      margin: '0 0 0 8px',
      background: 'none',
      display: 'block',
      shapeRendering: 'auto',
    }}
    width="20px"
    height="20px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke={color}
      strokeWidth="10"
      r="34"
      strokeDasharray="160.22122533307947 55.40707511102649"
      transform="rotate(169.273 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1.7241379310344827s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
)
