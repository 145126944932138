import { setIn } from 'final-form'

export const validate = (schema: any) => async (values: any) => {
  if (typeof schema === 'function') schema = schema()

  try {
    await schema.validate(values, { abortEarly: false })
  } catch (e) {
    return e.inner.reduce(
      (errors: any, error: any) => setIn(errors, error.path, error.message),
      {}
    )
  }
}
