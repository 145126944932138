import {
  ComponentSingleStyleConfig,
  SystemStyleObjectRecord,
  ThemeComponents,
} from '@chakra-ui/react'

type ContainerType = ThemeComponents['Container']

const defaultProps: ContainerType['defaultProps'] = {
  size: 'md',
}

const baseStyle: ContainerType['baseStyle'] = {
  px: 0,
}

const sizes: SystemStyleObjectRecord = {
  xl: {},
  lg: {},
  md: { maxW: '1200px' },
  sm: { maxW: '1020px' },
  xs: {},
}

export const Container: ComponentSingleStyleConfig = {
  defaultProps,
  baseStyle,
  sizes,
}
