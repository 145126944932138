import React from 'react'

export const StarIcon = ({ color = '#FA2F93', width = 16, height = 17 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.29203 14.9101C4.19485 14.9101 4.09884 14.8889 4.01075 14.848C3.92265 14.8071 3.84459 14.7475 3.78203 14.6734C3.71947 14.5993 3.67393 14.5125 3.6486 14.4191C3.62326 14.3256 3.61874 14.2277 3.63536 14.1323L4.28336 10.3943L1.53336 7.74824C1.44381 7.66172 1.38042 7.5519 1.3504 7.43126C1.32038 7.31063 1.32494 7.18403 1.36355 7.06585C1.40217 6.94767 1.47329 6.84266 1.56883 6.76275C1.66438 6.68284 1.78051 6.63124 1.90403 6.61382L5.70403 6.06388L7.40003 2.6593C7.46121 2.55483 7.5488 2.46817 7.65408 2.40793C7.75935 2.3477 7.87863 2.31601 8.00003 2.31601C8.12143 2.31601 8.24071 2.3477 8.34598 2.40793C8.45125 2.46817 8.53885 2.55483 8.60003 2.6593L10.298 6.06388L14.098 6.61382C14.2213 6.63175 14.337 6.68372 14.4321 6.76384C14.5272 6.84396 14.5979 6.94902 14.6361 7.06711C14.6744 7.1852 14.6787 7.31161 14.6485 7.43199C14.6183 7.55238 14.5549 7.66193 14.4654 7.74824L11.7187 10.395L12.3667 14.133C12.3876 14.2553 12.3736 14.3809 12.3265 14.4957C12.2794 14.6105 12.2009 14.7099 12.1 14.7826C11.9866 14.8661 11.8491 14.9108 11.708 14.9101C11.6004 14.9104 11.4943 14.8849 11.3987 14.8357L8.00003 13.0657L4.60003 14.835C4.50482 14.8841 4.39923 14.9098 4.29203 14.9101ZM8.00003 11.6523C8.10748 11.6522 8.21338 11.6779 8.30869 11.7273L10.8214 13.0358L10.3434 10.2748C10.3249 10.1681 10.3328 10.0586 10.3665 9.95565C10.4002 9.85274 10.4586 9.75959 10.5367 9.68432L12.5614 7.73429L9.76136 7.32781C9.65464 7.31237 9.55328 7.27137 9.46596 7.20832C9.37864 7.14528 9.30798 7.06207 9.26003 6.96584L8.00003 4.44594L6.74269 6.9665C6.69475 7.06273 6.62408 7.14594 6.53677 7.20899C6.44945 7.27203 6.34808 7.31304 6.24136 7.32848L3.44136 7.73496L5.46603 9.68498C5.54411 9.76026 5.60254 9.8534 5.63624 9.95631C5.66993 10.0592 5.67787 10.1688 5.65936 10.2754L5.18136 13.0364L7.69403 11.728C7.78854 11.6789 7.89344 11.6529 8.00003 11.6523Z"
      fill={color}
    />
  </svg>
)
