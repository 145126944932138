import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AppStoreIcon from 'src/components/footer/icons/app-store.icon'
import GooglePlayIcon from 'src/components/footer/icons/google-play.icon'

import NotFoundIcon from './icons/not-found.icon'
import { Header } from './reservation-code'

const VerifySuccess = () => {
  const { t } = useTranslation('ota-page')
  const navigate = useNavigate()

  return (
    <Flex direction="column" gap="24px" w="full">
      <Box
        w="full"
        mt={['none', '40px']}
        py={['none', '40px']}
        bg={['none', 'black.0']}
        borderRadius={['none', '12px']}
      >
        <Header
          withoutButton
          title={t('step-2.title')}
          back={() => navigate('/vincular')}
        />
        <Box mt={['20px', '0px']} w="full" px="24px">
          <Box w="full" bg="black.0" px="16px" py="20px" borderRadius="8px">
            <NotFoundIcon color="#0CC065" />
            <Heading fontSize="20px" as="h2" fontWeight="bold">
              {t('verify-success.title.0')}
            </Heading>
            <Heading fontSize="20px" as="h2" fontWeight="bold">
              {t('verify-success.title.1')}
            </Heading>
            <Text mt="16px" fontWeight="medium" fontSize="16px">
              {t('verify-success.desc.0')}
            </Text>
            <Text mt="16px" fontWeight="medium" fontSize="16px">
              {t('verify-success.desc.1').split('\b')[0]}
              <Text
                as="span"
                color="primary.500"
                mt="16px"
                fontWeight="medium"
                fontSize="16px"
              >
                {t('verify-success.desc.1').split('\b')[1]}
              </Text>
            </Text>
            <Text mt="16px" fontWeight="medium" fontSize="16px">
              {t('verify-success.desc.2')}
            </Text>
            <Flex mt="24px" gap="8px">
              <Box
                role="link"
                onClick={() =>
                  window.open(
                    'https://play.google.com/store/apps/details?id=br.com.xapps.housiapp'
                  )
                }
              >
                <GooglePlayIcon />
              </Box>
              <Box
                role="link"
                onClick={() =>
                  window.open(
                    'https://apps.apple.com/br/app/housi/id1472319764'
                  )
                }
              >
                <AppStoreIcon />
              </Box>
            </Flex>
          </Box>
          <Button
            mt="24px"
            isFullWidth
            onClick={() => window.open('https://lps.housi.com/appspace')}
            colorScheme="primary"
            bg="primary.500"
          >
            {t('verify-success.appspace')}
          </Button>
          <Button
            mt="8px"
            isFullWidth
            variant="ghost"
            onClick={() => navigate('/')}
          >
            {t('verify-success.home')}
          </Button>
        </Box>
      </Box>
    </Flex>
  )
}

export default VerifySuccess
