import * as c from '@chakra-ui/react'

import { useSearch } from '../search-context'

interface LabelProps {
  label: string
}

const Label = ({ label }: LabelProps) => {
  const search = useSearch()
  const isOpenOrClosing =
    search.valid.open || search.valid.opening || search.valid.closing

  return (
    <c.Flex align="baseline">
      <c.Heading
        color="black.500"
        fontSize={isOpenOrClosing ? 24 : 14}
        fontWeight={700}
        textAlign="start"
        whiteSpace="nowrap"
        pl={[8, 0]}
      >
        {label}
      </c.Heading>
    </c.Flex>
  )
}

export default Label
