import PropertyCardLoading from '../../../components/cards/property/complete-loading'
import { useMaps } from '../map/context'

const Loading = () => {
  const { valid } = useMaps()

  if (valid.idle) return null

  if (valid.error) return null

  return (
    <>
      <PropertyCardLoading />
      <PropertyCardLoading />
    </>
  )
}

export default Loading
