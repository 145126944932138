import { BuilderComponent } from '@builder.io/react'
import * as c from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useBuilderPage } from 'src/hooks/builder'

export default function HousiSalesDetailsBuilder() {
  const params = useParams()
  const { data, loading, error } = useBuilderPage(
    `/vendas/detalhes/${params.enterprise}`,
    'page-model-vendas-detalhes'
  )
  if (loading || error) return null
  return (
    <React.Suspense fallback={<Loading />}>
      <BuilderComponent model="page-model-vendas-detalhes" content={data} />
    </React.Suspense>
  )
}

function Loading() {
  return (
    <c.Container px={['s5', 0]}>
      <c.Flex mt="m3" direction="column" gap={['l4', 'l5']}>
        <c.Skeleton h="465px" w="full" borderRadius="20px" />
        <c.Skeleton h="465px" w="full" borderRadius="20px" />
      </c.Flex>
    </c.Container>
  )
}
