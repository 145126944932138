const BracketLeft = () => {
  return (
    <svg
      width="10"
      height="125"
      viewBox="0 0 10 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.81296 124.5H9.31296V124V122.924V122.424H8.81296H7.16263C4.72282 122.424 2.73755 120.438 2.73755 117.999V7.00171C2.73755 4.5619 4.72324 2.57663 7.16263 2.57663H8.81296H9.31296V2.07663V1.00022V0.500217H8.81296H7.16263C3.577 0.500217 0.661133 3.41609 0.661133 7.00171V117.998C0.661133 121.584 3.57752 124.5 7.16263 124.5H8.81296Z"
        fill="#FA2F93"
        stroke="#FA2F93"
      />
    </svg>
  )
}

export default BracketLeft
