import { ComponentStyleConfig } from '@chakra-ui/react'

export const Tabs: ComponentStyleConfig = {
  baseStyle: {
    tablist: {
      pt: 0,
      pb: 0,
      px: 0,
      borderBottom: '1px',
      borderBottomColor: '#F5F5F5 !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      button: {
        color: 'black.500',
        fontWeight: 'medium',
        fontSize: 't-md.0',
        p: 0,
        pb: 's1',
        mr: 's3',
        _last: {
          mr: 0,
        },
      },
    },
    tab: {
      _focus: {
        boxShadow: 'none',
      },
    },
    tabpanels: {},
    tabpanel: {
      py: 0,
      px: 0,
    },
  },
  sizes: {
    sm: {},
    md: {},
  },
  variants: {},
  defaultProps: {
    variant: 'unstyled',
  },
}
