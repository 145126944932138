import { useMachine } from '@xstate/react'
import React from 'react'
import { EventData } from 'xstate'

import pickerMachine, { fields_events, fields_states } from './fields-machine'

type SendEvent = (event: fields_events, payload?: EventData | undefined) => void

type Valid = {
  [key in fields_states]: boolean
}

interface FieldsContextProps {
  states: fields_states
  send: SendEvent
  valid: Valid
}

interface FieldsProviderProps {
  children?: React.ReactNode
}

export const FieldsContext = React.createContext({} as FieldsContextProps)

const FieldsProvider = ({ children }: FieldsProviderProps) => {
  const [current, send] = useMachine(pickerMachine)

  const states = current.value as fields_states

  const valid: Valid = {
    idle: current.matches(fields_states.idle),
    end: current.matches(fields_states.end),
    start: current.matches(fields_states.start),
  }

  return (
    <FieldsContext.Provider value={{ states, send, valid }}>
      {children}
    </FieldsContext.Provider>
  )
}

export default FieldsProvider
