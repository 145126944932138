import React from 'react'
import { useLocation } from 'react-router-dom'

type ContextType = {
  on: boolean
  setOn: React.Dispatch<React.SetStateAction<boolean>>
}
const Context = React.createContext({} as ContextType)

const ResetProvider: React.FC = ({ children }) => {
  const [on, setOn] = React.useState(true)
  const pathname = useLocation()

  React.useEffect(() => {
    if (on) {
      window.scrollTo(0, 0)
    }
  }, [pathname, on])

  return <Context.Provider value={{ on, setOn }}>{children}</Context.Provider>
}
export const EnableResetScroll = ({ children }) => {
  const { setOn } = React.useContext(Context)

  const pathname = useLocation()

  React.useEffect(() => {
    setOn(true)
  }, [pathname])

  return children
}

export const DisabledResetScroll = ({ children }) => {
  const { setOn } = React.useContext(Context)

  const pathname = useLocation()

  React.useEffect(() => {
    setOn(false)
  }, [pathname])

  return children
}

export default ResetProvider
