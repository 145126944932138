import * as c from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/pages/auth/auth-context'

import { MyProfileItemsConfig } from '../content/data/items.config'
import ButtonLogout from './button-logout'
import Item from './item'

const MyProfileItems = ({ isLogged }) => {
  const [isMobile] = c.useMediaQuery('(max-width: 720px)')
  const { myProfileItems } = MyProfileItemsConfig()

  const navigate = useNavigate()
  const { handleLogout } = useAuth()

  const linkHandler = (path: string) => {
    if (path === '/logout') return handleLogout()
    if (!path) return null
    return navigate(path)
  }

  return (
    <c.Flex direction="column">
      {myProfileItems.map((item) => (
        <>
          <Item
            key={item.id}
            label={item.label}
            hasTag={item.hasTag}
            Icon={item.Icon}
            onClick={() => linkHandler(item?.path!)}
          />
        </>
      ))}

      {!isMobile && <ButtonLogout isLogged={isLogged} />}
    </c.Flex>
  )
}

export default MyProfileItems
