import { lazy } from 'react'

export const ResetForm = lazy(() => import('src/pages/landing-pages/reset'))
export const SearchMobile = lazy(() => import('../../pages/search-mobile'))
export const EstimateComponent = lazy(
  () => import('../../pages/landing-pages/estimate')
)

export const CorporateComponent = lazy(
  () => import('../../pages/landing-pages/corporate')
)
export const IrentComponent = lazy(
  () => import('../../pages/landing-pages/irent')
)
export const JacquesPilonComponent = lazy(
  () => import('../../pages/landing-pages/jacques-pilon')
)
export const PickupFormsComponent = lazy(
  () => import('../../pages/landing-pages/pickup-forms')
)
export const PickupFormInvestorComponent = lazy(
  () => import('../../pages/landing-pages/pickup-forms/investor')
)
export const PickupFormIncorporatorComponent = lazy(
  () => import('../../pages/landing-pages/pickup-forms/incorporator')
)
export const PickupFormBrokerComponent = lazy(
  () => import('../../pages/landing-pages/pickup-forms/broker')
)

export const TermsComponent = lazy(() => import('../../pages/policies'))
export const TermsDetailsComponent = lazy(
  () => import('../../pages/policies/details')
)

export const PropertyDetailComponent = lazy(
  () => import('../../pages/property-detail')
)
export const PayComponent = lazy(() => import('../../pages/pay'))
export const PayUpdateComponent = lazy(() => import('../../pages/pay-update'))

export const ReservationsDetails = lazy(
  () =>
    import('../../pages/profile-v2/pages/my-reservations/reservation-details')
)

export const WhoWeAreComponent = lazy(
  () => import('../../pages/landing-pages/who-we-are')
)
export const HubComponent = lazy(() => import('../../pages/landing-pages/hub'))
export const FariaLimaComponent = lazy(
  () => import('../../pages/landing-pages/faria-lima')
)
export const HousiLIComponent = lazy(
  () => import('../../pages/landing-pages/housi-li/v2')
)
export const HousiLIComponentDetails = lazy(
  () => import('../../pages/landing-pages/housi-li/v2/details')
)
export const SubscriptionComponent = lazy(
  () => import('../../pages/landing-pages/subscription')
)

export const CovidComponent = lazy(
  () => import('../../pages/landing-pages/covid')
)

export const PlusComponent = lazy(
  () => import('../../pages/landing-pages/plus')
)

export const Error404Component = lazy(() => import('../../pages/404'))

export const PartnersListComponent = lazy(
  () => import('../../pages/partners/properties-list/properties')
)
export const PartnerDetail = lazy(() => import('../../pages/partners/[id]'))

export const FaqComponent = lazy(() => import('../../pages/landing-pages/faq'))

export const HousiOfferComponent = lazy(
  () => import('../../pages/landing-pages/housi-offer')
)

export const HousiSalesComponent = lazy(
  () => import('../../pages/landing-pages/housi-sales')
)

export const HousiExperience = lazy(
  () => import('../../pages/landing-pages/housi-experience')
)

export const Marketplace = lazy(
  () => import('../../pages/landing-pages/marketplace')
)

export const PinkFriday = lazy(
  () => import('../../pages/landing-pages/pink-friday')
)

export const Mood2022 = lazy(
  () => import('../../pages/landing-pages/mood-2022')
)

export const Growth = lazy(() => import('../../pages/landing-pages/growth'))
export const Decor = lazy(() => import('../../pages/landing-pages/decor-v2'))
export const Appspace = lazy(() => import('../../pages/landing-pages/appspace'))
export const Data = lazy(() => import('../../pages/landing-pages/data'))

export const ExpertiseTech = lazy(
  () => import('../../pages/landing-pages/expertise-tech')
)
