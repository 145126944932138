import { motion } from 'framer-motion'
import styled from 'styled-components'

export const ContentField = styled.div`
  border-bottom: 1px solid #d0d0d0;
  padding: 24px 0 20px 0;
  width: 100%;
`

export const PriceInputField = styled.div`
  max-width: calc(50% - 40px);
  min-width: calc(50% - 40px);
`

export const ContentAmenities = styled(motion.div)`
  margin-top: 24px;
  overflow: hidden;
  width: 100%;
`
