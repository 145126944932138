import React from 'react'

const handleDirection = (direction) => {
  return direction == 'left'
    ? '90deg'
    : direction == 'right'
    ? '-90deg'
    : direction == 'top'
    ? '180deg'
    : '0'
}
export const ArrowIndicateMinimalIcon = ({
  color = 'black',
  direction = 'bottom',
  style = {},
  ...props
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: `rotate(${handleDirection(direction)})`,
      ...style,
    }}
    {...props}
  >
    <path
      d="M7.99999 10.6673C7.84398 10.6676 7.69287 10.6129 7.57332 10.5126L3.57332 7.17931C3.43744 7.06615 3.35208 6.90365 3.33601 6.72755C3.31995 6.55146 3.37449 6.37619 3.48765 6.24031C3.60081 6.10443 3.76331 6.01907 3.93941 6.003C4.11551 5.98694 4.29077 6.04148 4.42665 6.15464L8.00732 9.13864L11.582 6.26198C11.7198 6.15226 11.8955 6.10158 12.0706 6.12099C12.2457 6.14041 12.406 6.22835 12.5165 6.36559C12.6269 6.50284 12.6786 6.67823 12.6601 6.85344C12.6417 7.02865 12.5546 7.18943 12.418 7.30064L8.41799 10.5193C8.29962 10.6149 8.15212 10.6671 7.99999 10.6673Z"
      fill={`var(--${color}-color)`}
    />
  </svg>
)
