import * as c from '@chakra-ui/react'
import { AnimatePresence, motion, Transition, Variants } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce, useOutsideClick } from 'rooks'
import { GeoType } from 'src/hooks/map/geocorder'

import { DatesContext } from '../dates/dates-context'
import { dates_events } from '../dates/dates-machine'
import { useSearch } from '../search-context'
import { search_events } from '../search-machine'
import { StepsContext } from '../steps/steps-context'
import Select from './autocomplete'
import { AutocompleteContext } from './autocomplete-context'
import {
  autocomplete_events,
  input_events,
  request_events,
} from './autocomplete-machine'
import Label from './label'

const variants: Variants = {
  initial: {
    opacity: 0,
    y: -80,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
}

const transition: Transition = {
  duration: 0.3,
  type: 'spring',
  stiffness: 300,
  damping: 35,
}

const createZoomByGeoType = (type: GeoType): number => {
  switch (type) {
    case 'street':
      return 20
    case 'neighborhood':
      return 15
    case 'city':
      return 14
    default:
      return 14
  }
}

const AutocompleteInput = () => {
  const { t } = useTranslation('components/search')

  const { next } = React.useContext(StepsContext)
  const { getGeocoderByPlaceId, ...autocomplete } =
    React.useContext(AutocompleteContext)
  const dates = React.useContext(DatesContext)
  const search = useSearch()

  const ref = React.useRef(null)

  useOutsideClick(ref, () => autocomplete.send(autocomplete_events.blur))

  const isOpenOrClosing = search.valid.open || search.valid.closing
  const [term, setTerm] = React.useState(search.context.searchTerm)

  const setDebounceValue = useDebounce((e) => {
    setTerm(e)
    autocomplete.send(autocomplete_events.stop)
    autocomplete.send(request_events.request, { term })
  }, 1000)

  const handleChange = React.useCallback((e: any) => {
    autocomplete.send(autocomplete_events.change, {
      inputValue: e.target.value,
    })
    setDebounceValue({ value: e.target.value, label: e.target.value })
  }, [])

  const handleClear = () => {
    autocomplete.send(autocomplete_events.clear)
    search.send(search_events.clear)
  }

  const handleSelect = React.useCallback(
    async (selected: any) => {
      autocomplete.send(request_events.set_term, {
        term: selected,
        inputValue: selected.label,
      })

      const { coordinates, type } = await getGeocoderByPlaceId({
        placeId: selected.value,
        address: selected.label,
      })

      autocomplete.send(input_events.blur)

      search.send(search_events.set_coords, {
        coords: {
          lat: coordinates?.[0],
          long: coordinates?.[1],
        },
        zoom: createZoomByGeoType(type),
        searchTerm: selected,
      })

      dates.send(dates_events.focus)
      next()
    },
    [autocomplete, dates]
  )

  const initialValue = {
    label: autocomplete.context.inputValue,
    value: autocomplete.context.inputValue,
  }

  React.useEffect(() => {
    autocomplete.send(autocomplete_events.change, {
      inputValue: search.context.searchTerm.label,
    })
  }, [search.context.searchTerm.label])

  const opacity = dates.states === 'focused'

  return (
    <AnimatePresence>
      {search.valid.closed ? (
        <c.Text
          color="black.300"
          fontSize={12}
          fontWeight={500}
          textAlign="start"
        >
          {t('components/search:autocomplete.placeholder')}
        </c.Text>
      ) : (
        <motion.div
          initial={isOpenOrClosing ? 'animate' : 'initial'}
          style={{ width: '100%' }}
          transition={transition}
          variants={variants}
          animate="animate"
          exit="initial"
        >
          <c.Box transition="all 0.2s" opacity={opacity ? 0.5 : 1}>
            <Label label={t('components/search:autocomplete.label')} />
            <div ref={ref}>
              <Select
                placeholder={t('components/search:autocomplete.placeholder')}
                isOpen={autocomplete.valid.input.focused}
                onFocus={() => autocomplete.send(input_events.focus)}
                onClose={() => autocomplete.send(input_events.blur)}
                options={autocomplete.context.options}
                onSelect={handleSelect}
                onChange={handleChange}
                onClear={handleClear}
                value={initialValue}
              />
            </div>
          </c.Box>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const Autocomplete = () => {
  const { t } = useTranslation('components/search')
  const search = useSearch()

  if (search.valid.closed)
    return (
      <c.Flex direction="column" align="start">
        <c.Text color="black.500" fontWeight={700}>
          {t('components/search:autocomplete.label')}
        </c.Text>
        <c.Text color="black.400" fontSize={12} fontWeight={500}>
          {t('components/search:autocomplete.placeholder')}
        </c.Text>
      </c.Flex>
    )

  return (
    <c.Box minH="118px" maxH="118px">
      <AutocompleteInput />
    </c.Box>
  )
}

export default Autocomplete
