import React from 'react'

export const GoogleIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.6875 9.9996C4.6875 9.00859 4.96054 8.08046 5.43476 7.28554V3.91406H2.06328C0.72539 5.65195 0 7.76991 0 9.9996C0 12.2293 0.72539 14.3473 2.06328 16.0851H5.43476V12.7137C4.96054 11.9187 4.6875 10.9906 4.6875 9.9996Z"
      fill="#FBBD00"
    />
    <path
      d="M10 15.3121L7.65625 17.6558L10 19.9996C12.2297 19.9996 14.3477 19.2742 16.0855 17.9363V14.5683H12.7176C11.9156 15.0445 10.9836 15.3121 10 15.3121Z"
      fill="#0F9D58"
    />
    <path
      d="M5.43496 12.7129L2.06348 16.0844C2.32832 16.4285 2.61699 16.7578 2.9291 17.0699C4.81777 18.9586 7.3291 19.9988 10.0002 19.9988V15.3113C8.06191 15.3113 6.36269 14.2676 5.43496 12.7129Z"
      fill="#31AA52"
    />
    <path
      d="M20 9.99921C20 9.39101 19.9449 8.78124 19.8363 8.18749L19.7484 7.70702H10V12.3945H14.7441C14.2836 13.3109 13.575 14.0586 12.7176 14.568L16.0855 17.9359C16.4297 17.6711 16.759 17.3824 17.0711 17.0703C18.9598 15.1816 20 12.6703 20 9.99921Z"
      fill="#3C79E6"
    />
    <path
      d="M13.7566 6.24335L14.1711 6.65781L17.4855 3.34336L17.0711 2.9289C15.1824 1.04023 12.6711 0 10 0L7.65625 2.34375L10 4.6875C11.4191 4.6875 12.7531 5.24023 13.7566 6.24335Z"
      fill="#CF2D48"
    />
    <path
      d="M10.0002 4.6875V0C7.3291 0 4.81777 1.04023 2.9291 2.9289C2.61699 3.24101 2.32832 3.5707 2.06348 3.91484L5.43496 7.28632C6.36269 5.73124 8.06191 4.6875 10.0002 4.6875Z"
      fill="#EB4132"
    />
  </svg>
)
