import * as c from '@chakra-ui/react'
import { ArrowRightIcon } from 'src/assets/icons/nav/arrow-right.icon'
import { ChevronDownIcon } from 'src/components/navbar/icons/chevron-down-icon'

import { NavbarConfigItem } from './navbar-item.config'

interface NavbarProps {
  item: NavbarConfigItem
}

const LandingPageItem = ({ item }: NavbarProps) => {
  const { onToggle, isOpen, onOpen } = c.useDisclosure()

  return (
    <>
      <c.Menu
        variant="navbar"
        placement="bottom-end"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onToggle}
      >
        <c.MenuButton
          as={c.Button}
          maxHeight="30px"
          py="s1"
          px="s2"
          bg="none"
          _active={{
            border: '1px solid',
            bg: 'black.0',
            borderColor: 'primary.500',
          }}
          _hover={{
            bg: 'black.100',
          }}
          rightIcon={
            <c.Icon as={ChevronDownIcon} color="black.500" w="1rem" h="1rem" />
          }
        >
          <c.Text fontSize="t-sm.0" fontWeight="medium" color="black.500">
            {item?.title}
          </c.Text>
        </c.MenuButton>

        <c.MenuList>
          {item.options?.map((subItem) => (
            <c.MenuItem
              key={subItem?.id}
              as={c.Link}
              href={subItem?.path}
              isExternal
            >
              <c.Icon
                as={subItem?.Icon}
                w="1.25rem"
                h="1.25rem"
                color="black.400"
              />
              {subItem?.label}

              <c.Icon
                as={ArrowRightIcon}
                color="black.300"
                w="1.25rem"
                h="1.25rem"
                ml="auto"
              />
            </c.MenuItem>
          ))}
        </c.MenuList>
      </c.Menu>

      <c.Divider orientation="vertical" height="20px" mx="s1" />
    </>
  )
}
export default LandingPageItem
