import styled from 'styled-components'

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`

export const LoaderImg = styled.img`
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
  position: absolute;
  width: 30px;
  z-index: 5;
`
