import * as c from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'src/utils/date'

import { PickerContext } from '../picker-context'

const HeaderDisplay = ({ date }: { date: Date }) => {
  const { i18n } = useTranslation()
  const { valid } = React.useContext(PickerContext)

  const locale = i18n.language === 'pt-BR' ? 'ptBR' : 'enUS'

  const month = format(date, 'MMMM', { locale })
  const year = format(date, 'yyyy', { locale })

  if (valid.month)
    return (
      <c.Flex
        gap={1}
        align="center"
        justify="center"
        bg={['black.0', 'transparent']}
        minH="36px"
        maxH="36px"
        minW={['100%', 'auto']}
        mb={[2, 0]}
        position={['sticky', 'static']}
        top={0}
      >
        <c.Text
          color="black.500"
          fontSize={[16, 14]}
          fontWeight={700}
          textTransform="capitalize"
        >
          {year}
        </c.Text>
      </c.Flex>
    )

  return (
    <c.Flex gap={1.5} align="center" justify="center" mt={[6, 0]}>
      <c.Text
        color="black.500"
        fontSize={14}
        fontWeight={[500, 700]}
        textTransform="capitalize"
      >
        {month}
      </c.Text>

      <c.Text
        color={['black.300', 'black.500']}
        fontSize={14}
        fontWeight={500}
        textTransform="capitalize"
      >
        {year}
      </c.Text>
    </c.Flex>
  )
}

export default HeaderDisplay
