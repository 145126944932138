import * as c from '@chakra-ui/react'
import { Link } from 'react-router-dom'

interface NavLinkProps {
  isExternal: boolean
  label: string
  path: string
}

const styles = {
  color: 'black.500',
  fontSize: 't-md.0',
  fontWeight: 'medium',
  transition: 'all 0.2s ease-in-out',
  cursor: 'pointer',
  _hover: {
    color: 'primary.500',
    textDecoration: 'none',
  },
}

export default function NavLink({ isExternal, label, path }: NavLinkProps) {
  return (
    <>
      {isExternal ? (
        <c.Text as={c.Link} href={path} isExternal {...styles}>
          {label}
        </c.Text>
      ) : (
        <c.Text as={Link} to={path} {...styles}>
          {label}
        </c.Text>
      )}
    </>
  )
}
