import { Box, Flex } from '@retriever-ui/layout'
import { retriever } from '@retriever-ui/system'
import { Link } from 'react-router-dom'

type Props = {
  title: string
  Icon: any
  path: string
  active: boolean
  isLogout?: boolean
}

const MenuItem = ({ title, Icon, path, isLogout }: Props) => {
  const isActive = window.location.pathname.includes(path)

  return (
    <Link to={isLogout ? '/' : path} style={{ textDecoration: 'none' }}>
      <Flex>
        <Box
          borderTopLeftRadius="8px"
          borderBottomLeftRadius="8px"
          height="56px"
          width="8px"
          backgroundColor={['none', isActive ? 'primary.400' : 'transparent']}
        />
        <Flex
          backgroundColor={[isActive ? 'black.0' : 'transparent']}
          alignItems="center"
          padding="18px"
          boxShadow={[
            'none',
            isActive ? '0px 4px 16px -5px #0000001a' : 'none',
          ]}
          cursor="pointer"
          minWidth="286px"
          color="black.500"
          height="56px"
        >
          <Box mRight="18px">
            <Icon />
          </Box>

          <retriever.p
            textDecoration="none"
            fontWeight={isActive ? 'bold' : 'initial'}
          >
            {title}
          </retriever.p>
        </Flex>
      </Flex>
    </Link>
  )
}

export default MenuItem
