import * as c from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import dateUtils from 'src/utils/date'

import { PickerContext } from '../../picker-context'
import { picker_events } from '../../picker-machine'
import { DateComponent } from './item'

interface MonthItemProps {
  date: Date
  index?: number
}

const MonthItem = ({ date, index }: MonthItemProps) => {
  const { i18n } = useTranslation()
  const { send } = React.useContext(PickerContext)

  const locale = i18n.language === 'pt-BR' ? 'ptBR' : 'enUS'

  const month = dateUtils.format(date, 'MMMM', { locale })

  const onJump = () => send(picker_events.jump, { date, index })

  return (
    <c.Box
      onClick={onJump}
      color="black.500"
      fontSize={16}
      fontWeight={500}
      textTransform="capitalize"
      py={2}
      px={4}
      minW={['164px', '142px']}
      maxW={['164px', '142px']}
      minH="40px"
      maxH="40px"
      bg="black.0"
      borderRadius="4px"
      cursor="pointer"
      transition="all 0.2s"
      _hover={{
        bg: 'black.200',
      }}
    >
      {month}
    </c.Box>
  )
}

const Dates = ({
  date: currentDate,
  blockedDates,
  onEnd,
  onStart,
  index,
}: {
  date: Date
  blockedDates?: string[]
  index?: number
  onStart?: (date: Date) => void
  onEnd?: (date: Date) => void
}) => {
  const { valid } = React.useContext(PickerContext)

  const months = dateUtils.eachMonthsOfYear(currentDate)
  const dates = dateUtils.eachDaysOfMonth(currentDate)

  if (valid.month)
    return (
      <c.SimpleGrid
        alignItems="center"
        justifyItems="center"
        columns={2}
        columnGap={[4, 3]}
        rowGap={[2, 0]}
        mb={[10, 0]}
        pb={[36, 0]}
      >
        {months.map((date) => (
          <MonthItem
            index={index}
            key={`months-${date.toISOString()}`}
            date={date}
          />
        ))}
      </c.SimpleGrid>
    )

  return (
    <c.Grid
      alignItems="center"
      justifyItems="center"
      gridTemplateColumns="repeat(7, 32px)"
      columnGap={[4, 3]}
      rowGap={[0, 3]}
    >
      {dates.map((date, index) => (
        <DateComponent
          blockedDates={blockedDates}
          currentDate={currentDate}
          key={date.toISOString()}
          date={date}
          index={index}
          onEnd={onEnd}
          onStart={onStart}
        />
      ))}
    </c.Grid>
  )
}

export default Dates
