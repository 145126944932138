import { ColorsType } from '@retriever-ui/core'
import styled, { css } from 'styled-components'

import { ColorsInterface } from './radio.interface'

const radioThemeColors: ColorsInterface = {
  primary: { primaryColor: '#FA2F93', secondaryColor: '#FFEAF4' },
  secondary: { primaryColor: '#000050', secondaryColor: '#D9D9E5' },
  success: { primaryColor: '#0CC065', secondaryColor: '#CEF2E0' },
  black: { primaryColor: '#474747', secondaryColor: '#FBFBFC' },
}

const radioColor = css<{ color: ColorsType }>`
  :checked + label:before,
  :not(:checked) + label:before {
    border-color: #474747;
    border-radius: 100%;
  }

  :checked + label:before {
    border-color: ${(props) => radioThemeColors[props.color].primaryColor};
  }

  :checked + label:after,
  :not(:checked) + label:after {
    background: ${(props) => radioThemeColors[props.color].primaryColor};
  }

  :not(:checked) + label:hover:before {
    background: ${(props) => radioThemeColors[props.color].secondaryColor};
  }
`

export const RadioButton = styled.input.attrs({ type: 'radio' })<{
  color: ColorsType
}>`
  position: absolute;
  left: -9999px;

  /* external circle */
  :checked + label:before,
  :not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border-style: solid;
    border-width: 1px;
    border-radius: 100%;
  }

  /* internal circle */
  :checked + label:after,
  :not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  :not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  :checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  ${radioColor}
`

export const LabelRadioControl = styled.label`
  cursor: pointer;
  display: flex;
  font-size: 14px;
  margin-left: 8px;
`

export const Label = styled.label`
  color: var(--black-color);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  margin-left: 8px;
`

export const ContentButton = styled.div`
  min-height: 18px;
  min-width: 18px;
  overflow: hidden;
  position: relative;
  transition: all 0.2s;
`

export const RadioButtonSquare = styled.label`
  align-items: center;
  background: #fff;
  border-color: var(--gray-color);
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  color: var(--gray-color);
  cursor: pointer;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;
  transition: all 0.2s;
  user-select: none;
  padding: 0px 8px;

  :hover {
    background: #ffeaf4;
  }
`

const radioColorSquare = css<{ color: ColorsType }>`
  ${RadioButtonSquare} {
    background: #fff;
    color: ${(p) => radioThemeColors[p.color].secondaryColor};
    border-color: var(--black-color);
  }

  :checked + ${RadioButtonSquare} {
    background: ${(p) => radioThemeColors[p.color].secondaryColor};
    color: ${(p) => radioThemeColors[p.color].primaryColor};
    border-color: ${(p) => radioThemeColors[p.color].primaryColor};
  }

  :disabled + ${RadioButtonSquare} {
    background: #fff;
    border-color: ${(p) => radioThemeColors[p.color].secondaryColor};
    border-color: ${(p) => radioThemeColors[p.color].secondaryColor};
    cursor: not-allowed;
  }
`

export const RefRadio = styled.input.attrs({ type: 'radio' })<{
  color: ColorsType
}>`
  position: absolute;
  display: none;
  user-select: none;

  ${radioColorSquare}
`
