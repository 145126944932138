import React from 'react'
import ReactInputMask from 'react-input-mask'
import styled from 'styled-components'

const StyledInputMask = styled(ReactInputMask)`
  padding: 0 12px;
  border: 1px solid #a3a3a3;
  outline: none;
  :focus {
    border: 1px solid #fa2f93;
    color: '#474747';
  }
  border-radius: 8px;
  height: 40px;
  width: 100%;
  color: '#474747';
  font-family: 'Montserrat';
`

interface FieldTextProps extends React.HTMLAttributes<HTMLInputElement> {
  mask?: string
  maskPlaceholder?: string
  alwaysShowMask?: boolean
}

const InputMask = ({ mask, alwaysShowMask, ...rest }: FieldTextProps) => {
  return (
    <StyledInputMask mask={mask} alwaysShowMask={alwaysShowMask} {...rest} />
  )
}

export default InputMask
