import React, { useEffect, useState } from 'react'

import loader from '../../assets/images/loading-housi.gif'
import { ImageContainer, LoaderImg } from './styled'

export const ImageLoading = ({
  Component,
  src,
  alt,
  className = '',
  ...props
}) => {
  const [loaded, setLoaded] = useState(false)
  const [imageSrc, setImageSrc] = useState('')

  useEffect(() => {
    setImageSrc('')
    setLoaded(false)
    setImageSrc(src)
  }, [src])

  return (
    <ImageContainer className={className}>
      {!loaded && <LoaderImg src={loader} alt="loader" loaded={loaded} />}
      <Component
        loading="lazy"
        src={imageSrc}
        alt={alt}
        onLoad={() => setLoaded(true)}
        style={{
          translate: 'all 0.2s',
          filter: loaded ? 'blur(0px)' : 'blur(2px)',
          zIndex: 1,
        }}
        {...props}
      />
    </ImageContainer>
  )
}
