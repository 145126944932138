import React from 'react'

const USAFlagIcon = () => {
  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 4V16C26 16.5 25.9 16.9 25.8 17.3C25.6 17.8 25.3 18.3 25 18.6C24.3 19.4 23.2 19.9 22 19.9H4C2.8 19.9 1.8 19.4 1 18.6C0.6 18.2 0.4 17.8 0.2 17.3C0.1 16.9 0 16.5 0 16V4C0 1.8 1.8 0 4 0H22C23.2 0 24.2 0.5 25 1.3C25.4 1.7 25.6 2.1 25.8 2.6C25.9 3.1 26 3.5 26 4Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1 0V9.3H0V4C0 1.8 1.8 0 4 0H11.1Z"
        fill="#1A47B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 0H11.1V1.3H25C24.2 0.5 23.2 0 22 0ZM25.8 2.7H11.1V4H26C26 3.5 25.9 3.1 25.8 2.7ZM11.1 5.3V6.6H26V5.3H11.1ZM11.1 8V9.3H26V8H11.1ZM0 10.7V12H26V10.7H0ZM0 13.3V14.6H26V13.3H0ZM0 16C0 16.5 0.1 16.9 0.2 17.3H25.7C25.8 16.9 25.9 16.4 25.9 16H0ZM1 18.7C1.8 19.5 2.8 20 4 20H22C23.2 20 24.2 19.5 25 18.7H1Z"
        fill="#F93939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.20001 1.29999V2.59999H2.40001V1.29999H1.20001ZM3.70001 1.29999V2.59999H5.00001V1.29999H3.70001ZM6.20001 1.29999V2.59999H7.40001V1.29999H6.20001ZM8.70001 1.29999V2.59999H9.90001V1.29999H8.70001ZM7.40001 2.69999V3.99999H8.60001V2.69999H7.40001ZM5.00001 2.69999V3.99999H6.20001V2.69999H5.00001ZM2.50001 2.69999V3.99999H3.70001V2.69999H2.50001ZM1.20001 3.99999V5.29999H2.40001V3.99999H1.20001ZM8.70001 3.99999V5.29999H9.90001V3.99999H8.70001ZM1.20001 6.69999V7.99999H2.40001V6.69999H1.20001ZM3.70001 6.69999V7.99999H5.00001V6.69999H3.70001ZM6.20001 6.69999V7.99999H7.40001V6.69999H6.20001ZM8.70001 6.69999V7.99999H9.90001V6.69999H8.70001ZM7.40001 5.29999V6.59999H8.60001V5.29999H7.40001ZM5.00001 5.29999V6.59999H6.20001V5.29999H5.00001ZM2.50001 5.29999V6.59999H3.70001V5.29999H2.50001Z"
        fill="white"
      />
    </svg>
  )
}

export default USAFlagIcon
