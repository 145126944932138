import React from 'react'

const PasswordEyeShown = () => {
  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.025 13.999C4.153 13.999 0.754998 8.58302 0.132998 7.49902C0.0452297 7.347 -0.000976562 7.17456 -0.000976562 6.99902C-0.000976563 6.82349 0.0452297 6.65104 0.132998 6.49902C0.986998 5.01302 4.2 0.144023 9.73 -0.000976562C9.83 -0.000976562 9.93 -0.000976562 10.024 -0.000976562C12.5883 0.0434732 15.0403 1.05915 16.885 2.84102C18.0572 3.90385 19.0628 5.13698 19.868 6.49902C19.9558 6.65104 20.002 6.82349 20.002 6.99902C20.002 7.17456 19.9558 7.347 19.868 7.49902C19.014 8.98802 15.797 13.857 10.268 13.999H10.025ZM9.967 1.99902H9.781C7.9699 2.0719 6.23652 2.75447 4.862 3.93602C3.81097 4.81009 2.9036 5.84366 2.173 6.99902C3.161 8.49902 5.906 11.999 10.048 11.999H10.22C12.0303 11.9271 13.7632 11.246 15.138 10.066C16.1892 9.19333 17.0967 8.16071 17.827 7.00602C16.839 5.49902 14.1 1.99902 9.967 1.99902ZM10 10.499C9.30776 10.499 8.63107 10.2938 8.0555 9.90917C7.47993 9.52458 7.03133 8.97796 6.76642 8.33842C6.50151 7.69887 6.4322 6.99514 6.56725 6.31621C6.7023 5.63727 7.03564 5.01363 7.52512 4.52415C8.01461 4.03467 8.63825 3.70132 9.31718 3.56627C9.99611 3.43123 10.6998 3.50054 11.3394 3.76544C11.9789 4.03035 12.5256 4.47896 12.9101 5.05453C13.2947 5.6301 13.5 6.30679 13.5 6.99902C13.5 7.92728 13.1312 8.81752 12.4749 9.4739C11.8185 10.1303 10.9283 10.499 10 10.499ZM10 5.49902C9.70333 5.49902 9.41332 5.587 9.16664 5.75182C8.91997 5.91664 8.72771 6.15091 8.61418 6.425C8.50065 6.69909 8.47094 7.00069 8.52882 7.29166C8.5867 7.58263 8.72956 7.84991 8.93934 8.05968C9.14912 8.26946 9.41639 8.41232 9.70736 8.4702C9.99833 8.52808 10.2999 8.49837 10.574 8.38484C10.8481 8.27131 11.0824 8.07905 11.2472 7.83238C11.412 7.58571 11.5 7.2957 11.5 6.99902C11.5 6.6012 11.342 6.21967 11.0607 5.93836C10.7794 5.65706 10.3978 5.49902 10 5.49902Z"
        fill="#FA2F93"
      />
    </svg>
  )
}

export default PasswordEyeShown
