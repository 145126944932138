import { dataLayerReport } from './logging'
import { detectMobile } from './utils'

const createVisitor = () => {
  try {
    const visitor = JSON.parse(localStorage.getItem('user'))

    if (visitor === null)
      return {
        isLoggedIn: false,
      }

    const device = detectMobile() ? 'mobile' : 'desktop'
    return {
      id: visitor._id,
      email: visitor.email,
      isLoggedIn: true,
      dateOfBirth: visitor.birthDate,
      name: visitor.firstName.concat(' ', visitor.lastName),
      gender: visitor.gender,
      device,
      register: visitor.createdAt,
      newBuyer: 'NOT FOUND', // Gui vai colocar no localStorage
    }
  } catch (e) {
    dataLayerReport(`Error creating visitor recurring structure: ${e}`)
    return undefined
  }
}

const createApartment = () => {
  const apartment =
    JSON.parse(sessionStorage.getItem('DL_Apartment')) || undefined

  let coupon
  try {
    coupon = [
      {
        name: apartment.coupon.name,
        discount: apartment.coupon.discountValue.amount,
      },
    ]
  } catch (e) {
    coupon = undefined
  }

  try {
    return {
      id: apartment.propertyId || apartment.aliasId,
      available: apartment.active || undefined,
      name: apartment.aliasName || undefined,
      variant: apartment.bedrooms || undefined,
      price: apartment.cost?.total.amount?.amount || undefined,
      originalPrice: apartment.cost?.total?.amount?.amount || undefined,
      quantity: 1,
      coupon,
      persons: apartment.guests || undefined,
      bathrooms: apartment.bathrooms || undefined,
    }
  } catch (e) {
    dataLayerReport(`Error creating product recurring structure: ${e}`)
    return undefined
  }
}

export const homeStructureVirtualPageViewData = (pageUrl) => {
  try {
    if (pageUrl === undefined) throw new Error('PageUrl cant be undefined')

    const previousPage = sessionStorage.getItem('DL_PreviousPage') || undefined
    sessionStorage.setItem('DL_PreviousPage', pageUrl)

    return {
      pageType: 'home',
      event: 'virtualPageView',
      previousPage,
      currentPage: pageUrl,
      visitor: createVisitor(),
    }
  } catch (e) {
    dataLayerReport(
      `Error structuring virtual page view data for home page: ${e}`
    )
    return undefined
  }
}

export const searchStructureVirtualPageViewData = (pageUrl) => {
  try {
    if (pageUrl === undefined) throw new Error('PageUrl cant be undefined')

    const previousPage = sessionStorage.getItem('DL_PreviousPage') || undefined
    sessionStorage.setItem('DL_PreviousPage', pageUrl)

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const term = urlParams.get('term')

    return {
      pageType: 'search',
      event: 'virtualPageView',
      previousPage,
      currentPage: pageUrl,
      searchTerm: term,
      visitor: createVisitor(),
    }
  } catch (e) {
    dataLayerReport(
      `Error structuring virtual page view data for search page: ${e}`
    )
    return undefined
  }
}

export const productStructureVirtualPageViewData = (pageUrl, apartment) => {
  try {
    if (pageUrl === undefined) throw new Error('PageUrl cant be undefined')

    const previousPage = sessionStorage.getItem('DL_PreviousPage') || undefined
    sessionStorage.setItem('DL_PreviousPage', pageUrl)

    sessionStorage.setItem('DL_Apartment', JSON.stringify(apartment))

    const data = {
      pageType: 'product',
      event: 'virtualPageView',
      previousPage,
      currentPage: pageUrl,
      products: createApartment(),
      visitor: createVisitor(),
    }

    return data
  } catch (e) {
    dataLayerReport(
      `Error structuring virtual page view data for product page: ${e}`
    )
    return undefined
  }
}

export const cartStructureVirtualPageViewData = (pageUrl) => {
  try {
    if (pageUrl === undefined) throw new Error('PageUrl cant be undefined')

    const previousPage = sessionStorage.getItem('DL_PreviousPage') || undefined
    sessionStorage.setItem('DL_PreviousPage', pageUrl)

    return {
      pageType: 'cart',
      event: 'virtualPageView',
      previousPage,
      currentPage: pageUrl,
      products: createApartment(),
      visitor: createVisitor(),
    }
  } catch (e) {
    dataLayerReport(
      `Error structuring virtual page view data for cart page: ${e}`
    )
    return undefined
  }
}

export const purchaseStructureVirtualPageViewData = (result, pageUrl) => {
  try {
    if (pageUrl === undefined) throw new Error('PageUrl cant be undefined')

    const previousPage = sessionStorage.getItem('DL_PreviousPage') || undefined
    sessionStorage.setItem('DL_PreviousPage', pageUrl)
    const apartmentStorage =
      JSON.parse(sessionStorage.getItem('DL_Apartment')) || undefined

    return {
      pageType: 'purchase',
      event: 'virtualPageView',
      previousPage,
      currentPage: pageUrl,
      reservationID: result?.request?.requestBodyJson?.reservationId,
      paymentOption: result?.request?.requestBodyJson?.paymentMethod,
      transactionTotal: apartmentStorage.cost?.total.amount,
      transactionID: apartmentStorage.aliasId,
      transactionProducts: createApartment(),
      visitor: createVisitor(),
    }
  } catch (e) {
    dataLayerReport(
      `Error structuring virtual page view data for purchase page: ${e}`
    )
    return undefined
  }
}

export const otherStructureVirtualPageViewData = (pageType, pageUrl) => {
  try {
    if (pageType === undefined || pageUrl === undefined)
      throw new Error('Page Type or PageUrl cant be undefined')

    const previousPage = sessionStorage.getItem('DL_PreviousPage') || undefined
    sessionStorage.setItem('DL_PreviousPage', pageUrl)

    return {
      pageType,
      event: 'virtualPageView',
      previousPage,
      currentPage: pageUrl,
      visitor: createVisitor(),
    }
  } catch (e) {
    dataLayerReport(
      `Error structuring virtual page view data for other pages: ${e}`
    )
    return undefined
  }
}

export const structureAddToCartData = (data) => {
  try {
    if (data === undefined) throw new Error('Data cant be undefined')

    const apartmentStorage =
      JSON.parse(sessionStorage.getItem('DL_Apartment')) || undefined
    const apartment = { ...apartmentStorage, ...data }
    sessionStorage.setItem('DL_Apartment', JSON.stringify(apartment))

    return {
      event: 'addToCart',
      product: createApartment(),
      quantity: 1,
    }
  } catch (e) {
    dataLayerReport(`Error structuring data for add to cart event: ${e}`)
    return undefined
  }
}

export const structureFormSubmissionData = (name, data) => {
  try {
    if (name === undefined || data === undefined)
      throw new Error('Type or data cant be undefined')

    let email
    switch (name) {
      case 'investor':
        email = data.owner.email
        break
      case 'incorporator':
        email = data.developer.email
        break
      case 'broker':
        email = data.broker.email
        break
      case 'housivendas-lead-investidor':
        email = data.email
        break
      case 'iRent':
        email = data.email
        break
      default:
        dataLayerReport(`Form name is invalid: Email is undefined`)
    }

    return {
      event: 'formSubmission',
      name,
      email,
    }
  } catch (e) {
    dataLayerReport(`Error structuring data for form submission event: ${e}`)
    return undefined
  }
}

export const structureProductsListData = (apartmentList, page) => {
  try {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const term = urlParams.get('term')

    const productsList = apartmentList.map((apartment, index) => {
      return {
        id: apartment.aliasId,
        available: apartment.building.active || undefined,
        name: apartment.aliasName,
        variant: apartment.rooms,
        list: term || undefined,
        position: index + 1,
        quantity: 1,
        bathrooms: apartment.bathrooms,
        originalPrice: {
          daily: apartment.newPrice.daily.grossAmount.amount,
          monthly: apartment.newPrice.monthly.grossAmount.amount,
        },
      }
    })

    return {
      event: 'productsList',
      page,
      productsList,
      visitor: createVisitor(),
    }
  } catch (e) {
    dataLayerReport(`Error structuring data for form products list event: ${e}`)
    return undefined
  }
}

export const structureCheckoutOptionData = (type, page) => {
  try {
    return {
      event: 'checkoutOption',
      page,
      type,
      visitor: createVisitor(),
    }
  } catch (e) {
    dataLayerReport(`Error structuring data for checkout option event: ${e}`)
    return undefined
  }
}

export const createSimple = (event, data) => {
  try {
    return {
      event,
      visitor: createVisitor(),
      ...data,
    }
  } catch (e) {
    dataLayerReport(`Error structuring data for simple events: ${e}`)
    return undefined
  }
}
