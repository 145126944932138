import * as c from '@chakra-ui/react'

export type AutocompleteValueType = {
  value: string
  label: string
}

interface OptionItemProps {
  option: AutocompleteValueType
  onSelect: (e?: any) => void
}

export const truncateString = (text: string, length: number) => {
  if (text.length <= length) {
    return text
  }
  return `${text.slice(0, length)}...`
}

const OptionItem = ({ option, onSelect }: OptionItemProps) => {
  return (
    <c.Flex
      as={c.Button}
      onClick={() => onSelect(option)}
      color="black.500"
      px="44px"
      minH="47px"
      maxH="47px"
      w="100%"
      bg="transparent"
      borderRadius="none"
      align="center"
      justify="space-between"
      gap={1}
      transition="all 0.2s"
      _hover={{ bg: 'black.200' }}
    >
      <c.Text color="black.500" fontSize={14} fontWeight={500} noOfLines={1}>
        {truncateString(option?.label, 30)}
      </c.Text>
    </c.Flex>
  )
}

export default OptionItem
