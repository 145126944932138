import { Box, Flex, Text } from '@chakra-ui/react'
import Dinero, { DineroObject } from 'dinero.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearch } from 'src/components/search-v2/search-context'
import { CLEANING_TAX_REGEX } from 'src/constants'
import { convertToMoney } from 'src/utils/monetary'

import { PropertyCardContext } from '../../property-card.context'

const PriceNight = () => {
  const { t } = useTranslation('card-apartment')
  const { property } = React.useContext(PropertyCardContext)
  const { context } = useSearch()

  const cleaningFee = React.useMemo(
    () =>
      property.price?.total?.fees?.find((fee: any) =>
        CLEANING_TAX_REGEX.test(JSON.stringify(fee.name))
      )?.amount ?? { amount: 0, currency: 'BRL', precision: 2 },
    [property.price?.total?.fees]
  ) as DineroObject

  const priceObject = React.useMemo(
    () =>
      Dinero(property?.price?.total?.grossAmount)
        .subtract(Dinero(cleaningFee))
        .toObject(),
    [property?.price?.total?.grossAmount, cleaningFee]
  )

  const nightPrice = React.useMemo(() => {
    if (!context.dailyCount) return property?.price?.daily?.grossAmount

    return Dinero(priceObject)
      .divide(context.dailyCount ?? 1)
      .toObject()
  }, [property?.price?.daily?.grossAmount, priceObject, context.dailyCount])

  return (
    <Box>
      <Flex alignItems="center" gap="8px">
        <Text m="0" color="black.500" fontWeight={700} fontSize={14}>
          {`${convertToMoney(nightPrice)}/`}
        </Text>
        <Text m="0" color="black.500" fontSize={14} fontWeight={500}>
          {t('card-apartment:NIGHT')}
        </Text>
      </Flex>
    </Box>
  )
}

export default PriceNight
