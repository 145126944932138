import { Box, Flex } from '@chakra-ui/react'
import copy from 'copy-to-clipboard'
import qs from 'qs'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { LikeHearthIcon } from 'src/assets/icons/like-hearth.icon'
import { ShareIcon } from 'src/assets/icons/share.icon'
import { notify } from 'src/components/alert/toast'
import { useUserFavorite } from 'src/hooks/user/favorites'
import { useAuth } from 'src/pages/auth/auth-context'

import { Gallery } from '../../../gallery'
import { PropertyCardContext } from '../../../property-card.context'

const LikeBtn = () => {
  const { property, isLiked, isPartner } = React.useContext(PropertyCardContext)
  const navigate = useNavigate()
  const { isAuth } = useAuth()

  const uri = qs.stringify({
    redirect_to: `${window.location.href}`,
  })
  const redirectAuth = () => navigate(`/auth/signin?${uri}`)

  const { insertFavorite, deleteFavorite } = useUserFavorite({
    reload: false,
  })

  const giveLike = useCallback(async () => {
    if (!isAuth) {
      return redirectAuth()
    }
    if (!isLiked) {
      await insertFavorite({ aliasId: property?.aliasId })
    } else await deleteFavorite({ aliasId: property?.aliasId })
  }, [isLiked, isAuth])

  return (
    <Flex
      height="40px"
      width="40px"
      backgroundColor="black.0"
      borderRadius="50%"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      onClick={giveLike}
    >
      <LikeHearthIcon
        width="20"
        height="17"
        active={isLiked}
        fillColor={
          isPartner ? 'var(--secondary-color)' : 'var(--primary-color)'
        }
        stroke={
          isLiked
            ? isPartner
              ? 'var(--secondary-color)'
              : 'var(--primary-color)'
            : 'var(--black-color)'
        }
      />
    </Flex>
  )
}

const ShareBtn = ({ property }) => {
  const { linkParams } = React.useContext(PropertyCardContext)

  const link = property?.building?.aliasId
    ? `${window.location.origin}/building/${property?.building?.aliasId}/apartment/${property?.aliasId}?${linkParams}`
    : `${window.location.origin}/apartment/${property?.aliasId}?${linkParams}`

  return (
    <Flex
      height="40px"
      width="40px"
      backgroundColor="black.0"
      borderRadius="50%"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      onClick={() => {
        copy(link)
        notify({ text: 'Link copiado com sucesso' }).success()
      }}
    >
      <ShareIcon />
    </Flex>
  )
}

const GalleryPreview = ({ gallery, property }) => {
  return (
    <>
      <Box zIndex={0} position="relative">
        <Flex gap="8px" position="absolute" zIndex="5" top="8px" left="8px">
          <LikeBtn />
          <ShareBtn property={property} />
        </Flex>
        <Gallery
          gallery={gallery}
          width="100%"
          height="210px"
          widthDesktop="100%"
          heightDesktop="210px"
          withIndicators
          imageProps={{
            style: { borderRadius: 8, width: '100%', height: '210px' },
          }}
        />
      </Box>
    </>
  )
}

export default GalleryPreview
