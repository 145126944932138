import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { IS_DEV_MODE } from 'src/constants'

import Header from '../header'
import Loading from '../list/loading'
import Map from '../map'
import Order from '../order'

const List = React.lazy(() => import('../list'))

const Desktop = () => {
  return (
    <Box h="100%" w="100%">
      <Flex h="100%" bg="black.100" position="relative" justify="space-between">
        <Box maxW="680px" h="100%" w="100%">
          <React.Suspense fallback="">
            <Header />
          </React.Suspense>
          <Box px="24px" h="100%" w="100%">
            {IS_DEV_MODE && <Order />}
            <React.Suspense fallback={<Loading />}>
              <List />
            </React.Suspense>
          </Box>
        </Box>
        <Box h="calc(100vh - 70px)" w="100%" position="sticky" top="60px">
          <Map />
        </Box>
      </Flex>
    </Box>
  )
}

export default Desktop
