interface CheckMarkIconProps {
  width?: number
  height?: number
  color?: string
}

export const CheckMarkIcon = ({
  height = 16,
  width = 16,
  color = 'success',
}: CheckMarkIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: width, minHeight: height }}
    >
      <path
        d="M6.57523 12C6.48418 12 6.39408 11.9814 6.31049 11.9453C6.2269 11.9092 6.1516 11.8563 6.08923 11.79L2.84723 8.33732C2.73182 8.20756 2.6716 8.03789 2.67938 7.86441C2.68716 7.69093 2.76232 7.52733 2.88887 7.40842C3.01543 7.28951 3.18339 7.22467 3.35702 7.22771C3.53064 7.23074 3.69624 7.3014 3.81856 7.42465L6.56856 10.352L12.1739 4.21865C12.2324 4.15213 12.3036 4.09793 12.3832 4.05923C12.4629 4.02052 12.5495 3.99808 12.638 3.99322C12.7264 3.98836 12.815 4.00118 12.8984 4.03093C12.9818 4.06067 13.0585 4.10675 13.1239 4.16647C13.1894 4.22618 13.2423 4.29834 13.2795 4.37873C13.3167 4.45911 13.3375 4.5461 13.3408 4.63463C13.344 4.72316 13.3295 4.81144 13.2983 4.89432C13.267 4.97719 13.2195 5.053 13.1586 5.11732L7.06723 11.784C7.00523 11.8516 6.92995 11.9056 6.84609 11.9428C6.76224 11.98 6.67162 11.9994 6.5799 12H6.57523Z"
        fill={`var(--${color}-color)`}
      />
    </svg>
  )
}
