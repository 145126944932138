import { createMachine } from 'xstate'

export const enum dates_states {
  idle = 'idle',
  focused = 'focused',
  filled = 'filled',
  disabled = 'disabled',
}

export const enum dates_events {
  focus = 'focus',
  blur = 'blur',
}
export interface ContextType {}

export interface EventsType {
  type: dates_events
}

export interface StatesType {
  value: dates_states
  context: ContextType
}

const datesMachine = createMachine<ContextType, EventsType, StatesType>({
  id: 'dates-machine',
  initial: dates_states.disabled,
  states: {
    [dates_states.disabled]: {
      on: {
        [dates_events.focus]: {
          target: dates_states.focused,
        },
      },
    },
    [dates_states.idle]: {
      on: {
        [dates_events.focus]: {
          target: dates_states.focused,
        },
      },
    },
    [dates_states.focused]: {
      on: {
        [dates_events.blur]: {
          target: dates_states.idle,
        },
      },
    },
    [dates_states.filled]: {
      on: {
        [dates_events.focus]: {
          target: dates_states.focused,
        },
        [dates_events.blur]: {
          target: dates_states.idle,
        },
      },
    },
  },
})

export default datesMachine
