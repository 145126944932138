import Dinero from 'dinero.js'

export const convertToMoney = (value, percentage) => {
  try {
    const obj = new Dinero({ currency: 'BRL', ...value })
    return percentage
      ? obj
          .add(obj.percentage(percentage))
          .setLocale('pt-BR')
          .toFormat('$0,0.00')
      : obj.setLocale('pt-BR').toFormat('$0,0.00')
  } catch (e) {
    return 'R$ -'
  }
}
