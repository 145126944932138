import React from 'react'

import { NavbarProvider } from './navbar.context'
import { WindowProvider } from './window.context'

export const RootProvider = ({ children }) => (
  <WindowProvider>
    <NavbarProvider>{children}</NavbarProvider>
  </WindowProvider>
)
