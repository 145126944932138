import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators: UsersActions } = createActions({
  setUser: ['user'],
  setUserBySocialMedia: ['user', 'method'],
  setToken: ['token', 'refreshToken'],
  setUserFields: ['user'],
  setPrefLang: ['lang'],
  setFavorites: ['favorites'],
  setCards: ['cards'],
  setDocs: ['docs'],
  setAvatar: ['avatarUrl'],
  logout: [],
})

const userByStorage = JSON.parse(localStorage.getItem('user') ?? '{}')

const favoritesByStorage = JSON.parse(localStorage.getItem('favorites') ?? '[]')

const cardsByStorage = JSON.parse(localStorage.getItem('userCards') ?? '[]')

const docsByStorage = JSON.parse(localStorage.getItem('userDocs') ?? '[]')

const INITIAL_STATE = {
  favorites: favoritesByStorage,
  kind: userByStorage?.kind || null,
  userToken: localStorage.getItem('userToken'),
  firstName: userByStorage?.firstName || null,
  lastName: userByStorage?.lastName || null,
  birthDate: userByStorage?.birthDate || null,
  phones: userByStorage?.phones || null,
  gender: userByStorage?.gender || null,
  email: userByStorage?.email || null,
  prefLang: userByStorage?.prefLang || 'pt_BR',
  avatarUrl: localStorage.getItem('avatar') || null,
  id: userByStorage?._id || null,
  method: userByStorage?.method || null,
  logged: !!userByStorage?._id,
  cards: cardsByStorage,
  docs: docsByStorage,
  staysId: userByStorage?.staysId || null,
  createdAt: userByStorage?.createdAt || null,
}

const setUser = (state = INITIAL_STATE, action) => {
  const {
    kind,
    bearerToken,
    firstName,
    lastName,
    birthDate,
    gender,
    phones,
    email,
    prefLang,
    avatarUrl,
    staysId,
    _id,
    createdAt,
    tokens,
  } = action.user

  localStorage.setItem('userToken', bearerToken)
  localStorage.setItem('refreshToken', tokens?.refreshToken)
  localStorage.setItem('avatar', avatarUrl)

  localStorage.setItem(
    'user',
    JSON.stringify({ ...action.user, method: 'default' })
  )

  return {
    ...state,
    method: 'default',
    kind,
    userToken: bearerToken,
    firstName,
    phones,
    lastName,
    birthDate,
    gender,
    email,
    prefLang,
    avatarUrl,
    id: _id,
    logged: true,
    staysId,
    createdAt,
  }
}

const setUserBySocialMedia = (state = INITIAL_STATE, action) => {
  const token = action.user.bearerToken
  localStorage.setItem('userToken', token)
  localStorage.setItem('avatar', action.user.avatarUrl)

  localStorage.setItem(
    'user',
    JSON.stringify({ ...action.user, method: action.method || 'facebook' })
  )

  return {
    ...state,
    ...action.user,
    method: action.method || 'facebook',
    userToken: token,
    logged: true,
    id: action.user._id,
  }
}

const setUserFields = (state = INITIAL_STATE, action) => {
  const storage = JSON.parse(localStorage.getItem('user'))
  localStorage.setItem('user', JSON.stringify({ ...storage, ...action.user }))

  return {
    ...state,
    ...action.user,
  }
}

const setPrefLang = (state = INITIAL_STATE, action) => ({
  ...state,
  prefLang: action.lang,
})

const setFavorites = (state = INITIAL_STATE, action) => {
  localStorage.setItem('favorites', JSON.stringify(action.favorites))

  return {
    ...state,
    favorites: action.favorites,
  }
}

const setToken = (state = INITIAL_STATE, action) => {
  localStorage.setItem('userToken', action.token)
  localStorage.setItem('refreshToken', action.refreshToken)

  return {
    ...state,
    userToken: action.token,
  }
}

const setCards = (state = INITIAL_STATE, action) => {
  localStorage.setItem('userCards', JSON.stringify(action.cards))

  return {
    ...state,
    cards: action.cards,
  }
}

const setDocs = (state = INITIAL_STATE, action) => {
  localStorage.setItem('userDocs', JSON.stringify(action.docs))

  return {
    ...state,
    docs: action.docs,
  }
}

const setAvatar = (state = INITIAL_STATE, action) => {
  localStorage.setItem('avatar', action.avatarUrl)

  return {
    ...state,
    avatarUrl: action.avatarUrl,
  }
}

const logout = () => {
  localStorage.removeItem('userToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('user')
  localStorage.removeItem('avatar')
  localStorage.removeItem('userCards')
  localStorage.removeItem('favorites')
  localStorage.removeItem('userDocs')

  return {
    favorites: [],
    docs: [],
    cards: [],
    kind: null,
    userToken: null,
    firstName: null,
    lastName: null,
    birthDate: null,
    gender: null,
    email: null,
    prefLang: 'pt_BR',
    avatarUrl: null,
    id: null,
    logged: false,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.SET_USER]: setUser,
  [Types.SET_USER_BY_SOCIAL_MEDIA]: setUserBySocialMedia,
  [Types.SET_TOKEN]: setToken,
  [Types.SET_USER_FIELDS]: setUserFields,
  [Types.SET_PREF_LANG]: setPrefLang,
  [Types.SET_FAVORITES]: setFavorites,
  [Types.SET_CARDS]: setCards,
  [Types.SET_DOCS]: setDocs,
  [Types.SET_AVATAR]: setAvatar,
  [Types.LOGOUT]: logout,
})
