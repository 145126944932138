import { Flex, Heading } from '@chakra-ui/react'

import { useMaps } from './context'

const MapLoading = () => {
  const { valid } = useMaps()

  if (!valid.requesting) return null

  return (
    <Flex
      align="center"
      gap={3}
      pos="absolute"
      top={5}
      zIndex={99}
      w="fit-content"
      bg="black.0"
      px={4}
      py={2}
      borderRadius={8}
      shadow="xl"
    >
      <Heading size="xs">Buscando nessa região...</Heading>
    </Flex>
  )
}

export default MapLoading
