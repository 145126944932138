import { Variants } from 'framer-motion'
import { __, match } from 'ts-pattern'

export const variants: Variants = {
  animate: {
    y: 0,
    x: 0,
    width: '100%',
  },
  initial: (direction: 'prev' | 'next' | 'jump') => {
    return {
      width: '100%',
      x: match(direction)
        .with('next', () => 400)
        .with('prev', () => -400)
        .with(__, () => 0)
        .run(),
      y: match(direction)
        .with('jump', () => -400)
        .with(__, () => 0)
        .run(),
    }
  },
  exit: (direction: 'prev' | 'next' | 'jump') => {
    return {
      width: '100%',
      y: match(direction)
        .with('jump', () => 400)
        .with(__, () => 0)
        .run(),
      x: match(direction)
        .with('next', () => 400)
        .with('prev', () => -400)
        .with('jump', () => 400)
        .run(),
    }
  },
}
