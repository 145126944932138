export const HeartIcon = (props: any) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99936 17C9.89428 16.9999 9.79025 16.98 9.69323 16.9414C9.5962 16.9028 9.50807 16.8463 9.43388 16.7752L3.22238 10.8263C2.43954 10.0749 2 9.05761 2 7.99711C2 6.93661 2.43954 5.91931 3.22238 5.16793C4.00697 4.42 5.06976 4 6.17776 4C7.28576 4 8.34855 4.42 9.13314 5.16793L9.99936 5.99986L10.8672 5.17023C11.6517 4.42211 12.7145 4.00198 13.8226 4.00198C14.9306 4.00198 15.9935 4.42211 16.7779 5.17023C17.5606 5.9217 18 6.93896 18 7.9994C18 9.05984 17.5606 10.0771 16.7779 10.8286L10.5672 16.776C10.4927 16.8472 10.4042 16.9037 10.3067 16.9422C10.2092 16.9806 10.1048 17.0003 9.99936 17ZM6.17776 5.53037C5.83912 5.52901 5.50356 5.5919 5.19052 5.71542C4.87749 5.83893 4.5932 6.0206 4.35414 6.2499C3.87056 6.71433 3.59906 7.34295 3.59906 7.99826C3.59906 8.65356 3.87056 9.28218 4.35414 9.74661L9.99936 15.1534L15.647 9.74661C15.8871 9.51761 16.0778 9.24564 16.208 8.94621C16.3382 8.64679 16.4055 8.32577 16.4059 8.0015C16.4064 7.67723 16.34 7.35604 16.2106 7.05629C16.0812 6.75654 15.8913 6.48409 15.6518 6.25449C15.4123 6.02489 15.1278 5.84265 14.8146 5.71816C14.5013 5.59367 14.1656 5.52938 13.8264 5.52896C13.1413 5.52809 12.484 5.78743 11.9989 6.2499L10.5672 7.62243C10.4145 7.76137 10.2118 7.83886 10.001 7.83886C9.79015 7.83886 9.5874 7.76137 9.43468 7.62243L7.99978 6.24914C7.76084 6.02015 7.47679 5.83873 7.16405 5.71536C6.85131 5.59198 6.51608 5.52911 6.17776 5.53037Z"
      fill="currentColor"
    />
  </svg>
)
