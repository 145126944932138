import { createApiRequest } from 'src/store/api'

export const getOta = async (partnerCode: string) => {
  return await createApiRequest({
    url: `/reservations/stays/retrieve/${partnerCode}`,
    params: {},
  })
}

export const importReservation = async (aliasId: string) => {
  return await createApiRequest({
    method: 'post',
    url: `/reservations/stays/import/${aliasId}`,
    params: {},
  })
}

export const sendSms = async (phone: string) => {
  return await createApiRequest({
    method: 'post',
    url: `/verify/sms`,
    data: { phone },
    params: {},
  })
}

export const verifySmsCode = async (code: string) => {
  return await createApiRequest({
    url: `/verify/${code}`,
    params: {},
  })
}
