import * as c from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { LogoutIcon } from 'src/components/navbar/icons/logout.icon'
import { useAuth } from 'src/pages/auth/auth-context'

interface ButtonLogoutInterface {
  isLogged: boolean
}

const ButtonLogout = ({ isLogged }: ButtonLogoutInterface) => {
  const { t } = useTranslation('components/nav')
  const [isMobile] = c.useMediaQuery('(max-width: 720px)')

  const { handleLogout } = useAuth()

  if (!isLogged) return null

  return (
    <>
      {isMobile && <c.Divider h="1px" w="full" />}

      <c.Button
        onClick={handleLogout}
        variant="outline"
        gap="0px"
        px="0px"
        py="s4"
        border="none"
        height="50px"
        color="red.500"
        fontWeight="medium"
        fontSize={['t-md.1', 't-md.0']}
        alignItems="center"
        justifyContent="start"
        leftIcon={
          <c.Icon as={LogoutIcon} w="1.25rem" h="1.25rem" color="red.500" />
        }
        _hover={{ bg: 'none' }}
      >
        {t('components/nav:ACTIONS.logout')}
      </c.Button>
    </>
  )
}

export default ButtonLogout
