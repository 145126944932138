import React from 'react'

const FacebookIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9 4.10004C21.3643 1.56999 17.8628 0 14 0C10.1372 0 6.63574 1.56999 4.10004 4.10004C1.56434 6.63574 0 10.1315 0 14C0 21.7313 6.26866 28 14 28C17.8628 28 21.3643 26.43 23.9 23.9C26.4357 21.3643 28 17.8685 28 14C28 10.1315 26.4357 6.63574 23.9 4.10004ZM17.3715 9.92255C17.3715 10.0581 17.2586 10.1767 17.1174 10.1767H16.0274C15.2763 10.1767 15.1408 10.4704 15.1408 11.0464V12.2436H17.044C17.1117 12.2436 17.1739 12.2719 17.2247 12.3227C17.2699 12.3679 17.2981 12.43 17.2981 12.4978V14.5534C17.2981 14.6946 17.1852 14.8076 17.044 14.8076H15.1408V19.9863C15.1408 20.1218 15.0278 20.2404 14.8866 20.2404H12.7632C12.622 20.2404 12.5091 20.1275 12.5091 19.9863V14.8076H10.8826C10.7414 14.8076 10.6285 14.6946 10.6285 14.5534V12.4978C10.6285 12.3622 10.7414 12.2436 10.8826 12.2436H12.5091V10.8318C12.5091 8.96248 13.6837 7.75958 15.5022 7.75958H17.1174C17.2586 7.75958 17.3715 7.87253 17.3715 8.01371V9.92255Z"
        fill="#FA2F93"
      />
    </svg>
  )
}

export default FacebookIcon
