import { motion } from 'framer-motion'
import styled from 'styled-components'

export const ButtonLike = styled.button`
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 180px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  max-height: 28px;
  max-width: 28px;
  min-height: 28px;
  min-width: 28px;
  padding: 3px;

  :hover,
  :active,
  :focus {
    background: #fff;
    max-height: 29px;
    max-width: 29px;
    min-height: 29px;
    min-width: 29px;
    outline: none;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    padding: 3px;
  }
  transition: all 0.2s;
  user-select: none;
  z-index: 9;

  svg,
  path {
    margin: 0;
    transition: all 0.2s;
  }
`

export const ImageGallery = styled.img`
  cursor: pointer;
  object-fit: cover;
  object-position: center;
`

export const BarAnimated = styled(motion.div)`
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 30%,
    rgba(255, 255, 255, 0.7) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  max-width: 60px;
  min-height: ${(p) => p.size || 220}px;
  min-width: 10%;
`
