import { Flex, retriever } from '@retriever-ui/react'
import React from 'react'
import { StarIcon } from 'src/assets/icons/star.icon'

import { PropertyCardContext } from '../property-card.context'

const FlagType = () => {
  const { isPartner, property } = React.useContext(PropertyCardContext)
  const isFlagship = property?.building?.flagship

  const FlagshipFlag = () => (
    <Flex
      top="12px"
      left="12px"
      h="20px"
      pX="8px"
      radius="8px"
      bg="black.0"
      pos="absolute"
      w="fit-content"
      alignItems="center"
      shadow="0px 4px 16px -5px black.700/7"
      backgroundColor="primary.500"
      style={{ zIndex: 1 }}
    >
      <retriever.p
        d="flex"
        h="fit-content"
        w="fit-content"
        fontSize={12}
        m="0"
        alignItems="center"
        fontWeight={500}
        gap="4px"
        color="black.0"
      >
        <StarIcon color="#FFF" /> Prédio Housi
      </retriever.p>
    </Flex>
  )

  const PartnerFlag = () => (
    <Flex
      top="12px"
      left="12px"
      h="20px"
      pX="8px"
      radius="8px"
      bg="black.0"
      pos="absolute"
      w="fit-content"
      alignItems="center"
      shadow="0px 4px 16px -5px black.700/7"
      backgroundColor={isPartner ? 'secondary.400' : 'primary.500'}
      style={{ zIndex: 1 }}
    >
      <retriever.p
        d="flex"
        h="fit-content"
        w="fit-content"
        fontSize={12}
        m="0"
        alignItems="center"
        fontWeight={500}
        gap="4px"
        color="black.0"
      >
        Unidade Parceira
      </retriever.p>
    </Flex>
  )

  if (!isPartner && !isFlagship) return null
  if (isPartner) return <PartnerFlag />

  return <FlagshipFlag />
}

export default FlagType
