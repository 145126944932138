import { sleep } from 'src/utils/sleep'

export const lazyZoom = async (
  map: google.maps.Map,
  zoom: number
): Promise<void> => {
  const currentZoom = map?.getZoom()!

  if (currentZoom > zoom) return map.setZoom(zoom)
  if (currentZoom === zoom) return

  for (let i = currentZoom; i <= zoom; i += 1) {
    await sleep(10 * i)
    map.setZoom(i)
  }
}

export const removeEmpty = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => !!v))
}

export const onlyEmpty = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => !v))
}
