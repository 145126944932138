import { useMediaQuery } from '@chakra-ui/react'
import * as c from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import HousiLogoIcon from 'src/assets/icons/nav/housi-logo.icon'
import { SearchButton } from 'src/components/search-v2'

import { NavbarContext } from '../../../store/context/navbar.context'
import NavItems from './items'

const NavbarDesktop = () => {
  const { searchNotVisible } = React.useContext(NavbarContext)
  const [isMobile] = useMediaQuery(['(max-width: 768px)'])

  if (isMobile) return null

  return (
    <c.Flex
      width="100%"
      height="70px"
      bg="black.0"
      position="sticky"
      justify="center"
      top="0"
      zIndex={99}
    >
      <c.Flex
        w="full"
        maxWidth="1200px"
        pos="relative"
        alignItems="center"
        justify="space-between"
      >
        <c.Flex gap="40px" alignItems="center">
          <Link to="/">
            <HousiLogoIcon />
          </Link>
          <React.Suspense fallback="">
            {!searchNotVisible && <SearchButton />}
          </React.Suspense>
        </c.Flex>
        <React.Suspense fallback="">
          <NavItems />
        </React.Suspense>
      </c.Flex>
    </c.Flex>
  )
}

export default NavbarDesktop
