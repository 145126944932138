import { v4 } from 'uuid'

import { SuggestionProps } from '../suggestions'

export const cities: SuggestionProps[] = [
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/site/search/cities/housi-cities-sao-paulo.jpg`,
    address: 'São Paulo - SP',
    _id: v4(),
    zoom: 14,
    coords: {
      lat: -23.5557714,
      long: -46.6395571,
    },
    searchTerm: 'São Paulo - SP',
  },
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/site/search/cities/housi-cities-goiania.jpg`,
    address: 'Goiânia - GO',
    _id: v4(),
    zoom: 14,
    coords: {
      lat: -16.6868912,
      long: -49.2647943,
    },
    searchTerm: 'Goiânia - GO',
  },
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/site/search/cities/housi-cities-recife.jpeg`,
    address: 'Recife - PE',
    _id: v4(),
    zoom: 14,
    coords: {
      lat: -8.059340474084824,
      long: -34.89081368426121,
    },
    searchTerm: 'Recife - PE',
  },
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/site/search/cities/housi-cities-curitiba.jpg`,
    address: 'Curitiba - PR',
    _id: v4(),
    zoom: 14,
    coords: {
      lat: -25.4372382,
      long: -49.2699727,
    },
    searchTerm: 'Curitiba - PR',
  },
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/site/search/cities/housi-cities-rio-de-janeiro.jpg`,
    address: 'Rio de Janeiro - RJ',
    _id: v4(),
    zoom: 14,
    coords: {
      lat: -22.9068467,
      long: -43.1728965,
    },
    searchTerm: 'Rio de Janeiro - RJ',
  },
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/site/search/cities/housi-cities-porto-alegre.jpg`,
    address: 'Porto Alegre - RS',
    _id: v4(),
    zoom: 14,
    coords: {
      lat: -30.0368176,
      long: -51.2089887,
    },
    searchTerm: 'Porto Alegre - RS',
  },
]

export const buildings: SuggestionProps[] = [
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/site/search/buildings/housi-search-bela-cintra.jpg`,
    address: 'Bela cintra - SP',
    _id: v4(),
    zoom: 17,
    searchTerm: 'Housi Bela Cintra, Rua Bela Cintra - Cerqueira César',
    coords: {
      lat: -23.558694,
      long: -46.66402679999999,
    },
  },
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/site/search/buildings/housi-search-paulista-v2.jpeg`,
    address: 'Paulista - SP',
    _id: v4(),
    zoom: 17,
    searchTerm: 'Housi Paulista, Rua Bela Cintra - Consolação',
    coords: {
      lat: -23.5557881,
      long: -46.66165170000001,
    },
  },
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/site/search/buildings/housi-search-tolive.jpg`,
    address: 'Tolive One - PE',
    _id: v4(),
    zoom: 17,
    searchTerm:
      'Edf. Tolive one - Rua General Joaquim Inácio - Ilha do Leite, Recife - PE',
    coords: {
      lat: -8.0630652,
      long: -34.8948801,
    },
  },
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/buildings/housi-town-co-poa.webp`,
    address: 'Town Co - RS',
    _id: v4(),
    zoom: 17,
    searchTerm: 'Housi - Town.Co, Rua Senhor dos Passos - Centro Histórico',
    coords: {
      lat: -30.028368056280232,
      long: -51.22318596060027,
    },
  },
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/site/search/buildings/housi-search-faria-lima.jpg`,
    address: 'Faria Lima - SP',
    _id: v4(),
    zoom: 17,
    searchTerm:
      'HOUSI - VN Faria Lima, Avenida Brigadeiro Faria Lima - Itaim Bibi',
    coords: {
      lat: -23.5958866,
      long: -46.6801203,
    },
  },
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/site/search/buildings/housi-search-brickell.png`,
    address: 'Brickell One - PR',
    _id: v4(),
    zoom: 17,
    searchTerm:
      'BRIKELL ONE - POWERED BY HOUSI, Avenida Silva Jardim - Água Verde',
    coords: {
      lat: -25.440631,
      long: -49.2694293,
    },
  },
  {
    mainPhoto: `${process.env.REACT_APP_CDN_IMAGE}/160x130/https://housi-images.s3.amazonaws.com/site/search/buildings/housi-search-metropolitan.jpg`,
    address: 'Metropolitan - PR',
    _id: v4(),
    zoom: 17,
    searchTerm:
      'Bristol Metropolitan Flat Hotel Curitiba, Rua Emiliano Perneta - Centro',
    coords: {
      lat: -25.4342823,
      long: -49.2742421,
    },
  },
]
