import * as c from '@chakra-ui/react'
import { ArrowRightIcon } from 'src/assets/icons/nav/arrow-right.icon'

const Button = ({ label, path, Icon, lastDivider }) => {
  return (
    <>
      <c.Button
        as={c.Link}
        href={path}
        isExternal
        variant="outline"
        gap="0px"
        px="0px"
        py="s4"
        border="none"
        height="50px"
        fontWeight="medium"
        fontSize="t-md.0"
        alignItems="center"
        leftIcon={
          <c.Icon as={Icon} w="1.25rem" h="1.25rem" color="black.400" />
        }
        _hover={{ bg: 'none' }}
      >
        {label}

        <c.Icon
          ml="auto"
          as={ArrowRightIcon}
          color="black.300"
          w="1.25rem"
          h="1.25rem"
        />
      </c.Button>
      {!lastDivider && <c.Divider h="1px" w="full" />}
    </>
  )
}

export default Button
