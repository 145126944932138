import { assign, createMachine } from 'xstate'

import { AutocompleteValueType } from './autocomplete'

// STATES - STATES OF MACHINE
export const enum input_states {
  idle = 'idle',
  disabled = 'disabled',
  focused = 'focused',
}

export const enum autocomplete_states {
  idle = 'idle',
  stopped = 'stopped',
  changing = 'changing',
}

export const enum request_states {
  idle = 'idle',
  success = 'success',
  error = 'error',
  pending = 'pending',
}

// EVENTS - EVENTS OF MACHINE
export const enum input_events {
  focus = 'focus',
  blur = 'blur',
  disable = 'disable',
}

export const enum autocomplete_events {
  change = 'change',
  stop = 'stop',
  blur = 'blur',
  clear = 'clear',
}

export const enum request_events {
  request = 'request',
  set_term = 'set_term',
}
export type Events = input_events | request_events | autocomplete_events

export type States = {
  input: input_states
  autocomplete: autocomplete_states
  request: request_states
}
export type ContextType = {
  options?: AutocompleteValueType[]
  value?: AutocompleteValueType
  term?: AutocompleteValueType
  inputValue: string
  error?: any
}

interface EventsType {
  type: Events
  value?: AutocompleteValueType
}

interface StatesType {
  value: {
    states: States
  }
  context: ContextType
}

export const initialOptions = [
  {
    label: 'Housi Bela Cintra, Rua Bela Cintra - Cerqueira César',
    value: 'ChIJ24mQNlZZzpQRkdT3NVhPPco',
  },
  {
    label: 'HOUSI - VN Faria Lima, Avenida Brigadeiro Faria Lima - Itaim Bibi',
    value: 'ChIJZV5ofGlXzpQRhboMADC6fsI',
  },
  {
    label: 'Housi Paraíso, Rua Doutor Nicolau de Sousa Queirós - Vila Mariana',
    value: 'ChIJE_IwX8pZzpQRzm4FpwNFwqM',
  },
  { label: 'São Paulo - SP', value: 'ChIJ0WGkg4FEzpQRrlsz_whLqZs' },
  {
    label: 'Rio de Janeiro - RJ',
    value: 'ChIJW6AIkVXemwARTtIvZ2xC3FA',
  },
]

const autocompleteMachine = createMachine<ContextType, EventsType, StatesType>(
  {
    id: 'autocomplete-search',
    context: {
      inputValue: '',
      value: undefined,
      error: undefined,
      options: initialOptions,
    },
    type: 'parallel',
    states: {
      input: {
        initial: input_states.idle,
        states: {
          [input_states.focused]: {
            on: {
              [input_events.blur]: { target: input_states.idle },
              [input_events.disable]: { target: input_states.disabled },
            },
          },
          [input_states.idle]: {
            on: {
              [input_events.focus]: { target: input_states.focused },
              [input_events.disable]: { target: input_states.disabled },
            },
          },
          [input_states.disabled]: {
            on: {
              [input_events.focus]: { target: input_states.focused },
            },
          },
        },
      },
      autocomplete: {
        initial: autocomplete_states.idle,
        states: {
          [autocomplete_states.idle]: {
            on: {
              [autocomplete_events.clear]: { actions: 'clearTerm' },
              [autocomplete_events.change]: {
                actions: assign((context, event) => {
                  const { inputValue } = event as any

                  return {
                    inputValue,
                  }
                }),
                target: autocomplete_states.changing,
              },
            },
          },
          [autocomplete_states.changing]: {
            on: {
              [autocomplete_events.clear]: { actions: 'clearTerm' },
              [autocomplete_events.stop]: {
                target: autocomplete_states.stopped,
                actions: assign((context) => {
                  const text = context.inputValue

                  if (text.length !== 0) return context

                  return {
                    options: initialOptions,
                  }
                }),
              },
              [autocomplete_events.change]: {
                actions: assign((context, event) => {
                  const { inputValue } = event as any

                  return {
                    inputValue,
                  }
                }),
              },
            },
          },
          [autocomplete_states.stopped]: {
            on: {
              [autocomplete_events.clear]: { actions: 'clearTerm' },
              [autocomplete_events.change]: {
                actions: assign((context, event) => {
                  const { inputValue } = event as any

                  return {
                    inputValue,
                  }
                }),
                target: autocomplete_states.changing,
              },
            },
          },
        },
      },
      request: {
        initial: request_states.idle,
        states: {
          [request_states.idle]: {
            on: {
              [request_events.request]: { target: request_states.pending },
              [request_events.set_term]: {
                actions: assign((context, event) => {
                  const { inputValue, term } = event as any

                  return {
                    inputValue,
                    term,
                  }
                }),
              },
            },
          },
          [request_states.pending]: {
            invoke: {
              id: 'request',
              src: 'request',
              onDone: {
                target: request_states.success,
                actions: assign({
                  options: (context, event) => event.data,
                }),
              },
              onError: {
                target: request_states.error,
                actions: assign({ error: (context, event) => event.data }),
              },
            },
          },
          [request_states.success]: {
            on: {
              [request_events.request]: { target: request_states.pending },
              [request_events.set_term]: {
                actions: assign((context, event) => {
                  const { inputValue, term } = event as any

                  return {
                    inputValue,
                    term,
                  }
                }),

                target: request_states.idle,
              },
            },
          },
          [request_states.error]: {
            on: {
              [request_events.request]: { target: request_states.pending },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      clearTerm: assign({
        inputValue: '',
        value: undefined,
        options: initialOptions,
      }) as any,
    },
  }
)

export default autocompleteMachine
