import styled from 'styled-components'

import { Column, CustomColumn } from './column'
import { Container, ContainerFullWidthAndHeight } from './container'
import { CustomRow, Row } from './row'

const ContainerCustom = styled.div`
  padding: 0 15px;
  position: relative;
  width: 100%;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
`

export {
  Column,
  Container,
  ContainerCustom,
  ContainerFullWidthAndHeight,
  CustomColumn,
  CustomRow,
  Row,
}
