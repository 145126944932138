import { RetrieverProps } from '@retriever-ui/system/lib/retriever'
import { AnimatePresence } from 'framer-motion'
import React from 'react'

import MotionRetriever from '../retriever-motion'

interface ShadowSkeletonProps extends RetrieverProps<'div'> {
  isLoading?: boolean
}

const ShadowSkeleton = ({
  isLoading,
  children,
  ...props
}: ShadowSkeletonProps) => {
  if (!isLoading) return <>{children}</>

  return (
    <AnimatePresence initial>
      {isLoading ? (
        <MotionRetriever
          radius={4}
          h={30}
          w={[260, 350]}
          {...(props as any)}
          initial={{ backgroundColor: '#EEE' }}
          animate={{ backgroundColor: '#DDD' }}
          exit={{ opacity: 0, backgroundColor: 'none' }}
          transition={{ repeat: Infinity, duration: 0.8, repeatType: 'mirror' }}
        />
      ) : (
        children
      )}
    </AnimatePresence>
  )
}

export default ShadowSkeleton
