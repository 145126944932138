import { useTranslation } from 'react-i18next'

import { FooterItem } from '../../../footer.interface'

export function LinksConfig() {
  const { t } = useTranslation('footer')

  const firstColumnItems: FooterItem[] = [
    {
      label: t('footer:links.LINK0'),
      path: 'https://appspace.housi.com',
      isExternal: true,
    },
    {
      label: t('footer:links.LINK3'),
      path: 'https://lps.housi.com/decor/',
      isExternal: true,
    },
    {
      label: t('footer:LIVING_FOR_COMPANIES'),
      path: 'https://lps.housi.com/reservas-corporativas',
      isExternal: true,
    },
  ]

  const secondColumnItems: FooterItem[] = [
    {
      label: t('footer:links.LINK6'),
      path: 'https://blog.housi.com.br/housi-blog',
      isExternal: true,
    },
    {
      label: t('footer:links.LINK1'),
      path: 'https://lps.housi.com/quemsomos',
      isExternal: true,
    },
    {
      label: t('footer:links.LINK5'),
      path: 'https://www.inhire.com.br/carreiras-housi',
      isExternal: true,
    },
    {
      label: t('footer:ANSWER'),
      path: 'https://lps.housi.com/faq',
      isExternal: true,
    },
  ]

  const thirdColumnItems: FooterItem[] = [
    {
      label: t('footer:HELP'),
      path: 'https://api.whatsapp.com/send?1=pt_BR&phone=5511916678278',
      isExternal: true,
    },
    {
      label: t('footer:TERMS'),
      path: '/termos-e-condicoes',
      isExternal: false,
    },
  ]

  return {
    firstColumnItems,
    secondColumnItems,
    thirdColumnItems,
  }
}
