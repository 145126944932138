export const colors = {
  primary: '#FA2F93',
  secondary: '#000050',
  black: '#616161',
  white: '#fff',
  gray: '#7E7E7E',
  background: '#e6e6e6',
  backgroundSecondary: '#474747',
  danger: '#EB5757',
  success: '#28C48C',
  info: '#F2994A',
  actions: {
    success: '#28C48C',
    danger: '#EB5757',
    info: '#F2994A',
  },
}

export const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1440px',
}

export const fontSizes = {
  mobile: {
    extrasmall: '14px',
    extraSmall: '14px',
    small: '16px',
    medium: '20px',
    large: '24px',
    extralarge: '32px',
    extraLarge: '32px',
    text: {
      extrasmall: '10px',
      extraSmall: '10px',
      small: '10px',
      medium: '12px',
      large: '14px',
      extralarge: '16px',
      extraLarge: '16px',
    },
  },
  desktop: {
    extrasmall: '16px',
    extraSmall: '16px',
    small: '20px',
    medium: '24px',
    large: '32px',
    extralarge: '40px',
    extraLarge: '40px',
    text: {
      extrasmall: '10px',
      small: '12px',
      medium: '14px',
      large: '16px',
      extralarge: '18px',
      extraLarge: '18px',
    },
  },
}
