import * as c from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomColumn } from '../../../../components/layout'
import { convertToMoney } from '../../../../utils/monetary'
import { BookingContext } from '../../booking.context'
import Fees from './fees'
import PackHousi from './pack-housi'

export const RentPrice = ({ isModal }: { isModal?: boolean }) => {
  const { t } = useTranslation('property-detail/booking-resume')
  const { price, isSubscriptionUnavailable } = useContext(BookingContext)

  if (isSubscriptionUnavailable) {
    return (
      <CustomColumn topCustom={16} flex justify="space-between">
        <c.Text
          fontSize={isModal ? 't-md.1' : 't-md.0'}
          color="black.500"
          fontWeight="medium"
        >
          {t('property-detail/booking-resume:RENT')}
        </c.Text>
        <c.Text
          fontSize={isModal ? 't-md.1' : 't-md.0'}
          fontWeight="medium"
          color="black.500"
          textDecoration="line-through"
        >
          {convertToMoney(price?.monthly?.netAmount)}
        </c.Text>
      </CustomColumn>
    )
  }

  return (
    <CustomColumn topCustom={16} flex justify="space-between">
      <c.Text
        fontSize={isModal ? 't-md.1' : 't-md.0'}
        color="black.500"
        fontWeight="medium"
      >
        {t('property-detail/booking-resume:RENT')}
      </c.Text>

      <c.Text
        fontSize={isModal ? 't-md.1' : 't-md.0'}
        fontWeight="medium"
        color="black.500"
      >
        {convertToMoney(price?.monthly?.netAmount)}
      </c.Text>
    </CustomColumn>
  )
}

const Subscription = () => {
  return (
    <c.Flex direction="column" gap={2} mb={4}>
      <RentPrice />
      <Fees />
      <PackHousi />
    </c.Flex>
  )
}

export default Subscription
