import propTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

const themePrimary = css`
  color: var(--primary-color);
  text-decoration-color: var(--primary-color);
  :hover {
    color: var(--primary-color-dark-1);
    text-decoration-color: var(--primary-color-dark-1);
  }
  :disabled {
    color: var(--gray-color);
    text-decoration-color: var(--gray-color);
  }
`

const themeSecondary = css`
  color: var(--secondary-color);
  text-decoration-color: var(--secondary-color);
  :hover {
    color: var(--secondary-color-dark-1);
    text-decoration-color: var(--secondary-color-dark-1);
  }
  :disabled {
    color: var(--gray-color);
    text-decoration-color: var(--gray-color);
  }
`

const themeBlack = css`
  color: var(--black-color);
  text-decoration-color: var(--black-color);
  :hover {
    color: var(--black-color-2);
    text-decoration-color: var(--black-color-2);
  }
  :disabled {
    color: var(--gray-color);
    text-decoration-color: var(--gray-color);
  }
`

const getTheme = (themeColor) => {
  switch (themeColor) {
    case 'primary':
      return themePrimary
    case 'secondary':
      return themeSecondary
    case 'black':
      return themeBlack
    default:
      return themeBlack
  }
}

const getFontWeight = (weight) => {
  switch (weight) {
    case 'bold':
      return 'bold'
    case 'semiBold':
      return 600
    case 'medium':
      return 500
    case 'regular':
      return 'normal'
    case 'light':
      return 300
    case 'thin':
      return 200
    default:
      return 'normal'
  }
}

const Button = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  height: fit-content;
  text-decoration: underline;
  width: fit-content;
  transition: all 0.2s;
  user-select: none;
  cursor: pointer;
  font-weight: ${(p) => getFontWeight(p.weight)};
  padding: 0;

  ${(p) => getTheme(p.buttonTheme)}
`

const LabelButton = ({ weight = 'semiBold', children, ...props }) => {
  return (
    <Button weight={weight} {...props}>
      {children}
    </Button>
  )
}

LabelButton.propTypes = {
  type: propTypes.oneOf(['button', 'submit']),
  buttonTheme: propTypes.oneOf(['primary', 'secondary', 'black']),
  weight: propTypes.oneOf(['regular', 'semiBold', 'bold']),
  onClick: propTypes.func,
  disabled: propTypes.bool,
}

export default LabelButton
