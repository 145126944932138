import '../card-list.css'

import { Button, Container, Flex, Heading } from '@chakra-ui/react'
import { retriever } from '@retriever-ui/react'
import { motion } from 'framer-motion'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowIndicateIcon } from 'src/assets/icons/arrow-indicate.icon'
import { useRequest } from 'src/hooks/use-request'

import { useHideSearchBar } from '../../../../store/context/navbar.context'

const CardItem = ({ name, id }) => {
  const navigate = useNavigate()
  return (
    <Button
      rightIcon={<ArrowIndicateIcon w={8} h={8} direction="right" />}
      variant="ghost"
      onClick={() => navigate(`/housi-ofertas/${id}?page=1`)}
    >
      {name}
    </Button>
  )
}

export const Loading = () => {
  return (
    <retriever.div
      borderColor="primary.400"
      mBottom="60px"
      mTop="120px"
      w="100%"
      d="flex"
      justifyContent="center"
    >
      <motion.div
        animate={{ rotate: 360 }}
        transition={{
          loop: Infinity,
          ease: 'linear',
          duration: 0.65,
        }}
        style={{
          height: '50px',
          width: '50px',
          borderRadius: '50%',
          border: 'solid 2px',
          borderColor: 'inherit',
          borderTopColor: 'transparent',
        }}
      />
    </retriever.div>
  )
}

type ListItem = {
  category: string
  list: string[]
  name: string
  type: string
  _id: string
  carousel: boolean
}

export type ListsType = {
  items: ListItem[]
}

const ListApartments = React.memo(() => {
  const { data, isLoading } = useRequest<ListsType>({
    url: '/lists',
  })
  if (isLoading) return <Loading />

  return (
    <Flex align="flex-start" direction="column" gap={8}>
      {data?.items!.map((item) => (
        <CardItem id={item._id} name={item.name} key={item._id} />
      ))}
    </Flex>
  )
})

const OffersList = () => {
  useHideSearchBar({})

  return (
    <retriever.div mBottom="120px">
      <Container mt="80px">
        <React.Suspense fallback={<Loading />}>
          <Heading size="md">Ofertas criadas:</Heading>
          <ListApartments />
        </React.Suspense>
      </Container>
    </retriever.div>
  )
}

export default OffersList
