import { Button, Flex } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IS_DEV_MODE } from 'src/constants'

import { useMaps } from '../../map/context'
import { FilterType } from '../../map/machine'
import BathroomsField from './fields/bathrooms'
import BedroomTypeField from './fields/bedroom-type'
import BedroomsField from './fields/bedrooms'
import GuestsField from './fields/guests'
import ModalitiesField from './fields/modalities'
import ParkingField from './fields/parking'
import PetsField from './fields/pets'
import PriceField from './fields/price'
import ValueField from './fields/value'

export const FormFilter = ({ onClose }) => {
  const { t } = useTranslation('search-filter-form')
  const map = useMaps()

  const onSubmit = (values: FilterType) => {
    map.updateFilters(values)
    onClose()
  }

  const { handleSubmit, getValues, register, unregister, setValue } = useForm({
    defaultValues: {
      ...map.context.filters,
    },
  })

  useEffect(() => {
    register({ name: 'minPrice' })
    register({ name: 'maxPrice' })
    return () => {
      unregister('minPrice')
      unregister('maxPrice')
    }
  }, [])

  const handleReset = React.useCallback(() => {
    map.onResetAllFilters()

    onClose()
  }, [])

  // To Don't render the order by price component when the backend is not fixed.
  const showField = false

  return (
    <form id="formFilter" onSubmit={handleSubmit(onSubmit)}>
      {showField && <ValueField register={register} setValue={setValue} />}
      <ModalitiesField
        getValues={getValues}
        register={register}
        setValue={setValue}
      />
      {IS_DEV_MODE && <PriceField setValue={setValue} />}
      <BedroomsField
        getValues={getValues}
        register={register}
        setValue={setValue}
      />
      {showField && (
        <BedroomTypeField
          getValues={getValues}
          register={register}
          setValue={setValue}
        />
      )}
      <GuestsField
        getValues={getValues}
        register={register}
        setValue={setValue}
      />
      <PetsField
        getValues={getValues}
        register={register}
        setValue={setValue}
      />
      <BathroomsField
        getValues={getValues}
        register={register}
        setValue={setValue}
      />
      <ParkingField
        getValues={getValues}
        register={register}
        setValue={setValue}
      />
      <Flex justify="space-between" my="24px">
        <Button onClick={handleReset} variant="link">
          {t('search-filter-form:CLEAR')}
        </Button>
        <Button type="submit" colorScheme="primary" bg="primary.500">
          {t('search-filter-form:APPLY_FILTER')}
        </Button>
      </Flex>
    </form>
  )
}
