import React, { ForwardedRef, forwardRef, HTMLProps } from 'react'

import { ThemesType } from './radio.interface'
import * as S from './styled'

interface RadioInterface extends HTMLProps<HTMLInputElement> {
  labelRadio?: string
  index?: number | string
  themeColor?: ThemesType
  asButton?: boolean
  rounded?: boolean
}

const RadioThemed = (
  {
    name = '',
    index = '',
    labelRadio = '',
    themeColor = 'primary',
    asButton = false,
    ...props
  }: RadioInterface,
  innerRef: ForwardedRef<any>
) => {
  if (asButton)
    return (
      <>
        <S.RefRadio
          type="radio"
          name={name}
          id={name + index}
          ref={innerRef}
          color={themeColor}
          {...props}
        />
        <S.RadioButtonSquare htmlFor={name + index}>
          {labelRadio}
        </S.RadioButtonSquare>
      </>
    )

  return (
    <>
      <S.ContentButton>
        <S.RadioButton
          name={name}
          color={themeColor}
          ref={innerRef}
          label="test"
          id={name + index}
          {...props}
        />
        <S.LabelRadioControl htmlFor={name + index} />
      </S.ContentButton>
      <S.Label htmlFor={name + index}>{labelRadio}</S.Label>
    </>
  )
}

export default forwardRef(RadioThemed)
