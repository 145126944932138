export const CLEANING_TAX_REGEX = new RegExp(
  '(cleaningTax|cleaningtax|LIMPEZA|limpeza|Taxa de Limpeza|taxa de limpeza|Cleaning Fee|cleaning fee|Cleaning Fee)'
)
export const IPTU_REGEX = new RegExp('iptu|IPTU')
export const CONDO_REGEX = new RegExp(
  'condo|CONDOMINIO|Condomínio|condomínio|condominio'
)

export const IS_DEV_MODE = /(onhousi.com|localhost)/g.test(
  window.location.origin
)
