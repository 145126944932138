import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PropertyCardContext } from '../../../property-card.context'

const Info = () => {
  const { property } = React.useContext(PropertyCardContext)
  const { t } = useTranslation('card-apartment')
  return (
    <Flex direction="column">
      <Text fontSize="16px" color="black.400">
        {property?.building?.aliasName}, {property?.address?.neighborhood.name},{' '}
        {property.address.city.name} - {property.address.state}
      </Text>
      {property?.building?.flagship && (
        <Flex gap="5px">
          <Text color="black.500">{t('card-apartment:FLAGSHIP.0')}</Text>
          <Text color="primary.500">{t('card-apartment:FLAGSHIP.1')}</Text>
        </Flex>
      )}
    </Flex>
  )
}

export default Info
