import { Builder } from '@builder.io/react'
import React from 'react'

const Apartaments = React.lazy(() => import('src/features/home/apartaments'))
const Appspace = React.lazy(() => import('src/features/home/appspace'))
const AppspacePartners = React.lazy(
  () => import('src/features/home/appspace-partners')
)
const LimitedOffer = React.lazy(() => import('src/features/home/limited-offer'))
const News = React.lazy(() => import('src/features/home/news'))
const Profiles = React.lazy(() => import('src/features/home/profiles'))
const Sales = React.lazy(() => import('src/features/home/sales'))
const Testimonials = React.lazy(() => import('src/features/home/testimonials'))
const Video = React.lazy(() => import('src/features/home/video'))

const Hero = React.lazy(() => import('src/features/home/hero'))

Builder.registerComponent(Hero, {
  name: 'Hero Carousel',
  inputs: [
    {
      name: 'banners',
      type: 'list',
      subFields: [
        {
          name: 'images',
          type: 'list',
          helperText: 'list of images to be randomly rendered',
          subFields: [
            {
              name: 'image',
              type: 'url',
            },
          ],
        },
        {
          name: 'title',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'html',
            },
            {
              name: 'en',
              type: 'html',
            },
          ],
        },
        {
          name: 'description',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'html',
            },
            {
              name: 'en',
              type: 'html',
            },
          ],
        },
        {
          name: 'cta',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'url',
          type: 'string',
        },
        {
          name: 'isAnchor',
          helperText: 'anchor to another section',
          type: 'boolean',
          defaultValue: 'false',
          enum: ['true', 'false'],
        },
        {
          name: 'hasModal',
          helperText: 'open modal on cta',
          type: 'boolean',
          defaultValue: 'false',
          enum: ['true', 'false'],
        },
        {
          name: 'idSection',
          type: 'string',
          helperText: 'id of section corresponding to navigation',
        },
        {
          name: 'modalOptions',
          type: 'object',
          subFields: [
            {
              name: 'title',
              type: 'object',
              subFields: [
                {
                  name: 'ptBr',
                  type: 'html',
                },
                {
                  name: 'en',
                  type: 'html',
                },
              ],
            },
            {
              name: 'videoUrl',
              type: 'url',
            },
          ],
        },
      ],
    },
  ],
})

Builder.registerComponent(Apartaments, {
  name: 'Apartaments Banner',
  inputs: [
    {
      name: 'apartamentsBanner',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'subtitle',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'idSection',
          type: 'string',
          helperText: 'id of this section',
        },
      ],
    },
    {
      name: 'data',
      type: 'json',
    },
  ],
})

Builder.registerComponent(Appspace, {
  name: 'Appspace Banner',
  inputs: [
    {
      name: 'appspaceBanner',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'subtitle',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'idSection',
          type: 'string',
          helperText: 'id of this section',
        },
      ],
    },
    {
      name: 'items',
      type: 'list',
      subFields: [
        {
          name: 'image',
          type: 'string',
        },
        {
          name: 'url',
          type: 'string',
        },
      ],
    },
  ],
})

Builder.registerComponent(Profiles, {
  name: 'Profiles Banner',
  inputs: [
    {
      name: 'profilesBanner',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'image',
          type: 'string',
        },
        {
          name: 'idSection',
          type: 'string',
          helperText: 'id of this section',
        },
        {
          name: 'profiles',
          type: 'list',
          subFields: [
            {
              name: 'icon',
              type: 'string',
              defaultValue: 'building',
              enum: ['building', 'color-palette', 'calendar'],
              required: true,
            },
            {
              name: 'title',
              type: 'object',
              subFields: [
                {
                  name: 'ptBR',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'description',
              type: 'object',
              subFields: [
                {
                  name: 'ptBR',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'cta',
              type: 'object',
              subFields: [
                {
                  name: 'ptBR',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'isLink',
              helperText: 'open link or modal on cta',
              type: 'boolean',
              defaultValue: 'false',
              enum: ['true', 'false'],
            },
            {
              name: 'url',
              type: 'options',
            },
          ],
        },
      ],
    },
  ],
})

Builder.registerComponent(LimitedOffer, {
  name: 'Limited Offer Banner',
  inputs: [
    {
      name: 'limitedOfferBanner',
      type: 'object',
      subFields: [
        {
          name: 'bannerImage',
          type: 'string',
        },
        {
          name: 'partnerLogo',
          type: 'string',
        },
        {
          name: 'partnerName',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'partnerDescription',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'url',
          type: 'string',
        },
        {
          name: 'idSection',
          type: 'string',
          helperText: 'id of this section',
        },
      ],
    },
  ],
})

Builder.registerComponent(Video, {
  name: 'Video Banner',
  inputs: [
    {
      name: 'videoBanner',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'subtitle',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'text',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'cta',
          type: 'object',
          subFields: [
            {
              name: 'text',
              type: 'object',
              subFields: [
                {
                  name: 'ptBr',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'url',
              type: 'url',
            },
          ],
        },
        {
          name: 'videoUrl',
          type: 'string',
        },
        {
          name: 'idSection',
          type: 'string',
          helperText: 'id of this section',
        },
      ],
    },
  ],
})

Builder.registerComponent(AppspacePartners, {
  name: 'Appspace Partners Banner',
  inputs: [
    {
      name: 'appspacePartnersBanner',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'subtitle',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'text',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'cta',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'url',
          type: 'string',
        },
        {
          name: 'idSection',
          type: 'string',
          helperText: 'id of this section',
        },
        {
          name: 'items',
          type: 'list',
          subFields: [
            {
              name: 'logo',
              type: 'string',
            },
            {
              name: 'title',
              type: 'object',
              subFields: [
                {
                  name: 'ptBR',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'text',
              type: 'object',
              subFields: [
                {
                  name: 'ptBR',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'cta',
              type: 'object',
              subFields: [
                {
                  name: 'ptBR',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'url',
              type: 'string',
            },
          ],
        },
      ],
    },
  ],
})

Builder.registerComponent(Sales, {
  name: 'Sales Banner',
  inputs: [
    {
      name: 'salesBanner',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'subtitle',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'profile',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'description',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'cta',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'url',
          type: 'string',
        },
        {
          name: 'idSection',
          type: 'string',
          helperText: 'id of this section',
        },
        {
          name: 'items',
          type: 'list',
          subFields: [
            {
              name: 'image',
              type: 'string',
            },
            {
              name: 'salesTime',
              type: 'object',
              subFields: [
                {
                  name: 'ptBR',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'totalSold',
              type: 'string',
            },
            {
              name: 'incorp',
              type: 'string',
            },
            {
              name: 'address',
              type: 'object',
              subFields: [
                {
                  name: 'city',
                  type: 'string',
                },
                {
                  name: 'state',
                  type: 'string',
                  helperText: '(UF)',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
})

Builder.registerComponent(Testimonials, {
  name: 'Testimonials Banner',
  inputs: [
    {
      name: 'testimonialsBanner',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'idSection',
          type: 'string',
          helperText: 'id of this section',
        },
        {
          name: 'items',
          type: 'list',
          subFields: [
            {
              name: 'name',
              type: 'object',
              subFields: [
                {
                  name: 'ptBR',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'role',
              type: 'object',
              subFields: [
                {
                  name: 'ptBR',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'text',
              type: 'object',
              subFields: [
                {
                  name: 'ptBR',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'star',
              type: 'number',
            },
          ],
        },
      ],
    },
  ],
})

Builder.registerComponent(News, {
  name: 'News Carousel',
  inputs: [
    {
      name: 'newsBanner',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'idSection',
          type: 'string',
          helperText: 'id of this section',
        },
        {
          name: 'subtitle',
          type: 'object',
          subFields: [
            {
              name: 'ptBR',
              type: 'string',
            },
            {
              name: 'en',
              type: 'string',
            },
          ],
        },
        {
          name: 'blogCard',
          type: 'object',
          subFields: [
            {
              name: 'text',
              type: 'object',
              subFields: [
                {
                  name: 'ptBR',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'cta',
              type: 'object',
              subFields: [
                {
                  name: 'ptBR',
                  type: 'string',
                },
                {
                  name: 'en',
                  type: 'string',
                },
              ],
            },
            {
              name: 'url',
              type: 'string',
            },
          ],
        },
        {
          name: 'items',
          type: 'list',
          subFields: [
            {
              name: 'image',
              type: 'string',
            },
            {
              name: 'magazine',
              type: 'string',
            },
            {
              name: 'title',
              type: 'string',
            },
            {
              name: 'cta',
              type: 'string',
            },
            {
              name: 'url',
              type: 'string',
            },
          ],
        },
      ],
    },
  ],
})
