import * as c from '@chakra-ui/react'
import React from 'react'
import { SearchIcon } from 'src/assets/icons/search.icon'

import { DatesContext } from '../dates/dates-context'
import { dates_events } from '../dates/dates-machine'
import { SearchContext } from '../search-context'
import { search_events } from '../search-machine'
import { StepsContext } from '../steps/steps-context'

const MinimalPropertiesList = React.lazy(
  () => import('src/components/list/properties/minimal-properties-list')
)
interface SuggestionsProps {
  title: string
  list: SuggestionProps[]
}
export interface SuggestionProps {
  _id?: string
  address?: string
  mainPhoto?: string
  searchTerm?: string
  zoom: number
  coords?: {
    lat: number
    long: number
  }
}

const Suggestion = React.forwardRef<HTMLDivElement, SuggestionProps>(
  ({ address, mainPhoto, searchTerm, coords, zoom }, ref) => {
    const searchState = React.useContext(SearchContext)
    const datesState = React.useContext(DatesContext)
    const { next } = React.useContext(StepsContext)

    const onSelectTerm = () => {
      searchState.send(search_events.set_coords, {
        coords,
        zoom,
        searchTerm: { value: searchTerm, label: searchTerm },
      })

      datesState.send(dates_events.focus)
      next()
    }

    const isActive = searchState.context.searchTerm.value === searchTerm

    return (
      <div ref={ref}>
        <c.Flex
          direction="column"
          align="center"
          gap={2}
          minH="138.5px"
          maxH="138.5px"
          minW="140px"
          maxW="140px"
          pos="relative"
          onClick={onSelectTerm}
        >
          <c.Image
            src={mainPhoto}
            alt={address}
            w="100%"
            h="100%"
            objectFit="cover"
            objectPosition="center"
            maxH={130}
            borderRadius="8px"
          />
          <c.Flex
            as={c.Button}
            alignItems="center"
            justifyContent="center"
            border="none"
            pos="absolute"
            top={2}
            right={2}
            p={0}
            bg={isActive ? 'primary.500' : 'black.0'}
            borderRadius="12px"
            minW="24px"
            minH="24px"
            maxW="24px"
            maxH="24px"
          >
            <SearchIcon
              color={isActive ? 'white' : 'gray'}
              height={10}
              width={10}
              style={{ minWidth: 10, minHeight: 10 }}
            />
          </c.Flex>
          <c.Text color="black.500" fontSize={12} fontWeight={500}>
            {address}
          </c.Text>
        </c.Flex>
      </div>
    )
  }
)

const Suggestions = ({ title, list }: SuggestionsProps) => {
  const [smScreen, mdScreen, lgScreen] = c.useMediaQuery([
    '(max-width: 380px)',
    '(max-width: 500px)',
    '(max-width: 720px)',
  ])

  const slidesPerView = smScreen ? 1.8 : mdScreen ? 2.2 : lgScreen ? 2.3 : 2.6

  return (
    <c.Box pt={5} pb={1} px={5} w="100%" borderRadius="12px" bg="black.0">
      <c.Heading color="black.500" fontSize={14} fontWeight={700} mb={4}>
        {title}
      </c.Heading>
      <React.Suspense fallback="">
        <MinimalPropertiesList
          slidesPerView={slidesPerView}
          isOld={false}
          CustomCard={Suggestion as any}
          properties={list ?? []}
        />
      </React.Suspense>
    </c.Box>
  )
}

export default Suggestions
