import * as c from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PickerContext } from '../picker-context'

interface DayProps {
  day: string
}

const Day = ({ day }: DayProps) => {
  return (
    <c.Flex
      as={c.Text}
      color="black.400"
      fontSize={12}
      fontWeight={[500, 700]}
      minH="18px"
      maxH="18px"
      justify="center"
      align="center"
    >
      {day}
    </c.Flex>
  )
}

const Week = () => {
  const { valid } = React.useContext(PickerContext)
  const { t } = useTranslation('components/search')

  const days = t('components/search:picker.week', {
    returnObjects: true,
  }) as string[]

  if (valid.month) return null

  return (
    <c.Grid
      mb={3}
      gridTemplateColumns="repeat(7, 32px)"
      alignItems="center"
      justifyItems="center"
      columnGap={[4, 3]}
    >
      {days.map((day) => (
        <Day key={day} day={day} />
      ))}
    </c.Grid>
  )
}

export default Week
