import { TFunction } from 'i18next'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

import { RequireSeo } from './custom'
import * as Components from './route-components'

const paymentRoutes = (t: TFunction): RouteObject[] => [
  {
    path: 'payment/:token',
    element: (
      <RequireSeo
        title={t('titles:apartments-payment-update.SEO_TITLE')}
        description={t('titles:apartments-payment-update.SEO_DESCRIPTION')}
      >
        <Suspense fallback="loading...">
          <Components.PayComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
]

export default paymentRoutes
