import { lightTheme as retrieverTheme } from '@retriever-ui/react'
export const foundations = {
  config: {
    cssVarPrefix: 'housi',
  },
  colors: {
    // retriever suport
    ...retrieverTheme.colors,
    primary: {
      50: '#FFF5FA',
      100: '#FED5E9',
      200: '#FDACD4',
      300: '#FC82BE',
      400: '#FB54A6',
      500: '#FA2F93',
      600: '#C32573',
      700: '#9E1E5D',
      800: '#86194F',
      900: '#62123A',
    },
    secondary: {
      100: '#B3B3CB',
      200: '#666696',
      300: '#333373',
      400: '#000050',
      500: '#000040',
      600: '#000030',
      700: '#000020',
    },
    black: {
      0: '#FFFFFF',
      50: '#F9FAFA',
      100: '#F1F1F2',
      150: '#E6E7E9',
      200: '#D2D4D7',
      300: '#A9ADB2',
      400: '#797F88',
      500: '#4D5560',
      600: '#2E3744',
      700: '#19202B',
      800: '#0C1015',
      900: '#05070A',
    },
    green: {
      50: '#F0FFF7',
      100: '#A5Fdd1',
      200: '#2EF391',
      300: '#29D981',
      400: '#24BE71',
      500: '#1FA361',
      600: '#198750',
      700: '#14693F',
      800: '#105633',
      900: '#0D472A',
    },
    red: {
      50: '#FFF5F5',
      100: '#FED7D7',
      200: '#FDB2B2',
      300: '#FC8181',
      400: '#FB6060',
      500: '#EF2D2D',
      600: '#CB2626',
      700: '#8B1A1A',
      800: '#A41F1F',
      900: '#661313',
    },
    yellow: {
      50: '#FFFAF5',
      100: '#FEEAD6',
      200: '#FDD1A5',
      300: '#FEB369',
      400: '#FF9F40',
      500: '#F28504',
      600: '#E67C00',
      700: '#B36100',
      800: '#804500',
      900: '#4C2900',
    },
    blue: {
      50: '#EFF7FF',
      100: '#C4E1FE',
      200: '#98CAfD',
      300: '#66B0FB',
      400: '#3396FA',
      500: '#218EFB',
      600: '#226CB6',
      700: '#1A528B',
      800: '#154372',
      900: '#11375D',
    },
  },
  shadows: {
    outline: '0 0 1px 3px #EC738E, 0 1px 1px rgba(0, 0, 0, .15)',
    md: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.05)',
  },
  fonts: {
    heading: 'Montserrat, sans-serif',
    body: 'Montserrat, sans-serif',
    mono: 'Montserrat, sans-serif',
  },
  fontSizes: {
    // font size heading
    'h-xs': ['0.875rem', '1rem'],
    'h-sm': ['1rem', '1.25rem'],
    'h-md': ['1.25rem', '1.5rem'],
    'h-lg': ['1.5rem', '2rem'],
    'h-xl': ['2rem', '2.5rem'],
    // font size text
    't-xs': ['0.625rem', '0.75rem'],
    't-sm': ['0.75rem', '0.875rem'],
    't-md': ['0.875rem', '1rem'],
    't-lg': ['1rem', '1.125rem'],
    't-xl': ['1.125rem', '1.25rem'],
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  space: {
    s1: '0.25rem',
    s2: '0.5rem',
    s3: '0.75rem',
    s4: '1rem',
    s5: '1.25rem',
    s6: '1.5rem',
    s7: '1.75rem',
    s8: '2rem',
    s9: '2.25rem',
    s10: '2.50rem',
    s11: '2.75rem',

    m1: '0.5rem',
    m2: '1rem',
    m3: '1.5rem',
    m4: '2rem',
    m5: '2.5rem',
    m6: '3rem',
    m7: '3.5rem',
    m8: '4rem',
    m9: '4.5rem',

    l1: '1.25rem',
    l2: '2.5rem',
    l3: '3.75rem',
    l4: '5rem',
    l5: '6.25rem',
    l6: '7.5rem',
    l7: '8.75rem',
    l8: '10rem',
    l9: '11.25rem',
    l10: '12.5rem',
  },
  sizes: {
    fit: 'fit-content',
    full: '100%',
  },
}
