import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { notify } from '../../components/alert/toast'
import {
  getApartmentByIds,
  getProperties,
  getPropertyById,
  searchProperties,
} from '../../requests/apartments'

export const usePropertiesListing = ({
  term = '',
  from,
  to,
  isSubscription,
  months,
  guests = null,
  rooms = null,
  amenities = undefined,
  buildingsFilter = undefined,
  sortBy = null,
  page = 1,
  price = [0, 15000],
  petFriendly = false,
}) => {
  const [apartments, setApartments] = useState([])
  const [loading, setLoading] = useState(true)
  const [calcTotal, setCalcTotal] = useState(true)
  const [hasNextPage, setHasNextPage] = useState(true)

  const [readyToSendRequest, setReadyToSendRequest] = useState(false)
  const [total, setTotal] = useState(0)
  const [maxPage, setMaxPage] = useState(0)
  const [buildings, setBuildings] = useState([])

  const fetch = useCallback(async () => {
    setReadyToSendRequest(false)
    setLoading(true)

    const filterParams = {}
    filterParams.isSubscription = isSubscription
    if (rooms) filterParams.bedrooms = rooms
    if (amenities) filterParams.amenityId = amenities
    if (guests) filterParams.guests = guests.replace(/\D/g, '')
    if (buildingsFilter) filterParams.buildingId = buildingsFilter
    if (from) filterParams.checkIn = moment(from).format('YYYY-MM-DD')
    if (to || isSubscription) {
      const dTo = isSubscription
        ? moment(from, 'YYYY-MM-DD').add(months, 'months')
        : to

      filterParams.checkOut = moment(dTo, 'YYYY-MM-DD').format('YYYY-MM-DD')
    }
    if (sortBy) filterParams.sort = sortBy
    if (petFriendly) filterParams.petFriendly = petFriendly
    if (price[0] !== 0 || price[1] !== 15000) {
      const [minPrice, maxPrice] = price
      filterParams.priceMin = minPrice
      filterParams.priceMax = maxPrice
    }

    try {
      const result = await searchProperties({
        params: {
          term,
          page,
          ...filterParams,
        },
      })

      const { data } = result

      try {
        if (calcTotal) {
          setApartments(data.items)
          setTotal(data.totalItems || 0)
          setCalcTotal(false)
          setMaxPage(data.totalPages)

          setBuildings(data.buildings)
        } else {
          const concated = [...apartments, ...data.items]
          setApartments(concated)
        }
      } catch (e) {
        // some error
      } finally {
        setHasNextPage(data.hasNextPage)
      }
    } catch (e) {
      // some error
      setTotal(0)
      setBuildings([])
      setApartments([])
      setCalcTotal(false)
      setMaxPage(1)
      setHasNextPage(false)
    } finally {
      setLoading(false)
    }
  }, [
    term,
    page,
    from,
    to,
    apartments,
    rooms,
    guests,
    amenities,
    calcTotal,
    hasNextPage,
  ])

  useEffect(() => {
    if (readyToSendRequest) {
      fetch().then()
    }
  }, [readyToSendRequest])

  return {
    apartments,
    buildings,
    loading,
    total,
    maxPage,
    setReadyToSendRequest,
    setCalcTotal,
    hasNextPage,
    readyToSendRequest,
  }
}

export const useProperties = ({
  apartmentIds = [],
  fetchList = true,
  params = {},
}) => {
  const [loading, setLoading] = React.useState(false)
  const [apartments, setApartments] = React.useState([])
  const [data, setData] = React.useState({ totalPages: 1 })

  const fetch = useCallback(
    async (ids = []) => {
      try {
        setLoading(true)

        const { data } = await getProperties({
          ids: apartmentIds || ids,
          params,
        })

        setData(data)
        setApartments(data.items)

        return Promise.resolve(data.items)
      } catch (e) {
        return Promise.reject(e)
      } finally {
        setLoading(false)
      }
    },
    [apartmentIds, params.page]
  )
  const output = React.useMemo(
    () => ({ apartments, apartmentLoading: loading, fetch, data }),
    [apartments, loading, fetch, data]
  )

  useEffect(() => {
    if (fetchList) {
      fetch().then()
    }
  }, [fetchList, params.page])

  return output
}

export const useApartmentDetail = (propertyId) => {
  const { t } = useTranslation('profile')
  const userId = useSelector((store) => store.user.id)
  const [property, setProperty] = React.useState(undefined)
  const [loading, setLoading] = React.useState(false)

  const fetchProperty = React.useCallback(() => {
    setLoading(true)

    if (!propertyId) {
      setLoading(false)
      return
    }

    const onFetchSuccess = (response) => {
      setLoading(false)
      setProperty(response.data)
    }

    const onRequestFail = () => {
      notify({ text: t('profile:ERROR') }).error()
      setLoading(false)
    }

    if (Array.isArray(propertyId)) {
      const propertyIds = propertyId
      if (propertyIds.length === 0) return

      getApartmentByIds({
        onSuccess: onFetchSuccess,
        onFail: onRequestFail,
        ids: propertyIds,
      })

      return
    }

    getPropertyById({
      onSuccess: onFetchSuccess,
      onFail: onRequestFail,
      id: propertyId,
    })
  }, [propertyId, t])

  React.useEffect(() => {
    fetchProperty()
  }, [userId, propertyId, fetchProperty])

  return { property, propertiesLoading: loading }
}
