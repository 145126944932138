import moment from 'moment'

import isAfterDay from '../../components/calendar/utils/is-after-day'
import isSameDay from '../../components/calendar/utils/is-same-day'

const fromIsAfterAdvanceDays = ({ from, advanceDays }) => {
  const dateAdvance = moment().add(advanceDays, 'days')
  const fromDate = moment(from)

  return isSameDay(fromDate, dateAdvance) || isAfterDay(fromDate, dateAdvance)
}

const rangeAfterAdvancedDays = ({ range, advanceDays }) => {
  if (Array.isArray(range) && range[0] && range[1]) {
    const dateAdvance = moment().add(advanceDays, 'days')
    const fromDate = moment(range[0])

    return isSameDay(fromDate, dateAdvance) || isAfterDay(fromDate, dateAdvance)
  }

  return false
}

const rangeHasMinimumNights = ({ range, minimumNights }) => {
  if (Array.isArray(range) && range[0] && range[1]) {
    const diffDates = moment(range[1]).diff(moment(range[0]), 'days')
    return diffDates >= minimumNights
  }
  return false
}

const dontHasBlockBetweenDates = ({
  range,
  from,
  to,
  blockedDates = [],
  bookingType,
}) => {
  if (bookingType === 'shortStay') {
    if (Array.isArray(range) && range[0] && range[1]) {
      const d1 = moment(range[0])
      const d2 = moment(range[1])
      return !blockedDates.some((d) => moment(d).isBetween(d1, d2))
    }
    return false
  }

  const d1 = moment(from)
  const d2 = moment(to)

  return !blockedDates.some((d) => moment(d).isBetween(d1, d2))
}

const dateValidation = ({
  from,
  to,
  advanceDaysSubscription,
  advanceDaysShortStay,
  range,
  minimumNights,
  blockedDates,
}) => ({
  subscription: {
    fromIsAfterAdvanceDays: fromIsAfterAdvanceDays({
      from,
      advanceDays: advanceDaysSubscription,
    }),
    dontHasBlockBetweenDates: dontHasBlockBetweenDates({
      from,
      to,
      blockedDates,
      bookingType: 'subscription',
    }),
  },
  shortStay: {
    rangeHasMinimumNights: rangeHasMinimumNights({ range, minimumNights }),
    fromIsAfterAdvanceDays: rangeAfterAdvancedDays({
      range,
      advanceDays: advanceDaysShortStay,
      bookingType: 'shortStay',
    }),
    dontHasBlockBetweenDates: dontHasBlockBetweenDates({
      blockedDates,
      bookingType: 'shortStay',
      range,
    }),
  },
})

export default dateValidation
