import { ComponentStyleConfig, cssVar } from '@chakra-ui/react'

const $arrowBg = cssVar('popper-arrow-bg')

export const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    fontSize: 't-xs.1',
    fontWeight: 'medium',
    py: 's2',
    px: 's2',
    minW: 'fit-content',
    minH: '31px',
    borderRadius: '0.5rem',
  },
  sizes: {},
  variants: {
    primary: {
      color: 'black.0',
      bg: 'black.500',
      boxShadow: 'light',
      [$arrowBg.variable]: 'colors.black.500',
    },
  },
  defaultProps: {},
}
