import { Input } from '@chakra-ui/react'
import { Flex } from '@retriever-ui/react'
import { retriever, StylesProps } from '@retriever-ui/system'
import { HTMLRetriever } from '@retriever-ui/system/lib/retriever'
import React from 'react'
import { CloseIcon } from 'src/assets/icons/close.icon'
import SearchIcon from 'src/components/navbar/icons/search.icon'

import Options from './options'

export type AutocompleteValueType = {
  value: string
  label: string
}

interface AutocompleteProps
  extends StylesProps,
    Omit<HTMLRetriever<'input'>, 'value' | 'label'> {
  value?: AutocompleteValueType
  options?: AutocompleteValueType[]
  isOpen?: boolean
  label?: React.ReactNode
  containerStyle?: StylesProps
  onClick?: (e?: any) => void
  onClose?: () => void
  onClear?: () => void
  onChange?: (e?: any) => void
  onSelect: (e?: any) => void
}

const Autocomplete = ({
  label,
  value,
  options,
  onSelect,
  onClick,
  containerStyle,
  isOpen,
  onClear,
  ...rest
}: AutocompleteProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  return (
    <div>
      <retriever.div pos="relative" onClick={onClick}>
        <retriever.p>{label}</retriever.p>
        <retriever.div
          d="flex"
          mTop={[24, 40]}
          radiusTopLeft={8}
          radiusTopRight={8}
          radiusBottomLeft={isOpen ? 0 : 8}
          radiusBottomRight={isOpen ? 0 : 8}
          minH={48}
          maxH={48}
          minW={['100%', '389px']}
          maxW={['100%', '389px']}
          zIndex={1}
          alignItems="center"
          border="1px solid"
          borderTopColor="black.500"
          borderBottomColor={isOpen ? 'black.200' : 'black.500'}
          borderLeftColor="black.500"
          borderRightColor="black.500"
          color="black.500"
          pos="relative"
          gap={8}
          pX={12}
          shadow={
            isOpen
              ? ''
              : '0px 4px 6px -1px rgba(0, 0, 0, 0.05), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);'
          }
          {...containerStyle}
        >
          <span>
            <SearchIcon
              color={value?.value ? '#A3A3A3' : '#FA2F93'}
              width="15px"
              height="15px"
            />
          </span>
          <form autoComplete="off" style={{ width: '100%', height: '100%' }}>
            <Input
              {...(rest as any)}
              ref={inputRef}
              aria-autocomplete="both"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
              type="text"
              name="autocomplete"
              value={value?.label}
              border="none"
              px={1}
              color="black.500"
              fontWeight={500}
              fontSize={14}
              _placeholder={{
                color: 'black.300',
                fontWeight: 500,
                fontSize: 14,
              }}
              _focus={{
                border: 'none',
              }}
            />
          </form>
          {value?.label.length !== 0 && (
            <Flex
              role="button"
              cursor="pointer"
              bg="black.0"
              h="100%"
              alignItems="center"
              justifyContent="center"
              pX={6}
              onClick={() => {
                onClear?.()
              }}
            >
              <div>
                <CloseIcon height={10} width={10} color="#6C6C6C" />
              </div>
            </Flex>
          )}
        </retriever.div>
        {isOpen && <Options onSelect={onSelect} options={options} />}
      </retriever.div>
    </div>
  )
}

export default Autocomplete
