import * as c from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { convertToMoney } from '../../../../../utils/monetary'
import { BookingContext } from '../../../booking.context'

const TotalSubscription = () => {
  const { t } = useTranslation([
    'property-detail/booking-resume',
    'card-apartment',
  ])

  const { price, isSubscriptionUnavailable } = useContext(BookingContext)

  if (isSubscriptionUnavailable) {
    return (
      <c.Flex justify="space-between">
        <c.Text fontSize={16} fontWeight={700} color="black.500">
          {t('card-apartment:UNAVAILABLE')}
        </c.Text>
        <c.Text
          fontSize={16}
          fontWeight={700}
          color="black.500"
          textDecorationLine="line-through"
        >
          {`${convertToMoney(price?.monthly?.grossAmount)}/ ${t(
            'property-detail/booking-resume:MONTH'
          )}`}
        </c.Text>
      </c.Flex>
    )
  }

  return (
    <c.Flex justify="space-between" pt={5}>
      <c.Text fontSize={16} fontWeight={700} color="black.500">
        {t('property-detail/booking-resume:TOTAL_MONTHLY')}
      </c.Text>
      <c.Text
        fontSize={16}
        fontWeight={700}
        color="black.500"
        //textDecorationLine="line-through"
      >
        {`${convertToMoney(price?.monthly?.grossAmount)}/ ${t(
          'property-detail/booking-resume:MONTH'
        )}`}
      </c.Text>
    </c.Flex>
  )
}

export default TotalSubscription
