import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

const defDirection = (direction) => {
  return direction === 'right'
    ? '0deg'
    : direction === 'top'
    ? '-90deg'
    : direction === 'bottom'
    ? '90deg'
    : '180deg'
}

export const ArrowIcon = ({
  width = 12,
  height = 24,
  direction = 'right',
  saturated = false,
  color = 'primary',
  style = {},
  ...props
}) => {
  const { colors } = useContext(ThemeContext)
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${defDirection(direction)})`, ...style }}
      {...props}
    >
      <path
        d="M13 11.994C13.0117 12.4018 12.8705 12.799 12.6046 13.1063L11.6221 14.2066L11.5023 14.3275L3.33088 23.5406C3.22363 23.6711 3.09154 23.7787 2.94241 23.8569C2.79328 23.9351 2.63014 23.9824 2.46261 23.9959C2.29508 24.0094 2.12656 23.9889 1.96701 23.9357C1.80746 23.8824 1.6601 23.7974 1.53364 23.6857C1.47343 23.6472 1.42064 23.598 1.37788 23.5406L0.407373 22.4403C0.147491 22.1337 0.00468445 21.7435 0.00468445 21.3401C0.00468445 20.9366 0.147491 20.5464 0.407373 20.2398L7.71614 11.994L0.407373 3.74811C0.147491 3.44151 0.00468445 3.05131 0.00468445 2.64786C0.00468445 2.2444 0.147491 1.85424 0.407373 1.54764L1.37788 0.44738C1.60029 0.188525 1.91461 0.0282419 2.25306 0.00110063C2.5915 -0.0260407 2.92697 0.0821402 3.18711 0.302298L3.33088 0.44738L11.5023 9.66047L11.6221 9.78139L12.6046 10.8816C12.8687 11.1853 13.0098 11.578 13 11.9819V11.994Z"
        fill={saturated ? '#fff' : colors[color]}
      />
    </svg>
  )
}
