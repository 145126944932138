import { Flex, IconButton, Text } from '@chakra-ui/react'
import React from 'react'
import { CloseIcon } from 'src/assets/icons/close.icon'
import ShadowSkeleton from 'src/components/skeleton'
import useMessages from 'src/hooks/messages/use-messages'
import { lazyZoom } from 'src/pages/search/utils'

import { useMaps } from '../../context'
import Title from './title'
import Type from './type'

const Head = ({ map, buildingId, label, isFlagship, isPartner }) => {
  const { emit } = useMessages()
  const { getPropertyByBuildingId, onResetCoords } = useMaps()

  const hasBuildingId = !!buildingId

  const card = getPropertyByBuildingId(buildingId)

  const handleClose = React.useCallback(async () => {
    if (!map) return

    emit('maps', { category: 'zoom-to-pin', data: false })
    await lazyZoom(map, 14)
    onResetCoords()
  }, [map])

  const isLoading = hasBuildingId
    ? !card?.building?.aliasName
    : !card?.aliasName

  return (
    <div>
      <Flex align="center" justifyContent="space-between">
        <ShadowSkeleton isLoading={isLoading} maxW="120px">
          <Title
            label={hasBuildingId ? card?.building?.aliasName : card?.aliasName}
          />
          <IconButton
            aria-label="close"
            size="sm"
            minH="32px"
            variant="outline"
            icon={<CloseIcon width={11} height={12} />}
            onClick={handleClose}
            _focus={{ shadow: 'none' }}
          />
        </ShadowSkeleton>
      </Flex>
      <Flex direction="column" gap={1} mt={[1, null, 4]}>
        <ShadowSkeleton isLoading={isLoading} maxW="180px" maxH="20px">
          <Text fontSize="12px" textTransform="capitalize">
            {`${card?.address?.street} ${card?.address?.streetNumber}, ${card?.address?.neighborhood?.name} - ${card?.address?.city.name}`.toLowerCase()}
          </Text>
        </ShadowSkeleton>
        <Flex align="center" gap={1}>
          <ShadowSkeleton isLoading={isLoading} maxW="80px" maxH="20px">
            <Text fontSize="12px">{`${label} Unidade(s):`}</Text>
          </ShadowSkeleton>
          <ShadowSkeleton isLoading={isLoading} maxW="80px" maxH="20px">
            <Type isPartner={isPartner} isFlagship={isFlagship} />
          </ShadowSkeleton>
        </Flex>
      </Flex>{' '}
    </div>
  )
}

export default Head
