import * as dateFns from 'date-fns'
import moment from 'moment'
import React from 'react'
import { useCallback, useState } from 'react'
import { SearchContext } from 'src/components/search-v2/search-context'
import dateUtils from 'src/utils/date'

import { addToCartPush } from '../../dataLayer/pushing'
import { createReservation } from '../../requests/reservation'

const useCreateBooking = ({ propertyId, guests, coupon }) => {
  const { context } = React.useContext(SearchContext)

  const [booking, setBooking] = useState({})
  const [bookingErrors, setBookingErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const isSubscription = React.useMemo(() => {
    return (
      dateFns.differenceInDays(
        dateUtils.create(context.end),
        dateUtils.create(context.start)
      ) >= 30
    )
  }, [context.end, context.start])

  const dateToParams = useCallback(() => {
    return {
      checkIn: { date: moment(context.start).format('YYYY-MM-DD') },
      checkOut: { date: moment(context.end).format('YYYY-MM-DD') },
    }
  }, [context.start, context.end])

  const create = useCallback(async () => {
    setLoading(true)

    try {
      const dateParams = dateToParams()

      const couponSend = coupon.valid ? { coupon: coupon.value } : {}

      const data = {
        ...dateParams,
        isSubscription,
        propertyId,
        ...couponSend,
        guests: {
          adults: guests,
          children: 0,
        },
      }

      const { data: payload } = await createReservation({
        data,
      })

      addToCartPush(payload)
      setBooking(payload)

      return payload
    } catch (err) {
      setBookingErrors(err?.response?.data)
      throw new Error(err)
    } finally {
      setLoading(false)
    }
  }, [guests, propertyId, dateToParams, coupon])

  return { create, loading, booking, bookingErrors }
}

export default useCreateBooking
