import { darken, lighten } from 'polished'
import { css } from 'styled-components'

export const actionButton = css`
  background: transparent !important;
  border-bottom-width: 2px !important;
  border-color: var(--primary-color) !important;
  border-radius: 0;
  border-style: solid;
  border-width: 0;
  max-height: fit-content !important;
  min-height: fit-content !important;
  padding: 0 0 4px 0 !important;
`

export const block = css`
  width: ${(props) => (props.block ? '100%' : 'auto')} !important;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: ${(props) => (props.blockMd ? '100%' : 'auto')} !important;
  }
`

export const blockTheme = css`
  width: ${(props) => (props.block ? '100%' : 'auto')};
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: ${(props) => (props.blockMd || props.block ? '100%' : 'auto')};
  }
`

export const btnSmall = css`
  padding: 2px 8px !important;
`

export const btnLarge = css`
  padding: 13px 22px;
`

export const customPadding = css`
  padding: ${(props) => props.customPadding || '10px 24px'};
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: ${(props) => props.customPaddingD || '10px 24px'};
  }
`

export const customTheme = (
  color,
  textColor,
  light = true,
  outline = false
) => css`
  background: ${outline ? 'transparent' : color};
  border: 1px solid ${outline ? textColor : color};
  color: ${lighten(0.1, textColor)};

  svg,
  path {
    fill: ${textColor};
  }

  :hover {
    background: ${() => (outline ? 'transparent' : darken(0.15, color))};
    color: ${textColor};
  }
  :disabled {
    background: ${() => (light ? lighten(0.15, color) : darken(0.1, color))};
    color: ${lighten(0.15, textColor)};
    cursor: not-allowed;
  }
`

export const getColor = (p, colors) => {
  const color = p.white
    ? colors.white
    : p.success
    ? colors.success
    : p.danger
    ? colors.danger
    : p.info
    ? colors.info
    : p.secondary
    ? colors.secondary
    : colors.primary

  const textColor = p.textBlack
    ? colors.black
    : p.textSuccess
    ? colors.success
    : p.textDanger
    ? colors.danger
    : p.textInfo
    ? colors.info
    : p.textSecondary
    ? colors.secondary
    : p.textPrimary
    ? colors.primary
    : colors.white

  return customTheme(color, textColor, p.white, p.outline)
}

export const rounded = css`
  align-items: center;
  border-radius: 180px;
  display: flex;
  justify-content: center;
  min-height: 36px;
  min-width: 36px;
  padding: 0;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0;
  }
`
