import { SearchIcon } from 'src/assets/icons/nav/search.icon'
import { BuildingIcon } from 'src/components/navbar/icons/building.icon'

interface NavbarMobileConfigItem {
  id: string
  label: string
  path: string
  Icon: (props: any) => JSX.Element
  isExternal: boolean
}

export const navbarLinksMobileData: NavbarMobileConfigItem[] = [
  {
    id: 'buscar',
    Icon: SearchIcon,
    label: 'Buscar',
    path: '/search',
    isExternal: false,
  },
  {
    id: 'condominio',
    Icon: BuildingIcon,
    label: 'Condomínio',
    path: 'https://appspace.housi.com/sindicos',
    isExternal: true,
  },
]
