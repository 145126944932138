import * as c from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { PinIcon } from '../../icons/pin.icon'
import SocialNetwork from '../primary-footer/social-network'

const SecondaryFooter = () => {
  const { t } = useTranslation('footer')

  return (
    <c.Box bg="black.0">
      <c.Flex
        w="100%"
        maxW="1200px"
        h={['203px', '100%']}
        pt={['s5', 's6']}
        pb={['m5', 's6']}
        px={['m5', '0']}
        bg="black.0"
        mx="auto"
        align={['start', 'center']}
        direction={['column-reverse', 'row']}
        justify="space-between"
      >
        <c.Flex gap="s2" align="center">
          <c.Icon as={PinIcon} color="black.400" width="20px" height="24px" />

          <c.Text fontSize="t-md.0" w={['267px', '100%']}>
            {t('footer:SECONDARY_FOOTER')}
          </c.Text>
        </c.Flex>
        <SocialNetwork />
      </c.Flex>
    </c.Box>
  )
}

export default SecondaryFooter
