import * as c from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { UserAvatarIcon } from 'src/components/navbar/icons/user-avatar.icon'
import { useAuth } from 'src/pages/auth/auth-context'
import { AvatarIcon } from 'src/pages/profile-v2/pages/avatar/avatar-icon'

const Header = () => {
  const { isAuth, user } = useAuth()
  const { t } = useTranslation(['components/nav', 'profile'])

  return (
    <c.Flex
      width="100%"
      height="140px"
      bg="primary.500"
      borderBottomRadius="16px"
      pt="l3"
      pb="s5"
      px="s5"
    >
      {isAuth ? (
        <c.Flex alignItems="center" gap="s6">
          <AvatarIcon />
          <c.Box>
            <c.Heading color="black.0" fontSize="h-sm.0" fontWeight="medium">
              {t('profile:HELLO')}
            </c.Heading>
            <c.Heading
              color="black.0"
              fontWeight="bold"
              fontSize="h-md.0"
              textTransform="capitalize"
            >
              {user?.firstName}
            </c.Heading>
          </c.Box>
        </c.Flex>
      ) : (
        <c.Flex alignItems="center" gap="s6">
          <c.Avatar
            bg="pink.300"
            border="2px solid #fff"
            height="60px"
            width="60px"
            icon={
              <c.Icon
                as={UserAvatarIcon}
                w="2.5rem"
                h="2.5rem"
                color="black.0"
              />
            }
          />

          <c.Heading color="black.0" fontWeight="bold" fontSize="h-md.0">
            {t('components/nav:LOGIN_ACCOUNT')}
          </c.Heading>
        </c.Flex>
      )}
    </c.Flex>
  )
}

export default Header
