import { TFunction } from 'i18next'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

import { RequireSeo } from './custom'
import * as Components from './route-components'

const termsRoutes = (t: TFunction): RouteObject[] => [
  {
    path: '/termos-e-condicoes',
    element: (
      <RequireSeo
        title={t('titles:terms.SEO_TITLE')}
        description={t('titles:terms.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <Components.TermsComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: 'termos-e-condicoes/:id',
    element: (
      <RequireSeo
        title={t('titles:terms.SEO_TITLE')}
        description={t('titles:terms.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <Components.TermsDetailsComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/politica-de-cancelamento/:id',
    element: (
      <RequireSeo
        title={t('titles:cancel-policy.SEO_TITLE')}
        description={t('titles:cancel-policy.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <Components.TermsDetailsComponent />
        </Suspense>
      </RequireSeo>
    ),
  },

  {
    path: '/politica-de-privacidade/:id',
    element: (
      <RequireSeo
        title={t('titles:privacy-policy.SEO_TITLE')}
        description={t('titles:privacy-policy.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <Components.TermsDetailsComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
]

export default termsRoutes
