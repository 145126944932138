import React from 'react'

type StepsContextType = {
  active: number
  steps: number[]
  jump: (goTo: number) => void
  next: () => void
  prev: () => void
}
interface StepsProviderProps {
  children?: React.ReactNode
  steps: number[]
}

export const StepsContext = React.createContext({} as StepsContextType)

const StepsProvider = ({ children, steps }: StepsProviderProps) => {
  const [active, setActive] = React.useState(0)

  const jump = React.useCallback((goTo: number) => {
    setActive(goTo)
  }, [])

  const next = React.useCallback(() => {
    setActive((currentState) => {
      if (steps.length - 1 === currentState) return currentState

      return currentState + 1
    })
  }, [])

  const prev = React.useCallback(() => {
    setActive((currentState) => {
      if (currentState === 0) return currentState

      return currentState - 1
    })
  }, [])

  return (
    <StepsContext.Provider
      value={{
        active,
        steps,
        jump,
        next,
        prev,
      }}
    >
      {children}
    </StepsContext.Provider>
  )
}
export default StepsProvider
