import { createApiRequest } from '../../store/api'

export const getProperties = ({ ids, params = {} }) => {
  if (ids) params.aliasId = ids

  return createApiRequest({
    url: '/properties',
    params,
  })
}

export const searchProperties = async ({ params }) => {
  return createApiRequest({
    params,
    url: '/geosearch',
  })
}

export const getPropertyById = async ({ id }) => {
  return createApiRequest({
    url: `/properties/${id}`,
  })
}

export const getApartmentByIds = async ({
  ids = [],
  onSuccess,
  onFail,
  params = {},
}) => {
  return createApiRequest({
    method: 'get',
    url: `/properties`,
    onSuccess,
    onFail,
    params: {
      ...params,
      id: ids,
    },
  })
}

export const getApartmentBlockedDates = async ({ id, onSuccess, onFail }) => {
  await createApiRequest({
    url: `/properties/${id}/calendars`,
    onSuccess,
    onFail,
  })
}

export const getApartmentPrice = async ({
  id,
  data: { from, to, guests = 1 },
  onSuccess,
  onFail,
}) => {
  return createApiRequest({
    url: `/properties/${id}/prices`,
    onSuccess,
    onFail,
    params: { from, to, guests },
  })
}

export const getBuilding = async ({ id, onSuccess, onFail }) => {
  return createApiRequest({
    url: `/buildings/${id}`,
    onSuccess,
    onFail,
  })
}

export const getPropertySuggestions = async ({ id }) => {
  return createApiRequest({
    url: `/properties/${id}/recommendations`,
  })
}
