import { Box } from '@chakra-ui/react'
import { Button, Flex } from '@chakra-ui/react'
import { Container } from '@retriever-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MapOpenIcon } from 'src/assets/icons/map-open.icon'
import { IS_DEV_MODE } from 'src/constants'
import useMessages from 'src/hooks/messages/use-messages'
import PasswordEyeShut from 'src/pages/profile-v2/components/icons/passwordEyeShut.icon'

import Header from '../header'
import Loading from '../list/loading'
import Map from '../map'
import Order from '../order'
import { useMaps } from './context'
import CardMobile from './pin/card/mobile'

const ShowMapButton = () => {
  const map = useMaps()
  const { t } = useTranslation('search-page')

  if (map.state === 'error') return null
  if (map.context.activeCard) return null
  if (map.context?.totalItems === 0) return null

  return (
    <Flex zIndex={9} justify="center" pos="sticky" bottom="100px">
      {map.isMapOpen ? (
        <Button
          colorScheme="black"
          fontSize="14px"
          rightIcon={<PasswordEyeShut color="white" />}
          onClick={map.onMapClose}
        >
          {t('search-page:CLOSE_MAP')}
        </Button>
      ) : (
        <Button
          colorScheme="black"
          fontSize="14px"
          rightIcon={<MapOpenIcon color="white" />}
          onClick={() => {
            map.onMapOpen()
            window.scrollTo(0, 0)
          }}
        >
          {t('search-page:SHOW_MAP')}
        </Button>
      )}
    </Flex>
  )
}

const List = React.lazy(() => import('../list'))

export const NavigationList = () => {
  const { isMapOpen } = useMaps()
  if (isMapOpen) return null

  return (
    <Box w="100%" h="100%" bg="black.100">
      <Container d="flex" flexDirection="column" alignItems="center">
        {IS_DEV_MODE && <Order />}
        <React.Suspense fallback={<Loading />}>
          <List />
        </React.Suspense>
      </Container>
    </Box>
  )
}

export const NavigationMap = () => {
  const { isMapOpen } = useMaps()
  if (!isMapOpen) return null
  return (
    <Box pos="relative" h="calc(100vh - 230px)" w="100%">
      <Map />
    </Box>
  )
}

export type ListingMapType = {
  map: google.maps.Map
  buildingId: string
  label: string
  isFlagship: boolean
  isPartner: boolean
  lat: number
  lng: number
}

const ListingMap = () => {
  const { context } = useMaps()
  const { value } = useMessages<ListingMapType>('map:listing')

  if (context.activeCard !== `${value?.data?.lat}${value?.data?.lng}`)
    return null

  return (
    <CardMobile
      map={value?.data?.map}
      buildingId={value?.data?.buildingId}
      label={value?.data?.label}
      isPartner={value?.data?.isPartner}
      isFlagship={value?.data?.isFlagship}
    />
  )
}

const Mobile = () => {
  return (
    <Box pos="relative" zIndex={0} h="100%" w="100%">
      <React.Suspense fallback="">
        <Header />
      </React.Suspense>
      <NavigationMap />
      <ListingMap />
      <NavigationList />
      <ShowMapButton />
    </Box>
  )
}

export default Mobile
