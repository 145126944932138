import * as c from '@chakra-ui/react'
import React from 'react'
import ShadowSkeleton from 'src/components/skeleton'
import { useProperties } from 'src/hooks/properties'
import { useRequest } from 'src/hooks/use-request'
import PropertyInterface from 'src/interfaces/property/property.interface'
import { ListItem } from 'src/pages/home-v2/components/apartment-banners'

import { ShadowHighlightCard } from '../loadings'
import HighlightCard from './card'

const HighlightsList = ({ list }: Partial<ListItem>) => {
  const { apartments: properties, apartmentLoading: loading } = useProperties({
    apartmentIds: list?.map((item) => item.housiId),
    fetchList: true,
  })

  if (loading)
    return (
      <c.Flex direction="column" gap={3}>
        <ShadowHighlightCard />
        <ShadowHighlightCard />
        <ShadowHighlightCard />
      </c.Flex>
    )

  return (
    <c.Flex direction="column" gap={3}>
      {(properties as PropertyInterface[]).map((property) => (
        <HighlightCard key={property._id} property={property} />
      ))}
    </c.Flex>
  )
}

const Highlights = () => {
  const { data, isLoading } = useRequest<ListItem>({
    url: `/lists/${process.env.REACT_APP_HIGHLIGHTS_LIST}`,
  })

  if (isLoading) {
    return (
      <c.Box p={5} w="100%" borderRadius="12px" bg="black.0">
        <ShadowSkeleton isLoading w="150px" h="20px" />
      </c.Box>
    )
  }

  return (
    <c.Box p={5} w="100%" borderRadius="12px" bg="black.0">
      <c.Heading color="black.500" fontSize={14} fontWeight={700} mb={4}>
        {data?.name}
      </c.Heading>
      <React.Suspense
        fallback={
          <c.Flex direction="column" gap={3}>
            <ShadowHighlightCard />
            <ShadowHighlightCard />
            <ShadowHighlightCard />
          </c.Flex>
        }
      >
        <HighlightsList {...data} />
      </React.Suspense>
    </c.Box>
  )
}

export default Highlights
