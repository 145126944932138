import * as c from '@chakra-ui/react'
import { ArrowIcon } from 'src/assets/icons/arrow.icon'

import Autocomplete from '../../autocomplete'
import { useSearch } from '../../search-context'

const StepAutocomplete = () => {
  const { handleClose } = useSearch()

  return (
    <c.Box
      pos="relative"
      zIndex={1}
      pt={10}
      pb={5}
      px={5}
      w="100%"
      maxW={900}
      bg="black.0"
      shadow="0px 4px 16px -5px black.800/2"
    >
      <c.Button
        pos="absolute"
        bg="transparent"
        maxW="fit-content"
        border="none"
        p={0}
        onClick={handleClose}
        top={9}
        left={2}
      >
        <ArrowIcon color="black" direction="left" height={14} width={14} />
      </c.Button>
      <Autocomplete />
    </c.Box>
  )
}

export default StepAutocomplete
