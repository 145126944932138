import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useWindowWidth } from './window.context'

interface NavbarContextInterface {
  loginVisible: boolean
  setLoginVisible: Dispatch<SetStateAction<boolean>>
  searchVisible: boolean
  setSearchVisible: Dispatch<SetStateAction<boolean>>
  searchNotVisible: boolean
  setSearchNotVisible: Dispatch<SetStateAction<boolean>>
  expanded: boolean
  setExpanded: Dispatch<SetStateAction<boolean>>
  height: number
  setHeight: Dispatch<SetStateAction<number>>
  color: string
  setColor: Dispatch<SetStateAction<string>>
  setDefaultHeight: () => void
  inTop: boolean
  setInTop: Dispatch<SetStateAction<boolean>>
}

export const NavbarContext = createContext<NavbarContextInterface>(
  {} as NavbarContextInterface
)

export const NavbarProvider = ({ children }) => {
  const [loginVisible, setLoginVisible] = useState(false)
  const [height, setHeight] = useState(70)
  const [inTop, setInTop] = useState(false)
  const [searchVisible, setSearchVisible] = useState(false)
  const [searchNotVisible, setSearchNotVisible] = useState(false)
  const [color, setColor] = useState('#fff')
  const [expanded, setExpanded] = useState(false)

  const setDefaultHeight = useCallback(() => setHeight(70), [])

  return (
    <NavbarContext.Provider
      value={{
        loginVisible,
        setLoginVisible,
        height,
        setHeight,
        setDefaultHeight,
        searchVisible,
        setSearchVisible,
        searchNotVisible,
        setSearchNotVisible,
        color,
        setColor,
        inTop,
        setInTop,
        expanded,
        setExpanded,
      }}
    >
      {children}
    </NavbarContext.Provider>
  )
}

export const useHideSearchBar = ({ mobile = true, desktop = true }) => {
  const { setSearchNotVisible } = useContext(NavbarContext)
  const { windowWidth } = useWindowWidth()

  const notVisibleBreakPoint = () => {
    if (windowWidth < 768 && mobile) {
      return setSearchNotVisible(true)
    }

    if (windowWidth >= 768 && desktop) {
      return setSearchNotVisible(true)
    }

    return setSearchNotVisible(false)
  }

  useEffect(() => {
    notVisibleBreakPoint()
    return () => setSearchNotVisible(false)
  })
}
