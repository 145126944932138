import * as c from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'src/pages/auth/auth-context'

import Language from '../components/language'

interface MenuHeaderInterface {
  isLogged: boolean
}

const MenuHeader = ({ isLogged }: MenuHeaderInterface) => {
  const { t } = useTranslation('components/nav')
  const { user } = useAuth()
  return (
    <c.Flex mb="s4" justifyContent="space-between" alignItems="center">
      <c.Text fontSize="t-xl.1" fontWeight="bold">
        {isLogged
          ? `${user?.firstName} ${user?.lastName}`
          : t('components/nav:LOGIN_ACCOUNT')}
      </c.Text>
      <Language />
    </c.Flex>
  )
}
export default MenuHeader
