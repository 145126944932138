import { createApiRequest } from '../../store/api'

export const createReservation = async ({
  data,
  params = {},
  onSuccess,
  onFail,
}) => {
  return createApiRequest({
    method: 'post',
    url: '/reservations',
    params,
    data,
    onSuccess,
    onFail,
  })
}

export const getReservations = async () =>
  createApiRequest({
    url: '/reservations',
  })

export const getReservationById = async ({ reservationId, params = {} }) =>
  createApiRequest({
    url: `/reservations/${reservationId}`,
    params,
  })

export const getGuestsFromReservation = async ({ reservationId }) =>
  createApiRequest({
    url: `/reservations/${reservationId}/guests`,
  })

export const createGuestFromReservation = async ({ reservationId, data }) =>
  createApiRequest({
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    url: `/reservations/${reservationId}/guests`,
    data,
  })

export const deleteGuestFromReservation = async ({ reservationId, guestId }) =>
  createApiRequest({
    url: `/reservations/${reservationId}/guests/${guestId}`,
    method: 'delete',
  })

export const getReservationPaymentMethod = async ({ reservationId }) =>
  createApiRequest({
    url: `/reservations/${reservationId}/bills`,
  })

export const createPreviews = async ({ data }) =>
  createApiRequest({
    method: 'post',
    url: '/checkout/payment/preview',
    data,
  })
