export const HomeIcon = (props: any) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.111 21.9879H4.888C4.37279 21.9719 3.88488 21.7526 3.53105 21.3777C3.17721 21.0029 2.98627 20.5032 3 19.9879V11.6119C2.9988 11.0763 3.20485 10.561 3.575 10.1739L11.285 2.2889C11.4767 2.10359 11.7329 2 11.9995 2C12.2661 2 12.5223 2.10359 12.714 2.2889L20.423 10.1729C20.7939 10.5601 21.0007 11.0757 21 11.6119V19.9869C21.0137 20.5024 20.8227 21.0024 20.4687 21.3774C20.1146 21.7524 19.6265 21.9719 19.111 21.9879ZM9 11.9869H15C15.2652 11.9869 15.5196 12.0923 15.7071 12.2798C15.8946 12.4673 16 12.7217 16 12.9869V19.9869H18.99L19 11.6119L12 4.4199L5.006 11.5709L5 19.9869H8V12.9869C8 12.7217 8.10536 12.4673 8.29289 12.2798C8.48043 12.0923 8.73478 11.9869 9 11.9869ZM10 13.9869V19.9869H14V13.9869H10Z"
      fill="currentColor"
    />
  </svg>
)
