import { Builder } from '@builder.io/react'
import React from 'react'

const HeroBanner = React.lazy(
  () => import('src/features/landing-page/housi-sales/home/hero-banner')
)

const EnterpriseList = React.lazy(
  () => import('src/features/landing-page/housi-sales/home/enterprise-list')
)

const DetailsHeroBanner = React.lazy(
  () => import('src/features/landing-page/housi-sales/details/main-banner')
)

const InfoBanner = React.lazy(
  () => import('src/features/landing-page/housi-sales/details/info-banner')
)

const Carousel = React.lazy(
  () => import('src/features/landing-page/housi-sales/details/carousel')
)

const Commodities = React.lazy(
  () => import('src/features/landing-page/housi-sales/details/commodities')
)

const VideoDetailsBanner = React.lazy(
  () => import('src/features/landing-page/housi-sales/details/video')
)
const FloorplanBanner = React.lazy(
  () => import('src/features/landing-page/housi-sales/details/floor-plan')
)

const NearbyPlaces = React.lazy(
  () => import('src/features/landing-page/housi-sales/details/nearby-places')
)

const MoreEnterprises = React.lazy(
  () => import('src/features/landing-page/housi-sales/details/more-enterprises')
)

Builder.registerComponent(HeroBanner, {
  name: 'Sales Hero Banner',
  inputs: [
    { name: 'image', type: 'url' },
    {
      name: 'title',
      type: 'object',
      subFields: [
        { name: 'ptBr', type: 'html' },
        { name: 'en', type: 'html' },
      ],
    },
    {
      name: 'description',
      type: 'object',
      subFields: [
        { name: 'ptBr', type: 'html' },
        { name: 'en', type: 'html' },
      ],
    },
    {
      name: 'cta',
      type: 'object',
      subFields: [
        {
          name: 'text',
          type: 'object',
          subFields: [
            { name: 'ptBr', type: 'text' },
            { name: 'en', type: 'text' },
          ],
        },
        { name: 'whatsapp', type: 'number' },
      ],
    },
  ],
})

Builder.registerComponent(EnterpriseList, {
  name: 'Sales Enterprise List',
  inputs: [
    {
      name: 'title',
      type: 'object',
      subFields: [
        { name: 'ptBr', type: 'html' },
        { name: 'en', type: 'html' },
      ],
    },
    { name: 'whatsapp', type: 'number' },
    {
      name: 'banner',
      type: 'object',
      subFields: [
        {
          name: 'profitability',
          type: 'object',
          subFields: [
            {
              name: 'label',
              type: 'object',
              subFields: [
                { name: 'ptBr', type: 'text' },
                { name: 'en', type: 'text' },
              ],
            },
            {
              name: 'helperText',
              type: 'object',
              subFields: [
                { name: 'ptBr', type: 'text' },
                { name: 'en', type: 'text' },
              ],
            },
            {
              name: 'modal',
              type: 'object',
              subFields: [
                {
                  name: 'cta',
                  type: 'object',
                  subFields: [
                    { name: 'ptBr', type: 'text' },
                    { name: 'en', type: 'text' },
                  ],
                },
                {
                  name: 'icon',
                  type: 'text',
                  enum: ['assured-income', 'balloon-money', 'hand-money'],
                },
                {
                  name: 'title',
                  type: 'object',
                  subFields: [
                    { name: 'ptBr', type: 'html' },
                    { name: 'en', type: 'html' },
                  ],
                },
                {
                  name: 'description',
                  type: 'object',
                  subFields: [
                    { name: 'ptBr', type: 'html' },
                    { name: 'en', type: 'html' },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'rental',
          type: 'object',
          subFields: [
            {
              name: 'label',
              type: 'object',
              subFields: [
                { name: 'ptBr', type: 'text' },
                { name: 'en', type: 'text' },
              ],
            },
            {
              name: 'helperText',
              type: 'object',
              subFields: [
                { name: 'ptBr', type: 'text' },
                { name: 'en', type: 'text' },
              ],
            },
            {
              name: 'modal',
              type: 'object',
              subFields: [
                {
                  name: 'cta',
                  type: 'object',
                  subFields: [
                    { name: 'ptBr', type: 'text' },
                    { name: 'en', type: 'text' },
                  ],
                },
                {
                  name: 'icon',
                  type: 'text',
                  enum: ['assured-income', 'balloon-money', 'hand-money'],
                },
                {
                  name: 'title',
                  type: 'object',
                  subFields: [
                    { name: 'ptBr', type: 'html' },
                    { name: 'en', type: 'html' },
                  ],
                },
                {
                  name: 'description',
                  type: 'object',
                  subFields: [
                    { name: 'ptBr', type: 'html' },
                    { name: 'en', type: 'html' },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'investments',
          type: 'object',
          subFields: [
            { name: 'ptBr', type: 'text' },
            { name: 'en', type: 'text' },
          ],
        },
        {
          name: 'status',
          type: 'object',
          subFields: [
            { name: 'ptBr', type: 'text' },
            { name: 'en', type: 'text' },
          ],
        },
        {
          name: 'category',
          type: 'object',
          subFields: [
            { name: 'ptBr', type: 'text' },
            { name: 'en', type: 'text' },
          ],
        },
        {
          name: 'size',
          type: 'object',
          subFields: [
            { name: 'ptBr', type: 'text' },
            { name: 'en', type: 'text' },
          ],
        },
        {
          name: 'bedroom',
          type: 'object',
          subFields: [
            { name: 'ptBr', type: 'text' },
            { name: 'en', type: 'text' },
          ],
        },
        {
          name: 'typologies',
          type: 'object',
          subFields: [
            { name: 'ptBr', type: 'text' },
            { name: 'en', type: 'text' },
          ],
        },
        {
          name: 'ctaPrimary',
          type: 'object',
          subFields: [
            { name: 'ptBr', type: 'text' },
            { name: 'en', type: 'text' },
          ],
        },
        {
          name: 'ctaSecondary',
          type: 'object',
          subFields: [
            { name: 'ptBr', type: 'text' },
            { name: 'en', type: 'text' },
          ],
        },
      ],
    },
  ],
})

Builder.registerComponent(DetailsHeroBanner, {
  name: 'Sales Details Hero Banner',
  inputs: [
    { name: 'city', type: 'text' },
    { name: 'state', type: 'text' },
    {
      name: 'investments',
      type: 'object',
      subFields: [
        { name: 'min', type: 'number' },
        { name: 'max', type: 'number' },
        { name: 'm2', type: 'number' },
      ],
    },
    { name: 'releaseAt', type: 'text' },
    { name: 'street', type: 'text' },
    { name: 'logo', type: 'url' },
    {
      name: 'images',
      type: 'list',
      subFields: [{ name: 'url', type: 'url' }],
    },
    { name: 'streetNumber', type: 'text' },
    { name: 'name', type: 'text' },
    { name: 'neighborhood', type: 'text' },
    { name: 'incorp', type: 'text' },
  ],
})

Builder.registerComponent(InfoBanner, {
  name: 'Sales Info Banner',
  inputs: [
    {
      name: 'rent',
      type: 'object',
      subFields: [
        { name: 'min', type: 'number' },
        { name: 'max', type: 'number' },
      ],
    },
    {
      name: 'iptu',
      type: 'object',
      subFields: [
        { name: 'min', type: 'number' },
        { name: 'max', type: 'number' },
      ],
    },
    {
      name: 'bills',
      type: 'object',
      subFields: [
        { name: 'min', type: 'number' },
        { name: 'max', type: 'number' },
      ],
    },
    {
      name: 'condo',
      type: 'object',
      subFields: [
        { name: 'min', type: 'number' },
        { name: 'max', type: 'number' },
      ],
    },
    {
      name: 'bedroom',
      type: 'object',
      subFields: [
        { name: 'min', type: 'number' },
        { name: 'max', type: 'number' },
      ],
    },
    {
      name: 'size',
      type: 'object',
      subFields: [
        { name: 'min', type: 'number' },
        { name: 'max', type: 'number' },
      ],
    },
    { name: 'housiFee', type: 'number' },
    { name: 'name', type: 'text' },
    { name: 'category', type: 'text' },
    { name: 'status', type: 'text' },
    {
      name: 'typologies',
      type: 'object',
      subFields: [{ name: 'type', type: 'text' }],
    },
    {
      name: 'profitability',
      type: 'object',
      subFields: [
        { name: 'min', type: 'number' },
        { name: 'max', type: 'number' },
      ],
    },
  ],
})

Builder.registerComponent(Carousel, {
  name: 'Sales Carousel',
  inputs: [
    {
      name: 'images',
      type: 'list',
      subFields: [{ name: 'url', type: 'url' }],
    },
  ],
})

Builder.registerComponent(Commodities, {
  name: 'Sales Commodities',
  inputs: [
    {
      name: 'commodities',
      type: 'list',
      subFields: [{ name: 'value', type: 'text' }],
    },
  ],
})

Builder.registerComponent(VideoDetailsBanner, {
  name: 'Sales Video',
  inputs: [
    { name: 'video', type: 'url' },
    { name: 'name', type: 'text' },
  ],
})

Builder.registerComponent(FloorplanBanner, {
  name: 'Sales Floor Plan Banner',
  inputs: [
    {
      name: 'floorPlan',
      type: 'object',
      subFields: [
        { name: 'image', type: 'url' },
        { name: 'type', type: 'select' },
        { name: 'm2', type: 'number' },
        { name: 'bedrooms', type: 'number' },
        { name: 'bathrooms', type: 'number' },
        { name: 'bathrooms', type: 'number' },
        { name: 'investment', type: 'number' },
      ],
    },
  ],
})

Builder.registerComponent(NearbyPlaces, {
  name: 'Sales Nearby Places',
  inputs: [
    {
      name: 'nearbyPlaces',
      type: 'object',
      subFields: [{ name: 'place', type: 'text' }],
    },
    { name: 'city', type: 'text' },
    { name: 'state', type: 'text' },
    { name: 'street', type: 'text' },
    { name: 'streetNumber', type: 'text' },
    { name: 'neighborhood', type: 'text' },
    {
      name: 'coord',
      type: 'object',
      subFields: [
        { name: 'lat', type: 'number' },
        { name: 'lng', type: 'number' },
      ],
    },
  ],
})

Builder.registerComponent(MoreEnterprises, {
  name: 'Sales More Enterprises',
  inputs: [{ name: 'limit', type: 'number', defaultValue: 12 }],
})
