import React from 'react'

export const BuildingIcon = ({
  width = 48,
  height = 59,
  color = '#FA2F93',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      minWidth: width,
      minHeight: height,
    }}
  >
    <path
      d="M19.1 2.9C18.6 2.3 17.8 2 17 2H7C6.2 2 5.4 2.3 4.9 2.9C4.3 3.4 4 4.2 4 5V19C4 19.8 4.3 20.6 4.9 21.1C5.4 21.7 6.2 22 7 22H17C17.8 22 18.6 21.7 19.1 21.1C19.7 20.5 20 19.8 20 19V5C20 4.2 19.7 3.4 19.1 2.9ZM18 19C18 19.3 17.9 19.5 17.7 19.7C17.5 19.9 17.3 20 17 20H7C6.7 20 6.5 19.9 6.3 19.7C6.1 19.5 6 19.3 6 19V5C6 4.7 6.1 4.5 6.3 4.3C6.5 4.1 6.7 4 7 4H17C17.3 4 17.5 4.1 17.7 4.3C17.9 4.5 18 4.7 18 5V19Z"
      fill={color}
    />
    <path
      d="M13.5 19.9999C13.5 20.3999 13.3 20.7999 13.1 21.0999C12.8 21.3999 12.4 21.4999 12 21.4999C11.7 21.4999 11.4 21.3999 11.2 21.2999C11 21.0999 10.8 20.8999 10.6 20.5999C10.5 20.2999 10.5 19.9999 10.5 19.6999C10.6 19.3999 10.7 19.0999 10.9 18.8999C11.1 18.6999 11.4 18.5999 11.7 18.4999C12 18.3999 12.3 18.4999 12.6 18.5999C12.9 18.6999 13.1 18.8999 13.3 19.0999C13.4 19.3999 13.5 19.6999 13.5 19.9999Z"
      fill={color}
    />
    <path
      d="M11.3999 6.8999C11.3999 7.1999 11.2999 7.3999 11.0999 7.5999C10.8999 7.7999 10.5999 7.8999 10.3999 7.8999H8.3999C8.0999 7.8999 7.8999 7.7999 7.6999 7.5999C7.4999 7.4999 7.3999 7.1999 7.3999 6.8999C7.3999 6.5999 7.4999 6.3999 7.6999 6.1999C7.8999 5.9999 8.0999 5.8999 8.3999 5.8999H10.3999C10.6999 5.8999 10.8999 5.9999 11.0999 6.1999C11.2999 6.3999 11.3999 6.6999 11.3999 6.8999Z"
      fill={color}
    />
    <path
      d="M16.6001 6.8999C16.6001 7.1999 16.5001 7.3999 16.3001 7.5999C16.1001 7.7999 15.8001 7.8999 15.6001 7.8999H13.6001C13.3001 7.8999 13.1001 7.7999 12.9001 7.5999C12.7001 7.4999 12.6001 7.1999 12.6001 6.8999C12.6001 6.5999 12.7001 6.3999 12.9001 6.1999C13.1001 5.9999 13.3001 5.8999 13.6001 5.8999H15.6001C15.9001 5.8999 16.1001 5.9999 16.3001 6.1999C16.5001 6.3999 16.6001 6.6999 16.6001 6.8999Z"
      fill={color}
    />
    <path
      d="M11.3999 10.6001C11.3999 10.9001 11.2999 11.1001 11.0999 11.3001C10.8999 11.5001 10.5999 11.6001 10.3999 11.6001H8.3999C8.0999 11.6001 7.8999 11.5001 7.6999 11.3001C7.4999 11.1001 7.3999 10.9001 7.3999 10.6001C7.3999 10.3001 7.4999 10.1001 7.6999 9.9001C7.8999 9.7001 8.0999 9.6001 8.3999 9.6001H10.3999C10.6999 9.6001 10.8999 9.7001 11.0999 9.9001C11.2999 10.1001 11.3999 10.4001 11.3999 10.6001Z"
      fill={color}
    />
    <path
      d="M16.6001 10.6001C16.6001 10.9001 16.5001 11.1001 16.3001 11.3001C16.1001 11.5001 15.8001 11.6001 15.6001 11.6001H13.6001C13.3001 11.6001 13.1001 11.5001 12.9001 11.3001C12.7001 11.1001 12.6001 10.9001 12.6001 10.6001C12.6001 10.3001 12.7001 10.1001 12.9001 9.9001C13.1001 9.7001 13.3001 9.6001 13.6001 9.6001H15.6001C15.9001 9.6001 16.1001 9.7001 16.3001 9.9001C16.5001 10.1001 16.6001 10.4001 16.6001 10.6001Z"
      fill={color}
    />
    <path
      d="M11.3999 14.2998C11.3999 14.5998 11.2999 14.7998 11.0999 14.9998C10.8999 15.1998 10.5999 15.2998 10.3999 15.2998H8.3999C8.0999 15.2998 7.8999 15.1998 7.6999 14.9998C7.4999 14.7998 7.3999 14.5998 7.3999 14.2998C7.3999 13.9998 7.4999 13.7998 7.6999 13.5998C7.8999 13.3998 8.0999 13.2998 8.3999 13.2998H10.3999C10.6999 13.2998 10.8999 13.3998 11.0999 13.5998C11.2999 13.7998 11.3999 13.9998 11.3999 14.2998Z"
      fill={color}
    />
    <path
      d="M16.7002 14.2998C16.7002 14.5998 16.6002 14.7998 16.4002 14.9998C16.2002 15.1998 15.9002 15.2998 15.7002 15.2998H13.7002C13.4002 15.2998 13.2002 15.1998 13.0002 14.9998C12.8002 14.7998 12.7002 14.5998 12.7002 14.2998C12.7002 13.9998 12.8002 13.7998 13.0002 13.5998C13.2002 13.3998 13.4002 13.2998 13.7002 13.2998H15.7002C16.0002 13.2998 16.2002 13.3998 16.4002 13.5998C16.5002 13.7998 16.7002 13.9998 16.7002 14.2998Z"
      fill={color}
    />
  </svg>
)
