import * as c from '@chakra-ui/react'

import { LandingPages } from './landing-pages'
import MenuProfile from './menu-profile'

const NavItems = () => {
  return (
    <c.Flex alignItems="center">
      <LandingPages />

      <MenuProfile />
    </c.Flex>
  )
}

export default NavItems
