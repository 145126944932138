import {
  Box,
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoIcon } from 'src/assets/icons/info.icon'
import { BillItem } from 'src/pages/property-detail/booking/resume/pack-housi/bills'
import { usePackHousi } from 'src/pages/property-detail/details/pack-housi'
import { convertToMoney } from 'src/utils/monetary'

import { PropertyCardContext } from '../../../property-card.context'

const HousiPackModal = ({ rent, condo, iptu, housiPack, total }) => {
  const { t } = useTranslation('card-apartment')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { property } = React.useContext(PropertyCardContext)
  const { facilitiesBills, livingBills } = usePackHousi({ property: property })

  return (
    <>
      <Box cursor="pointer">
        <InfoIcon
          height={14}
          width={14}
          color="var(--primary-color)"
          onClick={onOpen}
        />
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <Flex direction="column" padding="20px" gap="16px">
            <Text fontSize={20} fontWeight={700} color="black.500">
              {t('card-apartment:PRICE_DETAIL')}
            </Text>
            <Box
              margin="12px 0 12px 0"
              width="100%"
              height="1px"
              backgroundColor="black.200"
            />
            <Flex direction="column" gap="8px">
              <Flex justifyContent="space-between">
                <Text fontWeight="bold" fontSize="14px">
                  {t('card-apartment:RENT')}
                </Text>
                <Text fontSize="14px">{convertToMoney(rent)}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontWeight="bold" fontSize="14px">
                  {t('card-apartment:PACKAGE_ITEMS.0')}
                </Text>
                <Text fontSize="14px">{convertToMoney(condo)}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontWeight="bold" fontSize="14px">
                  {t('card-apartment:PACKAGE_ITEMS.1')}
                </Text>
                <Text fontSize="14px">{convertToMoney(iptu)}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontWeight="bold" fontSize="14px">
                  {t('card-apartment:PACKAGE_TITLE')}
                </Text>
                <Text fontSize="14px">{convertToMoney(housiPack)}</Text>
              </Flex>
            </Flex>
            <Text fontSize={14} fontWeight={500} color="black.400">
              {t('card-apartment:PACKAGE_INCLUDES')}
            </Text>
            <Flex direction="column" gap="5px">
              {livingBills.map((bill) => (
                <BillItem
                  key={bill.text}
                  haveBill={bill.haveBill}
                  text={bill.text}
                />
              ))}
              {facilitiesBills.map((bill) => (
                <BillItem
                  key={bill.text}
                  haveBill={bill.haveBill}
                  text={bill.text}
                />
              ))}
            </Flex>
            <Box width="100%" height="1px" backgroundColor="black.200" />
            <Flex justifyContent="space-between">
              <Text fontWeight="bold">{t('card-apartment:TOTAL_RENT')}</Text>
              <Text fontWeight="bold">
                {convertToMoney(total)} {t('card-apartment:MONTH')}
              </Text>
            </Flex>

            <Button onClick={onClose}>{t('card-apartment:OK')}</Button>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  )
}

export default HousiPackModal
