import Section from 'src/components/navbar/mobile/items/components/not-logged-links/section'

import { NavbarItemsConfig } from '../../../../desktop/items/landing-pages/navbar-item.config'

interface PersonasSectionInterface {
  isLogged: boolean
}

const PersonasSection = ({ isLogged }: PersonasSectionInterface) => {
  const { navbarItemsData } = NavbarItemsConfig()

  if (isLogged) return null

  return (
    <>
      {navbarItemsData?.map((item) => (
        <Section key={item.id} item={item} />
      ))}
    </>
  )
}

export default PersonasSection
