export const FigerprintIcon = (props: any) => (
  <svg
    {...props}
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9148 11.0036C16.9148 10.2737 16.9117 9.5438 16.9155 8.81395C16.9224 7.51567 16.6228 6.29627 15.9817 5.16492C15.86 4.95018 15.8177 4.72848 15.8955 4.49556C15.9895 4.21429 16.1934 4.03901 16.4847 3.99667C16.8143 3.94874 17.0848 4.07153 17.2537 4.36362C17.8225 5.34732 18.1993 6.40121 18.3291 7.53086C18.3936 8.09193 18.4149 8.66016 18.4196 9.22545C18.4316 10.6851 18.4253 12.1449 18.4231 13.6046C18.4203 15.4493 17.2266 16.9371 15.4321 17.3379C13.4499 17.7806 11.3626 16.3851 11.024 14.3822C10.9751 14.0932 10.9549 13.7963 10.9536 13.5029C10.9468 11.9811 10.9482 10.4592 10.9517 8.93733C10.9527 8.50056 10.853 8.10619 10.5248 7.79711C10.167 7.4602 9.74172 7.38958 9.29387 7.55104C8.84521 7.71278 8.57295 8.04697 8.4985 8.52695C8.48664 8.60343 8.48044 8.68161 8.48059 8.75899C8.48321 10.1565 8.45859 11.5549 8.4977 12.9515C8.55443 14.9772 9.37325 16.6744 10.8689 18.0305C12.209 19.2455 13.8007 19.8548 15.6127 19.8812C16.015 19.887 16.3072 20.0965 16.405 20.4418C16.4895 20.7402 16.3716 21.0893 16.0934 21.2526C15.96 21.3309 15.7875 21.3832 15.634 21.3805C12.9056 21.3326 10.6642 20.2509 8.9221 18.1576C7.91832 16.9515 7.31883 15.5511 7.07497 14.0033C7.01242 13.6063 6.98025 13.2008 6.97672 12.7988C6.96464 11.4246 6.96345 10.0502 6.97353 8.67591C6.98285 7.40567 7.89672 6.29811 9.12616 6.03881C10.608 5.72628 12.0343 6.63869 12.3646 8.12321C12.4246 8.39318 12.4535 8.67541 12.455 8.95221C12.4635 10.4973 12.4576 12.0425 12.4596 13.5876C12.4611 14.8287 13.2153 15.7044 14.4411 15.8945C15.5931 16.0733 16.8382 15.1037 16.8873 13.9376C16.9284 12.9612 16.8957 11.9817 16.8957 11.0036C16.9021 11.0036 16.9085 11.0036 16.9148 11.0036Z"
      fill="currentColor"
    />
    <path
      d="M2.51386 10.289C2.51386 10.9332 2.51963 11.5775 2.51165 12.2216C2.50701 12.5957 2.30478 12.8744 1.99592 12.9727C1.68152 13.0728 1.33672 12.9717 1.15778 12.6862C1.07203 12.5493 1.01418 12.3701 1.01272 12.2094C1.00209 11.0454 0.989791 9.88074 1.01409 8.71705C1.09019 5.07177 3.44948 1.71727 6.77761 0.533451C9.25221 -0.346761 11.6496 -0.126344 13.96 1.11068C14.1886 1.23303 14.3677 1.3942 14.419 1.6608C14.4786 1.97008 14.3943 2.23515 14.1375 2.42179C13.8765 2.61146 13.593 2.6287 13.3074 2.46741C12.6144 2.07598 11.8802 1.80141 11.0974 1.64701C8.26025 1.08742 5.39584 2.2938 3.79155 4.7307C2.92286 6.05022 2.50722 7.50181 2.51465 9.07831C2.51655 9.48188 2.51493 9.88547 2.51493 10.289L2.51386 10.289Z"
      fill="currentColor"
    />
    <path
      d="M13.9141 10.7987C13.9141 10.0535 13.9388 9.30732 13.9093 8.56326C13.8279 6.50674 12.1656 4.70591 10.1263 4.51614C8.64692 4.37848 7.40133 4.90412 6.44125 6.05602C6.26832 6.2635 6.09564 6.4544 5.80055 6.47044C5.49352 6.48713 5.25112 6.37636 5.09725 6.10926C4.94035 5.8369 4.94059 5.54953 5.12828 5.29831C5.98938 4.1458 7.1247 3.39657 8.535 3.11342C12.0343 2.41085 14.7877 4.85675 15.3399 7.84472C15.3988 8.16364 15.4331 8.49162 15.4355 8.81571C15.446 10.252 15.4419 11.6885 15.4404 13.1249C15.44 13.488 15.2628 13.7581 14.9595 13.8786C14.6809 13.9893 14.357 13.918 14.145 13.7016C13.9751 13.5283 13.932 13.3136 13.9324 13.0815C13.9337 12.3206 13.9329 11.5597 13.9329 10.7988C13.9266 10.7988 13.9203 10.7987 13.9141 10.7987Z"
      fill="currentColor"
    />
    <path
      d="M5.50878 11.9485C5.50878 12.9262 5.47526 13.9053 5.51602 14.8813C5.59233 16.7088 6.44347 18.1093 7.97065 19.1033C8.18341 19.2417 8.38873 19.3809 8.44856 19.6446C8.5197 19.9581 8.4451 20.2354 8.18117 20.4322C7.9038 20.639 7.60415 20.6387 7.30957 20.4647C6.40593 19.931 5.66554 19.2246 5.09261 18.3452C4.3445 17.197 3.98675 15.9342 3.98798 14.5659C3.98971 12.6414 3.98779 10.7168 3.98891 8.79229C3.98915 8.37107 4.18929 8.09129 4.55566 7.98776C4.95797 7.87409 5.39746 8.14066 5.47204 8.55132C5.49404 8.6724 5.49577 8.79831 5.49599 8.92202C5.49777 9.93085 5.497 10.9397 5.497 11.9485C5.50092 11.9485 5.50485 11.9485 5.50878 11.9485Z"
      fill="currentColor"
    />
  </svg>
)
