import * as c from '@chakra-ui/react'
import { AnimatePresence, motion, Transition, Variants } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SearchContext } from '../search-context'
import { DatesContext } from './dates-context'
import { dates_events } from './dates-machine'
import { FieldEnd, FieldStart } from './fields/field'
import Picker from './picker'

const DatePicker = () => {
  const { valid } = React.useContext(DatesContext)

  return (
    <>
      <AnimatePresence>
        {valid.focused && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Picker />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

const Dates = () => {
  const { t } = useTranslation('components/search')
  const searchState = React.useContext(SearchContext)
  const datesState = React.useContext(DatesContext)

  const isOpenOrClosing =
    searchState.valid.open ||
    searchState.valid.opening ||
    searchState.valid.closing

  const transition: Transition = {
    type: 'spring',
    stiffness: 300,
    damping: 35,
    duration: 0.3,
    delay: 0.3,
  }
  const variants: Variants = {
    initial: {
      x: -100,
      opacity: 0,
      transition,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        delay: 0.6,
      },
    },
  }

  const isFocused = datesState.states === 'focused'
  const isSelected = searchState.context.start && searchState.context.end

  return (
    <AnimatePresence>
      <c.Box
        transition="all 0.2s"
        opacity={isFocused || isSelected ? 1 : 0.5}
        minH="118px"
        maxH="118px"
      >
        {isOpenOrClosing && (
          <motion.div
            variants={variants}
            transition={transition}
            animate={searchState.valid.closing ? 'initial' : 'animate'}
            initial={searchState.valid.closing ? 'animate ' : 'initial'}
            style={{
              width: '100%',
            }}
            exit="initial"
          >
            <c.Flex mb={3} alignItems="baseline" gap={3} transition="all 0.2s">
              <c.Heading
                color="black.500"
                textAlign="start"
                fontWeight={700}
                fontSize={24}
                whiteSpace="nowrap"
              >
                {t('components/search:dates.label')}
              </c.Heading>
            </c.Flex>

            <c.Box pos="relative" transition="all 0.2s">
              <c.Flex pos="relative" zIndex={0}>
                <FieldStart
                  onClick={() => datesState.send(dates_events.focus)}
                />
                <FieldEnd onClick={() => datesState.send(dates_events.focus)} />
              </c.Flex>
              <React.Suspense fallback="">
                <DatePicker />
              </React.Suspense>
            </c.Box>
          </motion.div>
        )}
      </c.Box>
    </AnimatePresence>
  )
}

export default Dates
