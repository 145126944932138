import { Box, Flex } from '@retriever-ui/react'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import RadioInput from '../../../../../components/inputs/radio-themed'
import { Typography } from '../../../../../components/typograph'
import * as S from './styled'

const ModalitiesField = ({ register, getValues, setValue }) => {
  const { t } = useTranslation('search-filter-form')
  const beforeState = useRef(null)

  const handleUnCheck = (e) => {
    const { value } = e.target

    if (value === beforeState.current) {
      setValue('modalitie', null)
      beforeState.current = null
    } else beforeState.current = value
  }

  React.useEffect(() => {
    beforeState.current = getValues().modalitie
  }, [])

  return (
    <S.ContentField>
      <Box>
        <Typography title variant="extraSmall" weight="bold">
          {t('search-filter-form:MODALITIES')}
        </Typography>
      </Box>
      <Flex mTop="24px" direction="column" gap="16px">
        <Flex>
          <RadioInput
            labelRadio={t('search-filter-form:ALL')}
            index="1"
            value="ALL"
            name="modalitie"
            ref={register}
            onClick={handleUnCheck}
          />
        </Flex>
        <Flex>
          <RadioInput
            labelRadio={t('search-filter-form:FULL_EXPERIENCE')}
            index="2"
            value="FLAGSHIP"
            name="modalitie"
            ref={register}
            onClick={handleUnCheck}
          />
        </Flex>
        <Flex>
          <RadioInput
            labelRadio={t('search-filter-form:HOUSI_G')}
            index="3"
            value="HOUSIGESTAO"
            name="modalitie"
            ref={register}
            onClick={handleUnCheck}
          />
        </Flex>
        <Flex>
          <RadioInput
            labelRadio={t('search-filter-form:MARKETPLACE')}
            index="4"
            value="MARKETPLACE"
            name="modalitie"
            ref={register}
            onClick={handleUnCheck}
          />
        </Flex>
      </Flex>
    </S.ContentField>
  )
}

export default ModalitiesField
