import React from 'react'

const PasswordEyeShut = ({ color = '#A3A3A3' }) => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 18C17.8686 18.0003 17.7385 17.9746 17.6172 17.9243C17.4958 17.874 17.3856 17.8001 17.293 17.707L11.663 12.077C11.1532 12.3559 10.5811 12.5014 10 12.5C9.07174 12.5 8.1815 12.1313 7.52512 11.4749C6.86875 10.8185 6.5 9.92826 6.5 9.00001C6.49842 8.41937 6.64431 7.84785 6.924 7.33901L1.292 1.70601C1.11434 1.51656 1.01736 1.26542 1.02158 1.00574C1.0258 0.746054 1.13088 0.498199 1.31459 0.314616C1.4983 0.131033 1.74623 0.0261265 2.00592 0.0220934C2.26561 0.0180603 2.51667 0.115217 2.706 0.293007L18.706 16.293C18.8457 16.4328 18.9409 16.6109 18.9795 16.8047C19.0181 16.9986 18.9984 17.1995 18.9229 17.3822C18.8473 17.5649 18.7193 17.721 18.5551 17.831C18.3908 17.941 18.1977 17.9998 18 18ZM8.512 8.92501C8.51099 8.93682 8.50899 8.94853 8.506 8.96001C8.50236 8.97304 8.50035 8.98648 8.5 9.00001C8.5 9.39783 8.65803 9.77936 8.93934 10.0607C9.22064 10.342 9.60217 10.5 10 10.5C10.0131 10.4997 10.0262 10.4981 10.039 10.495C10.0508 10.4923 10.0629 10.4906 10.075 10.49L8.512 8.92501ZM10.025 16C4.153 16 0.754998 10.583 0.132998 9.50001C0.0452297 9.34799 -0.000976562 9.17554 -0.000976562 9.00001C-0.000976563 8.82447 0.0452297 8.65203 0.132998 8.50001C0.917196 7.15155 1.89873 5.92801 3.045 4.87001L4.456 6.28501C3.5783 7.08508 2.81061 7.99804 2.173 9.00001C3.161 10.5 5.906 14 10.048 14H10.22C10.7946 13.9839 11.3653 13.8996 11.92 13.749L13.5 15.329C12.4723 15.741 11.3798 15.9678 10.273 15.999L10.025 16ZM16.955 13.127L15.544 11.716C16.4223 10.9164 17.1898 10.003 17.826 9.00001C16.839 7.50001 14.1 4.00001 9.967 4.00001H9.781C9.2064 4.01617 8.63575 4.10043 8.081 4.25101L6.5 2.67401C7.52773 2.25809 8.6217 2.02982 9.73 2.00001C9.83 2.00001 9.93 2.00001 10.024 2.00001C12.5883 2.04446 15.0403 3.06013 16.885 4.84201C18.0572 5.90482 19.0628 7.13796 19.868 8.50001C19.9558 8.65203 20.002 8.82447 20.002 9.00001C20.002 9.17554 19.9558 9.34799 19.868 9.50001C19.0825 10.847 18.1011 12.0696 16.956 13.128L16.955 13.127Z"
        fill={color}
      />
    </svg>
  )
}

export default PasswordEyeShut
