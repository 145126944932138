import React from 'react'

const SafetyLocker = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.31607 18.5587 0 17.7956 0 17L0 9C0 8.20435 0.31607 7.44129 0.87868 6.87868C1.44129 6.31607 2.20435 6 3 6H4V4.111C3.98691 3.03589 4.4006 1.99944 5.1504 1.22884C5.9002 0.45823 6.92493 0.0163313 8 0C9.07515 0.0160686 10.1 0.457884 10.8499 1.22855C11.5998 1.99921 12.0134 3.03581 12 4.111V6H13C13.7956 6 14.5587 6.31607 15.1213 6.87868C15.6839 7.44129 16 8.20435 16 9V17C16 17.7956 15.6839 18.5587 15.1213 19.1213C14.5587 19.6839 13.7956 20 13 20ZM3 8C2.73478 8 2.48043 8.10536 2.29289 8.29289C2.10536 8.48043 2 8.73478 2 9V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H13C13.2652 18 13.5196 17.8946 13.7071 17.7071C13.8946 17.5196 14 17.2652 14 17V9C14 8.73478 13.8946 8.48043 13.7071 8.29289C13.5196 8.10536 13.2652 8 13 8H3ZM8 2C7.45518 2.0157 6.93879 2.24668 6.56392 2.64235C6.18906 3.03802 5.98628 3.56613 6 4.111V6H10V4.111C10.0137 3.56613 9.81094 3.03802 9.43608 2.64235C9.06121 2.24668 8.54482 2.0157 8 2ZM8 16C7.40666 16 6.82664 15.8241 6.33329 15.4944C5.83994 15.1648 5.45542 14.6962 5.22836 14.1481C5.0013 13.5999 4.94189 12.9967 5.05764 12.4147C5.1734 11.8328 5.45912 11.2982 5.87868 10.8787C6.29824 10.4591 6.83279 10.1734 7.41473 10.0576C7.99667 9.94189 8.59987 10.0013 9.14805 10.2284C9.69623 10.4554 10.1648 10.8399 10.4944 11.3333C10.8241 11.8266 11 12.4067 11 13C11 13.7956 10.6839 14.5587 10.1213 15.1213C9.55871 15.6839 8.79565 16 8 16ZM8 12C7.80222 12 7.60888 12.0586 7.44443 12.1685C7.27998 12.2784 7.15181 12.4346 7.07612 12.6173C7.00043 12.8 6.98063 13.0011 7.01921 13.1951C7.0578 13.3891 7.15304 13.5673 7.29289 13.7071C7.43275 13.847 7.61093 13.9422 7.80491 13.9808C7.99889 14.0194 8.19996 13.9996 8.38268 13.9239C8.56541 13.8482 8.72159 13.72 8.83147 13.5556C8.94135 13.3911 9 13.1978 9 13C9 12.7348 8.89464 12.4804 8.70711 12.2929C8.51957 12.1054 8.26522 12 8 12Z"
        fill="#6C6C6C"
      />
    </svg>
  )
}

export default SafetyLocker
