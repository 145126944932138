import React from 'react'

const GrowthIcon = ({ color = '#474747' }) => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9166 1.41669C12.5 0.916687 11.8333 0.666687 11.1666 0.666687H2.83331C2.16665 0.666687 1.49998 0.916687 1.08331 1.41669C0.583313 1.83335 0.333313 2.50002 0.333313 3.16669V14.8334C0.333313 15.5 0.583313 16.1667 1.08331 16.5834C1.49998 17.0834 2.16665 17.3334 2.83331 17.3334H11.1666C11.8333 17.3334 12.5 17.0834 12.9166 16.5834C13.4166 16.0834 13.6666 15.5 13.6666 14.8334V3.16669C13.6666 2.50002 13.4166 1.83335 12.9166 1.41669ZM12 14.8334C12 15.0834 11.9166 15.25 11.75 15.4167C11.5833 15.5834 11.4166 15.6667 11.1666 15.6667H2.83331C2.58331 15.6667 2.41665 15.5834 2.24998 15.4167C2.08331 15.25 1.99998 15.0834 1.99998 14.8334V3.16669C1.99998 2.91669 2.08331 2.75002 2.24998 2.58335C2.41665 2.41669 2.58331 2.33335 2.83331 2.33335H11.1666C11.4166 2.33335 11.5833 2.41669 11.75 2.58335C11.9166 2.75002 12 2.91669 12 3.16669V14.8334Z"
        fill={color}
      />
      <path
        d="M8.25 15.6666C8.25 15.9999 8.08333 16.3333 7.91667 16.5833C7.66667 16.8333 7.33333 16.9166 7 16.9166C6.75 16.9166 6.5 16.8333 6.33333 16.7499C6.16667 16.5833 6 16.4166 5.83333 16.1666C5.75 15.9166 5.75 15.6666 5.75 15.4166C5.83333 15.1666 5.91667 14.9166 6.08333 14.7499C6.25 14.5833 6.5 14.4999 6.75 14.4166C7 14.3333 7.25 14.4166 7.5 14.4999C7.75 14.5833 7.91667 14.7499 8.08333 14.9166C8.16667 15.1666 8.25 15.4166 8.25 15.6666Z"
        fill={color}
      />
      <path
        d="M6.50002 4.74996C6.50002 4.99996 6.41669 5.16663 6.25002 5.33329C6.08335 5.49996 5.83335 5.58329 5.66669 5.58329H4.00002C3.75002 5.58329 3.58335 5.49996 3.41669 5.33329C3.25002 5.24996 3.16669 4.99996 3.16669 4.74996C3.16669 4.49996 3.25002 4.33329 3.41669 4.16663C3.58335 3.99996 3.75002 3.91663 4.00002 3.91663H5.66669C5.91669 3.91663 6.08335 3.99996 6.25002 4.16663C6.41669 4.33329 6.50002 4.58329 6.50002 4.74996Z"
        fill={color}
      />
      <path
        d="M10.8333 4.74996C10.8333 4.99996 10.7499 5.16663 10.5833 5.33329C10.4166 5.49996 10.1666 5.58329 9.99994 5.58329H8.33327C8.08327 5.58329 7.91661 5.49996 7.74994 5.33329C7.58327 5.24996 7.49994 4.99996 7.49994 4.74996C7.49994 4.49996 7.58327 4.33329 7.74994 4.16663C7.91661 3.99996 8.08327 3.91663 8.33327 3.91663H9.99994C10.2499 3.91663 10.4166 3.99996 10.5833 4.16663C10.7499 4.33329 10.8333 4.58329 10.8333 4.74996Z"
        fill={color}
      />
      <path
        d="M6.50002 7.83346C6.50002 8.08346 6.41669 8.25012 6.25002 8.41679C6.08335 8.58346 5.83335 8.66679 5.66669 8.66679H4.00002C3.75002 8.66679 3.58335 8.58346 3.41669 8.41679C3.25002 8.25012 3.16669 8.08346 3.16669 7.83346C3.16669 7.58346 3.25002 7.41679 3.41669 7.25012C3.58335 7.08346 3.75002 7.00012 4.00002 7.00012H5.66669C5.91669 7.00012 6.08335 7.08346 6.25002 7.25012C6.41669 7.41679 6.50002 7.66679 6.50002 7.83346Z"
        fill={color}
      />
      <path
        d="M10.8333 7.83346C10.8333 8.08346 10.7499 8.25012 10.5833 8.41679C10.4166 8.58346 10.1666 8.66679 9.99994 8.66679H8.33327C8.08327 8.66679 7.91661 8.58346 7.74994 8.41679C7.58327 8.25012 7.49994 8.08346 7.49994 7.83346C7.49994 7.58346 7.58327 7.41679 7.74994 7.25012C7.91661 7.08346 8.08327 7.00012 8.33327 7.00012H9.99994C10.2499 7.00012 10.4166 7.08346 10.5833 7.25012C10.7499 7.41679 10.8333 7.66679 10.8333 7.83346Z"
        fill={color}
      />
      <path
        d="M6.50002 10.9165C6.50002 11.1665 6.41669 11.3332 6.25002 11.4999C6.08335 11.6665 5.83335 11.7499 5.66669 11.7499H4.00002C3.75002 11.7499 3.58335 11.6665 3.41669 11.4999C3.25002 11.3332 3.16669 11.1665 3.16669 10.9165C3.16669 10.6665 3.25002 10.4999 3.41669 10.3332C3.58335 10.1665 3.75002 10.0832 4.00002 10.0832H5.66669C5.91669 10.0832 6.08335 10.1665 6.25002 10.3332C6.41669 10.4999 6.50002 10.6665 6.50002 10.9165Z"
        fill={color}
      />
      <path
        d="M10.9166 10.9165C10.9166 11.1665 10.8333 11.3332 10.6666 11.4999C10.4999 11.6665 10.2499 11.7499 10.0833 11.7499H8.41659C8.16659 11.7499 7.99992 11.6665 7.83325 11.4999C7.66659 11.3332 7.58325 11.1665 7.58325 10.9165C7.58325 10.6665 7.66659 10.4999 7.83325 10.3332C7.99992 10.1665 8.16659 10.0832 8.41659 10.0832H10.0833C10.3333 10.0832 10.4999 10.1665 10.6666 10.3332C10.7499 10.4999 10.9166 10.6665 10.9166 10.9165Z"
        fill={color}
      />
    </svg>
  )
}

export default GrowthIcon
