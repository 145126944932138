import { Container, useMediaQuery } from '@chakra-ui/react'
import { Flex, retriever } from '@retriever-ui/react'
import { useTranslation } from 'react-i18next'
import { SearchIcon } from 'src/assets/icons/search.icon'

import ShadowSkeleton from '../skeleton'
import Label from './autocomplete/label'

export const GoogleMapsWrapperLoading = () => {
  const [isMobile] = useMediaQuery(['(max-width: 768px)'])
  const { t } = useTranslation('components/search')

  if (isMobile) return null

  return (
    <retriever.button
      radius={32}
      border="none"
      pLeft={16}
      pY={8}
      pRight={8}
      bg="black.0"
      shadow="0px 4px 16px -5px black.800/2"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        pos="relative"
        zIndex={1}
        gap={40}
      >
        <div>
          <Label label={t('components/search:autocomplete.label')} />
          <retriever.p m="0px" fontSize={12} fontWeight={400} color="black.300">
            {t('components/search:autocomplete.placeholder')}
          </retriever.p>
        </div>
        <retriever.button
          border="none"
          color="black.0"
          bg="primary.500"
          fontSize={12}
          fontWeight="bold"
          cursor="pointer"
          radius={20}
          p={12}
          d="flex"
          alignItems="center"
          gap={8}
        >
          <SearchIcon color="white" />
          {t('components/search:submit')}
        </retriever.button>
      </Flex>
    </retriever.button>
  )
}

export const ShadowHighlightCard = () => (
  <Flex h={130} alignItems="center" gap={16}>
    <ShadowSkeleton
      isLoading
      style={{
        height: 130,
        width: 160,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      }}
    >
      <div />
    </ShadowSkeleton>

    <Flex gap={40} pY={24} direction="column">
      <Flex gap={8} direction="column">
        <ShadowSkeleton isLoading style={{ height: 20, width: 120 }}>
          <div />
        </ShadowSkeleton>
        <ShadowSkeleton isLoading style={{ height: 16, width: 180 }}>
          <div />
        </ShadowSkeleton>
      </Flex>
      <ShadowSkeleton isLoading style={{ height: 20, width: 80 }}>
        <div />
      </ShadowSkeleton>
    </Flex>
  </Flex>
)

export const ShadowThematic = () => (
  <Flex maxW={300} h={252} alignItems="center" direction="column">
    <ShadowSkeleton isLoading w="100%" h={200} radius={8} />
    <Flex pTop={20} h={52} gap={10} direction="column">
      <Flex gap={5} direction="column">
        <ShadowSkeleton isLoading h={20} w="100%" maxW={260} />
        <ShadowSkeleton isLoading h={20} w={110} />
      </Flex>
    </Flex>
  </Flex>
)

export const ShadowNews = () => (
  <Flex direction="column" maxH={390} maxW={256} gap="5px">
    <ShadowSkeleton
      isLoading
      style={{
        minHeight: 150,
        maxHeight: 150,
        width: 256,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
      }}
    />
    <Flex pTop="8px" h={180} gap={10} direction="column">
      <Flex gap={5} direction="column">
        <ShadowSkeleton isLoading style={{ height: 20, width: 230 }} />

        <ShadowSkeleton isLoading style={{ height: 50, width: 220 }} />
      </Flex>
    </Flex>
  </Flex>
)

export const ShadowFullBanner = () => (
  <Container>
    <ShadowSkeleton
      isLoading
      style={{
        minHeight: '400px',
        maxHeight: '400px',
        width: '100%',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
      }}
    />
  </Container>
)
