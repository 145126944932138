import React from 'react'

export const UserIcon = ({
  color = 'black',
  width = 14,
  height = 18,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 18C12.7348 18 12.4804 17.8946 12.2929 17.7071C12.1054 17.5196 12 17.2652 12 17C12 15.6739 11.4732 14.4022 10.5355 13.4645C9.59785 12.5268 8.32608 12 7 12C5.67392 12 4.40215 12.5268 3.46447 13.4645C2.52678 14.4022 2 15.6739 2 17C2 17.2652 1.89464 17.5196 1.70711 17.7071C1.51957 17.8946 1.26522 18 1 18C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17C0 15.1435 0.737498 13.363 2.05025 12.0503C3.36301 10.7375 5.14348 10 7 10C8.85652 10 10.637 10.7375 11.9497 12.0503C13.2625 13.363 14 15.1435 14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18ZM7 8C6.20887 8 5.43552 7.76541 4.77772 7.32588C4.11992 6.88635 3.60723 6.26164 3.30448 5.53074C3.00173 4.79983 2.92252 3.99556 3.07686 3.21964C3.2312 2.44372 3.61216 1.73098 4.17157 1.17157C4.73098 0.612165 5.44371 0.231202 6.21964 0.0768607C6.99556 -0.0774802 7.79983 0.00173312 8.53073 0.304484C9.26164 0.607234 9.88635 1.11992 10.3259 1.77772C10.7654 2.43552 11 3.20888 11 4C10.9987 5.06046 10.5768 6.07711 9.82697 6.82697C9.07711 7.57683 8.06046 7.99868 7 8ZM7 2C6.60444 2 6.21776 2.1173 5.88886 2.33706C5.55996 2.55683 5.30362 2.86918 5.15224 3.23464C5.00087 3.60009 4.96126 4.00222 5.03843 4.39018C5.1156 4.77814 5.30608 5.13451 5.58579 5.41422C5.86549 5.69392 6.22186 5.8844 6.60982 5.96157C6.99778 6.03874 7.39991 5.99914 7.76537 5.84776C8.13082 5.69639 8.44318 5.44004 8.66294 5.11114C8.8827 4.78224 9 4.39556 9 4C9 3.46957 8.78929 2.96086 8.41421 2.58579C8.03914 2.21072 7.53043 2 7 2Z"
      fill={`var(--${color}-color)`}
    />
  </svg>
)
