import * as c from '@chakra-ui/react'
import i18next from 'i18next'
import { ArrowDownIcon } from 'src/components/navbar/icons/arrow-down.icon'
import acceptLanguages from 'src/utils/accept-languages'

const LanguageItem = ({ label, code, icon }) => {
  return (
    <c.MenuItem
      onClick={() => {
        localStorage.setItem('language', code)
        i18next.changeLanguage(code)
      }}
      _hover={{ bg: 'primary.50' }}
    >
      {icon}
      <c.Text fontSize="t-md.0">{label}</c.Text>
    </c.MenuItem>
  )
}

const Language = () => {
  return (
    <c.Menu>
      <c.MenuButton
        bg="black.0"
        _hover={{ bg: 'transparent' }}
        _expanded={{ bg: 'black.0' }}
        transition="none"
        width={['100%', null, '101px']}
        height={['50px', '100%']}
        borderRadius="8px"
      >
        <c.Flex
          alignItems="center"
          justify={['start', 'space-between']}
          gap={['s2', '0px']}
        >
          {
            acceptLanguages.filter((l) => l.CODE === i18next.language)?.[0]
              ?.ICON
          }
          <c.Text fontSize="t-sm.1" textTransform="capitalize">
            {
              acceptLanguages.filter((l) => l.CODE === i18next.language)?.[0]
                ?.LABEL
            }
          </c.Text>
          <c.Icon
            as={ArrowDownIcon}
            color="black.300"
            w="1.25rem"
            h="1.25rem"
            ml={['auto', '0px']}
          />
        </c.Flex>
      </c.MenuButton>
      <c.MenuList>
        {acceptLanguages.map(({ LABEL, CODE, ICON }) => {
          return (
            <LanguageItem key={LABEL} label={LABEL} code={CODE} icon={ICON} />
          )
        })}
      </c.MenuList>
    </c.Menu>
  )
}

export default Language
