import * as c from '@chakra-ui/react'
import React from 'react'
import { FixedSizeList as List } from 'react-window'
import dateUtils from 'src/utils/date'

import Dates from '../atomic/dates'
import DateProvider from '../atomic/dates/date-context'
import HeaderDisplay from '../atomic/month'
import { PickerContext } from '../picker-context'
import { picker_events } from '../picker-machine'
import useInfiniteDates from './use-infinite-dates'

const Calendar = ({
  date,
  dates,
  isLast,
  scrollToItem,
}: {
  date: Date
  dates: Date[]
  isLast?: boolean
  scrollToItem: (item: number) => void
}) => {
  const { context, valid, send } = React.useContext(PickerContext)

  const getIndex = () => {
    if (context.selectedDate) {
      return dates.findIndex(
        (x) => x.valueOf() === context.selectedDate!.valueOf()
      )
    }
    return 0
  }

  React.useEffect(() => {
    if (valid.dates && !!context.selectedDate) {
      scrollToItem(getIndex())
      return () => send(picker_events.clear_selected)
    }
  }, [])

  return (
    <c.Flex
      w="100%"
      direction="column"
      align="center"
      justify="center"
      gap={2}
      mb={isLast ? '60px' : 0}
    >
      <HeaderDisplay date={date} />
      <c.Flex minW="100%" justify="center">
        <Dates date={date} />
      </c.Flex>
    </c.Flex>
  )
}

const CalendarList = ({ dates }: { dates: Date[] }) => {
  const { loadMore } = useInfiniteDates()
  const ref = React.useRef<any>(null)
  const { valid } = React.useContext(PickerContext)

  const scrollToItem = (item: number) =>
    ref.current?.scrollToItem(item, 'center')

  React.useEffect(() => {
    if (valid.month) {
      scrollToItem(0)
    }
  }, [valid.month])

  return (
    <DateProvider>
      <List
        className="List"
        height={400}
        width="100%"
        itemSize={valid.month ? 362 : 212}
        ref={ref}
        itemCount={dates.length}
      >
        {({ style, index }) => {
          const isLast = index === dates.length - 1

          return (
            <div
              id={
                valid.dates
                  ? `calendar-dates-${dateUtils.format(
                      dates[index],
                      'yyyy-MM'
                    )}`
                  : `calendar-months-${dateUtils.format(
                      dates[index],
                      'yyyy-MM'
                    )}`
              }
              style={{
                ...style,
                width: '100%',
              }}
            >
              <Calendar
                isLast={isLast}
                date={dates[index]}
                dates={dates}
                scrollToItem={scrollToItem}
              />

              {isLast && valid.dates && (
                <c.Flex align="center" justify="center">
                  <c.Button
                    onClick={loadMore}
                    color="black.500"
                    fontSize={12}
                    fontWeight={500}
                    mb="180px"
                    background="transparent"
                    border="none"
                  >
                    Carregar mais
                  </c.Button>
                </c.Flex>
              )}
            </div>
          )
        }}
      </List>
    </DateProvider>
  )
}

const DatePickerMobile = () => {
  const pickerState = React.useContext(PickerContext)

  const { valid } = React.useContext(PickerContext)

  const { dates, months } = useInfiniteDates()

  React.useEffect(() => {
    pickerState.send(picker_events.to_dates)
  }, [])

  return (
    <c.Flex
      direction="column"
      align="center"
      minH="288px"
      maxH="500px"
      zIndex={0}
      overflow="hidden"
      pos="relative"
    >
      <c.Flex
        w="100%"
        h="100%"
        direction="column"
        align="center"
        gap={10}
        maxH="400px"
        overflowY="auto"
      >
        {valid.dates ? (
          <CalendarList dates={dates} />
        ) : (
          <CalendarList dates={months} />
        )}
      </c.Flex>
    </c.Flex>
  )
}

export default DatePickerMobile
