export const BlogIcon = (props: any) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.82781 2.52458C7.07793 2.49271 7.33047 2.50115 7.58204 2.50068C10.0339 2.50208 12.4858 2.49974 14.9376 2.50208C15.6996 2.50396 16.4514 2.85455 16.927 3.43199C17.3024 3.88007 17.508 4.45517 17.4998 5.03215V14.9687C17.5075 15.5344 17.3106 16.0987 16.9487 16.5431C16.4756 17.1369 15.7112 17.4992 14.9366 17.4987C11.6619 17.5006 8.38707 17.4992 5.11277 17.4997C4.13116 17.5189 3.17132 16.9429 2.75768 16.0795C2.58351 15.7355 2.50272 15.3516 2.50224 14.9696C2.50127 11.751 2.50417 13.5574 2.50079 10.3388C2.50514 10.1162 2.46644 9.85277 2.64399 9.67841C2.83944 9.45906 3.22599 9.46515 3.41805 9.68544C3.58883 9.86027 3.55061 10.119 3.55545 10.3383C3.55255 13.5565 3.554 11.7496 3.55497 14.9673C3.54094 15.4135 3.74461 15.8559 4.0973 16.1423C4.40015 16.4015 4.81089 16.5112 5.20905 16.5098C8.40401 16.5093 11.5985 16.5088 14.793 16.5098C15.1911 16.5117 15.6023 16.4034 15.9047 16.1433C16.2588 15.8573 16.4601 15.4135 16.4475 14.9677C16.4504 11.781 16.4475 8.59383 16.449 5.40711C16.448 5.14792 16.4606 4.88498 16.4035 4.63C16.2864 4.17489 15.9328 3.78305 15.4828 3.61057C15.2642 3.5248 15.0271 3.49386 14.7925 3.49386C12.2753 3.49527 9.75765 3.49386 7.24049 3.49386C6.91344 3.49574 6.58011 3.56885 6.30774 3.74977C5.86894 4.0235 5.60334 4.52548 5.61156 5.02887C5.61301 8.32714 5.61108 11.6254 5.61253 14.9232C5.63672 15.1937 5.39821 15.4557 5.11471 15.4486C4.85249 15.4716 4.61399 15.2616 4.58593 15.0132C4.56561 14.8117 4.58012 14.6082 4.57722 14.4058C4.57722 11.2969 4.57819 8.1884 4.57673 5.07949C4.57673 4.6914 4.65366 4.3005 4.82347 3.94757C5.18534 3.18639 5.9657 2.62348 6.82781 2.52458Z"
      fill="currentColor"
    />
    <path
      d="M6.96947 11.3346C7.13976 11.307 7.31345 11.3215 7.48519 11.3191C9.98542 11.322 12.4861 11.3173 14.9859 11.3215C15.3303 11.3145 15.5592 11.6969 15.4368 11.9955C15.3618 12.1914 15.1509 12.3184 14.9375 12.3137C12.3889 12.317 9.84029 12.3109 7.29168 12.317C7.06865 12.3306 6.80837 12.2945 6.6821 12.093C6.48181 11.83 6.64098 11.4077 6.96947 11.3346Z"
      fill="currentColor"
    />
    <path
      d="M6.96947 13.7526C7.13976 13.7249 7.31345 13.7395 7.48519 13.7371C9.98542 13.7399 12.4861 13.7352 14.9859 13.7395C15.3303 13.7324 15.5592 14.1149 15.4368 14.4135C15.3618 14.6094 15.1509 14.7364 14.9375 14.7317C12.3889 14.735 9.84029 14.7289 7.29168 14.735C7.06865 14.7486 6.80837 14.7125 6.6821 14.5109C6.48181 14.248 6.64098 13.8257 6.96947 13.7526Z"
      fill="currentColor"
    />
    <path
      d="M7.44897 5.59196C8.19981 5.56055 8.95308 5.58727 9.70489 5.57837C10.2361 5.56759 10.7315 6.02129 10.7257 6.54108C10.731 7.38569 10.7281 8.23076 10.7271 9.07584C10.7417 9.49064 10.449 9.88904 10.044 10.0189C9.77746 10.1014 9.49396 10.0751 9.21868 10.0784C8.67006 10.0723 8.12144 10.0887 7.57331 10.0718C7.05904 10.0615 6.60282 9.62188 6.59701 9.12271C6.59073 8.34091 6.59895 7.55911 6.59363 6.77731C6.5946 6.60202 6.58637 6.4211 6.64878 6.2533C6.76828 5.91536 7.08564 5.65195 7.44897 5.59196ZM7.62701 6.58514C7.62701 7.41709 7.62701 8.24857 7.62652 9.08052C8.31834 9.08381 9.01016 9.08287 9.70199 9.08052C9.70247 8.24857 9.70247 7.41616 9.70247 6.58421C9.01065 6.5828 8.31883 6.58139 7.62701 6.58421V6.58514Z"
      fill="currentColor"
    />
    <path
      d="M12.1015 6.15469C12.1673 6.14203 12.2346 6.14156 12.3013 6.14062C13.1968 6.14531 14.0923 6.14062 14.9883 6.14203C15.178 6.13734 15.3618 6.25921 15.4363 6.44669C15.5021 6.63323 15.474 6.86759 15.3294 7.00492C15.2307 7.11647 15.0836 7.1474 14.9457 7.14272C14.0202 7.13803 13.0952 7.14459 12.1697 7.13943C11.9235 7.14459 11.7164 6.89711 11.7193 6.6412C11.7169 6.41013 11.8887 6.19359 12.1015 6.15469Z"
      fill="currentColor"
    />
    <path
      d="M12.1015 8.52773C12.1673 8.51508 12.2345 8.51461 12.3013 8.51367C13.1968 8.51836 14.0923 8.51367 14.9882 8.51508C15.1779 8.51039 15.3617 8.63225 15.4362 8.81974C15.502 9.00628 15.474 9.24063 15.3293 9.37796C15.2306 9.48951 15.0836 9.52045 14.9457 9.51576C14.0202 9.51107 13.0952 9.51764 12.1697 9.51248C11.9234 9.51764 11.7164 9.27016 11.7193 9.01425C11.7168 8.78318 11.8886 8.56664 12.1015 8.52773Z"
      fill="currentColor"
    />
  </svg>
)
