import * as dateFns from 'date-fns'
import qs from 'qs'
import dateUtils from 'src/utils/date'
import { assign, createMachine } from 'xstate'

export const enum search_states {
  closed = 'closed',
  open = 'open',
  opening = 'opening',
  closing = 'closing',
}

export const enum search_events {
  close = 'close',
  open = 'open',
  set_coords = 'set_coords',
  set_month = 'set_month',
  set_date_start = 'set_date_start',
  set_date_end = 'set_date_end',
  set_subscription = 'set_subscription',
  clear = 'clear',
}

export interface ContextType {
  searchTerm: {
    value: string
    label: string
  }
  zoom: number
  coords?: {
    lat: number
    long: number
  }
  start?: Date
  end?: Date
  dailyCount?: number
  bookingType?: 'subscripton' | 'night'
}

export interface EventsType {
  type: search_events
}

export interface StatesType {
  value: search_states
  context: ContextType
}

const params = qs.parse(window.location.search.replace('?', '')) as any

const decodedCoords =
  params?.position && Buffer.from(params?.position, 'base64').toString('utf-8')

const coordinates = decodedCoords && JSON.parse(decodedCoords as any)

const start = params?.from ? dateUtils.create(params?.from) : undefined
const end = params?.to ? dateUtils.create(params?.to) : undefined

const days =
  end && start
    ? dateFns.differenceInDays(dateUtils.create(end), dateUtils.create(start))
    : undefined

const context: ContextType = {
  searchTerm: {
    label: params?.searchTerm ?? '',
    value: params?.searchTerm ?? '',
  },
  zoom: Number(params?.zoom) ?? 14,
  coords: {
    lat: (coordinates && coordinates?.[0]) ?? undefined,
    long: (coordinates && coordinates?.[1]) ?? undefined,
  },
  start: params?.from ? dateUtils.create(params?.from) : undefined,
  end: params?.to ? dateUtils.create(params?.to) : undefined,
  dailyCount: days,
  bookingType: days ? (days >= 30 ? 'subscripton' : 'night') : undefined,
}

const searchMachine = createMachine<ContextType, EventsType, StatesType>(
  {
    id: 'search-machine',
    initial: search_states.closed,
    context,
    states: {
      [search_states.closed]: {
        on: {
          [search_events.open]: {
            target: search_states.opening,
          },
          [search_events.set_date_start]: {
            actions: 'setStart',
          },
          [search_events.set_date_end]: {
            actions: 'setEnd',
          },
        },
        invoke: {
          src: 'closed',
        },
      },
      [search_states.open]: {
        on: {
          [search_events.clear]: { actions: 'clearTerm' },
          [search_events.close]: {
            target: search_states.closing,
          },
          [search_events.set_coords]: {
            actions: 'setTerms',
          },
          [search_events.set_date_start]: {
            actions: 'setStart',
          },
          [search_events.set_date_end]: {
            actions: 'setEnd',
          },
        },

        invoke: {
          src: 'open',
        },
      },
      [search_states.opening]: {
        on: {
          [search_events.close]: {
            target: search_states.closing,
          },
        },
        invoke: {
          src: 'opening',
          onDone: {
            target: search_states.open,
          },
        },
      },
      [search_states.closing]: {
        on: {
          [search_events.open]: {
            target: search_states.opening,
          },
        },
        invoke: {
          src: 'closing',
          onDone: {
            target: search_states.closed,
          },
        },
      },
    },
  },
  {
    actions: {
      setStart: assign((_context, event: any) => ({
        start: event.start,
        bookingType: undefined,
        dailyCount: undefined,
      })),
      setEnd: assign((context, event: any) => {
        const days = dateFns.differenceInDays(
          dateUtils.create(event.end),
          dateUtils.create(context.start!)
        )

        return {
          end: event.end,
          bookingType: days >= 30 ? 'subscripton' : 'night',
          dailyCount: days,
        }
      }),
      setTerms: assign((_, event: any) => ({
        coords: event.coords,
        searchTerm: event.searchTerm,
        zoom: event.zoom,
      })),
      clearTerm: assign({
        zoom: 14,
        coords: undefined,
        searchTerm: {
          label: '',
          value: '',
        },
      }) as any,
    },
  }
)

export default searchMachine
