import { Box, Flex, Text } from '@chakra-ui/react'
import { DineroObject } from 'dinero.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { convertToMoney } from 'src/utils/monetary'
import { __, match } from 'ts-pattern'

import { PropertyCardContext } from '../../property-card.context'

interface FeesPriceProps {
  amount?: DineroObject
  isUnavailable?: boolean
  fee?: string
}

const FeesPrice = ({
  fee,
  amount = { amount: 0, currency: 'BRL', precision: 2 },
  isUnavailable = true,
}: FeesPriceProps) => {
  return (
    <Flex gap="4px">
      <Text m="0" color="black.400" fontSize={12}>
        {fee}
      </Text>
      <Text
        m="0"
        fontWeight={500}
        color={isUnavailable ? 'black.400' : 'black.500'}
        fontSize={12}
      >
        {isUnavailable ? '-' : convertToMoney(amount)}
      </Text>
    </Flex>
  )
}

const regexIptu = /iptu|IPTU/g
const regexCondo = /condo|CONDOMINIO|Condomínio/g

const PriceRent = ({ isCardMap }) => {
  const { t } = useTranslation('card-apartment')
  const { property } = React.useContext(PropertyCardContext)

  const fees = property?.price?.monthly?.fees
    .map((fee: any) => {
      const isUnavailablePrice =
        fee.amount.amount === 0 || fee.amount.amount === 100

      const isIptu =
        regexIptu.test(fee?.name) || regexIptu.test(fee?.name?.pt_BR)
      const isCondo =
        regexCondo.test(fee?.name) || regexCondo.test(fee?.name?.pt_BR)

      const isUnavailableFees = isCondo || isIptu

      return match([isUnavailablePrice, isUnavailableFees])
        .with([false, true], () => ({
          name: typeof fee?.name === 'string' ? fee?.name : fee?.name?.pt_BR,
          amount: fee.amount,
          isUnavailable: false,
        }))
        .with([__], () => ({
          name: typeof fee?.name === 'string' ? fee?.name : fee?.name?.pt_BR,

          amount: { amount: 0, currency: 'BRL', precision: 2 },
          isUnavailable: true,
        }))
        .exhaustive()
    })
    .filter(Boolean)

  const iptuAmount = fees.find((fee) => regexIptu.test(fee.name))
  const condoAmount = fees.find((fee) => regexCondo.test(fee.name))

  return (
    <Box>
      <Flex alignItems="center" gap="8px">
        <Text m="0" color="black.500" fontSize={14} fontWeight={500}>
          {t('card-apartment:RENT')}
        </Text>
        <Text m="0" color="black.500" fontWeight={700} fontSize={14}>
          {convertToMoney(property?.price?.monthly?.netAmount)}
        </Text>
      </Flex>
      <Flex
        direction={isCardMap ? 'column' : 'row'}
        marginTop="4px"
        alignItems={isCardMap ? 'flex-start' : 'center'}
        gap={isCardMap ? '0px' : '12px'}
      >
        <FeesPrice
          fee={t('card-apartment:PACKAGE_ITEMS.0')}
          isUnavailable={condoAmount?.isUnavailable}
          amount={condoAmount?.amount as any}
        />
        {!isCardMap && (
          <Box
            minH="4px"
            minW="4px"
            maxW="4px"
            maxH="4px"
            borderRadius="5px"
            bg="black.400"
          />
        )}
        <FeesPrice
          fee={t('card-apartment:PACKAGE_ITEMS.1')}
          isUnavailable={iptuAmount?.isUnavailable}
          amount={iptuAmount?.amount as any}
        />
      </Flex>
    </Box>
  )
}

export default PriceRent
