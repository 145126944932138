import * as c from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { HomeIcon } from 'src/components/navbar/icons/home.icon'
import { MenuDrawerMobileIcon } from 'src/components/navbar/icons/menu-drawer-mobile.icon'

import NavLink from './nav-link'
import { navbarLinksMobileData } from './nav-link/nav-links.config'

interface NavbarMobileProps {
  onToggle: () => void
  onClose: () => void
  isOpen: boolean
}

const NavbarMobileItems = ({
  onToggle,
  isOpen,
  onClose,
}: NavbarMobileProps) => {
  const location = useLocation()
  const isHome = location.pathname === '/'

  return (
    <c.Flex justifyContent="space-between" width="100%" alignItems="center">
      {isHome ? (
        <c.Flex
          as={c.Button}
          p={0}
          variant="outlineLight"
          onClick={isOpen ? onToggle : onClose}
          direction="column"
          alignItems="center"
          justify="center"
          gap="s1"
        >
          <c.Icon as={HomeIcon} color="primary.500" w="1.5rem" h="1.5rem" />
          <c.Text fontSize="t-sm.0" fontWeight="medium" color="black.400">
            Início
          </c.Text>
        </c.Flex>
      ) : (
        <c.Flex
          as={Link}
          to="/"
          direction="column"
          alignItems="center"
          justify="center"
          gap="s1"
        >
          <c.Icon as={HomeIcon} color="primary.500" w="1.5rem" h="1.5rem" />
          <c.Text fontSize="t-sm.0" fontWeight="medium" color="black.400">
            Início
          </c.Text>
        </c.Flex>
      )}

      {navbarLinksMobileData.map((item) => (
        <NavLink
          key={item.id}
          path={item.path}
          label={item.label}
          Icon={item?.Icon!}
          isExternal={item.isExternal}
        />
      ))}

      <c.Flex
        onClick={onToggle}
        direction="column"
        alignItems="center"
        justify="center"
        gap="s1"
        position="relative"
      >
        <c.Icon
          as={MenuDrawerMobileIcon}
          color="primary.500"
          w="1.5rem"
          h="1.5rem"
        />

        <c.Text
          fontSize="t-sm.0"
          fontWeight={isOpen ? 'bold' : 'medium'}
          color="black.400"
        >
          Menu
        </c.Text>
      </c.Flex>
    </c.Flex>
  )
}

export default NavbarMobileItems
