import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import AuthProvider from 'src/pages/auth/auth-context'
import { RootProvider } from 'src/store/context/root.context'

import Routes from './routes'

export const Main = () => {
  return (
    <BrowserRouter basename="/">
      <AuthProvider>
        <RootProvider>
          <Routes />
        </RootProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}
