import {
  ComponentSingleStyleConfig,
  SystemStyleObjectRecord,
  ThemeComponents,
} from '@chakra-ui/react'

type ButtonType = ThemeComponents['Button']

const baseStyle: ButtonType['baseStyle'] = {
  fontWeight: 'bold',
  borderRadius: 'md',
  px: 2,
  transition: 'all 0.2s ease-in-out',
}

const defaultProps: ButtonType['defaultProps'] = {
  size: 'md',
}

const sizes: SystemStyleObjectRecord = {
  xs: {
    borderRadius: 'lg',
    height: '24px',
    fontWeight: 'semiBold',
    fontSize: '14px',
  },
  sm: {
    borderRadius: 'lg',
    height: '28px',
    fontWeight: 'semiBold',
    fontSize: '14px',
  },
  md: {
    borderRadius: 'lg',
    height: '42px',
    fontSize: '14px',
  },
  lg: {
    borderRadius: '3xl',
    height: '52px',
    fontSize: '14px',
  },
}

const variants = {
  primary: {
    color: 'black.0',
    backgroundColor: 'primary.500',
    _hover: {
      backgroundColor: 'primary.600',
      _disabled: {
        backgroundColor: 'primary.600',
      },
    },
  },
  lightPrimary: {
    color: 'primary.500',
    bg: 'primary.50',
    _hover: {
      bg: 'primary.100',
    },
  },
  secondary: {
    color: 'primary.500',
    backgroundColor: 'black.0',
    border: '1px solid',
    borderColor: 'primary.100',
    _hover: {
      backgroundColor: 'primary.50',
    },
  },
  light: {
    color: 'black.500',
    backgroundColor: 'black.100',
    _hover: {
      backgroundColor: 'black.200',
    },
  },
  outline: {
    color: 'black.500',
    fontSize: 't-md.1',
    backgroundColor: 'black.0',
    borderColor: 'black.200',
    display: 'flex',
    align: 'center',
    gap: 's2',
    _hover: {
      backgroundColor: 'black.100',
    },
  },
  outlineLight: {
    backgroundColor: 'black.0',
    border: 'none',
  },
  successOutline: {
    color: 'green.500',
    border: '1px solid',
    borderColor: 'green.500',
    bg: 'green.50',
    _hover: {
      backgroundColor: 'green.100',
    },
  },
  danger: {
    color: 'black.0',
    bg: 'red.400',
    _hover: {
      _disabled: { bg: 'red.500' },
      bg: 'red.500',
    },
  },
}

export const Button: ComponentSingleStyleConfig = {
  baseStyle,
  defaultProps,
  sizes,
  variants,
}
