import { Drawer, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom'
import ShadowSkeleton from 'src/components/skeleton'
import PropertyInterface from 'src/interfaces/property/property.interface'

import {
  PropertyCardContext,
  PropertyCardProvider,
} from '../property-card.context'
import PropertyPrice from './price'

interface CardSearchProps {
  property: PropertyInterface
  from?: string
  to?: string
  bookingType?: any
}

type ContextTpe = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

const Card = () => {
  const { property } = React.useContext(PropertyCardContext)

  const navigate = useNavigate()
  const location = useLocation()

  const onOpen = () =>
    navigate(
      `/search/properties/preview/${property?.aliasId}${location.search}`
    )

  const onClose = () => navigate(`/search/properties/${location.search}`)

  const isOpen = location.pathname.includes(`/preview/${property?.aliasId}`)

  return (
    <>
      <Flex
        onClick={onOpen}
        bg="black.0"
        minW={310}
        maxW={310}
        h="130px"
        borderRadius="8px"
        border="1px solid"
        gap="20px"
        justify="space-between"
        p={1}
        pl={4}
        borderColor="black.200"
      >
        <Flex
          maxW="fit-content"
          h="100%"
          direction="column"
          justify="space-between"
        >
          <Text noOfLines={2} fontSize="14px" fontWeight="medium">
            {property?.aliasName}
          </Text>

          <PropertyPrice isCardMap={true} />
        </Flex>
        <Image
          w="100%"
          h="100%"
          borderRadius="4px"
          objectFit="cover"
          src={`${process.env.REACT_APP_CDN_IMAGE}/200x200/${property?.mainPhoto}`}
          alt={property?.aliasName}
        />
      </Flex>
      <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
        <Outlet context={{ onClose, isOpen, onOpen }} />
      </Drawer>
    </>
  )
}

export const usePreviewCard = () => useOutletContext<ContextTpe>()

const CardMobileMap = (props: CardSearchProps) => {
  return (
    <PropertyCardProvider {...props}>
      <Card />
    </PropertyCardProvider>
  )
}

export const CardLoading = () => {
  return (
    <Flex
      bg="black.0"
      minW={310}
      maxW={310}
      h="130px"
      borderRadius="8px"
      border="1px solid"
      gap="20px"
      justify="space-between"
      p={2}
      pl={4}
      borderColor="black.200"
    >
      <Flex
        maxW="fit-content"
        h="100%"
        direction="column"
        justify="space-between"
        py="12px"
      >
        <ShadowSkeleton maxW={150} maxH={18} isLoading />

        <Flex gap={2} direction="column">
          <ShadowSkeleton maxW={100} maxH={18} isLoading />
          <ShadowSkeleton maxW={80} maxH={12} isLoading />
        </Flex>
      </Flex>

      <ShadowSkeleton borderRadius="4px" h="100%" isLoading />
    </Flex>
  )
}

export default CardMobileMap
