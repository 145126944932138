import { Flex, Heading } from '@chakra-ui/react'
import { BuildingIcon } from 'src/assets/icons/building.icon'

const Title = ({ label }) => {
  return (
    <Flex maxH="32px" pr={4} align="center" gap={1}>
      <BuildingIcon width={24} height={24} />
      <Heading textTransform="capitalize" size="sm" noOfLines={1}>
        {label}
      </Heading>
    </Flex>
  )
}

export default Title
