import { useMachine } from '@xstate/react'
import { AnimationControls, useAnimation } from 'framer-motion'
import qs from 'qs'
import React from 'react'
import { useLocation } from 'react-router-dom'
import useMessages from 'src/hooks/messages/use-messages'
import { useHistory, useNavigate } from 'src/hooks/use-navigate'
import dateUtils from 'src/utils/date'
import { EventData } from 'xstate'

import DatesProvider from './dates/dates-context'
import FieldsProvider from './dates/fields/fields-context'
import PickerProvider from './dates/picker/picker-context'
import searchMachine, {
  ContextType,
  search_events,
  search_states,
} from './search-machine'

type StateValid = {
  [key in search_states]: boolean
}
type SendEvent = (event: search_events, payload?: EventData | undefined) => void
interface SearchContextProps {
  state: search_states
  send: SendEvent
  context: ContextType
  valid: StateValid
  isSubscription: boolean
  handleSearch: () => void
  handleOpen: () => void
  handleClose: () => void
  controls: {
    open: AnimationControls
    opening: AnimationControls
    closing: AnimationControls
  }
}

interface SearchProviderProps {
  children?: React.ReactNode
}

export const SearchContext = React.createContext({} as SearchContextProps)

const SearchProvider = ({ children }: SearchProviderProps) => {
  const navigate = useNavigate()
  const history = useHistory()
  const location = useLocation()
  const { emit } = useMessages('maps:reload')

  const closed = async () => await openControls.start('closed')
  const opening = async () => {
    await openControls.start('opening')
    await closingControls.start('animate')
  }
  const closing = async () => {
    await openControls.start('closing')
    await closingControls.start('initial')
  }
  const open = async () => {
    await openControls.start('open')
    await openingControls.start('animate')
  }

  const [current, send] = useMachine(searchMachine, {
    services: {
      open,
      closed,
      opening,
      closing,
    },
  })

  const state = current.value as search_states
  const context = current.context

  const valid = {
    closed: current.matches(search_states.closed),
    closing: current.matches(search_states.closing),
    open: current.matches(search_states.open),
    opening: current.matches(search_states.opening),
  }

  const handleSearch = React.useCallback(() => {
    if (!context.start || !context.end) return

    const jsonString = JSON.stringify([
      context.coords?.lat,
      context.coords?.long,
    ])
    const codedCoords = Buffer.from(jsonString).toString('base64')

    const params = {
      searchTerm: context.searchTerm.label,
      position: codedCoords,
      zoom: context.zoom,
    }

    const datesParams = {
      from: dateUtils.format(context.start, 'yyyy-MM-dd'),
      to: dateUtils.format(context.end, 'yyyy-MM-dd'),
    }

    navigate(
      `/search/properties?${qs.stringify({ ...params, ...datesParams })}`
    )
    emit('maps:reload', { category: 'reload', data: true })

    send(search_events.close)
  }, [context, location])

  const handleOpen = React.useCallback(() => {
    send(search_events.open)
    navigate('/search')
  }, [])

  const handleClose = React.useCallback(() => {
    if (valid.closed) {
      return
    }

    history()

    setTimeout(() => {
      send(search_events.close)
    }, 400)
  }, [valid.closed])

  const openControls = useAnimation()
  const openingControls = useAnimation()
  const closingControls = useAnimation()

  return (
    <SearchContext.Provider
      value={{
        state,
        send,
        context,
        valid,
        handleSearch,
        handleOpen,
        handleClose,
        isSubscription: context.bookingType === 'subscripton',
        controls: {
          closing: closingControls,
          open: openControls,
          opening: openingControls,
        },
      }}
    >
      <DatesProvider>
        <PickerProvider>
          <FieldsProvider>{children}</FieldsProvider>
        </PickerProvider>
      </DatesProvider>
    </SearchContext.Provider>
  )
}

export const useSearch = () => React.useContext(SearchContext)

export default SearchProvider
