import { Flex, retriever } from '@retriever-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CLEANING_TAX_REGEX, CONDO_REGEX, IPTU_REGEX } from 'src/constants'
import { __, match } from 'ts-pattern'

import { convertToMoney } from '../../../../utils/monetary'
import { BookingContext } from '../../booking.context'

const FeePrice = ({ price, text, isUnavailable, isModal }) => (
  <Flex justifyContent="space-between">
    <retriever.p
      fontSize={isModal ? 16 : 14}
      color="black.500"
      fontWeight={500}
    >
      {text}
    </retriever.p>
    <retriever.p
      fontSize={isModal ? 16 : 14}
      fontWeight={500}
      color="black.500"
    >
      {isUnavailable ? '-' : convertToMoney(price)}
    </retriever.p>
  </Flex>
)

const Fees = ({ isModal }: { isModal?: boolean }) => {
  const { t } = useTranslation('property-detail/booking-resume')
  const { price } = React.useContext(BookingContext)

  //Create a list with condo and iptu
  const fees = React.useMemo(
    () =>
      (price?.monthly?.fees)
        .map((fee: any) => {
          // to dont show price with R$ 0 or R$ 1
          const isUnavailablePrice =
            fee.amount.amount === 0 || fee.amount.amount === 100

          // get the fee name and manipulate they to a valid string
          // options from payload: name: {pt_BR: 'fee'} || name: 'fee' || name: 'FEE'
          const feeName = Object.values(fee.name).join().toLowerCase()

          // valid with regex to know if is a iptu or condo.
          const isIptu = IPTU_REGEX.test(feeName)
          const isCondo = CONDO_REGEX.test(feeName)
          const isCleaning = CLEANING_TAX_REGEX.test(feeName)

          return (
            // will match the available price if is iptu or condo fee
            match([isUnavailablePrice, isIptu, isCondo, isCleaning])
              // hotfix to fast resolve unavailable cleaning tax
              .with([false, false, false, true], () => ({
                name: 'CLEANING',
                amount: fee.amount,
                isUnavailable: false,
              }))
              .with([true, false, false, true], () => ({
                name: 'CLEANING',
                amount: fee.amount,
                isUnavailable: true,
              }))

              // is available price and is iptu fee
              .with([false, true, false, false], () => ({
                name: 'IPTU',
                amount: fee.amount,
                isUnavailable: false,
              }))
              // is unavailable price and is iptu fee
              .with([true, true, false, false], () => ({
                name: 'IPTU',
                amount: fee.amount,
                isUnavailable: true,
              }))
              // is available price and is condo fee
              .with([false, false, true, false], () => ({
                name: 'CONDO',
                amount: fee.amount,
                isUnavailable: false,
              }))
              // is unavailable price and is condo fee
              .with([true, false, true, false], () => ({
                name: 'CONDO',
                amount: fee.amount,
                isUnavailable: true,
              }))
              // isn't condo and iptu
              .with([__, __, __, __], () => undefined)
              .run()
          )
        })
        // this will remove all undefined values from array.
        .filter(Boolean),
    [price]
  )

  return (
    <>
      {fees.map((fee: any, index: number) => (
        <FeePrice
          key={index}
          isUnavailable={fee.isUnavailable}
          price={fee.amount}
          text={t(`property-detail/booking-resume:${fee.name}`)}
          isModal={isModal}
        />
      ))}
    </>
  )
}

export default Fees
