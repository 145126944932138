import * as c from '@chakra-ui/react'
import Flex from '@retriever-ui/flex'
import { Box } from '@retriever-ui/layout'
import { retriever } from '@retriever-ui/system'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CameraIcon } from 'src/assets/icons/camera.icon'
import { useAuth } from 'src/pages/auth/auth-context'
import { ProfileContext } from 'src/pages/profile-v2/context/ProfileContext'

export const AvatarIcon = () => {
  const { user } = useAuth()
  const avatarUrl = `${process.env.REACT_APP_CDN_IMAGE}/${user?.avatarUrl}`

  const method = user?.method

  const profileImage = useMemo(() => {
    if (!avatarUrl)
      return `${process.env.REACT_APP_CDN_IMAGE}/site/user/profile-housi.png`

    const accept = ['facebook', 'google']

    if (accept.includes(user?.method!)) {
      return avatarUrl
    }

    return `${process.env.REACT_APP_CDN_IMAGE}/200x200/${avatarUrl}`
  }, [avatarUrl, method])
  return (
    <c.Box w="60px" h="60px">
      <Link to="/profile/avatar-edit">
        <c.Avatar
          border="2px solid #fff"
          w="60px"
          h="60px"
          src={profileImage}
        />
        <c.Flex
          alignItems="center"
          justifyContent="center"
          w="28px"
          h="28px"
          borderRadius="50%"
          bg="black.0"
          bottom="30px"
          left="45px"
          position="relative"
          zIndex={1}
        >
          <c.Icon as={CameraIcon} w="1.25rem" h="1.25rem" color="primary.500" />
        </c.Flex>
      </Link>
    </c.Box>
  )
}

const Avatar = () => {
  const { data } = React.useContext(ProfileContext)

  const { t } = useTranslation('profile')

  return (
    <Flex alignItems="center">
      <AvatarIcon />
      <Box mLeft="16px">
        <retriever.p size={16} fontWeight="bold" color="black.500">
          {t('profile:HELLO')}
        </retriever.p>
        <retriever.p
          color="black.500"
          size={16}
          style={{ fontWeight: 500 }}
        >{`${data?.firstName} ${data?.lastName}`}</retriever.p>
      </Box>
    </Flex>
  )
}

export default Avatar
