import { TFunction } from 'i18next'
import React, { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'
import { RequireAuth } from 'src/main/routes/custom'

const BillsPayment = React.lazy(() => import('./bills-detail'))
const BillsList = React.lazy(() => import('./bills-list'))
const NewCardBills = React.lazy(
  () => import('./bills-payment-methods/bills-new-card')
)
const CreditCardBills = React.lazy(
  () => import('./bills-payment-methods/bills-credit-card')
)
const PixBills = React.lazy(() => import('./bills-payment-methods/bills-pix'))

const BoletoBills = React.lazy(
  () => import('./bills-payment-methods/bills-boleto')
)

const SuccessPayment = React.lazy(
  () => import('./bills-payment-methods/payment-responses/payment-success')
)

const FailPayment = React.lazy(
  () => import('./bills-payment-methods/payment-responses/payment-fail')
)

const InvalidPayment = React.lazy(
  () => import('./bills-payment-methods/payment-responses/payment-invalid')
)

const reservationBills = (t: TFunction): RouteObject[] => [
  {
    path: '/profile/reservations/bills/:id',
    element: (
      <RequireAuth
        title={t('titles:profile-reservations.SEO_TITLE')}
        description={t('titles:profile-reservations.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <BillsList />
        </Suspense>
      </RequireAuth>
    ),
  },
  {
    path: '/profile/reservations/bills/:id/payment',
    element: (
      <RequireAuth
        title={t('titles:profile-reservations.SEO_TITLE')}
        description={t('titles:profile-reservations.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <BillsPayment />
        </Suspense>
      </RequireAuth>
    ),
  },
  {
    path: '/profile/reservations/bills/:id/payment/new-card',
    element: (
      <RequireAuth
        title={t('titles:profile-reservations.SEO_TITLE')}
        description={t('titles:profile-reservations.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <NewCardBills />
        </Suspense>
      </RequireAuth>
    ),
  },
  {
    path: '/profile/reservations/bills/:reservationId/payment/credit-card/:cardId',
    element: (
      <RequireAuth
        title={t('titles:profile-reservations.SEO_TITLE')}
        description={t('titles:profile-reservations.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <CreditCardBills />
        </Suspense>
      </RequireAuth>
    ),
  },
  {
    path: '/profile/reservations/bills/:id/payment/pix',
    element: (
      <RequireAuth
        title={t('titles:profile-reservations.SEO_TITLE')}
        description={t('titles:profile-reservations.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <PixBills />
        </Suspense>
      </RequireAuth>
    ),
  },
  {
    path: '/profile/reservations/bills/:id/payment/boleto',
    element: (
      <RequireAuth
        title={t('titles:profile-reservations.SEO_TITLE')}
        description={t('titles:profile-reservations.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <BoletoBills />
        </Suspense>
      </RequireAuth>
    ),
  },
  {
    path: '/profile/reservations/bills/:id/payment/success',
    element: (
      <RequireAuth
        title={t('titles:profile-reservations.SEO_TITLE')}
        description={t('titles:profile-reservations.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <SuccessPayment />
        </Suspense>
      </RequireAuth>
    ),
  },
  {
    path: '/profile/reservations/bills/:id/payment/fail',
    element: (
      <RequireAuth
        title={t('titles:profile-reservations.SEO_TITLE')}
        description={t('titles:profile-reservations.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <FailPayment />
        </Suspense>
      </RequireAuth>
    ),
  },
  {
    path: '/profile/reservations/bills/:id/payment/invalid',
    element: (
      <RequireAuth
        title={t('titles:profile-reservations.SEO_TITLE')}
        description={t('titles:profile-reservations.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <InvalidPayment />
        </Suspense>
      </RequireAuth>
    ),
  },
]

export default reservationBills
