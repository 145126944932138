import { Box, Flex } from '@retriever-ui/react'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import RadioInput from '../../../../../components/inputs/radio-themed'
import { Typography } from '../../../../../components/typograph'
import * as S from './styled'

const GuestsField = ({ register, getValues, setValue }) => {
  const { t } = useTranslation('search-filter-form')
  const beforeState = useRef(null)

  const handleUnCheck = (e) => {
    const { value } = e.target

    if (value == beforeState.current) {
      setValue('guests', null)
      beforeState.current = null
    } else beforeState.current = Number(value) as any
  }

  React.useEffect(() => {
    beforeState.current = getValues().guests
  }, [])

  return (
    <S.ContentField>
      <Box>
        <Typography title variant="extraSmall" weight="bold">
          {t('search-filter-form:RESIDENTS')}
        </Typography>
      </Box>
      <Flex mTop="24px" gap="8px">
        <RadioInput
          asButton
          labelRadio="1"
          index="1"
          value="1"
          name="guests"
          ref={register}
          onClick={handleUnCheck}
        />
        <RadioInput
          asButton
          labelRadio="2"
          index="2"
          value="2"
          name="guests"
          ref={register}
          onClick={handleUnCheck}
        />
        <RadioInput
          asButton
          labelRadio="3+"
          index="3"
          value="3"
          name="guests"
          ref={register}
          onClick={handleUnCheck}
        />
      </Flex>
    </S.ContentField>
  )
}

export default GuestsField
