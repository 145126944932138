import * as c from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { ArrowRightIcon } from 'src/assets/icons/nav/arrow-right.icon'

import { HelpItemsConfig } from './data/help-item.config'

interface ItemHelpInterface {
  label: string
  icon: (props: any) => JSX.Element
  path: string
  lastDivider: boolean
}

const Item = ({ label, icon, path, lastDivider }: ItemHelpInterface) => {
  return (
    <>
      <c.Button
        as={c.Link}
        href={path}
        isExternal
        variant="outline"
        gap="0px"
        px="0px"
        py="s4"
        border="none"
        height="50px"
        fontWeight="medium"
        fontSize="t-md.0"
        alignItems="center"
        leftIcon={
          <c.Icon as={icon} w="1.25rem" h="1.25rem" color="black.400" />
        }
        _hover={{ bg: 'none' }}
      >
        {label}

        <c.Icon
          ml="auto"
          as={ArrowRightIcon}
          color="black.300"
          w="1.25rem"
          h="1.25rem"
        />
      </c.Button>
      {!lastDivider && <c.Divider h="1px" w="full" />}
    </>
  )
}

const HelpSection = ({ isLogged }) => {
  const { t } = useTranslation()
  const { notLoggedHelpItems, loggedHelpItems } = HelpItemsConfig()

  if (!isLogged)
    return (
      <c.Box mb={['s4', '0px']}>
        {notLoggedHelpItems.map((item) => (
          <Item
            key={item.id}
            path={item.path}
            icon={item.Icon}
            label={item.label}
            lastDivider={item.id === 'faq'}
          />
        ))}
      </c.Box>
    )

  return (
    <c.Box>
      <c.Text mt="s6" color="black.400" fontWeight="semiBold" fontSize="t-sm.0">
        {t('components/nav:NEED_HELP')}
      </c.Text>
      {loggedHelpItems.map((item) => (
        <Item
          key={item.id}
          path={item.path}
          icon={item.Icon}
          label={item.label}
          lastDivider={item.id === 'envie-feedback'}
        />
      ))}
    </c.Box>
  )
}

export default HelpSection
