export enum ReservationTypes {
  SUBSCRIPTION = 'subscription',
  SHORT_STAY = 'short_stay',
}

export type ReservationType =
  | ReservationTypes.SUBSCRIPTION
  | ReservationTypes.SHORT_STAY

export enum ReservationStatusTypes {
  RESERVED = 'reserved',
  CANCELED = 'canceled',
  BOOKED = 'booked',
  WAITING_PAYMENT = 'waiting_payment',
  EXPIRED = 'expired',
}

export type ReservationStatusType =
  | ReservationStatusTypes.RESERVED
  | ReservationStatusTypes.CANCELED
  | ReservationStatusTypes.BOOKED
  | ReservationStatusTypes.WAITING_PAYMENT
  | ReservationStatusTypes.EXPIRED

export enum ReservationErrors {
  invalid_card = 'invalid_card',
  card_not_found = 'card_not_found',
  refused = 'refused',
  reservation_not_found = 'reservation_not_found',
  user_document_not_found = 'user_document_not_found',
  boleto_generate = 'boleto_generate',
  ame_generate = 'ame_generate',
  default = 'default',
}

export type ReservationErrorsType = keyof typeof ReservationErrors
