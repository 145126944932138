import React from 'react'

import CarouselV2 from '../../carousel-v2'
import { ImageLoading } from '../../image-loading'
import { ImageGallery } from './styled'

const Carousel = CarouselV2 as any

export const Gallery = React.memo(
  ({
    gallery = [],
    width = '320px',
    height = '280px',
    heightDesktop = '280px',
    widthDesktop = '320px',
    withIndicators = false,
    imageProps = {},
    withArrows = true,
    clickImage = () => {},
    swipeable = true,
  }: any) => {
    return (
      <Carousel
        swipeable={swipeable}
        isInfinite={true}
        customStylesDesktop={{
          minWidth: widthDesktop,
          maxWidth: width,
          minHeight: heightDesktop,
          maxHeight: heightDesktop,
        }}
        customStylesMobile={{
          minWidth: width,
          maxWidth: width,
          minHeight: height,
          maxHeight: height,
        }}
        autoPlay={false}
        withIndicators={withIndicators}
        onClickImage={clickImage}
        withArrows={withArrows}
      >
        {gallery.map((g) => (
          <ImageLoading
            {...imageProps}
            src={g.src}
            alt={g.alt}
            key={g.alt}
            Component={ImageGallery}
          />
        ))}
      </Carousel>
    )
  }
)
