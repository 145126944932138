import { ComponentStyleConfig } from '@chakra-ui/react'

export const Radio: ComponentStyleConfig = {
  parts: ['container', 'control', 'label'],
  baseStyle: {
    container: {
      py: 's3',
      px: 's4',
      border: '1px',
      borderRadius: '8px',
      borderColor: 'black.200',
      _last: {
        mb: '0',
      },
      _checked: {
        backgroundColor: 'primary.50',
        borderColor: 'primary.500',
      },
      _disabled: {
        opacity: 1,
        borderColor: 'black.200',
        cursor: 'not-allowed',
      },
    },
    label: {
      color: 'black.500',
      fontWeight: 'medium',
      width: 'full',
    },
    control: {
      borderColor: 'black.300',
      _checked: {
        backgroundColor: 'primary.100',
        borderColor: 'primary.500',
        _before: {
          backgroundColor: 'primary.500',
        },
        _hover: {
          backgroundColor: 'primary.100',
          borderColor: 'primary.500',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
  sizes: {
    lg: {
      control: {
        height: '16px',
        width: '16px',
        _focus: {
          boxShadow: 'none',
        },
      },
      label: {
        fontSize: 't-sm',
      },
    },
  },
  variants: {},
  defaultProps: {
    size: 'lg',
  },
}
