export const PinIcon = (props: any) => {
  return (
    <svg
      {...props}
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10933_457)">
        <path
          d="M9.60047 0C4.29705 0 0 4.31562 0 9.63942C0 14.6533 5.66224 20.8526 8.28979 23.4595C9.01616 24.1802 10.1848 24.1802 10.9111 23.4595C13.5384 20.8525 19.2 14.6532 19.2 9.63942C19.2 4.31562 14.9014 0 9.60047 0ZM9.60061 15.3815C6.49389 15.3815 3.98055 12.8309 4.02424 9.70215C4.06633 6.68684 6.51709 4.22659 9.51994 4.18397C12.636 4.13974 15.1756 6.66263 15.1756 9.78246C15.1756 12.8744 12.6797 15.3815 9.60061 15.3815Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_10933_457">
          <rect width="20px" height="24px" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
