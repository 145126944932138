import { Box, Flex } from '@retriever-ui/react'
import React from 'react'
import ShadowSkeleton from 'src/components/skeleton'

const PropertyCardLoading = () => {
  return (
    <Box w="100%">
      <Flex
        direction={['column', 'row']}
        bg="black.200"
        radius={8}
        shadow="0px 4px 16px -5px black.0"
        h={[355, 200]}
      >
        <ShadowSkeleton isLoading radius={8} w={['100%', 260]} h={200} />
        <Flex
          h="100%"
          justifyContent="space-between"
          direction="column"
          pY={[20, 24]}
          pX={[16, 20]}
        >
          <ShadowSkeleton isLoading h={20} w={260} />
          <ShadowSkeleton isLoading h={16} w={210} mTop={-32} />
          <div>
            <ShadowSkeleton isLoading h={20} w={120} />
          </div>
        </Flex>
      </Flex>
    </Box>
  )
}

export default PropertyCardLoading
