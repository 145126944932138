import React from 'react'

const InstagramIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0118 8.04544H10.9905C9.36487 8.04544 8.05444 9.36416 8.05444 10.9815V17.0028C8.05444 18.6284 9.37316 19.9388 10.9905 19.9388H17.0118C18.6374 19.9388 19.9478 18.6201 19.9478 17.0028V10.9815C19.9478 9.36416 18.6374 8.04544 17.0118 8.04544ZM14.0011 17.8405C11.8779 17.8405 10.1528 16.1153 10.1528 13.9921C10.1528 11.8689 11.8779 10.1438 14.0011 10.1438C16.1243 10.1438 17.8495 11.8689 17.8495 13.9921C17.8495 16.1153 16.1243 17.8405 14.0011 17.8405ZM17.8578 11.0976C17.3435 11.0976 16.9371 10.6829 16.9371 10.177C16.9371 9.67103 17.3518 9.25634 17.8578 9.25634C18.3637 9.25634 18.7784 9.67103 18.7784 10.177C18.7867 10.6829 18.372 11.0976 17.8578 11.0976ZM14.0011 11.6367C12.699 11.6367 11.6457 12.69 11.6457 13.9921C11.6457 15.2943 12.699 16.3476 14.0011 16.3476C15.3033 16.3476 16.3566 15.2943 16.3566 13.9921C16.3566 12.69 15.3033 11.6367 14.0011 11.6367Z"
        fill="#FA2F93"
      />
      <path
        d="M14 0C6.27014 0 0 6.27014 0 14C0 21.7299 6.27014 28 14 28C21.7299 28 28 21.7299 28 14C28 6.27014 21.7299 0 14 0ZM21.4479 17.0024C21.4479 19.4491 19.4573 21.4396 17.0107 21.4396H10.9893C8.54265 21.4396 6.55213 19.4491 6.55213 17.0024V10.981C6.55213 8.53436 8.54265 6.54384 10.9893 6.54384H17.0107C19.4573 6.54384 21.4479 8.53436 21.4479 10.981V17.0024Z"
        fill="#FA2F93"
      />
    </svg>
  )
}

export default InstagramIcon
