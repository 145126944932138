import React from 'react'

const SearchIcon = ({ color = '#6C6C6C', width = '19', height = '19' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 18C17.3686 18.0003 17.2385 17.9746 17.1172 17.9243C16.9958 17.874 16.8856 17.8001 16.793 17.707L13.393 14.312C11.9975 15.4075 10.2742 16.002 8.5 16C6.91775 16 5.37103 15.5308 4.05544 14.6518C2.73985 13.7727 1.71447 12.5233 1.10897 11.0615C0.503466 9.59966 0.34504 7.99113 0.653721 6.43928C0.962403 4.88743 1.72433 3.46197 2.84315 2.34315C3.96197 1.22433 5.38743 0.462403 6.93928 0.153721C8.49113 -0.15496 10.0997 0.00346625 11.5615 0.608967C13.0233 1.21447 14.2727 2.23985 15.1518 3.55544C16.0308 4.87103 16.5 6.41775 16.5 8C16.5036 9.77656 15.909 11.5026 14.812 12.9L18.207 16.3C18.3444 16.4402 18.4376 16.6178 18.4748 16.8105C18.512 17.0033 18.4916 17.2028 18.4162 17.384C18.3408 17.5653 18.2137 17.7204 18.0508 17.8299C17.8879 17.9395 17.6963 17.9986 17.5 18ZM8.5 2C7.31332 2 6.15328 2.3519 5.16658 3.01119C4.17989 3.67047 3.41085 4.60755 2.95673 5.7039C2.5026 6.80026 2.38378 8.00666 2.61529 9.17055C2.8468 10.3344 3.41825 11.4035 4.25736 12.2426C5.09648 13.0818 6.16558 13.6532 7.32946 13.8847C8.49335 14.1162 9.69975 13.9974 10.7961 13.5433C11.8925 13.0892 12.8295 12.3201 13.4888 11.3334C14.1481 10.3467 14.5 9.18669 14.5 8C14.4982 6.40927 13.8654 4.88423 12.7406 3.75941C11.6158 2.63459 10.0907 2.00186 8.5 2Z"
        fill={color}
      />
    </svg>
  )
}

export default SearchIcon
