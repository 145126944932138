import { Transition, Variants } from 'framer-motion'

export const transition: Transition = {
  type: 'spring',
  stiffness: 300,
  damping: 35,
  duration: 0.35,
}

export const variants: Variants = {
  open: {
    zIndex: 99999,
    paddingTop: '40px',
    y: 0,
    background: '#e5e5e5',
    position: 'fixed',
    inset: '0px',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    transition: {
      duration: 0.2,
    },
  },
  closed: {
    minWidth: '100%',
    overflowY: 'initial',
    maxWidth: '100%',
    position: 'initial',
    background: 'transparent',
    transition: {
      duration: 0.4,
    },
  },
  opening: {
    zIndex: 99999,
    paddingTop: '40px',
    y: 0,
    background: '#fff',
    position: 'fixed',
    inset: '0px',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    transition: {
      duration: 0.2,
    },
  },
  closing: {
    y: 0,
    overflowY: 'initial',
    paddingTop: '0px',
    background: 'transparent',
    position: 'initial',
    minHeight: 'initial',
    maxHeight: 'initial',
    minWidth: '20%',
    maxWidth: '20%',
    inset: 'initial',
    zIndex: 9999999,
    transition: {
      duration: 0.4,
      delay: 0.2,
    },
  },
}

export const variantsMobile: Variants = {
  open: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 9999999999,
    y: 0,
    background: '#f5f5f5',
    position: 'fixed',
    inset: '0px',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflowY: 'auto',
    transition: {
      duration: 0.2,
    },
  },
  closed: {
    y: -400,
    display: 'none',
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
  opening: {
    display: 'none',
    y: 0,
    zIndex: 9999999,
    position: 'fixed',
    inset: '0px',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    background: '#f5f5f5',
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  closing: {
    y: -200,
    display: 'block',
    overflowY: 'initial',
    background: 'transparent',
    position: 'fixed',
    minHeight: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    inset: '0px',
    opacity: 0,
    zIndex: 9999999,
    transition: {
      duration: 0.3,
    },
  },
}
