import {
  cartStructureVirtualPageViewData,
  createSimple,
  homeStructureVirtualPageViewData,
  otherStructureVirtualPageViewData,
  productStructureVirtualPageViewData,
  purchaseStructureVirtualPageViewData,
  searchStructureVirtualPageViewData,
  structureAddToCartData,
  structureCheckoutOptionData,
  structureFormSubmissionData,
  structureProductsListData,
} from './modelling'

const DLPush = (payload) => {
  if (payload) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(payload)
    return true
  }
  return false
}

export const homeVirtualPageViewPush = (pageUrl) => {
  const payload = homeStructureVirtualPageViewData(pageUrl)
  return DLPush(payload)
}

export const searchVirtualPageViewPush = (pageUrl) => {
  const payload = searchStructureVirtualPageViewData(pageUrl)
  return DLPush(payload)
}

export const productVirtualPageViewPush = (pageUrl, apartment) => {
  const payload = productStructureVirtualPageViewData(pageUrl, apartment)
  return DLPush(payload)
}

export const cartVirtualPageViewPush = (pageUrl) => {
  const payload = cartStructureVirtualPageViewData(pageUrl)
  return DLPush(payload)
}

export const purchaseVirtualPageViewPush = (result, page) => {
  const payload = purchaseStructureVirtualPageViewData(result, page)
  return DLPush(payload)
}

export const otherVirtualPageViewPush = (pageType, pageUrl) => {
  const payload = otherStructureVirtualPageViewData(pageType, pageUrl)
  return DLPush(payload)
}

export const addToCartPush = (data) => {
  const payload = structureAddToCartData(data)
  return DLPush(payload)
}

export const formSubmissionPush = (name, data) => {
  const payload = structureFormSubmissionData(name, data)
  return DLPush(payload)
}

export const productsListPush = (apartmentList, page) => {
  const payload = structureProductsListData(apartmentList, page)
  return DLPush(payload)
}

export const checkoutOptionPush = (type, page) => {
  const payload = structureCheckoutOptionData(type, page)
  return DLPush(payload)
}

export const simplePush = (event, data = {}) => {
  const payload = createSimple(event, data)
  return DLPush(payload)
}
