import { useSessionstorageState } from 'rooks'
import useSWR from 'swr'

import { index } from '../../store/api'

interface UseRequestProps {
  url: string
  params?: { [key: string]: any }
  withCache?: boolean
  cacheKey?: string
}

export const useRequest = <T>({
  url,
  params,
  withCache,
  cacheKey,
}: UseRequestProps) => {
  const [cache, setStories] = useSessionstorageState<T | undefined>(
    cacheKey ?? url,
    undefined
  )

  const fetcher = (url: string) =>
    index.get(url, { params }).then((response) => {
      setStories(response.data)
      return response.data
    })

  const { data, error, isValidating } = useSWR<T>(url, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
  })

  return {
    data: withCache && !isValidating ? cache : data,
    error,
    isLoading: isValidating,
  }
}
