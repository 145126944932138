import React, { CSSProperties, FC } from 'react'

import { TextTypography } from './text'
import { TitleTypography } from './title'

type VariantTypes = 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge'
type ColorsTypes =
  | 'black'
  | 'gray'
  | 'success'
  | 'danger'
  | 'primary'
  | 'secondary'
  | 'white'
  | 'info'

type WeightTypes = 'thin' | 'light' | 'medium' | 'regular' | 'semiBold' | 'bold'

type TagTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'label'

type AlignTypes = 'left' | 'center' | 'right'

interface TypographyInterface {
  text?: boolean
  title?: boolean
  uppercase?: boolean
  variant?: VariantTypes
  color?: ColorsTypes
  fixSize?: number
  weight?: WeightTypes
  id?: string
  tag?: TagTypes
  alignMobile?: AlignTypes
  alignDesktop?: AlignTypes
  style?: CSSProperties
  onClick?: () => void | MouseEvent
  className?: string
}

export const Typography: FC<TypographyInterface> = ({
  text = false,
  title = false,
  uppercase = false,
  variant = 'medium',
  color = 'black',
  weight = 'regular',
  alignMobile = 'left',
  alignDesktop = 'left',
  fixSize = undefined,
  tag = 'p',
  style = {},
  onClick = () => {},
  children,
  id,
}) => {
  if (title) {
    return (
      <TitleTypography
        variant={variant}
        tag={tag}
        weight={weight}
        uppercase={uppercase}
        alignMobile={alignMobile}
        alignDesktop={alignDesktop}
        fixSize={fixSize}
        color={color}
        style={style}
        onClick={onClick}
        id={id}
      >
        {children}
      </TitleTypography>
    )
  }
  if (text) {
    return (
      <TextTypography
        variant={variant}
        tag={tag}
        weight={weight}
        uppercase={uppercase}
        alignMobile={alignMobile}
        alignDesktop={alignDesktop}
        fixSize={fixSize}
        color={color}
        style={style}
        onClick={onClick}
        id={id}
      >
        {children}
      </TextTypography>
    )
  }
  return <></>
}
