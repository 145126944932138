import * as c from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useOutsideClick } from 'rooks'

import { SearchContext, useSearch } from '../../search-context'
import { search_events } from '../../search-machine'
import { DatesContext } from '../dates-context'
import { dates_events } from '../dates-machine'
import { FieldEnd, FieldStart } from '../fields/field'
import { FieldsContext } from '../fields/fields-context'
import { fields_events } from '../fields/fields-machine'
import { variants } from './animation'
import Actions from './atomic/actions'
import DateOrMonth from './atomic/date-or-month'
import Dates from './atomic/dates'
import DateProvider from './atomic/dates/date-context'
import HeaderDisplay from './atomic/month'
import Week from './atomic/week'
import { PickerContext } from './picker-context'
import { picker_events } from './picker-machine'

const buttonStyle = {
  fontSize: 14,
  fontWeight: 700,
  px: 3,
  py: 2,
  minH: '37px',
  maxH: '37px',
  transition: 'all 0.2s ease-in-out',
}

export const Calendar = ({
  isNext,
  blockDates,
}: {
  isNext: boolean
  blockDates?: string[]
}) => {
  const { date } = React.useContext(PickerContext)

  return (
    <c.Box minW={['100%', '296px']} maxW={['100%', '296px']}>
      <HeaderDisplay date={date(isNext)} />
      <c.Flex direction="column" mt={[2, 4]}>
        <Week />
        <c.Flex direction="column" mt={2}>
          <Dates blockedDates={blockDates} date={date(isNext)} />
        </c.Flex>
      </c.Flex>
    </c.Box>
  )
}

const CalendarContent = () => {
  const { context } = React.useContext(PickerContext)

  return (
    <c.Flex
      px={5}
      mt={5}
      direction="column"
      minH="288px"
      zIndex={0}
      overflowX="hidden"
      pos="relative"
    >
      <AnimatePresence initial={false} custom={context.direction}>
        <>
          <motion.div
            key={`animate-${context.current.toISOString()}`}
            variants={variants}
            initial="initial"
            custom={context.direction}
            animate="animate"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 25 },
              duration: 0.25,
            }}
          >
            <c.Flex justify="space-between">
              <DateProvider>
                <Calendar isNext={false} />
                <Calendar isNext />
              </DateProvider>
            </c.Flex>
          </motion.div>
        </>
      </AnimatePresence>
    </c.Flex>
  )
}

const Header = () => {
  const fieldsState = React.useContext(FieldsContext)

  const toStart = () => fieldsState.send(fields_events.to_start)
  const toEnd = () => fieldsState.send(fields_events.to_end)

  return (
    <c.Flex
      minW={['100%', '664px']}
      minH="117px"
      maxH="117px"
      align="center"
      justify="space-between"
      gap={10}
      px={5}
    >
      <c.Flex mt={[4, 0]} zIndex={0} pos="relative">
        <FieldStart onClick={toStart} />
        <FieldEnd onClick={toEnd} />
      </c.Flex>
      <DateOrMonth />
    </c.Flex>
  )
}

const Footer = ({ onFinish }: { onFinish: () => void }) => {
  const { t } = useTranslation(['search', 'landing-pages/growth'])
  const { send } = React.useContext(SearchContext)
  const dates = React.useContext(DatesContext)

  const onClose = () => dates.send(dates_events.blur)

  const clearDates = () => {
    send(search_events.set_date_start, { date: undefined })
    send(search_events.set_date_end, { date: undefined })
  }

  return (
    <c.Flex
      pos="relative"
      zIndex={1}
      p={5}
      mt={3}
      minH="69px"
      w="100%"
      justify="space-between"
    >
      <c.Button
        {...buttonStyle}
        onClick={onClose}
        color="black.500"
        bg="black.0"
        _hover={{
          background: 'black.100',
        }}
      >
        {t('landing-pages/growth:expansion.CLOSE')}
      </c.Button>

      <c.Flex align="center" gap={5}>
        <c.Button
          {...buttonStyle}
          onClick={clearDates}
          color="black.500"
          bg="black.0"
          _hover={{
            background: 'black.100',
          }}
        >
          {t('search:CLEAR_DATES')}
        </c.Button>

        <FinishButton onFinish={onFinish} />
      </c.Flex>
    </c.Flex>
  )
}

const FinishButton = ({ onFinish }: { onFinish: () => void }) => {
  const { t } = useTranslation('components/search')
  const search = useSearch()

  const disabled = !(search.context.start && search.context.end)

  if (disabled)
    return (
      <c.Button
        {...buttonStyle}
        isDisabled
        color="black.0"
        bg="primary.500"
        _hover={{
          background: 'primary.600',
        }}
      >
        {t('components/search:dates.finish')}
      </c.Button>
    )

  return (
    <c.Button
      {...buttonStyle}
      onClick={onFinish}
      color="black.0"
      bg="primary.500"
      _hover={{
        background: 'primary.600',
      }}
    >
      {t('components/search:dates.finish')}
    </c.Button>
  )
}

const DatePicker = () => {
  const ref = React.useRef<HTMLDivElement>(null)
  const picker = React.useContext(PickerContext)
  const search = useSearch()
  const dates = React.useContext(DatesContext)
  const fields = React.useContext(FieldsContext)

  const onFinish = () => {
    if (search.context.searchTerm.value !== '') {
      search.handleSearch()
    }

    dates.send(dates_events.blur)
    picker.send(picker_events.close)
    fields.send(fields_events.blur)
  }

  const onClose = () => dates.send(dates_events.blur)

  useOutsideClick(ref, onClose)

  return (
    <div ref={ref}>
      <c.Flex
        pos="absolute"
        top={-6}
        right={-3}
        zIndex={1}
        minW={['100%', '664px']}
        maxW={['100%', '664px']}
        bg="black.0"
        borderRadius="12px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="black.200"
        direction="column"
        align="flex-end"
        justify="space-between"
        shadow="0px 10px 15px -3px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
      >
        <c.Box w="100%">
          <Header />
          <Actions />
          <AnimatePresence>
            <CalendarContent />
          </AnimatePresence>
        </c.Box>
        <Footer onFinish={onFinish} />
      </c.Flex>
    </div>
  )
}

export default DatePicker
