import qs from 'qs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CreditCardIcon } from 'src/components/navbar/icons/credit-card.icon'
import { HeartIcon } from 'src/components/navbar/icons/heart.icon'
import { LoginIcon } from 'src/components/navbar/icons/login.icon'
import { PinIcon } from 'src/components/navbar/icons/pin.icon'
import { UserIcon } from 'src/components/navbar/icons/user.icon'

export interface MyProfileConfigItemInterface {
  id: string
  label: string
  path?: string
  hasTag: boolean
  onClick?: () => void
  Icon: (props: any) => JSX.Element
}

export function MyProfileItemsConfig() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const uri = qs.stringify({
    redirect_to: `${window.location.href}`,
  })

  const redirectAuth = () => navigate(`/auth/signin?${uri}`)

  const userAccountItems: MyProfileConfigItemInterface[] = [
    {
      id: 'acessar',
      label: t('components/nav:LOGIN'),
      Icon: LoginIcon,
      onClick: () => redirectAuth(),
      hasTag: false,
    },
    {
      id: 'vincular',
      label: t('components/nav:OTA'),
      Icon: PinIcon,
      onClick: () => navigate('/vincular'),
      hasTag: false,
    },
  ]

  const myProfileItems: MyProfileConfigItemInterface[] = [
    {
      id: 'infos-pessoais',
      label: t('components/nav:PROFILE.personal-info'),
      Icon: UserIcon,
      path: '/profile/personal-info',
      hasTag: false,
    },
    {
      id: 'minhas-reservas',
      label: t('components/nav:PROFILE.my-reservations'),
      Icon: PinIcon,
      path: '/profile/reservations',
      hasTag: true,
    },
    {
      id: 'favoritos',
      label: t('components/nav:PROFILE.favorites'),
      Icon: HeartIcon,
      path: '/profile/favorites',
      hasTag: false,
    },
    {
      id: 'metodos-pagamento',
      label: t('components/nav:PROFILE.payment'),
      Icon: CreditCardIcon,
      path: '/profile/payment',
      hasTag: false,
    },
  ]

  return {
    userAccountItems,
    myProfileItems,
  }
}
