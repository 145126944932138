import * as c from '@chakra-ui/react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'src/pages/auth/auth-context'

import { MenuDrawerIcon } from '../../../icons/menu-drawer.icon'
import HelpSection from '../help'
import MenuHeader from './content/header'
import MyProfile from './content/my-profile'

const MenuProfile = () => {
  const { isAuth } = useAuth()
  const { onToggle, onOpen, onClose, isOpen } = c.useDisclosure()
  const { pathname } = useLocation()

  useEffect(() => {
    onClose()
  }, [pathname])

  return (
    <c.Menu
      variant="navbar"
      placement="bottom-end"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onToggle}
    >
      <c.MenuButton
        as={c.Button}
        maxHeight="30px"
        py="s1"
        px="s2"
        bg="none"
        _hover={{ bg: 'black.100' }}
        _active={{
          bg: 'black.0',
          border: '1px solid',
          borderColor: 'primary.500',
          shadow: 'none',
        }}
        transition="none"
        rightIcon={
          <c.Icon as={MenuDrawerIcon} w="1rem" h="1rem" color="black.500" />
        }
      >
        <c.Text fontSize="t-sm.0" fontWeight="medium" color="black.500">
          Menu
        </c.Text>
      </c.MenuButton>

      <c.MenuList py="s6" px="s5" width="350px" maxH="calc(100vh - 100px)">
        <c.Flex direction="column" gap="s6">
          <MenuHeader isLogged={isAuth} />
          <MyProfile isLogged={isAuth} />
          <HelpSection isLogged={isAuth} />
        </c.Flex>
      </c.MenuList>
    </c.Menu>
  )
}

export default MenuProfile
