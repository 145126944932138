interface HousiLogoIconProps {
  title?: '#FFF' | '#FA2F93'
  subTitle?: '#2E3744'
}

export const HousiLogoIcon = ({
  title = '#FA2F93',
  subTitle = '#2E3744',
}: HousiLogoIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="158"
    height="48"
    viewBox="0 0 208 61"
    fill="none"
  >
    <path
      d="M21.2252 7.85665L4.24813 7.85572L4.24834 0.00770658H0V19.9705H4.24834L4.25668 12.0936H21.2245L21.2251 19.9696L25.4694 19.9705L25.4659 0.00770658L21.2251 0.00683594L21.2252 7.85665Z"
      fill={title}
    />
    <path
      d="M172.218 7.86258L158.866 7.85327C157.869 7.85327 157.062 7.04657 157.062 6.05156C157.062 5.05704 157.869 4.2508 158.865 4.25047L178.285 4.24587V0L158.867 0.0117739C155.525 0.0127836 152.816 2.71822 152.815 6.05531C152.815 9.39335 155.525 12.0995 158.869 12.0995L172.216 12.0936C173.224 12.0936 174.041 12.9086 174.042 13.9146C174.043 14.9217 173.225 15.7386 172.217 15.7387L152.818 15.7402L152.815 19.9721L172.218 19.9782C175.569 19.978 178.285 17.2658 178.285 13.9203C178.285 10.5747 175.569 7.86258 172.218 7.86258Z"
      fill={title}
    />
    <path d="M208 0.00769043H203.755V19.9705H208V0.00769043Z" fill={title} />
    <path
      d="M123.102 0.013675L123.098 12.8704C123.098 14.4484 121.813 15.7323 120.232 15.7323L108.992 15.7263C107.412 15.7263 106.126 14.4425 106.126 12.8644L106.129 0.200649L106.122 0.00769059H101.877L101.899 13.2846C101.899 14.2014 102.075 15.0672 102.422 15.8577C102.769 16.6477 103.262 17.3555 103.888 17.9613C104.187 18.251 104.518 18.5209 104.87 18.7637C105.225 19.0074 105.604 19.2187 105.996 19.3916C106.39 19.566 106.807 19.7028 107.235 19.7983C107.664 19.8939 108.112 19.9424 108.567 19.9424H120.678C121.133 19.9424 121.582 19.8939 122.011 19.7983C122.439 19.7028 122.856 19.566 123.249 19.3917C123.642 19.2186 124.02 19.0073 124.375 18.7635C124.728 18.521 125.058 18.2511 125.358 17.9613C125.984 17.3554 126.477 16.6476 126.824 15.8577C127.171 15.0666 127.347 14.2009 127.347 13.2846V0.00769043L123.102 0.013675Z"
      fill={title}
    />
    <path
      d="M75.885 4.0924C75.5383 3.30252 75.0453 2.59475 74.4195 1.98887C74.1196 1.69906 73.789 1.42915 73.4365 1.18659C73.0816 0.942794 72.7029 0.731506 72.3106 0.558457C71.9168 0.384166 71.5 0.247352 71.072 0.151877C70.6428 0.0561859 70.1946 0.00769043 69.7396 0.00769043H57.6285C57.1735 0.00769043 56.7253 0.0561859 56.2964 0.151877C55.8685 0.247284 55.4516 0.384098 55.0574 0.558526C54.6655 0.731438 54.2865 0.942726 53.9318 1.18646C53.5791 1.42922 53.2485 1.69913 52.949 1.9888C52.323 2.59462 51.8299 3.30239 51.4834 4.09247C51.1363 4.88298 50.9602 5.74871 50.9602 6.66548L50.954 8.51188H50.9387L50.9474 10.4575L50.9387 13.0542H50.9591L50.9602 13.2987C50.9602 14.2154 51.1363 15.0812 51.4834 15.8717C51.8299 16.6618 52.323 17.3695 52.949 17.9754C53.2485 18.265 53.5791 18.5349 53.9318 18.7777C54.2865 19.0214 54.6655 19.2327 55.0574 19.4056C55.4516 19.5801 55.8685 19.7169 56.2964 19.8123C56.7253 19.908 57.1735 19.9565 57.6285 19.9565H69.7396C70.1946 19.9565 70.6428 19.908 71.072 19.8123C71.5 19.7168 71.9168 19.58 72.3106 19.4057C72.7029 19.2326 73.0816 19.0214 73.4365 18.7776C73.789 18.535 74.1196 18.2651 74.4195 17.9753C75.0453 17.3694 75.5383 16.6616 75.885 15.8717C76.2322 15.0806 76.4081 14.215 76.4081 13.2987V6.66548C76.4081 5.74919 76.2322 4.88353 75.885 4.0924ZM69.2936 15.7463L58.0535 15.7403C56.4912 15.7403 55.2189 14.4852 55.1898 12.9321L55.1888 10.7854L55.1905 8.70484L55.1878 8.62875L55.1871 7.08572C55.1871 5.50767 56.4731 4.22386 58.0535 4.22386L69.2936 4.21788C70.8741 4.21788 72.1598 5.50169 72.1598 7.07974L72.1617 10.4319L72.1598 12.8844C72.1598 14.4625 70.8741 15.7463 69.2936 15.7463Z"
      fill={title}
    />
    <path
      d="M30.2405 42.889C30.1303 42.3388 29.7308 41.5136 28.4634 41.5136C27.5405 41.5136 26.9343 42.1051 26.9343 42.7515C26.9343 43.2879 27.2787 43.7006 27.9951 43.8518L29.3589 44.1131C31.1222 44.4432 32.0865 45.6262 32.0865 46.9878C32.0865 48.4869 30.8191 50 28.5599 50C25.97 50 24.8266 48.3357 24.675 46.9465L26.4384 46.4789C26.5073 47.4278 27.1961 48.3081 28.5736 48.3081C29.6068 48.3081 30.1579 47.813 30.1579 47.1115C30.1579 46.5476 29.7446 46.1075 28.9732 45.9562L27.6093 45.6811C26.0527 45.3647 25.047 44.3745 25.047 42.889C25.047 41.1697 26.6037 39.8356 28.4497 39.8356C30.8054 39.8356 31.7283 41.2659 31.9487 42.3663L30.2405 42.889Z"
      fill={subTitle}
    />
    <path
      d="M47.8057 49.7936V42.9578L44.9954 49.7936H43.4112L40.6009 43.0129V49.7936H38.7825V40.0418H41.2897L44.224 47.084L47.0893 40.0418H49.6792V49.7936H47.8057Z"
      fill={subTitle}
    />
    <path
      d="M62.8088 47.5655H58.8414L58.0287 49.7936H56.0173L59.7644 40.0418H61.9686L65.6881 49.7936H63.6216L62.8088 47.5655ZM59.4751 45.8325H62.1752L60.8252 42.1462L59.4751 45.8325Z"
      fill={subTitle}
    />
    <path
      d="M74.9326 46.0387H73.9408V49.7936H72.0259V40.0418H75.8418C77.7429 40.0418 78.9552 41.3485 78.9552 43.0402C78.9552 44.4158 78.1562 45.4336 76.875 45.805L78.969 49.7936H76.8475L74.9326 46.0387ZM75.4836 44.402C76.4617 44.402 77.0128 43.8656 77.0128 43.054C77.0128 42.2288 76.4617 41.6924 75.4836 41.6924H73.9408V44.402H75.4836Z"
      fill={subTitle}
    />
    <path
      d="M89.8254 41.8437V49.7936H87.9106V41.8437H84.8247V40.0418H92.9112V41.8437H89.8254Z"
      fill={subTitle}
    />
    <path
      d="M107.818 49.7936V40.0418H109.719V47.978H114.073V49.7936H107.818Z"
      fill={subTitle}
    />
    <path
      d="M120.617 49.7936V40.0418H122.546V49.7936H120.617Z"
      fill={subTitle}
    />
    <path
      d="M136.06 40.0418H138.072L134.449 49.7936H132.492L128.883 40.0418H130.963L133.512 47.2903L136.06 40.0418Z"
      fill={subTitle}
    />
    <path d="M144.41 49.7936V40.0418H146.338V49.7936H144.41Z" fill={subTitle} />
    <path
      d="M159.991 49.7936L155.555 42.8752V49.7936H153.654V40.0418H156.023L160.087 46.4927V40.0418H161.988V49.7936H159.991Z"
      fill={subTitle}
    />
    <path
      d="M176.606 49.7936L176.482 48.6795C176.041 49.3536 175.077 50 173.602 50C170.971 50 168.726 48.0744 168.726 44.9247C168.726 41.775 171.082 39.8356 173.727 39.8356C176.303 39.8356 177.708 41.3348 178.19 42.8341L176.371 43.4805C176.123 42.614 175.311 41.6098 173.727 41.6098C172.252 41.6098 170.654 42.614 170.654 44.9247C170.654 47.1253 172.101 48.267 173.74 48.267C175.559 48.267 176.247 47.0702 176.358 46.3688H173.272V44.7184H178.149V49.7936H176.606Z"
      fill={subTitle}
    />
  </svg>
)

export default HousiLogoIcon
