import { Box } from '@chakra-ui/react'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, RouteObject, useRoutes } from 'react-router-dom'
import Footer from 'src/components/footer'
import NavbarDesktop from 'src/components/navbar/desktop'
import NavbarMobile from 'src/components/navbar/mobile'
import SearchProvider from 'src/components/search-v2/search-context'
import authRoutes from 'src/pages/auth/routes'
import HomeBuilder from 'src/pages/home-builder'
import ResetProvider from 'src/store/context/scroll.context'

import profileRoutes from '../../pages/profile-v2'
import apartmentsRoutes from './apartments-routes'
import { RequireSeo } from './custom'
import landingPagesRoutes from './lps-routes'
import offersRoutes from './offers-routes'
import otaRoutes from './ota-routes'
import paymentRoutes from './payment-routes'
import * as Components from './route-components'
import searchRoutes from './search-routes'
import termsRoutes from './term-routes'

const Search = React.lazy(() => import('src/components/search-v2'))

const DefaultLayout = ({ children }) => {
  return (
    <ResetProvider>
      <SearchProvider>
        <Box w="100%">
          <NavbarDesktop />
          {children}
          <NavbarMobile />
        </Box>
        <React.Suspense fallback="">
          <Search />
          <Footer />
        </React.Suspense>
      </SearchProvider>
    </ResetProvider>
  )
}

const routes = (t: TFunction): RouteObject[] => [
  {
    path: '/',
    element: (
      <DefaultLayout>
        <Outlet />
      </DefaultLayout>
    ),
    children: [
      {
        index: true,
        element: (
          <RequireSeo
            title={t('titles:home.SEO_TITLE')}
            description={t('titles:home.SEO_DESCRIPTION')}
          >
            <HomeBuilder />
          </RequireSeo>
        ),
      },
      {
        path: 'reset',
        element: (
          <RequireSeo title="Housi">
            <Components.ResetForm />
          </RequireSeo>
        ),
      },
      ...otaRoutes(),
      ...searchRoutes(t),
      ...apartmentsRoutes(t),
      ...paymentRoutes(t),
      ...termsRoutes(t),
      ...landingPagesRoutes(t),
      ...offersRoutes(),
      profileRoutes(t),
      {
        path: '*',
        element: (
          <RequireSeo title="Erro 404">
            <Components.Error404Component />
          </RequireSeo>
        ),
      },
    ],
  },
  authRoutes(t),
]

const Routes = () => {
  const { t } = useTranslation('titles')
  const elements = useRoutes(routes(t))

  return elements
}
export default Routes
