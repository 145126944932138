import React from 'react'

const ParkingIcon = () => {
  return (
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.99 3.98C20.99 1.79 19.21 0 17.01 0C14.81 0 13.03 1.78 13.03 3.98C13.03 5.95 14.47 7.58 16.36 7.89V14.96H17.66V7.89C19.55 7.57 20.99 5.95 20.99 3.98ZM17.02 6.65C15.54 6.65 14.34 5.45 14.34 3.97C14.34 2.49 15.54 1.29 17.02 1.29C18.5 1.29 19.7 2.49 19.7 3.97C19.7 5.45 18.49 6.65 17.02 6.65Z"
        fill="#A3A3A3"
      />
      <path
        d="M18.05 4.86035H16.58V4.31035H17.82C17.92 4.31035 18.01 4.23035 18.01 4.12035V3.78035C18.01 3.68035 17.93 3.59035 17.82 3.59035H16.58V3.09035H18C18.1 3.09035 18.19 3.01035 18.19 2.90035V2.55035C18.19 2.45035 18.11 2.36035 18 2.36035H15.99C15.89 2.36035 15.8 2.44035 15.8 2.55035V5.40035C15.8 5.50035 15.88 5.59035 15.99 5.59035H18.06C18.16 5.59035 18.25 5.51035 18.25 5.40035V5.05035C18.24 4.94035 18.15 4.86035 18.05 4.86035Z"
        fill="#A3A3A3"
      />
      <path
        d="M3.55998 10.8799H2.75998C2.52998 10.8799 2.34998 11.0599 2.34998 11.2899V11.7199C2.34998 11.9499 2.52998 12.1299 2.75998 12.1299H3.55998C3.78998 12.1299 3.96998 11.9499 3.96998 11.7199V11.2899C3.96998 11.0599 3.78998 10.8799 3.55998 10.8799Z"
        fill="#A3A3A3"
      />
      <path
        d="M14.1 11.2198C14.1 10.0998 13.28 9.17984 12.22 8.98984L11.22 6.97984C11.08 6.60984 10.7 6.33984 10.3 6.33984H3.81C3.4 6.33984 3.04 6.58984 2.91 6.91984L1.89 8.97984C0.82 9.16984 0.01 10.0998 0.01 11.2098V13.9298C0.01 13.9298 0 14.0398 0 14.1098V14.5398C0 14.7698 0.18 14.9498 0.41 14.9498H1.96C2.19 14.9498 2.37 14.7698 2.37 14.5398V14.1098C2.37 14.0498 2.33 13.9998 2.3 13.9398H11.85C11.83 13.9898 11.78 14.0398 11.78 14.1098V14.5398C11.78 14.7698 11.96 14.9498 12.19 14.9498H13.74C13.97 14.9498 14.15 14.7698 14.15 14.5398V14.1098C14.14 14.0398 14.15 13.9898 14.13 13.9398V11.2198H14.1ZM3.93 7.54984H10.17L10.87 8.94984H3.25L3.93 7.54984ZM12.89 12.7498H1.21V11.2298C1.21 10.6398 1.69 10.1598 2.28 10.1598H11.83C12.42 10.1598 12.9 10.6398 12.9 11.2298V12.7498H12.89Z"
        fill="#A3A3A3"
      />
      <path
        d="M8.71 10.8799H5.41C5.18 10.8799 5 11.0599 5 11.2799V11.7099C5 11.9399 5.18 12.1199 5.41 12.1199H8.71C8.94 12.1199 9.12 11.9399 9.12 11.7099V11.2799C9.11 11.0599 8.93 10.8799 8.71 10.8799Z"
        fill="#A3A3A3"
      />
      <path
        d="M11.36 10.8799H10.56C10.33 10.8799 10.15 11.0599 10.15 11.2899V11.7199C10.15 11.9499 10.33 12.1299 10.56 12.1299H11.36C11.59 12.1299 11.77 11.9499 11.77 11.7199V11.2899C11.77 11.0599 11.59 10.8799 11.36 10.8799Z"
        fill="#A3A3A3"
      />
    </svg>
  )
}

export default ParkingIcon
