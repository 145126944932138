import { Box, Flex, retriever } from '@retriever-ui/react'
import React, { useCallback } from 'react'
import GoogleLogin from 'react-google-login'
import { GoogleIcon } from 'src/assets/icons/google.icon'
import { notify } from 'src/components/alert/toast'
import useToRedirect from 'src/hooks/use-to-redirect'

import { useAuth } from '../auth-context'

const style = {
  alignItems: 'center',
  justifyContent: 'center',
  d: 'flex',
  role: 'button',
  w: '100%',
  h: 52,
  radius: 8,
  bg: 'black.0',
  cursor: 'pointer',
  border: '1px solid black.300',
  color: 'black.500',
  fontSize: 14,
  transition: 'all 0.2s',
  fontFamily: "'Montserrat', sans-serif",
  fontWeight: 500,
  _hover: { bg: 'black.200' },
}

const Button = ({ children, ...rest }: HTMLButtonElement) => {
  return (
    <retriever.button {...(rest as any)} {...style}>
      {children}
    </retriever.button>
  )
}

const GoogleAndFacebookButtons = ({ onFinish }: { onFinish?: () => void }) => {
  const { handleLoginGoogle } = useAuth()
  const { redirect } = useToRedirect()

  const callbackGoogle = useCallback(async (data) => {
    try {
      await handleLoginGoogle({
        firstName: data.profileObj.givenName,
        lastName: data.profileObj.familyName,
        accessToken: data.tokenId,
        email: data.profileObj.email,
        avatarUrl: data.profileObj.imageUrl,
        method: 'google',
        acceptTerms: true,
        acceptConditions: true,
        kind: 'natural_person',
      })

      notify({ text: 'Conectado com sucesso!' }).success()
      if (onFinish) {
        onFinish()
      } else {
        redirect()
      }
    } catch (e) {
      return e
    }
  }, [])

  return (
    <>
      <Flex
        width="100%"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
        gap="14px"
      >
        <Box w={['25%', '20%']} height="1px" background="black.200" />
        <retriever.p whiteSpace="nowrap" color="black.500" fontSize="16px">
          ou continuar com
        </retriever.p>
        <Box w={['25%', '20%']} height="1px" background="black.200" />
      </Flex>

      <Flex width="154px" justifyContent="center" alignItems="center">
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_ID as any}
          onSuccess={callbackGoogle}
          cookiePolicy="single_host_origin"
          render={(props: any) => (
            <Button {...props}>
              <Flex maxW="fit-content" alignItems="center" gap="18px">
                <GoogleIcon />
                <retriever.span fontSize={14} fontWeight={500}>
                  Google
                </retriever.span>
              </Flex>
            </Button>
          )}
        />
      </Flex>
    </>
  )
}

export default GoogleAndFacebookButtons
