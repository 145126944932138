import * as dateFns from 'date-fns'
import dateUtils from 'src/utils/date'
import { assign, createMachine } from 'xstate'

export const enum picker_states {
  dates = 'dates',
  month = 'month',
  closed = 'closed',
}

export const enum picker_events {
  next = 'next',
  jump = 'jump',
  prev = 'prev',
  to_month = 'to_month',
  to_dates = 'to_dates',
  clear_selected = 'clear_index',
  close = 'close',
}

export interface ContextType {
  current: Date
  next: Date
  selectedDate?: Date
  direction: 'prev' | 'next' | 'jump'
}

export interface EventsType {
  type: picker_events
}

export interface StatesType {
  value: picker_states
  context: ContextType
}

const pickerMachine = createMachine<ContextType, EventsType, StatesType>(
  {
    id: 'picker-machine',
    context: {
      current: dateFns.startOfMonth(new Date()),
      next: dateUtils.sum(dateFns.startOfMonth(new Date()), { months: 1 }),
      direction: 'prev',
    },
    initial: picker_states.dates,
    states: {
      [picker_states.closed]: {
        on: {
          [picker_events.to_month]: {
            target: picker_states.month,
            actions: 'toMonths',
          },
          [picker_events.to_dates]: {
            target: picker_states.dates,
            actions: 'toDates',
          },
          [picker_events.next]: {
            actions: 'nextMonth',
          },
          [picker_events.prev]: {
            actions: 'prevMonth',
          },
        },
      },

      [picker_states.dates]: {
        on: {
          [picker_events.next]: {
            actions: 'nextMonth',
          },
          [picker_events.clear_selected]: {
            actions: 'clearSelected',
          },
          [picker_events.prev]: {
            actions: 'prevMonth',
          },
          [picker_events.to_month]: {
            target: picker_states.month,
            actions: 'toMonths',
          },
        },
      },
      [picker_states.month]: {
        on: {
          [picker_events.jump]: {
            actions: 'jumpDate',
            target: picker_states.dates,
          },
          [picker_events.next]: {
            actions: 'nextYear',
          },
          [picker_events.prev]: {
            actions: 'prevYear',
          },
          [picker_events.to_dates]: {
            target: picker_states.dates,
            actions: 'toDates',
          },
        },
      },
    },
  },
  {
    actions: {
      clearSelected: assign(() => ({
        selectedDate: undefined,
      })) as any,
      toDates: assign((context) => ({
        current: context.current,
        next: dateUtils.sum(context.current, { months: 1 }),
        direction: 'jump',
      })),
      toMonths: assign((context) => ({
        current: context.current,
        next: dateUtils.sum(context.current, { years: 1 }),
        direction: 'jump',
      })),

      jumpDate: assign((_, event: any) => ({
        current: event.date,
        next: dateUtils.sum(event.date, { months: 1 }),
        direction: 'jump',
        selectedDate: event.date,
      })),

      nextMonth: assign((context) => ({
        current: dateUtils.sum(context.current, { months: 1 }),
        next: dateUtils.sum(context.next, { months: 1 }),
        direction: 'next',
      })),
      prevMonth: assign((context) => ({
        current: dateUtils.sub(context.current, { months: 1 }),
        next: dateUtils.sub(context.next, { months: 1 }),
        direction: 'prev',
      })),

      nextYear: assign((context) => ({
        current: dateUtils.sum(context.current, { years: 1 }),
        next: dateUtils.sum(context.next, { years: 1 }),
        direction: 'next',
      })),
      prevYear: assign((context) => ({
        current: dateUtils.sub(context.current, { years: 1 }),
        next: dateUtils.sub(context.next, { years: 1 }),
        direction: 'prev',
      })),
    },
  }
)
export default pickerMachine
