import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom'
import PropertyInterface from 'src/interfaces/property/property.interface'
import { ReservationType } from 'src/interfaces/reservation/reservation.type'

import { useCommonCard } from '../common'
import { Gallery } from '../gallery'
import {
  PropertyCardContext,
  PropertyCardProvider,
} from '../property-card.context'
import Detail from './detail'
import FlagType from './flag'

interface CardSearchProps {
  property: PropertyInterface
  from?: string
  to?: string
  bookingType?: ReservationType
}

type ContextTpe = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

const Card = () => {
  const { property } = React.useContext(PropertyCardContext)

  const { gallery } = useCommonCard({
    property,
  })

  const navigate = useNavigate()
  const location = useLocation()

  const onOpen = () =>
    navigate(
      `/search/properties/preview/${property?.aliasId}${location.search}`
    )

  const onClose = () => navigate(`/search/properties/${location.search}`)

  const isOpen = location.pathname.includes(`/preview/${property?.aliasId}`)

  return (
    <>
      <Flex
        onClick={onOpen}
        direction={['column', null, 'row']}
        alignItems="center"
        backgroundColor="black.0"
        gap={['0', null, '20px']}
        borderRadius="8px"
        h={['400px', null, '200px']}
        w="100%"
        transition="0.2s all"
        cursor="pointer"
        _hover={{ shadow: 'lg' }}
      >
        <Box maxW={['100%', null, '260px']} pos="relative">
          <FlagType />
          <Gallery
            gallery={gallery}
            width="100%"
            height="200px"
            widthDesktop="260px"
            heightDesktop="200px"
            withIndicators
            withArrows={false}
            imageProps={{
              style: { borderRadius: 8, width: '100%', height: '200px' },
            }}
          />
        </Box>
        <Detail />
      </Flex>
      <Outlet context={{ onClose, isOpen, onOpen }} />
    </>
  )
}

export const usePreviewCard = () => useOutletContext<ContextTpe>()

const CardSearch = (props: CardSearchProps) => {
  return (
    <PropertyCardProvider {...props}>
      <Card />
    </PropertyCardProvider>
  )
}

export default React.memo(CardSearch)
