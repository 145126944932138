import qs from 'qs'
import React, { createContext, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import useBuilding from '../../hooks/building'
import { useApartmentDetail } from '../../hooks/properties/detail'

const PropertyDetailContext = createContext({
  property: {},
  building: {},
  isPartner: false,
  minimumNights: null,
  flags: [],
  subscriptionCalendarProps: {
    minStay: null,
    blockAfterNextAvailableDate: null,
  },
  shortStayCalendarProps: {
    minStay: null,
    blockAfterNextAvailableDate: null,
  },
  acceptType: [],
})

const PropertyDetailProvider = ({ children }) => {
  const { propertyId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const { apartment: property } = useApartmentDetail({
    apartmentId: propertyId,
  })

  const { building } = useBuilding({ buildingId: property?.building?._id })

  const isPartner = useMemo(() => {
    const isBySearch = location.search.includes('partnerId=HOUSI')
    const isByRequest = property?.partnerId === 'HOUSI'

    return !(isBySearch || isByRequest)
  }, [location.search, property?.partnerId, property?.aliasId, property])

  const calendarProps = useMemo(() => {
    const shortStay = property?.stayTypes?.filter(
      (s) => s.type === 'SHORT_STAY'
    )?.[0] ?? {
      advanceDays: 0,
      minStayDays: 0,
    }

    const subscription = property?.stayTypes?.filter(
      (s) => s.type === 'SUBSCRIPTION'
    )?.[0] ?? {
      advanceDays: 0,
      minStayDays: 0,
    }

    const shortStayCalendarProps = {
      minStay: shortStay?.minStayDays || 1,
      blockAfterNextAvailableDate: shortStay?.advanceDays
        ? Number(shortStay?.advanceDays)
        : 1,
    }

    const subscriptionCalendarProps = {
      minStay: subscription?.minStayDays || 1,
      blockAfterNextAvailableDate: subscription?.advanceDays
        ? Number(subscription?.advanceDays)
        : 1,
    }

    return { shortStayCalendarProps, subscriptionCalendarProps }
  }, [property])

  const acceptType = useMemo(
    () => property?.stayTypes?.map((t) => t.type) || [],
    [property]
  )

  React.useEffect(() => {
    if (
      !location.pathname.includes('/building') &&
      property?.building?.aliasId
    ) {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true })

      const linkParams = qs.stringify({
        partnerId: isPartner ? 'marketplace' : property?.partnerId,
        ...params,
      })

      navigate(
        `/building/${property?.building?.aliasId}/apartment/${property?.aliasId}?${linkParams}`
      )
    }
  }, [isPartner, property])

  return (
    <PropertyDetailContext.Provider
      value={{ property, building, isPartner, ...calendarProps, acceptType }}
    >
      {children}
    </PropertyDetailContext.Provider>
  )
}

export { PropertyDetailContext, PropertyDetailProvider }
