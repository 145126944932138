import qs from 'qs'
import PropertyInterface from 'src/interfaces/property/property.interface'
import { assign, createMachine } from 'xstate'

export const enum states {
  idle = 'idle',
  moving = 'moving',
  requesting = 'requesting',
  zooming = 'zooming',
  error = 'error',
}

export const enum events {
  stop = 'stop',
  move = 'move',
  zoom = 'zoom',
  request = 'request',
  clearCards = 'clearCards',
  filter = 'filter',
  clearFilter = 'clearFilter',
}

export type PinType = {
  count: number
  buildingId: string
  lat: number
  lng: number
  flagship: boolean
  isPartner: boolean
}

export type CardType = PropertyInterface

export type FilterType = {
  lat?: number
  lng?: number
  zoom?: number
  radius?: number
  minPrice?: number
  maxPrice?: number
  guests?: number
  amenities?: string
  modalitie?: string
  bedrooms?: number
  parkingSpaces?: number
  bathrooms?: number
  petFriendly?: string
  price?: number
  sortingOrder?: string
  sortingType?: string
  page?: number
  limit?: number
}

export type ContextType = {
  pins: PinType[]
  cards: CardType[]
  filters: FilterType
  activeCard?: string
  totalItems?: number
  totalPages?: number
  hasNextPage?: boolean
  hasPrevPage?: boolean
}

export type StatesType = {
  value: states
  context: ContextType
}

export type EventsType = {
  type: events
}
const params = qs.parse(window.location.search, {
  ignoreQueryPrefix: true,
}) as any

const context: ContextType = {
  filters: {
    radius: 3000,
    zoom: Number(params?.zoom) ?? 14,
    lat: Number(params?.coordinates?.[0]),
    lng: Number(params?.coordinates?.[1]),
    maxPrice: params?.maxPrice,
    minPrice: params?.minPrice,
    guests: params?.guests,
    modalitie: params?.modalitie,
    amenities: params?.amenities,
    bedrooms: params?.bedrooms,
    bathrooms: params?.bathrooms,
    parkingSpaces: params?.parkingSpaces,
    petFriendly: params?.petFriendly,
    price: params?.price,
    sortingOrder: params?.sortingOrder,
    sortingType: params?.sortingType,
    page: 1,
    limit: 10,
  },
  pins: [],
  cards: [],
}

const mapMachine = createMachine<ContextType, EventsType, StatesType>(
  {
    id: 'maps-machine',
    initial: states.idle,
    context,
    states: {
      [states.idle]: {
        on: {
          [events.clearCards]: { actions: 'clearCards' },
          [events.request]: { target: states.requesting },
          [events.filter]: { actions: 'updateFilters' },
          [events.clearFilter]: { actions: 'clearFilter' },
          [events.zoom]: {
            target: states.zooming,
            actions: 'updateFiltersOnMove',
          },
          [events.move]: {
            actions: 'updateFiltersOnMove',
            target: states.moving,
          },
        },
      },
      [states.error]: {
        on: {
          [events.stop]: { target: states.idle },
          [events.clearCards]: { actions: 'clearCards' },
          [events.clearFilter]: { actions: 'clearFilter' },
          [events.request]: { target: states.requesting },
          [events.filter]: { actions: 'updateFilters' },
          [events.zoom]: {
            target: states.zooming,
            actions: 'updateFiltersOnMove',
          },
          [events.move]: {
            actions: 'updateFiltersOnMove',
            target: states.moving,
          },
        },
      },
      [states.zooming]: {
        on: {
          [events.stop]: { target: states.idle },
          [events.request]: { target: states.requesting },
        },
      },
      [states.moving]: {
        on: {
          [events.request]: { target: states.requesting },
          [events.zoom]: {
            target: states.zooming,
            actions: 'updateFilters',
          },
          [events.clearCards]: { actions: 'clearCards' },
        },
      },
      [states.requesting]: {
        invoke: {
          src: 'request',
          onDone: {
            actions: 'getData',
            target: states.idle,
          },
          onError: {
            target: states.error,
          },
        },
      },
    },
  },
  {
    actions: {
      clearCards: assign(() => ({ cards: [] })) as any,
      getData: assign((ctx, event: any) => {
        const { items, pins, ...rest } = event.data

        return {
          cards: [...ctx.cards, ...items],
          pins,
          hasNextPage: rest.hasNextPage,
          hasPrevPage: rest.hasPrevPage,
          totalItems: rest.totalItems,
          totalPages: rest.totalPages,
        }
      }),
      clearFilter: assign((_, event: any) => {
        return {
          filters: event.filters,
        }
      }),
      updateFiltersOnMove: assign((ctx, event: any) => {
        return {
          ...ctx,
          ...event,
          filters: {
            ...ctx.filters,
            ...event.filters,
          },
        }
      }),
      updateFilters: assign((ctx, event: any) => {
        return {
          ...ctx,
          ...event,
          filters: {
            ...event.filters,
          },
        }
      }),
    },
  }
)

export default mapMachine
