import React from 'react'

const ArrowPink = () => {
  return (
    <svg
      width="5"
      height="10"
      viewBox="0 0 5 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666417 9.66659C0.539741 9.66654 0.4157 9.63041 0.30882 9.56241C0.20194 9.49442 0.116644 9.39738 0.0629217 9.28266C0.00919907 9.16793 -0.0107269 9.04028 0.00547714 8.91465C0.0216812 8.78901 0.0733444 8.67059 0.154417 8.57326L3.13775 4.99259L0.261084 1.41793C0.150223 1.2801 0.0986538 1.10388 0.11772 0.928037C0.136786 0.752191 0.224926 0.59112 0.362751 0.48026C0.500575 0.369399 0.676793 0.31783 0.85264 0.336896C1.02849 0.355962 1.18956 0.444102 1.30042 0.581926L4.51908 4.58193C4.6156 4.70191 4.6676 4.8516 4.66626 5.00557C4.66493 5.15954 4.61033 5.30831 4.51175 5.42659L1.17842 9.42659C1.11588 9.50167 1.0376 9.56209 0.949123 9.60357C0.860646 9.64504 0.764133 9.66656 0.666417 9.66659Z"
        fill="#FA2F93"
      />
    </svg>
  )
}

export default ArrowPink
