export const LogoutIcon = (props: any) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16667 16.6666C3.94565 16.6666 3.73369 16.5788 3.57741 16.4225C3.42113 16.2662 3.33333 16.0543 3.33333 15.8333V4.16659C3.33333 3.94557 3.42113 3.73361 3.57741 3.57733C3.73369 3.42105 3.94565 3.33325 4.16667 3.33325H5.83333C6.05435 3.33325 6.26631 3.42105 6.42259 3.57733C6.57887 3.73361 6.66667 3.94557 6.66667 4.16659C6.66667 4.3876 6.57887 4.59956 6.42259 4.75584C6.26631 4.91212 6.05435 4.99992 5.83333 4.99992H5V14.9999H5.83333C6.05435 14.9999 6.26631 15.0877 6.42259 15.244C6.57887 15.4003 6.66667 15.6122 6.66667 15.8333C6.66667 16.0543 6.57887 16.2662 6.42259 16.4225C6.26631 16.5788 6.05435 16.6666 5.83333 16.6666H4.16667ZM13.6667 13.9999C13.5791 13.9343 13.5054 13.852 13.4496 13.7578C13.3938 13.6637 13.3572 13.5594 13.3417 13.4511C13.3262 13.3428 13.3322 13.2324 13.3594 13.1264C13.3866 13.0204 13.4343 12.9208 13.5 12.8333L15 10.8333H8.33333C8.11232 10.8333 7.90036 10.7455 7.74408 10.5892C7.5878 10.4329 7.5 10.2209 7.5 9.99992C7.5 9.7789 7.5878 9.56694 7.74408 9.41066C7.90036 9.25438 8.11232 9.16658 8.33333 9.16658H15C15.0242 9.17008 15.0481 9.17509 15.0717 9.18159L13.64 7.14575C13.5268 6.96513 13.4871 6.74797 13.5291 6.53896C13.571 6.32995 13.6916 6.14498 13.8658 6.02211C14.04 5.89924 14.2547 5.84782 14.4656 5.87841C14.6766 5.909 14.8679 6.01929 15 6.18659L17.345 9.51992C17.4468 9.66355 17.5006 9.83575 17.4985 10.0118C17.4964 10.1879 17.4386 10.3587 17.3333 10.4999L14.8333 13.8333C14.7677 13.9208 14.6854 13.9946 14.5912 14.0503C14.4971 14.1061 14.3929 14.1427 14.2845 14.1582C14.1762 14.1737 14.0659 14.1677 13.9598 14.1405C13.8538 14.1134 13.7542 14.0656 13.6667 13.9999Z"
      fill="currentColor"
    />
  </svg>
)
