export const RightBracket = () => (
  <svg
    width="10"
    height="46"
    viewBox="0 0 10 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.65182 45.4998H1.15182V44.9998V43.9238V43.4238H1.65182H3.30216C5.74197 43.4238 7.72724 41.4381 7.72724 38.9987V7.00174C7.72724 4.56192 5.74154 2.57666 3.30216 2.57666H1.65182H1.15182V2.07666V1.00024V0.500244H1.65182H3.30216C6.88778 0.500244 9.80365 3.41611 9.80365 7.00174V38.9983C9.80365 42.5839 6.88727 45.4998 3.30216 45.4998H1.65182Z"
      fill="#D2D4D7"
      stroke="#D2D4D7"
    />
  </svg>
)
