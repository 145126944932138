import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import GoogleAndFacebookButtons from 'src/pages/auth/components/google-facebook-login'
import { SignInForm } from 'src/pages/auth/sign-in'
import { FormSignUpOnly } from 'src/pages/auth/sign-up'

import { useOta } from '../context'
import { useModalLogin } from '../login.context'
import { Header } from '../reservation-code'

const LoginStep = () => {
  const { handleSubmit } = useOta()
  const { setStep } = useModalLogin()
  const [isMobile] = useMediaQuery(['(max-width: 578px'])

  const Body = isMobile ? DrawerBody : ModalBody
  const ContentHeader = isMobile ? DrawerHeader : ModalHeader

  return (
    <>
      <ContentHeader>
        <Header withArrow back={() => setStep('access')} title="Entrar" />
        <Divider borderColor="black.200" />
        <Text mt="24px">Insira seus dados para continuar:</Text>
      </ContentHeader>
      <Body>
        <SignInForm onFinish={handleSubmit} />
        <Flex my="40px" align="center" direction="column">
          <Flex mb="16px" w="full" gap="16px" direction="column">
            <GoogleAndFacebookButtons onFinish={handleSubmit} />
          </Flex>
          <Button
            isFullWidth
            colorScheme="primary"
            color="primary.500"
            variant="ghost"
            onClick={() => setStep('register')}
          >
            Criar nova conta
          </Button>
        </Flex>
      </Body>
    </>
  )
}

const AccessStep = () => {
  const { setStep } = useModalLogin()
  const [isMobile] = useMediaQuery(['(max-width: 578px'])

  const Body = isMobile ? DrawerBody : ModalBody
  const ContentHeader = isMobile ? DrawerHeader : ModalHeader

  return (
    <>
      <ContentHeader my="40px">
        <Flex>
          <Header
            withoutButton
            back={() => setStep('access')}
            title="Acesse sua conta para continuar"
          />
        </Flex>
        <Divider borderColor="black.200" />
      </ContentHeader>
      <Body mb={['40px', '0px']}>
        <Flex direction="column" w="full" align="center">
          <Button
            colorScheme="primary"
            bg="primary.500"
            minH="40px"
            isFullWidth
            onClick={() => setStep('login')}
          >
            Entrar
          </Button>
          <Button
            variant="ghost"
            colorScheme="primary"
            color="primary.500"
            minH="40px"
            mt="8px"
            isFullWidth
            onClick={() => setStep('register')}
          >
            Criar novo acesso
          </Button>
          <Button
            variant="ghost"
            mb="20px"
            minH="40px"
            isFullWidth
            mt="40px"
            onClick={() => setStep('exit')}
          >
            Cancelar
          </Button>
        </Flex>
      </Body>
    </>
  )
}

const RegisterStep = () => {
  const { setStep } = useModalLogin()
  const { handleSubmit } = useOta()
  const [isMobile] = useMediaQuery(['(max-width: 578px'])

  const Body = isMobile ? DrawerBody : ModalBody
  const ContentHeader = isMobile ? DrawerHeader : ModalHeader

  return (
    <>
      <ContentHeader>
        <Header withArrow back={() => setStep('access')} title="Entrar" />
        <Divider borderColor="black.200" />
        <Text mt="24px">Insira seus dados para continuar:</Text>
      </ContentHeader>
      <Body>
        <FormSignUpOnly onFinish={handleSubmit} />
        <Flex my="40px" align="center" direction="column">
          <Flex mb="16px" w="full" gap="16px" direction="column">
            <GoogleAndFacebookButtons onFinish={handleSubmit} />
          </Flex>
          <Button
            isFullWidth
            colorScheme="primary"
            color="primary.500"
            variant="ghost"
            onClick={() => setStep('login')}
          >
            Já possuo cadastro
          </Button>
        </Flex>
      </Body>
    </>
  )
}

const ExitStep = () => {
  const { setStep, onClose } = useModalLogin()
  const [isMobile] = useMediaQuery(['(max-width: 578px'])

  const Body = isMobile ? DrawerBody : ModalBody
  const ContentHeader = isMobile ? DrawerHeader : ModalHeader

  return (
    <>
      <ContentHeader my="40px">
        <Flex>
          <Header
            withoutButton
            withArrow={false}
            back={() => setStep('access')}
            title="Deseja sair sem concluir?"
          />
        </Flex>
        <Divider borderColor="black.200" />
        <ModalCloseButton onClick={() => setStep('exit')} />
      </ContentHeader>
      <Body>
        <Flex direction="column" w="full" align="center">
          <Text>
            As informações não serão salvas se o processo nao for concluído{' '}
          </Text>
          <Button
            minH="40px"
            mt="40px"
            colorScheme="black"
            isFullWidth
            onClick={() => onClose()}
          >
            Sair mesmo assim
          </Button>
          <Button
            colorScheme="primary"
            color="primary.500"
            variant="ghost"
            minH="40px"
            mt="8px"
            mb="40px"
            isFullWidth
            onClick={() => setStep('register')}
          >
            Criar novo acesso
          </Button>
        </Flex>
      </Body>
    </>
  )
}

const AuthModal = () => {
  const { isOpen, step } = useModalLogin()
  const [isMobile] = useMediaQuery(['(max-width: 578px)'])

  const Root = isMobile ? Drawer : Modal
  const Content = isMobile ? DrawerContent : ModalContent
  const Overlay = isMobile ? DrawerOverlay : ModalOverlay

  return (
    <Root isCentered placement="bottom" isOpen={isOpen} onClose={() => {}}>
      <Overlay />
      <Content
        bg="black.0"
        borderTopRadius="16px"
        borderBottomRadius={['0px', '16px']}
      >
        {step === 'access' && <AccessStep />}
        {step === 'login' && <LoginStep />}
        {step === 'register' && <RegisterStep />}
        {step === 'exit' && <ExitStep />}
      </Content>
    </Root>
  )
}

export default AuthModal
