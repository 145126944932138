export const ArrowDownIcon = (props: any) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16667 8.33327C4.16673 8.17492 4.2119 8.01987 4.29689 7.88627C4.38189 7.75267 4.50319 7.64605 4.64659 7.5789C4.78999 7.51174 4.94955 7.48684 5.1066 7.50709C5.26364 7.52735 5.41166 7.59192 5.53333 7.69327L10.0092 11.4224L14.4775 7.8266C14.6498 7.68802 14.8701 7.62356 15.0899 7.64739C15.3097 7.67123 15.511 7.7814 15.6496 7.95368C15.7882 8.12596 15.8526 8.34624 15.8288 8.56604C15.805 8.78585 15.6948 8.98719 15.5225 9.12577L10.5225 13.1491C10.3725 13.2697 10.1854 13.3347 9.99295 13.3331C9.80048 13.3314 9.61452 13.2632 9.46667 13.1399L4.46667 8.97327C4.37281 8.89509 4.29729 8.79724 4.24545 8.68665C4.19361 8.57605 4.16671 8.45541 4.16667 8.33327Z"
      fill="currentColor"
    />
  </svg>
)
