import * as c from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import NavLink from '../nav-link'
import { LinksConfig } from './links.config'

export default function Links() {
  const { t } = useTranslation('footer')
  const { firstColumnItems, secondColumnItems, thirdColumnItems } =
    LinksConfig()

  return (
    <c.Flex direction={['column', 'row']} wrap="wrap" gap={['m5', 'l3']}>
      <c.Flex
        w={['100%', '95px']}
        maxW={['100%', '95px']}
        direction="column"
        gap="s2"
      >
        <c.Text color="black.600" fontSize="t-md.0" fontWeight="bold">
          {t('footer:SERVICES')}
        </c.Text>
        {firstColumnItems.map(({ isExternal, label, path }) => (
          <NavLink
            key={label}
            label={label as string}
            path={path}
            isExternal={isExternal}
          />
        ))}
      </c.Flex>

      <c.Flex
        w={['100%', '115px']}
        maxW={['100%', '115px']}
        direction="column"
        gap="s2"
      >
        <c.Text color="black.600" fontSize="t-md.0" fontWeight="bold">
          {t('footer:ABOUT_US')}
        </c.Text>
        {secondColumnItems.map(({ label, path, isExternal }) => (
          <NavLink
            key={label}
            label={label as string}
            path={path}
            isExternal={isExternal}
          />
        ))}
      </c.Flex>

      <c.Flex
        w={['100%', '189px']}
        maxW={['100%', '189px']}
        direction="column"
        gap="s2"
      >
        <c.Text color="black.600" fontSize="t-md.0" fontWeight="bold">
          {t('footer:SUPPORT')}
        </c.Text>
        {thirdColumnItems.map(({ label, path, isExternal }) => (
          <NavLink
            key={label}
            label={label as string}
            path={path}
            isExternal={isExternal}
          />
        ))}
      </c.Flex>
    </c.Flex>
  )
}
