import { Box, Flex, Text } from '@chakra-ui/react'
import Dinero, { DineroObject } from 'dinero.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearch } from 'src/components/search-v2/search-context'
import { CLEANING_TAX_REGEX } from 'src/constants'
import { convertToMoney } from 'src/utils/monetary'

import { PropertyCardContext } from '../../../../property-card.context'

const PriceNightItem = ({ text, amount }) => (
  <Flex my="2px" justifyContent="space-between">
    <Text fontSize="14px">{text}</Text>
    <Text fontSize="14px">{amount}</Text>
  </Flex>
)

const Night = () => {
  const { t } = useTranslation([
    'card-apartment',
    'property-detail/booking-resume',
  ])

  const { context } = useSearch()

  const { property } = React.useContext(PropertyCardContext)

  const cleaningFee = React.useMemo(
    () =>
      property.price?.total?.fees?.find((fee: any) =>
        CLEANING_TAX_REGEX.test(JSON.stringify(fee.name))
      )?.amount ?? { amount: 0, currency: 'BRL', precision: 2 },
    [property.price?.total?.fees]
  ) as DineroObject

  const priceObject = React.useMemo(
    () =>
      Dinero(property?.price?.total?.grossAmount)
        .subtract(Dinero(cleaningFee))
        .toObject(),
    [property?.price?.total?.grossAmount, cleaningFee]
  )

  const nightPrice = React.useMemo(() => {
    if (!context.dailyCount) return property?.price?.daily?.grossAmount

    return Dinero(priceObject)
      .divide(context.dailyCount ?? 1)
      .toObject()
  }, [property?.price?.daily?.grossAmount, priceObject, context.dailyCount])

  return (
    <>
      <PriceNightItem
        text={t('property-detail/booking-resume:DAILY', {
          number: context.dailyCount,
        })}
        amount={`${convertToMoney(nightPrice)}/${t('card-apartment:NIGHT')}`}
      />
      <PriceNightItem
        text={t('property-detail/booking-resume:CLEANING_SHORT')}
        amount={convertToMoney(cleaningFee)}
      />
      <Box
        width="100%"
        height="1px"
        margin="4px 0px"
        backgroundColor="black.200"
      />

      <Flex justifyContent="space-between">
        <Text fontSize="14px" fontWeight="bold">
          {t('card-apartment:TOTAL_RENT')}
        </Text>
        <Text fontSize="14px" fontWeight="bold">
          {convertToMoney(property?.price?.total.grossAmount)}
        </Text>
      </Flex>
    </>
  )
}

export default Night
