import { css } from 'styled-components'

const primary = css`
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;

  :hover {
    background: var(--primary-color-dark-1);
    border-color: var(--primary-color-dark-1);
  }

  :disabled {
    background: var(--primary-color-light-2);
    border-color: var(--primary-color-light-2);
  }
`

const secondary = css`
  background: var(--secondary-color);
  border-color: var(--secondary-color);
  color: #fff;

  :hover {
    background: var(--secondary-color-dark-1);
    border-color: var(--secondary-color-dark-1);
  }

  :disabled {
    background: var(--secondary-color-light-2);
    border-color: var(--secondary-color-light-2);
  }
`

const black = css`
  background: var(--black-color);
  border-color: var(--black-color);
  color: #fff;

  :hover {
    background: var(--black-color-2);
    border-color: var(--black-color-2);
  }

  :disabled {
    background: var(--gray-color-2);
    border-color: var(--gray-color-2);
  }
`

const danger = css`
  background: var(--danger-color);
  border-color: var(--danger-color);
  color: #fff;

  :hover {
    background: #b21235;
    border-color: #b21235;
  }

  :disabled {
    background: #ef8099;
    border-color: #ef8099;
  }
`

export const solidStyles = { primary, secondary, black, danger }
