import * as c from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { SocialNetworkConfig } from './social-network.config'

export default function SocialNetwork() {
  const { t } = useTranslation('footer')
  const socialNetworkItems = SocialNetworkConfig()

  return (
    <c.Flex
      direction={['column', 'row']}
      align={['start', 'center']}
      justify={['start', 'flex-end']}
      w="330px"
      gap={['s3', 's5']}
    >
      <c.Text color="black.500" fontSize="t-md.0" fontWeight="medium">
        {t('FOLLOW')}
      </c.Text>

      <c.Flex gap="s4">
        {socialNetworkItems.map(({ icon, path }) => (
          <c.Link
            key={path}
            href={path}
            isExternal
            _hover={{
              svg: {
                path: {
                  fill: 'primary.600',
                },
              },
            }}
          >
            {icon}
          </c.Link>
        ))}
      </c.Flex>
    </c.Flex>
  )
}
