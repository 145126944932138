import React from 'react'

const OutIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 16C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0L3 0C3.26522 0 3.51957 0.105357 3.70711 0.292893C3.89464 0.48043 4 0.734784 4 1C4 1.26522 3.89464 1.51957 3.70711 1.70711C3.51957 1.89464 3.26522 2 3 2H2V14H3C3.26522 14 3.51957 14.1054 3.70711 14.2929C3.89464 14.4804 4 14.7348 4 15C4 15.2652 3.89464 15.5196 3.70711 15.7071C3.51957 15.8946 3.26522 16 3 16H1ZM12.4 12.8C12.2949 12.7212 12.2064 12.6225 12.1395 12.5095C12.0726 12.3965 12.0286 12.2714 12.0101 12.1414C11.9915 12.0114 11.9987 11.879 12.0313 11.7518C12.0639 11.6246 12.1212 11.5051 12.2 11.4L14 9H6C5.73478 9 5.48043 8.89464 5.29289 8.70711C5.10536 8.51957 5 8.26522 5 8C5 7.73478 5.10536 7.48043 5.29289 7.29289C5.48043 7.10536 5.73478 7 6 7H14C14.029 7.00419 14.0577 7.01021 14.086 7.018L12.368 4.575C12.2321 4.35825 12.1845 4.09766 12.2349 3.84685C12.2853 3.59604 12.4299 3.37408 12.6389 3.22663C12.848 3.07919 13.1056 3.01748 13.3588 3.05419C13.612 3.0909 13.8414 3.22325 14 3.424L16.814 7.424C16.9362 7.59636 17.0007 7.803 16.9982 8.01428C16.9956 8.22555 16.9263 8.4306 16.8 8.6L13.8 12.6C13.7212 12.7051 13.6225 12.7936 13.5095 12.8605C13.3965 12.9274 13.2714 12.9714 13.1414 12.9899C13.0114 13.0085 12.879 13.0013 12.7518 12.9687C12.6246 12.9361 12.5051 12.8788 12.4 12.8Z"
        fill="#6C6C6C"
      />
    </svg>
  )
}

export default OutIcon
