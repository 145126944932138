import { Flex } from '@chakra-ui/react'
import { GoogleMap, MarkerClusterer } from '@react-google-maps/api'
import qs from 'qs'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDebounce } from 'rooks'
import useMessages from 'src/hooks/messages/use-messages'

import { useMaps } from './context'
import Loading from './loading'
import Pin from './pin'

const List = React.memo(
  ({ map, clusterer }: { map?: google.maps.Map; clusterer: any }) => {
    const { context } = useMaps()

    return (
      <>
        {context.pins.length !== 0 &&
          context.pins.map(
            ({ count, lat, lng, buildingId, flagship, isPartner }) => (
              <React.Fragment key={`${lat}${lng}`}>
                <Pin
                  clusterer={clusterer}
                  lat={lat}
                  lng={lng}
                  map={map}
                  label={String(count)}
                  buildingId={buildingId}
                  isFlagship={flagship}
                  isPartner={isPartner}
                />
              </React.Fragment>
            )
          )}
      </>
    )
  }
)

const Map = () => {
  const [, setSearchParams] = useSearchParams()
  const { onMove, onZoom, context } = useMaps()
  const [map, setMap] = React.useState<google.maps.Map>()
  const { value } = useMessages<boolean>('maps')

  const center = React.useMemo(
    () => ({
      lat: context?.filters?.lat!,
      lng: context?.filters?.lng!,
    }),
    [context?.filters?.lat, context?.filters?.lng]
  )

  const onLoad = React.useCallback((googleMaps) => {
    setMap(googleMaps)
  }, [])

  const handleMove = React.useCallback(() => {
    if (!map) return
    onMove({
      zoom: map?.getZoom()!,
      lat: map?.getCenter()?.toJSON().lat!,
      lng: map?.getCenter()?.toJSON().lng!,
    })

    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }) as any
    const jsonString = JSON.stringify([
      map?.getCenter()?.toJSON().lat!,
      map?.getCenter()?.toJSON().lng!,
    ])
    const codedCoords = Buffer.from(jsonString).toString('base64')

    setSearchParams({ ...params, position: codedCoords })
  }, [map])

  const handleZoom = useDebounce(() => {
    if (!map) return

    // zoom to pin and request update this value
    // zoom to pin set true and request set false.
    if (value?.data) return

    onZoom({
      zoom: map?.getZoom()!,
      lat: map?.getCenter()?.toJSON().lat!,
      lng: map?.getCenter()?.toJSON().lng!,
    })
  }, 800)

  const icon = React.useMemo(
    () => ({
      url: 'https://housi-images.s3.amazonaws.com/site/pins/pin_preto.svg',
      width: 80,
      height: 80,
      textSize: 14,
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
      textColor: '#474747',
    }),
    []
  )

  const iconFlag = React.useMemo(
    () => ({
      url: 'https://housi-images.s3.amazonaws.com/site/pins/pin_rosa.svg',
      width: 80,
      height: 80,
      textSize: 14,
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
      textColor: '#474747',
    }),
    []
  )
  const calculator = React.useCallback((markers: any[]) => {
    const count = markers.reduce((prev, current) => {
      return prev + Number(current.label.text)
    }, 0)

    if (markers.some((curr) => curr.label.isFlagship)) {
      return {
        index: 2,
        text: count,
        title: count,
      }
    }

    return {
      index: 1,
      text: count,
      title: count,
    }
  }, [])

  return (
    <Flex
      bg="black.200"
      direction="column"
      align="center"
      pos="relative"
      w="100%"
      h="100%"
    >
      <Loading />
      <GoogleMap
        mapContainerStyle={{ height: '100%', width: '100%' }}
        zoom={context.filters.zoom}
        center={center}
        onLoad={onLoad}
        onZoomChanged={handleZoom}
        onDragEnd={handleMove}
        options={{
          styles: [
            {
              featureType: 'landscape.man_made',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'poi.place_of_worship',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'poi.business',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'simplified',
                },
              ],
            },
          ],
          scrollwheel: true,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          noClear: true,
          clickableIcons: false,
          minZoom: 14,
          gestureHandling: 'greedy',
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
        }}
      >
        <MarkerClusterer
          calculator={calculator as any}
          options={{ styles: [icon, iconFlag], imageSizes: [40], maxZoom: 20 }}
        >
          {(clusterer) => <List clusterer={clusterer} map={map} />}
        </MarkerClusterer>
      </GoogleMap>
    </Flex>
  )
}

export default Map
