import moment from 'moment'
import { useCallback, useState } from 'react'

import { verifyCouponValid } from '../../requests/coupon'

const initial = {
  value: '',
  amount: 0,
  valid: false,
  discountValue: 0,
  originalValue: 0,
  finalValue: 0,
}

export const useCoupon = ({
  isSubscription = true,
  guests = 1,
  propertyId,
  from = null,
  to = null,
  range = [null, null],
}) => {
  const [loading, setLoading] = useState(false)
  const [coupon, setCoupon] = useState(initial)

  const dateToParams = useCallback(() => {
    if (!from && !to) return

    return {
      checkInDate: moment(from).format('YYYY-MM-DD'),
      checkOutDate: moment(to).format('YYYY-MM-DD'),
      isSubscription,
    }
  }, [from, to, range])

  const validateCoupon = useCallback(
    async (value) => {
      const dateParams = dateToParams()

      if (dateParams === null) throw Error('invalid dates')

      const params = {
        name: value,
        propertyId,
        guests,
        ...dateParams,
      }

      setLoading(true)

      try {
        const { data } = await verifyCouponValid({ params })
        const result = {
          value,
          valid: true,
          ...data,
        }

        setCoupon(result)
        return result
      } catch (e) {
        // some error
        setCoupon({
          valid: false,
          amount: 0,
          discountValue: 0,
          originalValue: 0,
          finalValue: 0,
        })

        throw new Error(e)
      } finally {
        setLoading(false)
      }
    },
    [propertyId, dateToParams, guests]
  )

  const deleteCoupon = useCallback(() => {
    setCoupon(initial)
  }, [])

  return { coupon, couponLoading: loading, validateCoupon, deleteCoupon }
}
