import { Box } from '@retriever-ui/react'
import { motion } from 'framer-motion'
import React from 'react'
import useScrollBlock from 'src/hooks/use-scroll-block'
import { useWindowWidth } from 'src/store/context/window.context'

import { variantsMobile } from '../animation'
import { SearchContext } from '../search-context'
import { StepChild } from '../steps'
import StepAutocomplete from './steps/autocomplete'
import StepDate from './steps/dates'

const HotContent = React.lazy(() => import('../hot-listing'))

const SearchMobile = () => {
  const { windowWidth } = useWindowWidth()
  const { controls, valid } = React.useContext(SearchContext)

  const { blockScroll, allowScroll } = useScrollBlock()

  React.useEffect(() => {
    if (valid.open) {
      blockScroll()
    }
    return () => {
      allowScroll()
    }
  }, [valid])

  if (windowWidth > 768) return null

  return (
    <motion.div
      variants={variantsMobile}
      animate={controls.open}
      initial={valid.open ? 'open' : 'closed'}
    >
      <StepChild step={0}>
        <Box w="100%">
          <StepAutocomplete />
          <React.Suspense fallback="">
            <HotContent />
          </React.Suspense>
        </Box>
      </StepChild>
      <StepChild step={1}>
        <StepDate />
      </StepChild>
    </motion.div>
  )
}

export default SearchMobile
