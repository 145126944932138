import React from 'react'

const Location = ({ color = '#6C6C6C' }) => {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 21.0001C7.76106 20.9998 7.53012 20.914 7.349 20.7581C5.97683 19.5484 4.70994 18.2243 3.562 16.8001C2.6112 15.6411 1.7878 14.3832 1.106 13.0481C0.426981 11.7753 0.0486331 10.3639 0 8.92212C0 6.80039 0.842855 4.76556 2.34315 3.26526C3.84344 1.76497 5.87827 0.922119 8 0.922119C10.1217 0.922119 12.1566 1.76497 13.6569 3.26526C15.1571 4.76556 16 6.80039 16 8.92212C15.9509 10.3629 15.5726 11.7732 14.894 13.0451C14.2124 14.3813 13.389 15.6402 12.438 16.8001C11.2902 18.2254 10.0233 19.5505 8.651 20.7611C8.46945 20.9159 8.23856 21.0007 8 21.0001ZM8 3.00012C6.41959 2.9916 4.90033 3.6103 3.77551 4.7205C2.65069 5.8307 2.01216 7.34173 2 8.92212C2 10.5131 2.8 12.4151 4.372 14.5751C5.46031 16.0353 6.67427 17.3975 8 18.6461C9.32594 17.3977 10.5399 16.0355 11.628 14.5751C13.2 12.4151 14 10.5131 14 8.92212C13.9878 7.34173 13.3493 5.8307 12.2245 4.7205C11.0997 3.6103 9.58041 2.9916 8 3.00012ZM8 12.0001C7.30777 12.0001 6.63108 11.7948 6.0555 11.4103C5.47993 11.0257 5.03133 10.4791 4.76642 9.83951C4.50151 9.19997 4.4322 8.49624 4.56725 7.8173C4.7023 7.13837 5.03564 6.51473 5.52513 6.02525C6.01461 5.53576 6.63825 5.20242 7.31718 5.06737C7.99612 4.93232 8.69985 5.00163 9.33939 5.26654C9.97893 5.53145 10.5256 5.98005 10.9101 6.55562C11.2947 7.1312 11.5 7.80788 11.5 8.50012C11.5 9.42838 11.1313 10.3186 10.4749 10.975C9.8185 11.6314 8.92826 12.0001 8 12.0001ZM8 7.00012C7.70333 7.00012 7.41332 7.08809 7.16664 7.25291C6.91997 7.41774 6.72771 7.652 6.61418 7.92609C6.50065 8.20018 6.47094 8.50178 6.52882 8.79275C6.5867 9.08373 6.72956 9.351 6.93934 9.56078C7.14912 9.77056 7.41639 9.91342 7.70736 9.9713C7.99834 10.0292 8.29994 9.99947 8.57403 9.88594C8.84811 9.77241 9.08238 9.58015 9.2472 9.33347C9.41203 9.0868 9.5 8.79679 9.5 8.50012C9.5 8.10229 9.34196 7.72076 9.06066 7.43946C8.77936 7.15815 8.39782 7.00012 8 7.00012Z"
        fill={color}
      />
    </svg>
  )
}

export default Location
