import * as c from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PinIcon } from 'src/assets/icons/pin.icon'

import loadingSrc from '../../../../assets/images/loading-horizontal.gif'
import { AutocompleteContext } from '../autocomplete-context'
import OptionItem from './item'

export type AutocompleteValueType = {
  value: string
  label: string
}

interface OptionsProps {
  options?: AutocompleteValueType[]
  resultMessage?: string
  onSelect: (e?: any) => void
}

const OptionsContent = ({ options, onSelect }: OptionsProps) => {
  const { t } = useTranslation('components/search')
  const { context, valid } = React.useContext(AutocompleteContext)

  const resultMessage =
    !valid.autocomplete.changing && context.inputValue.length === 0
      ? t('components/search:autocomplete.suggested')
      : t('components/search:autocomplete.result')

  if (valid.request.pending) {
    return (
      <c.Flex px={5} mt={3} direction="column" align="center">
        <c.Image maxH="16px" src={loadingSrc} alt="loading..." />
      </c.Flex>
    )
  }

  return (
    <>
      <c.Flex px={4} align="center" gap={2}>
        <PinIcon color="#A3A3A3" width={15} height={19} />
        <c.Heading color="black.500" fontSize={12} fontWeight={700}>
          {resultMessage}
        </c.Heading>
      </c.Flex>

      <c.Flex pos="relative" zIndex={0} direction="column" gap={1} mt={2}>
        {options?.map(({ label, value }) => (
          <OptionItem
            key={value}
            onSelect={onSelect}
            option={{ label, value }}
          />
        ))}
      </c.Flex>
    </>
  )
}

const Options = ({ options, onSelect }: OptionsProps) => {
  return (
    <c.Box
      pos="absolute"
      top="90%"
      zIndex={2}
      py={4}
      minW={['100%', '389px']}
      maxW={['100%', '389px']}
      bg="black.0"
      borderBottomLeftRadius="8px"
      borderBottomRightRadius="8px"
      border="1px"
      borderStyle="solid"
      borderTopColor="transparent"
      borderBottomColor="black.500"
      borderLeftColor="black.500"
      borderRightColor="black.500"
    >
      <OptionsContent onSelect={onSelect} options={options} />
    </c.Box>
  )
}
export default Options
