import { useMachine } from '@xstate/react'
import React from 'react'
import { useMapAutocomplete } from 'src/hooks/map/autocomplete'
import useGeocorder, { ResultType } from 'src/hooks/map/geocorder'
import { EventData } from 'xstate'

import autocompleteMachine, {
  autocomplete_states,
  ContextType,
  Events,
  input_states,
  request_states,
  States,
} from './autocomplete-machine'

type SendEvent = (event: Events, payload?: EventData | undefined) => void

type ValidState = {
  [state in keyof States]: {
    [key in States[state]]: boolean
  }
}

interface AutocompleteContextProps {
  send: SendEvent
  states: States
  context: ContextType
  valid: ValidState
  getGeocoderByPlaceId: (payload: {
    address: string
    placeId: string
  }) => Promise<ResultType>
}

interface AutocompleteProviderProps {
  children?: React.ReactNode
}

export const AutocompleteContext = React.createContext(
  {} as AutocompleteContextProps
)

export const useSearching = () => {
  const { getDescriptionAndLocation } = useMapAutocomplete()
  const { getGeocorderByPlaceId: getGeocoderByPlaceId } = useGeocorder()

  return {
    getDescriptionAndLocation,
    getGeocoderByPlaceId,
  }
}

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const AutocompleteProvider = ({ children }: AutocompleteProviderProps) => {
  const { getDescriptionAndLocation, getGeocoderByPlaceId } = useSearching()

  const request = async (context: ContextType) => {
    if (context.inputValue.length === 0) {
      await sleep(300)
      return context.options
    }

    await sleep(300)
    const response = await getDescriptionAndLocation(context.inputValue)

    const options = response.map((options) => ({
      label: options.address,
      value: options.placeId,
    }))

    return options
  }

  const [current, send] = useMachine(autocompleteMachine, {
    services: {
      request,
    },
  })

  const states = current.value as unknown as States
  const context = current.context

  const valid: ValidState = {
    input: {
      disabled: current.matches({ input: input_states.disabled } as any),
      focused: current.matches({ input: input_states.focused } as any),
      idle: current.matches({ input: input_states.idle } as any),
    },
    autocomplete: {
      changing: current.matches({
        autocomplete: autocomplete_states.changing,
      } as any),
      idle: current.matches({ autocomplete: autocomplete_states.idle } as any),
      stopped: current.matches({
        autocomplete: autocomplete_states.stopped,
      } as any),
    },
    request: {
      idle: current.matches({ request: request_states.idle } as any),
      error: current.matches({ request: request_states.error } as any),
      pending: current.matches({ request: request_states.pending } as any),
      success: current.matches({ request: request_states.success } as any),
    },
  }

  return (
    <AutocompleteContext.Provider
      value={{
        getGeocoderByPlaceId,
        context,
        send,
        states,
        valid,
      }}
    >
      {children}
    </AutocompleteContext.Provider>
  )
}

export default AutocompleteProvider
