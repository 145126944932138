import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useCommonCard } from '../../common'
import { PropertyCardContext } from '../../property-card.context'
import { usePreviewCard } from '..'
import AptReviewFooter from './components/apt-footer'
import AptItems from './components/apt-items'
import GalleryPreview from './components/gallery-preview'
import Info from './components/info'
import PackInfo from './components/pack-info'
import Price from './components/price'

const Desktop = React.memo(() => {
  const { t } = useTranslation('card-apartment')

  const { property } = React.useContext(PropertyCardContext)
  const { onClose } = usePreviewCard()

  const location = useLocation()

  const { gallery } = useCommonCard({
    property,
  })

  const isOpen = location.pathname.includes(
    `/properties/preview/${property?.aliasId}`
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" motionPreset="scale">
      <ModalOverlay />
      <ModalContent rounded="md">
        <ModalCloseButton />
        <ModalHeader>
          <Text fontSize="20px" fontWeight={700} color="black.500">
            {t('card-apartment:PREVIEW')}
          </Text>
        </ModalHeader>
        <ModalBody>
          <Flex direction="column" marginTop="24px" gap="20px">
            <GalleryPreview gallery={gallery} property={property} />
            <Text fontSize="16px" fontWeight="bold" color="black.500">
              {property?.aliasName}
            </Text>
            <Info />
            <AptItems />
            <PackInfo />
            <Price />
            <AptReviewFooter onClose={onClose} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
})
const Mobile = React.memo(() => {
  const { onClose } = usePreviewCard()
  const { t } = useTranslation('card-apartment')

  const { property } = React.useContext(PropertyCardContext)
  const location = useLocation()

  const { gallery } = useCommonCard({
    property,
  })

  const isOpen = location.pathname.includes(
    `/properties/preview/${property?.aliasId}`
  )

  return (
    <Drawer
      variant="preview"
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
    >
      <DrawerOverlay />
      <DrawerContent
        bg="black.0"
        borderTopRadius="16px"
        borderBottomRadius="0px"
        top="60px"
      >
        <DrawerCloseButton />
        <DrawerHeader>
          <Text fontSize="20px" fontWeight={700} color="black.500">
            {t('card-apartment:PREVIEW')}
          </Text>
        </DrawerHeader>
        <DrawerBody h="100%" bg="black.0">
          <Flex direction="column" marginTop="24px" gap="20px">
            <GalleryPreview gallery={gallery} property={property} />
            <Text fontSize="16px" fontWeight="bold" color="black.500">
              {property?.aliasName}
            </Text>
            <Info />
            <AptItems />
            <PackInfo />
            <Price />
            <AptReviewFooter onClose={onClose} />
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
})
const ApartmentPreview = () => {
  const [isMobile] = useMediaQuery('(max-width: 720px)')

  if (isMobile) return <Mobile />

  return <Desktop />
}

export default ApartmentPreview
