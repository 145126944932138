import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import reducers from './redux'

export const history = createBrowserHistory({
  forceRefresh: false,
})

const prodMiddleware = [routerMiddleware(history)]
const middlewares = composeWithDevTools(applyMiddleware(...prodMiddleware))
const rootReducer = combineReducers({
  router: connectRouter(history),
  ...reducers,
})

const store = createStore(rootReducer, middlewares)

export default store
