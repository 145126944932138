import BrazilFlagIcon from 'src/components/navbar/icons/brazil-flag.icon'
import USAFlagIcon from 'src/components/navbar/icons/usa-flag.icon'

const acceptLanguages = [
  {
    CODE: 'pt-BR',
    LABEL: 'Pt-BR',
    HEADER_CODE: 'pt_BR',
    ICON: <BrazilFlagIcon />,
  },
  {
    CODE: 'en',
    LABEL: 'En-US',
    HEADER_CODE: 'en_US',
    ICON: <USAFlagIcon />,
  },
]

export default acceptLanguages
