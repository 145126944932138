import * as c from '@chakra-ui/react'
import React from 'react'

import Fees from '../../booking/resume/fees'
import { PackHousiValues } from '../../booking/resume/pack-housi'
import { RentPrice } from '../../booking/resume/subscription'
import TotalSubscription from '../../booking/resume/total/total-subscription'
import { PropertyDetailContext } from '../../property-detail.context'
import { BillItem, usePackHousi } from '.'

interface ListBillItemProps {
  text: string
  haveBill: boolean
}

export const ListBillItem = ({ text, haveBill }: ListBillItemProps) => (
  <c.Flex justify="space-between" align="center">
    <BillItem textColor={haveBill} text={text} status={haveBill} />
    <c.Text
      fontSize={12}
      fontWeight={500}
      color={haveBill ? 'black.500' : 'black.400'}
    >
      {haveBill ? 'Incluso' : 'Não incluso'}
    </c.Text>
  </c.Flex>
)

const PackHousiModal = () => {
  const { property } = React.useContext(PropertyDetailContext)
  const { facilitiesBills, livingBills } = usePackHousi({ property: property })
  const { isOpen, onOpen, onClose } = c.useDisclosure()

  const bills = [...facilitiesBills, ...livingBills].sort(
    (a, b) => Number(b.haveBill) - Number(a.haveBill)
  )

  const showWarning = bills.map((x) => x.haveBill)

  return (
    <>
      <c.Flex
        as={c.Button}
        onClick={onOpen}
        px={2}
        mt={2}
        height="44px"
        fontSize={16}
        fontWeight={700}
        bg="transparent"
        color="primary.500"
        gap={2}
        _hover={{
          background: 'transparent',
        }}
      >
        O que é o pacote de moradia?
      </c.Flex>

      <c.Modal isOpen={isOpen} onClose={onClose}>
        <c.ModalOverlay />

        <c.ModalContent py={10} maxW="375px">
          <c.Flex px={5} align="center" justify="space-between">
            <c.Text fontSize={20} fontWeight={700} color="black.500">
              Detalhes dos preços
            </c.Text>
            <c.ModalCloseButton />
          </c.Flex>

          <c.ModalBody>
            <c.Divider mt={3} />

            <c.Flex direction="column" gap={2} mb={6}>
              <RentPrice isModal />
              <Fees isModal />
              <PackHousiValues isModal />
            </c.Flex>

            <c.Box
              p={3}
              border="1px"
              borderColor="black.200"
              borderRadius="8px"
            >
              <c.Heading fontSize={14} mb={3} color="black.500">
                No
                <c.Box as="span" fontWeight={700}>
                  {` pacote de moradia `}
                </c.Box>
                dessa unidade, estão inclusas as taxas de:
              </c.Heading>

              <c.Flex direction="column" gap={2}>
                {bills?.map((bill, key) => (
                  <ListBillItem
                    key={key}
                    text={bill.text}
                    haveBill={bill.haveBill}
                  />
                ))}

                {showWarning.includes(false) && (
                  <c.Text
                    mt={5}
                    fontSize={12}
                    fontWeight={500}
                    color="black.500"
                  >
                    * Itens não inclusos no pacote de moradia devem ser
                    combinados diretamente com o proprietário/ operador da
                    unidade.
                  </c.Text>
                )}
              </c.Flex>
            </c.Box>

            <c.Divider my={6} />
            <TotalSubscription />
          </c.ModalBody>

          <c.ModalFooter mt={10}>
            <c.Button
              onClick={onClose}
              h="44px"
              w="100%"
              bg="black.500"
              color="black.0"
              fontWeight={700}
              fontSize={16}
              py={2.5}
              transition="all 0.2s"
              _hover={{ bg: 'black.600' }}
            >
              Ok, entendi
            </c.Button>
          </c.ModalFooter>
        </c.ModalContent>
      </c.Modal>
    </>
  )
}

export default PackHousiModal
