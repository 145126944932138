import { createApiRequest } from '../../store/api'

export const doUserLogin = async ({ data = {}, onSuccess, onFail }) => {
  return createApiRequest({
    method: 'post',
    url: '/users/login',
    data,
    onSuccess,
    onFail,
  })
}

export const doFacebookLogin = async ({ data }) =>
  await createApiRequest({ url: '/users/login/facebook', method: 'post', data })

export const doGoogleLogin = async ({ data }) =>
  await createApiRequest({ url: '/users/login/google', method: 'post', data })

export const postResetPassword = async ({ data }) =>
  await createApiRequest({
    method: 'post',
    url: '/users/password/reset',
    data,
  })

export const refreshToken = async ({ data, onSuccess, onFail }) => {
  return await createApiRequest({
    method: 'post',
    url: '/users/login/refresh',
    data,
    onSuccess,
    onFail,
  })
}

export const createUser = async ({ data }) => {
  return await createApiRequest({
    method: 'post',
    url: '/users',
    data,
  })
}

export const forgotUserPassword = async ({ data, onSuccess, onFail }) => {
  await createApiRequest({
    method: 'post',
    url: '/users/password/reset',
    data,
    onSuccess,
    onFail,
  })
}

export const getUserDocument = async () => {
  return createApiRequest({
    url: '/user/documents',
  })
}

export const updateUserPassword = async ({ data }) =>
  createApiRequest({
    method: 'put',
    data,
    url: '/user/password',
  })

export const updateUsersPassword = async ({ onSuccess, onFail, data }) => {
  await createApiRequest({
    method: 'put',
    data,
    url: '/users/password',
    onSuccess,
    onFail,
  })
}

export const createUserDocument = async ({ data }) => {
  return createApiRequest({
    method: 'post',
    url: '/user/documents',
    data,
  })
}

export const updateUserDocument = async ({ id, data }) => {
  return createApiRequest({
    method: 'patch',
    url: `/user/documents/${id}`,
    data,
  })
}

export const deleteUserDocument = async ({ id }) => {
  return createApiRequest({
    method: 'delete',
    url: `/user/documents/${id}`,
  })
}

export const createUserFavorites = async ({ aliasId }) => {
  return createApiRequest({
    method: 'post',
    url: `/user/favorites/${aliasId}`,
  })
}

export const deleteUserFavorites = async ({ aliasId }) => {
  return createApiRequest({
    method: 'delete',
    url: `/user/favorites/${aliasId}`,
  })
}

export const getUserFavorites = async () => {
  return createApiRequest({
    url: `/user/favorites`,
  })
}

export const getCreditCards = async () =>
  createApiRequest({
    url: '/user/payment-cards',
  })

export const addCreditCard = async ({ data }) =>
  createApiRequest({
    method: 'post',
    url: '/user/payment-cards',
    data,
  })

export const deleteCreditCard = async ({ cardId }) =>
  createApiRequest({
    method: 'delete',
    url: `/user/payment-cards/${cardId}`,
  })

export const updateUserProfile = async ({ data }) =>
  createApiRequest({
    method: 'patch',
    url: `/user`,
    data,
  })

export const getUserProfile = async () =>
  createApiRequest({
    url: '/user',
  })
