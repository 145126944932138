import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BillItem } from 'src/pages/property-detail/booking/resume/pack-housi/bills'
import { usePackHousi } from 'src/pages/property-detail/details/pack-housi'

import { PropertyCardContext } from '../../../property-card.context'

const PackInfo = () => {
  const { t } = useTranslation('card-apartment')
  const { property } = React.useContext(PropertyCardContext)
  const { facilitiesBills, livingBills } = usePackHousi({ property: property })
  return (
    <Box
      padding="24px 16px 12px 16px"
      border="4px dashed #EEEEEE"
      backgroundColor="black.0"
      borderRadius="8px"
      width="100%"
    >
      <Flex direction="column" width={['100%', null, '70%']} gap="10px">
        <Flex direction="column">
          <Text fontWeight="bold" fontSize="16px" color="black.500">
            {t('card-apartment:PACK.title')}
          </Text>
          <Text color="black.500" fontSize="14px">
            {t('card-apartment:PACK.subtitle')}
          </Text>
        </Flex>
        <Text color="black.500" fontSize="14px">
          {t('card-apartment:PACK.text')}
        </Text>
        <Flex justifyContent="space-between" alignItems="start">
          <Flex direction="column" gap="3px">
            {livingBills.map((bill) => (
              <BillItem
                key={bill.text}
                haveBill={bill.haveBill}
                text={bill.text}
              />
            ))}
          </Flex>
          <Flex direction="column" gap="3px">
            {facilitiesBills.map((bill) => (
              <BillItem
                key={bill.text}
                haveBill={bill.haveBill}
                text={bill.text}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export default PackInfo
