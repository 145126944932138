import * as c from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EllipseIcon } from 'src/assets/icons/ellipse'

import { Flags } from '../../booking/resume/pack-housi/bills'
import { PropertyDetailContext } from '../../property-detail.context'
import PackHousiModal from './pack-housi-modal'

export const usePackHousi = ({ property }) => {
  const { t } = useTranslation('property-detail/booking-resume')

  let flags = property.flags as Flags[]

  if (!property.flags) {
    flags = []
  }

  const living = ['WATER', 'LIGHT', 'TV', 'INTERNET']
  const facilities = ['KITCHEN_UTILITIES', 'TROUSSEAU', 'FURNITURE']

  const livingBills = living.map((bill) => ({
    haveBill: flags.includes(bill as any),
    text: t(`property-detail/booking-resume:BILLS_FLAGS.${bill}`),
  }))

  const facilitiesBills = facilities.map((bill) => ({
    haveBill: flags.includes(bill as any),
    text: t(`property-detail/booking-resume:BILLS_FLAGS.${bill}`),
  }))

  return {
    facilitiesBills,
    livingBills,
  }
}

interface BillItemProps {
  text: string
  status: boolean
  textColor: boolean
}

export const BillItem = ({ text, status, textColor }: BillItemProps) => (
  <c.Flex as="span" align="center" gap={2}>
    <EllipseIcon fill={status ? '#0A9A51' : '#F2A2B3'} />
    <c.Text
      fontSize={[14, 16]}
      fontWeight={500}
      color={textColor ? 'black.500' : 'black.400'}
    >
      {text}
    </c.Text>
  </c.Flex>
)

const PackHousi = () => {
  const { t } = useTranslation('property-detail/booking-resume')
  const { property } = React.useContext(PropertyDetailContext)
  const { facilitiesBills, livingBills } = usePackHousi({ property: property })

  const bills = [...facilitiesBills, ...livingBills].sort(
    (a, b) => Number(b.haveBill) - Number(a.haveBill)
  )

  const hasPackHousi = bills.filter((x) => x.haveBill)

  if (hasPackHousi.length <= 0) return null

  return (
    <>
      {bills.length >= 1 && (
        <c.Box mb={4} bg="#fff" px={6} py={5}>
          <c.Heading fontWeight={700} color="black.500" fontSize={16} mb={1}>
            {t('property-detail/booking-resume:TAKE_CARE')}
          </c.Heading>
          <c.Text fontWeight={500} fontSize={16} color="black.400">
            {t(`property-detail/life-style:PACK_HOUSI.description.0`)}
          </c.Text>

          <c.Grid templateColumns="repeat(2, 1fr)" gap={2} mt={6}>
            {bills.map((bill, key) =>
              bill.haveBill ? (
                <BillItem
                  text={bill.text}
                  status={bill.haveBill}
                  key={key}
                  textColor={bill.haveBill}
                />
              ) : null
            )}
          </c.Grid>

          <PackHousiModal />
        </c.Box>
      )}
    </>
  )
}

export default PackHousi
