import React from 'react'

const LinkedinIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0C6.26903 0 0 6.26903 0 14C0 21.731 6.26903 28 14 28C21.731 28 28 21.731 28 14C28 6.26903 21.731 0 14 0ZM11.4478 18.7575H9.14336V11.8195H11.4478V18.7575ZM10.2832 10.8531C9.49027 10.8531 8.99469 10.308 8.99469 9.66372C8.99469 8.99469 9.51504 8.47434 10.308 8.47434C11.1009 8.47434 11.5965 8.99469 11.5965 9.66372C11.5965 10.3327 11.1009 10.8531 10.2832 10.8531ZM19.7735 18.7575H17.469V15.0407C17.469 14.0991 17.1469 13.4796 16.3044 13.4796C15.6602 13.4796 15.2885 13.9009 15.115 14.3221C15.0655 14.4708 15.0407 14.669 15.0407 14.892V18.7823H12.7363C12.7363 18.7823 12.7611 12.4885 12.7363 11.8442H15.0407V12.8354C15.3381 12.3646 15.8832 11.6956 17.1221 11.6956C18.6336 11.6956 19.7735 12.6867 19.7735 14.8177V18.7575V18.7575Z"
        fill="#FA2F93"
      />
    </svg>
  )
}

export default LinkedinIcon
