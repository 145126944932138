import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators: StoriesActions } = createActions({
  setStories: ['stories'],
})

const INITIAL_STATE = {
  stories: [],
}

const setStories = (state = INITIAL_STATE, action) => ({
  ...state,
  stories: action.stories,
})

export default createReducer(INITIAL_STATE, {
  [Types.SET_STORIES]: setStories,
})
