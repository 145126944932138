import React from 'react'

export const InfoIcon = ({
  width = 20,
  height = 20,
  color = '#FA2F93',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 20C8.02219 20 6.08879 19.4135 4.4443 18.3147C2.79981 17.2159 1.51809 15.6541 0.761209 13.8268C0.00433284 11.9996 -0.193701 9.98891 0.192152 8.0491C0.578004 6.10929 1.53041 4.32746 2.92894 2.92894C4.32746 1.53041 6.10929 0.578004 8.0491 0.192152C9.98891 -0.193701 11.9996 0.00433284 13.8268 0.761209C15.6541 1.51809 17.2159 2.79981 18.3147 4.4443C19.4135 6.08879 20 8.02219 20 10C19.9971 12.6513 18.9426 15.1931 17.0679 17.0679C15.1931 18.9426 12.6513 19.9971 10 20ZM10 2C8.41775 2 6.87104 2.4692 5.55544 3.34825C4.23985 4.2273 3.21447 5.47673 2.60897 6.93854C2.00347 8.40035 1.84504 10.0089 2.15372 11.5607C2.4624 13.1126 3.22433 14.538 4.34315 15.6569C5.46197 16.7757 6.88743 17.5376 8.43928 17.8463C9.99113 18.155 11.5997 17.9965 13.0615 17.391C14.5233 16.7855 15.7727 15.7602 16.6518 14.4446C17.5308 13.129 18 11.5823 18 10C17.9976 7.879 17.154 5.84556 15.6542 4.34578C14.1545 2.84601 12.121 2.00239 10 2ZM10 15C9.73479 15 9.48043 14.8946 9.2929 14.7071C9.10536 14.5196 9 14.2652 9 14V9C9 8.73479 9.10536 8.48043 9.2929 8.2929C9.48043 8.10536 9.73479 8 10 8C10.2652 8 10.5196 8.10536 10.7071 8.2929C10.8946 8.48043 11 8.73479 11 9V14C11 14.2652 10.8946 14.5196 10.7071 14.7071C10.5196 14.8946 10.2652 15 10 15ZM10 7C9.80222 7 9.60888 6.94136 9.44443 6.83147C9.27999 6.72159 9.15181 6.56541 9.07613 6.38269C9.00044 6.19996 8.98063 5.9989 9.01922 5.80491C9.05781 5.61093 9.15305 5.43275 9.2929 5.2929C9.43275 5.15305 9.61093 5.0578 9.80491 5.01922C9.9989 4.98063 10.2 5.00044 10.3827 5.07612C10.5654 5.15181 10.7216 5.27998 10.8315 5.44443C10.9414 5.60888 11 5.80222 11 6C11 6.26522 10.8946 6.51957 10.7071 6.70711C10.5196 6.89465 10.2652 7 10 7Z"
      fill={color}
    />
  </svg>
)
