export const UserAvatarIcon = (props: any) => (
  <svg
    {...props}
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.1274 36.1276C36.1274 37.0559 35.3749 37.8084 34.4466 37.8084C33.5183 37.8084 32.7657 37.0559 32.7657 36.1276V32.7659C32.7657 29.981 30.5081 27.7234 27.7232 27.7234H14.2765C11.4916 27.7234 9.23396 29.981 9.23396 32.7659V36.1276C9.23396 37.0559 8.48143 37.8084 7.55312 37.8084C6.62482 37.8084 5.87228 37.0559 5.87228 36.1276V32.7659C5.87228 28.1244 9.63497 24.3617 14.2765 24.3617H27.7232C32.3647 24.3617 36.1274 28.1244 36.1274 32.7659V36.1276ZM20.9998 21C16.3583 21 12.5956 17.2374 12.5956 12.5958C12.5956 7.95434 16.3583 4.19165 20.9998 4.19165C25.6414 4.19165 29.404 7.95434 29.404 12.5958C29.404 17.2374 25.6414 21 20.9998 21ZM20.9998 17.6384C23.7847 17.6384 26.0424 15.3808 26.0424 12.5958C26.0424 9.81094 23.7847 7.55333 20.9998 7.55333C18.2149 7.55333 15.9573 9.81094 15.9573 12.5958C15.9573 15.3808 18.2149 17.6384 20.9998 17.6384Z"
      fill="currentColor"
    />
  </svg>
)
