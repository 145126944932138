import { TFunction } from 'i18next'
import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { RequireAuth } from 'src/main/routes/custom'
import { useHideSearchBar } from 'src/store/context/navbar.context'
import { useWindowWidth } from 'src/store/context/window.context'

import Menu from './components/menu'
import reservationBills from './pages/my-reservations/reservation-bills'

const AvatarEdit = React.lazy(() => import('./pages/avatar/avatar-edit'))
const Favorites = React.lazy(() => import('./pages/favorites/index'))
const MyReservations = React.lazy(
  () => import('./pages/my-reservations/reservation-list')
)
const ReservationsDetails = React.lazy(
  () => import('./pages/my-reservations/reservation-details')
)
const Password = React.lazy(() => import('./pages/password/index'))
const Payment = React.lazy(() => import('./pages/payment/card-list'))
const NewCard = React.lazy(
  () => import('./pages/payment/new-card/payment-new-card')
)
const PersonalInfo = React.lazy(() => import('./pages/personal-info'))
import { ProfileHoc } from './context/ProfileContext'

const Layout = ProfileHoc(() => {
  const navigate = useNavigate()
  useHideSearchBar({})

  const { windowWidth } = useWindowWidth()

  const path = window.location.pathname
  useEffect(() => {
    if ((path === '/profile/' || path === '/profile') && windowWidth > 720) {
      return navigate('/profile/reservations')
    }
  }, [path, windowWidth])

  return (
    <Menu>
      <Outlet />
    </Menu>
  )
})

const profileRoutes = (t: TFunction) => ({
  path: 'profile',

  element: <Layout />,
  children: [
    {
      path: '/profile/favorites',
      element: (
        <RequireAuth
          title={t('titles:profile-favorites.SEO_TITLE')}
          description={t('titles:profile-favorites.FAVORITES_SEO_DESCRIPTION')}
        >
          <React.Suspense fallback="">
            <Favorites />
          </React.Suspense>
        </RequireAuth>
      ),
    },
    {
      path: '/profile/personal-info',
      element: (
        <RequireAuth
          title={t('titles:profile-information.SEO_TITLE')}
          description={t('titles:profile-information.SEO_DESCRIPTION')}
        >
          <React.Suspense fallback="">
            <PersonalInfo />
          </React.Suspense>
        </RequireAuth>
      ),
    },
    {
      path: '/profile/payment',
      element: (
        <RequireAuth
          title={t('titles:profile-cards.SEO_TITLE')}
          description={t('titles:profile-cards.SEO_DESCRIPTION')}
        >
          <React.Suspense fallback="">
            <Payment />
          </React.Suspense>
        </RequireAuth>
      ),
    },
    {
      path: '/profile/payment/new-card',
      element: (
        <RequireAuth
          title={t('titles:profile-cards.SEO_TITLE')}
          description={t('titles:profile-cards.SEO_DESCRIPTION')}
        >
          <React.Suspense fallback="">
            <NewCard />
          </React.Suspense>
        </RequireAuth>
      ),
    },
    {
      path: '/profile/password',
      element: (
        <RequireAuth
          title={t('titles:profile-password.SEO_TITLE')}
          description={t('titles:profile-password.SEO_DESCRIPTION')}
        >
          <React.Suspense fallback="">
            <Password />
          </React.Suspense>
        </RequireAuth>
      ),
    },
    {
      path: '/profile/avatar-edit',
      element: (
        <RequireAuth
          title={t('titles:profile-avatar.SEO_TITLE')}
          description={t('titles:profile-avatar.SEO_DESCRIPTION')}
        >
          <React.Suspense fallback="">
            <AvatarEdit />
          </React.Suspense>
        </RequireAuth>
      ),
    },

    {
      path: '/profile/reservations/:id',
      element: (
        <RequireAuth
          title={t('titles:profile-reservations.SEO_TITLE')}
          description={t('titles:profile-reservations.SEO_DESCRIPTION')}
        >
          <React.Suspense fallback="">
            <ReservationsDetails />
          </React.Suspense>
        </RequireAuth>
      ),
    },
    {
      path: '/profile/reservations',
      element: (
        <RequireAuth
          title={t('titles:profile-reservations.SEO_TITLE')}
          description={t('titles:profile-reservations.SEO_DESCRIPTION')}
        >
          <React.Suspense fallback="">
            <MyReservations />
          </React.Suspense>
        </RequireAuth>
      ),
    },
    ...reservationBills(t),
  ],
})

export default profileRoutes
