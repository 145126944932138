import { useMachine } from '@xstate/react'
import React from 'react'
import { EventData } from 'xstate'

import datesMachine, {
  ContextType,
  date_events,
  date_states,
} from './date-machine'

type SendEvent = (event: date_events, payload?: EventData | undefined) => void

type Valid = {
  [key in date_states]: boolean
}

interface DateContextProps {
  states: date_states
  send: SendEvent
  context: ContextType
  valid: Valid
}

interface DateProviderProps {
  children?: React.ReactNode
}

export const DateContext = React.createContext({} as DateContextProps)

const DateProvider = ({ children }: DateProviderProps) => {
  const [current, send] = useMachine(datesMachine)

  const states = current.value as date_states
  const context = current.context

  const valid: Valid = {
    disabled: current.matches(date_states.disabled),
    focused: current.matches(date_states.focused),
    hovered: current.matches(date_states.hovered),
    idle: current.matches(date_states.idle),
  }

  return (
    <DateContext.Provider value={{ states, send, context, valid }}>
      {children}
    </DateContext.Provider>
  )
}

export default DateProvider
