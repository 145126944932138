/* eslint-disable react/jsx-curly-newline */
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import React, { useMemo } from 'react'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'

import { ArrowIndicateMinimalIcon } from '../../assets/icons/arrow-indicate-minimal.icon'
import { useWindowWidth } from '../../store/context/window.context'
import * as S from './styled'

const CarouselV2 = ({
  customSizeDesktop = {},
  customSizeMobile = {},
  customStylesDesktop = {},
  customStylesMobile = {},
  withArrows = true,
  CustomLeftArrow,
  CustomRightArrow,
  withIndicators = true,
  CustomIndicator,
  customStyleControlIndicators = {},
  isInfinite = true,
  autoPlay = true,
  showStatus = false,
  interval = 3000,
  showThumbs = false,
  dynamicHeight = false,
  emulateTouch = false,
  onClickImage = () => {},
  onChange = () => {},
  arrowColor = 'gray',
  children,
  swipeable = true,
}) => {
  const { windowWidth } = useWindowWidth()

  const defaultStyle = {
    minHeight: '320px',
    maxHeight: '320px',
    minWidth: windowWidth > 576 ? '400px' : '100%',
    maxWidth: windowWidth > 576 ? '400px' : '100%',
    position: 'relative',
  }

  const sizeStyles = useMemo(() => {
    if (windowWidth > 576)
      return {
        ...defaultStyle,
        ...customSizeDesktop,
      }
    return {
      ...defaultStyle,
      ...customSizeMobile,
    }
  }, [customSizeDesktop, customSizeMobile, defaultStyle, windowWidth])

  return (
    <S.CarouselWrapper
      customStylesDesktop={{ ...customStylesDesktop }}
      customStylesMobile={{ ...customStylesMobile }}
      customStyleControlIndicators={customStyleControlIndicators}
    >
      <ReactCarousel
        swipeable={swipeable}
        autoPlay={autoPlay}
        emulateTouch={emulateTouch}
        infiniteLoop={isInfinite}
        showThumbs={showThumbs}
        dynamicHeight={dynamicHeight}
        css={sizeStyles}
        showStatus={showStatus}
        showArrows={withArrows}
        showIndicators={withIndicators}
        stopOnHover
        interval={interval}
        onClickItem={onClickImage}
        axis="horizontal"
        onChange={onChange}
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev &&
          (CustomLeftArrow ? (
            <CustomLeftArrow onClick={onClickHandler} />
          ) : (
            <S.ButtonArrows onClick={onClickHandler}>
              <S.ButtonStyle style={{ marginLeft: '12px' }}>
                <ArrowIndicateMinimalIcon color={arrowColor} direction="left" />
              </S.ButtonStyle>
            </S.ButtonArrows>
          ))
        }
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext &&
          (CustomRightArrow ? (
            <CustomRightArrow onClick={onClickHandler} />
          ) : (
            <S.ButtonArrows
              onClick={onClickHandler}
              style={{ right: 0, top: 0 }}
            >
              <S.ButtonStyle style={{ marginRight: '12px' }}>
                <ArrowIndicateMinimalIcon
                  color={arrowColor}
                  direction="right"
                />
              </S.ButtonStyle>
            </S.ButtonArrows>
          ))
        }
        renderIndicator={(onClickHandler, isSelected, index, label) =>
          CustomIndicator ? (
            <CustomIndicator
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              isSelected={isSelected}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          ) : (
            <S.DotsIndicator
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              isSelected={isSelected}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          )
        }
      >
        {children}
      </ReactCarousel>
    </S.CarouselWrapper>
  )
}
export default CarouselV2
