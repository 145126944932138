import './components/translate'
import './assets/styles/global-style.css'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import 'src/lib/builder'

import builder from '@builder.io/react'
import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { GlobalStyle } from './assets/styles/global-style'
import {
  breakpoints,
  colors,
  fontSizes as font,
} from './assets/styles/theme-variables'
import Toast from './components/alert/toast'
import theme from './lib/chakra/theme'
import { Main } from './main'
import store from './store'
import { RootProvider } from './store/context/root.context'

builder.init(process.env.REACT_APP_BUILDER_API_KEY!)

function App() {
  React.useEffect(() => {
    const language = localStorage.getItem('language')
    language ? language : localStorage.setItem('language', 'pt-BR')
  }, [])

  return (
    <Provider store={store}>
      <ThemeProvider theme={{ colors, breakpoints, font }}>
        <ChakraProvider theme={theme}>
          <GlobalStyle />
          <React.Suspense fallback="">
            <RootProvider>
              <Main />
            </RootProvider>
          </React.Suspense>
          <Toast />
        </ChakraProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
