import * as c from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import ArrowRight from '../../../../icons/arrow-right.icon'
import MyProfileItems from '../components/my-profile-items'
import { MyProfileItemsConfig } from './data/items.config'

interface MyProfileInterface {
  isLogged: boolean
}

const Item = ({ onClick, label, Icon }) => {
  return (
    <c.Button
      onClick={onClick}
      variant="outline"
      gap="0px"
      px="0px"
      py="s4"
      border="none"
      height="50px"
      alignItems="center"
      leftIcon={<c.Icon as={Icon} color="black.500" h="1.25rem" w="1.25rem" />}
      _hover={{ bg: 'none' }}
    >
      {label}

      <c.Icon
        ml="auto"
        as={ArrowRight}
        color="black.500"
        w="1.25rem"
        h="1.25rem"
      />
    </c.Button>
  )
}

const MyProfile = ({ isLogged }: MyProfileInterface) => {
  const { t } = useTranslation('components/nav')

  const { userAccountItems } = MyProfileItemsConfig()

  return (
    <>
      <c.Flex direction="column">
        <c.Text fontSize="t-sm.0" fontWeight="bold" color="black.300" mb="s4">
          {t('components/nav:MY_ACCOUNT')}
        </c.Text>

        {!isLogged &&
          userAccountItems.map((item) => (
            <Item
              key={item.id}
              label={item.label}
              onClick={item?.onClick!}
              Icon={item.Icon}
            />
          ))}

        {isLogged && <MyProfileItems isLogged={isLogged} />}
      </c.Flex>
    </>
  )
}
export default MyProfile
