import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PropertyCardContext } from '../../property-card.context'
import LikeButton from '../like-button'
import PropertyPrice from '../price'

interface PropertyNameProps {
  aliasName?: string
  neighborhood?: string
  city?: string
}

interface PropertyAboutProps {
  bedrooms?: number
  area?: number
  guests?: number
  petFriendly?: boolean
}

const PropertyName = ({ aliasName, city, neighborhood }: PropertyNameProps) => {
  return (
    <Box>
      <Text
        m="0"
        maxW="280px"
        overflow="hidden"
        fontSize={[14, null, 16]}
        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        color="black.500"
        fontWeight={700}
      >
        {aliasName}
      </Text>
      <Text m="0" fontSize={12} color="black.400" fontWeight={500}>
        {`${neighborhood}, ${city}`}
      </Text>
    </Box>
  )
}

const PropertyAbout = ({
  area,
  bedrooms,
  guests,
  petFriendly,
}: PropertyAboutProps) => {
  const { t } = useTranslation('card-apartment')

  return (
    <Flex gap="10px" alignItems="center">
      <Text fontSize={12} color="black.600">
        {t('card-apartment:ROOMS', { total: bedrooms })}
      </Text>

      <Text m="0" fontSize={12} color="black.600">
        {area} m²
      </Text>

      <Text m="0" fontSize={12} color="black.600">
        {t('card-apartment:PEOPLE', { total: guests })}
      </Text>

      <Text m="0" fontSize={12} color="black.600">
        {t(`card-apartment:PET_${petFriendly ? 'ALLOWED' : 'NOT_ALLOWED'}`)}
      </Text>
    </Flex>
  )
}

const Detail = () => {
  const { property, searchByType, hasSubscriptionPrice } =
    React.useContext(PropertyCardContext)
  const isSubscription = searchByType === 'subscription' && hasSubscriptionPrice

  return (
    <Flex
      h={[150, 200]}
      w="100%"
      paddingTop={['20px', null, '24px']}
      paddingLeft={['16px', '0']}
      paddingBottom={['16px', '24px']}
      paddingRight={['16px', '20px']}
      direction="column"
      alignSelf="flex-start"
      justifyContent="space-between"
      pos="relative"
      gap={isSubscription ? '24px' : '35px'}
      style={{ zIndex: 0 }}
    >
      <Box>
        <PropertyName
          aliasName={property?.aliasName}
          neighborhood={property?.address?.neighborhood?.name}
          city={property?.address?.city.name}
        />
      </Box>
      <PropertyAbout
        area={property?.area}
        bedrooms={property?.bedrooms}
        guests={property?.guests}
        petFriendly={property?.petFriendly}
      />
      <Flex alignItems="flex-end" justifyContent="space-between" gap="15px">
        <PropertyPrice />
        <LikeButton />
      </Flex>
    </Flex>
  )
}

export default Detail
