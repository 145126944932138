import React, { ReactNode } from 'react'
import { usePassword } from 'src/hooks/user/password'
import { useProfile } from 'src/hooks/user/profile'

import { UserInterface } from '../../../interfaces/user/user.interface'

interface ProfileContextProps {
  updateAvatar: ({ value }) => Promise<void>
  updateLoading: boolean
  data?: UserInterface
  updateProfile: (props: any) => Promise<void>
  profileLoading: boolean
  fetchLoading: boolean
  updatePassword: (props: any) => Promise<void>
  passwordLoading: boolean
}

interface ProfileProviderProps {
  children: ReactNode
}

export const ProfileContext = React.createContext({} as ProfileContextProps)

const ProfileProvider = ({ children }: ProfileProviderProps) => {
  const {
    updateProfile,
    profileLoading,
    data,
    updateAvatar,
    updateLoading,
    fetchLoading,
  } = useProfile()

  const { updatePassword, passwordLoading } = usePassword()

  return (
    <ProfileContext.Provider
      value={{
        updateAvatar,
        updateLoading,
        data,
        fetchLoading,
        updateProfile,
        profileLoading,
        updatePassword,
        passwordLoading,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

export const ProfileHoc = (Component: (props?: any) => JSX.Element) => {
  return () => (
    <ProfileProvider>
      <Component />
    </ProfileProvider>
  )
}

export default ProfileProvider
