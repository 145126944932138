import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ArrowRight from 'src/components/navbar/icons/arrow-right.icon'

import LinkIcon from './icons/link.icon'

const OtaPage = () => {
  const { t } = useTranslation('ota-page')
  const navigate = useNavigate()

  return (
    <Box
      mt={['none', '40px']}
      px="20px"
      borderRadius="12px"
      py="40px"
      w="full"
      bg="black.0"
    >
      <LinkIcon />
      <Heading
        w="full"
        maxW="255px"
        mt="16px"
        fontWeight="bold"
        fontSize="24px"
      >
        {t('step-1.title')}
      </Heading>
      <Heading fontWeight="bold" color="primary.500" fontSize="24px">
        {t('step-1.import')}
      </Heading>
      <Text mt="24px" fontWeight="medium" fontSize="16px">
        {t('step-1.desc.0').split('\b')[0]}
        <Text as="span" fontWeight="bold" fontSize="16px">
          {t('step-1.desc.0').split('\b')[1]}
        </Text>
      </Text>
      <Text mt="24px" fontWeight="medium" fontSize="16px">
        {t('step-1.desc.1')}
      </Text>
      <Flex direction="column" gap="8px">
        <Button
          onClick={() => navigate('/vincular/reserva')}
          colorScheme="primary"
          bg="primary.500"
          mt="60px"
          rightIcon={<ArrowRight />}
        >
          {t('step-1.button')}
        </Button>
        <Button variant="ghost" onClick={() => navigate('/')}>
          {t('step-1.back')}
        </Button>
      </Flex>
    </Box>
  )
}

export default OtaPage
