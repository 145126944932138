import React from 'react'

export const SearchIcon = ({
  width = 14,
  height = 14,
  color = 'black',
  ...props
}) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M17 18C16.8686 18.0003 16.7385 17.9746 16.6172 17.9243C16.4958 17.874 16.3856 17.8001 16.293 17.707L12.893 14.312C11.4975 15.4075 9.77415 16.002 8 16C6.41775 16 4.87103 15.5308 3.55544 14.6518C2.23985 13.7727 1.21447 12.5233 0.608967 11.0615C0.00346625 9.59966 -0.15496 7.99113 0.153721 6.43928C0.462403 4.88743 1.22433 3.46197 2.34315 2.34315C3.46197 1.22433 4.88743 0.462403 6.43928 0.153721C7.99113 -0.15496 9.59966 0.00346625 11.0615 0.608967C12.5233 1.21447 13.7727 2.23985 14.6518 3.55544C15.5308 4.87103 16 6.41775 16 8C16.0036 9.77656 15.409 11.5026 14.312 12.9L17.707 16.3C17.8444 16.4402 17.9376 16.6178 17.9748 16.8105C18.012 17.0033 17.9916 17.2028 17.9162 17.384C17.8408 17.5653 17.7137 17.7204 17.5508 17.8299C17.3879 17.9395 17.1963 17.9986 17 18V18ZM8 2C6.81332 2 5.65328 2.3519 4.66658 3.01119C3.67989 3.67047 2.91085 4.60755 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17055C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66558 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C13.9982 6.40927 13.3654 4.88423 12.2406 3.75941C11.1158 2.63459 9.59073 2.00186 8 2V2Z"
      fill={`var(--${color}-color)`}
    />
  </svg>
)
