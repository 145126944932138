import { useDisclosure } from '@chakra-ui/react'
import React from 'react'

type Steps = 'access' | 'exit' | 'login' | 'register'
type ContextType = {
  step: Steps
  setStep: (step: Steps) => void
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

interface Props {
  children: React.ReactNode
}

const context = React.createContext({} as ContextType)

export const useModalLogin = () => React.useContext(context)

const ModalLoginProvider = ({ children }: Props) => {
  const [step, setStep] = React.useState<Steps>('access')
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <context.Provider value={{ step, setStep, isOpen, onOpen, onClose }}>
      {children}
    </context.Provider>
  )
}

export default ModalLoginProvider
