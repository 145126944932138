import { Text } from '@chakra-ui/react'

const Type = ({ isFlagship, isPartner }) => {
  if (isFlagship) {
    return (
      <Text
        fontSize={['12px', null, '14px']}
        textTransform="capitalize"
        color="primary.500"
      >{` Prédio Housi`}</Text>
    )
  }

  if (isPartner) {
    return (
      <Text
        fontSize={['12px', null, '14px']}
        textTransform="capitalize"
        color="secondary.400"
      >{` Parceiro`}</Text>
    )
  }

  return (
    <Text
      fontSize={['12px', null, '14px']}
      textTransform="capitalize"
      color="primary.500"
    >{` Housi Gestão`}</Text>
  )
}

export default Type
