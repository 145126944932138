import { Box } from '@chakra-ui/react'
import React from 'react'
import { search_events } from 'src/components/search-v2/search-machine'
import { useHideSearchBar } from 'src/store/context/navbar.context'

import { useSearch } from '../../components/search-v2/search-context'

const SearchMobile = () => {
  useHideSearchBar({})
  const { send } = useSearch()

  React.useEffect(() => {
    setTimeout(() => {
      send(search_events.open)
    }, 700)
  }, [])

  return <Box h="150vh" w="100%" bg="black.50" />
}

export default SearchMobile
