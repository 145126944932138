import { css } from '@emotion/css'
import { useTheme } from '@emotion/react'
import { styles } from '@retriever-ui/react'
import { RetrieverProps } from '@retriever-ui/system/lib/retriever'
import { motion, MotionProps } from 'framer-motion'
import React from 'react'

const MotionRetriever = React.memo(
  React.forwardRef<any, RetrieverProps<'div'> & MotionProps>((props, ref) => {
    const theme = useTheme()

    const className = css(styles({ theme, ...props }))

    const Component = React.cloneElement(
      <motion.div />,
      { ...props, className, ref },
      props.children
    )

    return Component
  })
)

export default MotionRetriever
