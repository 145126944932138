import { Flex, retriever } from '@retriever-ui/react'
import React from 'react'
import { Field } from 'react-final-form'

import PasswordEyeShown from '../icons/passwordEyeShown.icon'
import PasswordEyeShut from '../icons/passwordEyeShut.icon'
import InputMask from './components/input-mask'

interface FieldTextProps extends React.HTMLAttributes<HTMLInputElement> {
  name: string
  disabled?: boolean
  type?: string
  label?: string
  labelColor?: string
  mask?: string
  maskPlaceholder?: string
  customError?: boolean
  maxLength?: number
}

export const FieldText = ({
  label,
  placeholder,
  labelColor,
  mask,
  customError,
  maxLength,
  ...rest
}: FieldTextProps) => {
  return (
    <Field {...rest} disabled>
      {({ input, meta }) => (
        <Flex direction="column">
          <retriever.label w="100%">
            <retriever.p
              color={labelColor ? labelColor : 'black.600'}
              fontSize="14px"
              mBottom="8px"
              style={{ fontWeight: 500 }}
            >
              {label}
            </retriever.p>
            {mask ? (
              <InputMask
                mask={mask}
                maskPlaceholder={placeholder}
                alwaysShowMask={true}
                {...(input as any)}
                disabled={rest.disabled}
                style={{
                  WebkitAppearance: 'none',
                  fontWeight: 500,
                  fontSize: '14px',
                  color: '#474747',
                }}
              />
            ) : (
              <retriever.input
                {...(input as any)}
                maxLength={maxLength}
                placeholder={placeholder}
                _placeholder={{ fontWeight: 500, fontSize: '14px' }}
                pX="12px"
                border="1px solid black.300"
                outline="none"
                _focus={{ border: '1px solid primary.400' }}
                radius="8px"
                h="40px"
                w="100%"
                fontSize={16}
                fontFamily="'Montserrat'"
                disabled={rest.disabled}
                color="black.500"
                style={{ WebkitAppearance: 'none', fontWeight: 500 }}
              />
            )}
          </retriever.label>
          {meta.touched && (meta.error || customError) && (
            <retriever.span fontSize="12px" color="red.400">
              {customError ? meta.submitError ?? meta.error : meta.error}
            </retriever.span>
          )}
        </Flex>
      )}
    </Field>
  )
}

export const FieldTextPassword = ({
  label,
  placeholder,
  ...rest
}: FieldTextProps) => {
  const [passwordShown, setPasswordShown] = React.useState(false)

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }
  return (
    <Field {...rest}>
      {({ input, meta }) => (
        <Flex width="100%" direction="column">
          <retriever.label w="100%">
            <retriever.p
              color="black.600"
              fontSize="small"
              mBottom="8px"
              style={{ fontWeight: 500 }}
            >
              {label}
            </retriever.p>
            <Flex
              width="100%"
              maxWidth="100%"
              p="10px"
              border="1px solid black.300"
              _focus={{ border: '1px solid primary.400' }}
              radius="8px"
              h="40px"
              justifyContent="space-between"
              alignContent="center"
            >
              <retriever.input
                {...(input as any)}
                placeholder={placeholder}
                type={passwordShown ? 'text' : 'password'}
                border="none"
                outline="none"
                fontSize={16}
                flex="1"
                style={{ webKitAppearance: 'none' }}
              />
              {input.type === 'password' ? (
                <retriever.button
                  onClick={togglePasswordVisiblity}
                  border="none"
                  backgroundColor="transparent"
                  cursor="pointer"
                  color="primary.400"
                  fontWeight="bold"
                  type="button"
                >
                  {passwordShown ? <PasswordEyeShown /> : <PasswordEyeShut />}
                </retriever.button>
              ) : null}
            </Flex>
          </retriever.label>
          {meta.touched && meta.error && (
            <retriever.span fontSize="12px" color="red.400">
              {meta.error}
            </retriever.span>
          )}
        </Flex>
      )}
    </Field>
  )
}
