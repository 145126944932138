import { Box, Flex } from '@retriever-ui/react'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import RadioInput from '../../../../../components/inputs/radio-themed'
import { Typography } from '../../../../../components/typograph'
import * as S from './styled'

const PetsField = ({ register, getValues, setValue }) => {
  const { t } = useTranslation('search-filter-form')
  const beforeState = useRef(null)

  const handleUnCheck = (e) => {
    const { value } = e.target

    if (value === beforeState.current) {
      setValue('petFriendly', null)
      beforeState.current = null
    } else beforeState.current = value
  }

  React.useEffect(() => {
    beforeState.current = getValues().petFriendly
  }, [])

  return (
    <S.ContentField>
      <Box>
        <Typography title variant="extraSmall" weight="bold">
          {t('search-filter-form:PETS')}
        </Typography>
      </Box>
      <Flex mTop="24px" gap="8px">
        <RadioInput
          asButton
          labelRadio={t('search-filter-form:YES')}
          index="1"
          value={true as any}
          name="petFriendly"
          ref={register}
          onClick={handleUnCheck}
        />
        <RadioInput
          asButton
          labelRadio={t('search-filter-form:NO')}
          index="2"
          value={false as any}
          name="petFriendly"
          ref={register}
          onClick={handleUnCheck}
        />
        <RadioInput
          asButton
          labelRadio={t('search-filter-form:ANY')}
          index="3"
          value={undefined}
          name="petFriendly"
          ref={register}
          onClick={handleUnCheck}
        />
      </Flex>
    </S.ContentField>
  )
}

export default PetsField
