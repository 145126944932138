import * as c from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useKey } from 'rooks'
import { CloseIcon } from 'src/assets/icons/close.icon'

import { variants } from './animation'
import Autocomplete from './autocomplete'
import AutocompleteProvider from './autocomplete/autocomplete-context'
import Button from './button'
import Dates from './dates'
import HotContent from './hot-listing'
import { LeftBracket } from './icons/bracketleft.icon'
import { RightBracket } from './icons/bracketright.icon'
import { SearchIcon } from './icons/search-icon'
import SearchMobile from './mobile'
import { useSearch } from './search-context'
import StepsProvider from './steps/steps-context'
import Wrapper from './wrapper'

const SearchDesktop = () => {
  const [isMobile] = c.useMediaQuery(['(max-Width: 576px)'])
  const { valid, controls, handleClose } = useSearch()

  if (isMobile) return null

  return (
    <motion.div variants={variants} animate={controls.open} initial="closed">
      <Wrapper>
        {!valid.closed && (
          <c.Button
            onClick={handleClose}
            pos="absolute"
            right={5}
            top={10}
            minH="25px"
            maxH="25px"
            py={4}
            px={2}
            bg="transparent"
            border="none"
            transition="all 0.2s ease-in-out"
            _hover={{
              background: 'black.100',
            }}
          >
            <CloseIcon color="#474747" height={14} width={14} />
          </c.Button>
        )}

        <c.Flex
          pos="relative"
          zIndex={1}
          align="center"
          justify="space-between"
          gap={7.5}
        >
          <Autocomplete />
          <Button />
          <Dates />
        </c.Flex>
      </Wrapper>
      <HotContent />
    </motion.div>
  )
}

const Search = React.memo(() => {
  const { valid, handleOpen, handleClose } = useSearch()

  useKey(['/'], handleOpen)
  useKey(['Escape'], handleClose)

  return (
    <c.Box w="100%" display={valid.closed ? 'none' : 'block'}>
      <AutocompleteProvider>
        <StepsProvider steps={[0, 1]}>
          <SearchMobile />
          <SearchDesktop />
        </StepsProvider>
      </AutocompleteProvider>
    </c.Box>
  )
})

export const SearchButton = () => {
  const { handleOpen } = useSearch()
  const { t } = useTranslation('components/search')

  return (
    <c.Flex
      as={c.Button}
      onClick={handleOpen}
      minH="full"
      maxH="45px"
      w="full"
      maxW="351px"
      align="center"
      bg="black.50"
      p="0"
      gap="12px"
      border="none"
      borderRadius="4px"
      transition="all 0.2s ease-in-out"
      _hover={{
        background: 'black.100',
      }}
    >
      <c.Icon as={LeftBracket} />
      <c.Flex direction="column" maxW="211px">
        <c.Heading
          color="black.500"
          fontSize="h-sm.0"
          fontWeight="bold"
          textAlign="start"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {t('components/search:autocomplete.label')}
        </c.Heading>
        <c.Text
          color="black.300"
          fontSize="t-sm.1"
          fontWeight="normal"
          textAlign="start"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {t('components/search:autocomplete.placeholder')}
        </c.Text>
      </c.Flex>

      <c.Button
        variant="primary"
        w="full"
        maxWidth="81px"
        height="30px"
        px="s2"
        py="s1"
        gap="s1"
        borderRadius="6px"
        fontSize="t-sm.0"
      >
        <c.Text color="black.0" mr="s1" fontSize="t-xs.1" fontWeight="bold">
          {t('components/search:submit')}
        </c.Text>

        <c.Icon as={SearchIcon} w="1rem" h="1rem" color="black.0" />
      </c.Button>
      <c.Icon as={RightBracket} />
    </c.Flex>
  )
}

export default Search
