import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FilterIcon } from 'src/assets/icons/filter.icon'
import SearchIcon from 'src/components/navbar/icons/search.icon'
import { useSearch } from 'src/components/search-v2/search-context'
import ShadowSkeleton from 'src/components/skeleton'

import { FormFilter } from '../filter/form'
import { useMaps } from '../map/context'

const Result = () => {
  const [isMobile] = useMediaQuery(['(max-width: 578px)'])
  const { t } = useTranslation('search-page')
  const search = useSearch()

  if (isMobile)
    return (
      <Flex
        onClick={search.handleOpen}
        gap="16px"
        align={['center', null, 'flex-start']}
        w="100%"
        h="52px"
        borderRadius={8}
        border="1px solid"
        borderColor="black.200"
        bg="black.100"
        px="20px"
      >
        <SearchIcon width="18px" height="18px" color="#A3A3A3" />
        <Text size="lg" noOfLines={1} color="black.400">
          {search.context.searchTerm.label}
        </Text>
      </Flex>
    )

  return (
    <Flex gap={2}>
      <Heading whiteSpace="nowrap" size="xs" fontWeight={500}>
        {t('search-page:SHOW')}
      </Heading>
      <Heading size="xs" noOfLines={1}>
        {search.context.searchTerm.label}
      </Heading>
    </Flex>
  )
}

const FilterButton = () => {
  const { t } = useTranslation('search-page')
  const [isMobile] = useMediaQuery(['(max-width: 578px)'])
  const { isOpen, onClose, onOpen } = useDisclosure()

  if (isMobile)
    return (
      <>
        <IconButton
          variant="outline"
          colorScheme="whiteAlpha"
          aria-label="filter-button"
          maxH="32px"
          maxW="32px"
          onClick={onOpen}
          icon={<FilterIcon />}
        />

        <Modal size="md" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />

          <React.Suspense fallback="">
            <ModalContent px="24px" mx="12px">
              <ModalHeader px="0px">
                <Heading size="md" fontWeight="bold">
                  Filtros
                </Heading>
              </ModalHeader>
              <ModalCloseButton />
              <FormFilter onClose={onClose} />
            </ModalContent>
          </React.Suspense>
        </Modal>
      </>
    )

  return (
    <>
      <Button
        variant="ghost"
        fontSize="14px"
        leftIcon={<FilterIcon />}
        onClick={onOpen}
        bg="black.0"
        color="black.500"
        maxH={28}
      >
        {t('search-page:MORE_FILTERS')}
      </Button>
      <Modal size="md" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent px="24px" mx="12px">
          <ModalHeader px="0px">
            <Heading size="md" fontWeight="bold">
              Filtros
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <FormFilter onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  )
}

const Header = () => {
  const { t } = useTranslation('search-page')
  const map = useMaps()

  return (
    <Box
      bg={['black.0', null, 'black.50']}
      pt={['20px', null, '24px']}
      px={['20px', null, '24px']}
      shadow={['xl', null, 'sm']}
      borderBottomRadius={['8px', null]}
      pb="12px"
      w="100%"
    >
      <Flex
        mb={[3, null, 2]}
        gap={4}
        align={['center', null, 'flex-start']}
        justify="space-between"
      >
        <Result />
        <FilterButton />
      </Flex>
      <Flex justify={['center', null, 'flex-start']}>
        <ShadowSkeleton isLoading={map.valid.requesting} maxW={180} maxH={18}>
          <Text fontSize="12px" maxW="fit-content" color="black.400">
            {t('search-page:TOTAL', {
              total: map.context?.totalItems,
              cards: map.context.cards.length,
            })}
          </Text>
        </ShadowSkeleton>
      </Flex>
    </Box>
  )
}

export default Header
