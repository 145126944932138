import { createApiRequest } from '../../store/api'

export const updateUserAvatar = async ({ photo }) => {
  const data = new FormData()
  data.append('photo', photo)

  return createApiRequest({
    method: 'put',
    url: `/user/avatar`,
    data,
  })
}

export const getUserAvatar = async () => {
  return createApiRequest({
    method: 'get',
    url: `/user`,
  })
}
