import axios from 'axios'
import i18n from 'i18next'
import qs from 'qs'
import { bindActionCreators } from 'redux'

import store from '../index'
import { UsersActions } from '../redux/users.reducer'

const acceptMethods = ['facebook', 'google']

export const index = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: 'repeat', encodeValuesOnly: true }),
})

index.interceptors.request.use(async (config) => {
  const paymentToken = sessionStorage.getItem('paymentToken')
  const isPaymentRoute = window.location.pathname.startsWith('/payment')
  const { userToken: token, method } = store.getState().user

  const userToken =
    paymentToken && isPaymentRoute
      ? { Authorization: `Bearer ${paymentToken}` }
      : token
      ? { Authorization: `Bearer ${token}` }
      : {}
  const userMethod = acceptMethods.includes(method) ? { provider: method } : {}

  const lang = i18n.language === 'pt-BR' ? 'pt_BR' : 'en_US'

  return {
    ...config,
    headers: {
      ...config.headers,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      v3: true,

      lang,
      ...userMethod,
      ...userToken,
    },
  }
})

index.interceptors.response.use(
  (response) => response,
  async (err) => {
    if (err?.response?.status === 401 && !err?.config?.url?.includes('login')) {
      const refreshToken = localStorage.getItem('refreshToken')
      const { logout, setToken } = bindActionCreators(
        UsersActions,
        store.dispatch
      )

      if (store.getState().user.method !== 'default') {
        logout()
        return Promise.reject(err)
      }

      try {
        const { accessToken, refreshToken: refreshed } = (
          await axios.post(
            `${process.env.REACT_APP_API_GATEWAY_URL}/users/login/refresh`,
            {
              refreshToken,
            }
          )
        ).data
        setToken(accessToken, refreshed)

        const config = {
          ...err.config,
          headers: {
            ...err.config.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        }

        try {
          const res = await axios.request(config)
          return Promise.resolve(res)
        } catch (e) {
          return Promise.reject(e)
        }
      } catch (e) {
        logout()
      }
    }

    return Promise.reject(err)
  }
)

const getMethod = ({ method, url, data, headers, params, ...props }) => {
  const m = method.trim().toLocaleLowerCase()
  switch (m) {
    case 'post':
      return index.post(url, data, {
        headers: { ...headers },
        params,
        ...props,
      })
    case 'put':
      return index.put(url, data, { headers: { ...headers }, params, ...props })
    case 'delete':
      return index.delete(url, { headers: { ...headers }, params, ...props })
    case 'patch':
      return index.patch(url, data, {
        headers: { ...headers },
        params,
        ...props,
      })
    default:
      return index.get(url, { headers: { ...headers }, params, ...props })
  }
}

export const createApiRequest = async ({
  method = 'get',
  url = '',
  data = {},
  headers = {},
  onSuccess = () => {},
  onFail = () => {},
  params = {},
  ...props
}) => {
  const req = getMethod({
    url,
    data,
    headers,
    method,
    params,
    props,
  })

  try {
    const response = await req
    if (onSuccess) {
      if (onSuccess[Symbol.toStringTag] === 'AsyncFunction')
        await onSuccess(response)
      else onSuccess(response)
    }
    return response
  } catch (err) {
    if (onFail) {
      if (onFail[Symbol.toStringTag] === 'AsyncFunction') await onFail(err)
      else onFail(err)
    }
    return Promise.reject(err)
  }
}
