import builder from '@builder.io/react'
import type { BuilderContent } from '@builder.io/sdk'
import { GetContentOptions } from '@builder.io/sdk'
import { IncomingMessage } from 'http'
import React from 'react'
import { useSessionstorageState } from 'rooks'
import { index } from 'src/store/api'
export const useBuilderPage = (url: string, model: string, deps?: any[]) => {
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [data, setData] = React.useState()
  const [cache, setCache] = useSessionstorageState<any>(model)

  React.useEffect(() => {
    async function fetch() {
      try {
        setLoading(true)

        if (cache) {
          return setData(cache)
        }

        const res = await index.get('/bff/builder', {
          params: {
            url,
            model,
          },
        })

        setData(res.data)
        setCache(res.data)
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }

    fetch()
  }, deps ?? [])

  return {
    loading,
    error,
    data,
  }
}

type BuilderOptions =
  | (GetContentOptions & {
      req?: IncomingMessage | undefined
      apiKey?: string | undefined
      authToken?: string | undefined
    })
  | undefined

export function useBuilderAll(
  model: string,
  options: BuilderOptions,
  deps: any[]
) {
  const [notFound, setNotFound] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState<BuilderContent[] | null>(null)

  React.useEffect(() => {
    async function fetch() {
      try {
        setLoading(true)
        const content = await builder.getAll(model, options)
        setData(content)
      } catch (e) {
        setNotFound(!data)
      } finally {
        setLoading(false)
      }
    }

    fetch()
  }, deps)

  return {
    content: data,
    notFound,
    loading,
  }
}
