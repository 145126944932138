import { Flex } from '@chakra-ui/react'
import React from 'react'
import { PropertyCardContext } from 'src/components/cards/property/property-card.context'
import { useSearch } from 'src/components/search-v2/search-context'

import Night from './night'
import Subscription from './subscription'

const Price = () => {
  const { context } = useSearch()
  const { property } = React.useContext(PropertyCardContext)

  if (!property.price) return null

  return (
    <Flex
      direction="column"
      width="100%"
      backgroundColor="black.0"
      padding="24px 16px 12px 16px"
      border="1px solid #EEEEEE"
      borderRadius="8px"
      gap="8px"
    >
      {context.bookingType === 'subscripton' && <Subscription />}
      {context.bookingType === 'night' && <Night />}
    </Flex>
  )
}

export default Price
