import React, { Suspense } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'
import OtaPage from 'src/pages/ota'
import OtaLayout from 'src/pages/ota/layout'
import ReservationCode from 'src/pages/ota/reservation-code'
import ReservationNotFound from 'src/pages/ota/reservation-not-found'
import VerifySmsCode from 'src/pages/ota/verify-sms-code'
import VerifySuccess from 'src/pages/ota/verify-success'

import { RequireSeo } from './custom'

const otaRoutes = (): RouteObject[] => [
  {
    path: '/vincular',
    element: <OtaLayout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback="">
            <RequireSeo title="Housi - Vincular reserva">
              <OtaPage />
            </RequireSeo>
          </Suspense>
        ),
      },
      {
        path: 'reserva',
        element: (
          <Suspense fallback="">
            <RequireSeo title="Housi - Vincular reserva">
              <Outlet />
            </RequireSeo>
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback="">
                <RequireSeo title="Housi - validar código do parceiro">
                  <ReservationCode />
                </RequireSeo>
              </Suspense>
            ),
          },
          {
            path: 'nao-encontrada',
            element: (
              <Suspense fallback="">
                <RequireSeo title="Housi - Reserva não encontrada">
                  <ReservationNotFound />
                </RequireSeo>
              </Suspense>
            ),
          },
          {
            path: ':partnerCode/codigo',
            element: (
              <Suspense fallback="">
                <RequireSeo title="Housi - Verificar sms">
                  <VerifySmsCode />
                </RequireSeo>
              </Suspense>
            ),
          },
          {
            path: ':partnerCode/sucesso',
            element: (
              <Suspense fallback="">
                <RequireSeo title="Housi - importação realizada com sucesso">
                  <VerifySuccess />
                </RequireSeo>
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
]

export default otaRoutes
