import * as dateFns from 'date-fns'
import React from 'react'

import dateUtils from '../../../../../utils/date'
import { PickerContext } from '../picker-context'

const moreDates = (date: Date) => {
  const firstDate = dateFns.startOfMonth(date)

  return dateUtils.datesBetween(
    firstDate,
    dateUtils.sum(firstDate, { months: 48 }),
    {
      months: 1,
    }
  )
}

const moreMonths = (date: Date) => {
  const firstDate = dateFns.startOfMonth(date)
  return dateUtils.datesBetween(
    firstDate,
    dateUtils.sum(firstDate, { years: 2 }),
    {
      years: 1,
    }
  )
}
const useInfiniteDates = () => {
  const { context, valid } = React.useContext(PickerContext)

  const initialDates = moreDates(context.current)
  const initialMonths = moreMonths(context.current)
  const [months, setMonths] = React.useState(initialMonths)

  const [dates, setDates] = React.useState(initialDates)

  React.useEffect(() => {
    if (valid.month) {
      return setDates(moreMonths(new Date()))
    }
    setDates(moreDates(new Date()))
  }, [valid])

  const loadMore = React.useCallback(() => {
    if (valid.month) {
      setMonths((months) => [
        ...months,
        ...moreMonths(dateUtils.sum(months[months.length - 1], { years: 1 })),
      ])
    }

    setDates((dates) => [
      ...dates,
      ...moreDates(dateUtils.sum(dates[dates.length - 1], { months: 1 })),
    ])
  }, [dates, valid.month, months])

  return {
    dates,
    months,
    loadMore,
  }
}

export default useInfiniteDates
