import { ComponentStyleConfig } from '@chakra-ui/react'

export const Tag: ComponentStyleConfig = {
  //parts: ['container', 'label', 'closeButton'],
  baseStyle: {
    container: {},
    label: {},
    closeButton: {},
  },
  sizes: {},
  variants: {
    status: {
      container: {
        fontSize: 't-xs.0',
        fontWeight: 'bold',
        minH: '20px',
        w: 'fit-content',
        py: 's1',
        px: 's3',
        borderRadius: '25px',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
  defaultProps: {},
}
