import { useCallback, useEffect, useState } from 'react'

import { getBuildingById } from '../../requests/building'

const useBuilding = ({ buildingId }) => {
  const [building, setBuilding] = useState({})
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async () => {
    setLoading(true)

    try {
      if (buildingId) {
        const { data } = await getBuildingById({ buildingId })
        setBuilding(data)
        return data
      }
      return null
    } catch (e) {
      return Promise.reject(e)
    } finally {
      setLoading(false)
    }
  }, [buildingId])

  useEffect(() => {
    fetch()
  }, [fetch])

  return { building, loading }
}

export default useBuilding
