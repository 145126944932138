import { useMachine } from '@xstate/react'
import React from 'react'
import { EventData } from 'xstate'

import datesMachine, {
  ContextType,
  dates_events,
  dates_states,
} from './dates-machine'

type SendEvent = (event: dates_events, payload?: EventData | undefined) => void

type Valid = {
  [key in dates_states]: boolean
}

interface DatesContextProps {
  states: dates_states
  send: SendEvent
  context: ContextType
  valid: Valid
}

interface DatesProviderProps {
  children?: React.ReactNode
}

export const DatesContext = React.createContext({} as DatesContextProps)

const DatesProvider = ({ children }: DatesProviderProps) => {
  const [current, send] = useMachine(datesMachine)

  const states = current.value as dates_states
  const context = current.context

  const valid: Valid = {
    disabled: current.matches(dates_states.disabled),
    filled: current.matches(dates_states.filled),
    focused: current.matches(dates_states.focused),
    idle: current.matches(dates_states.idle),
  }

  return (
    <DatesContext.Provider value={{ states, send, context, valid }}>
      {children}
    </DatesContext.Provider>
  )
}

export default DatesProvider
