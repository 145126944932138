import React from 'react'

const AreaIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.57 14.615V6.635C20.57 6.365 20.41 6.125 20.16 6.025L12.52 3.045C12.37 2.985 12.2 2.985 12.05 3.045L4.41 6.025C4.16 6.125 4 6.365 4 6.635V15.455C4 15.465 4 15.465 4 15.475V17.855C4 18.125 4.17 18.365 4.42 18.465L12.06 21.425C12.13 21.455 12.21 21.465 12.28 21.465C12.37 21.465 12.47 21.445 12.55 21.415L20.16 18.465C20.41 18.365 20.58 18.125 20.58 17.855L20.57 14.615C20.57 14.625 20.57 14.625 20.57 14.615ZM15.09 19.015L17.17 16.925L17.84 16.665L15.74 18.765L15.09 19.015ZM13.81 19.515L15.89 17.435L16.6 17.155L14.51 19.245L13.81 19.515ZM12.58 19.995L14.65 17.925L15.32 17.665L13.23 19.745L12.58 19.995ZM12.28 18.045L5.68 15.455L12.28 12.855L14.57 13.755C14.58 13.765 14.6 13.765 14.61 13.775L18.89 15.455L12.28 18.045ZM15.11 9.565L17.1 10.345V13.945L15.11 13.165V9.565ZM18.42 16.445L19.01 16.215L16.91 18.315L16.32 18.545L18.42 16.445ZM19.27 16.315V16.695L18.12 17.845L17.5 18.085L19.27 16.315ZM19.27 14.795L17.85 14.235V10.085C17.85 10.055 17.84 10.015 17.83 9.985C17.83 9.975 17.83 9.955 17.82 9.945C17.81 9.925 17.79 9.905 17.77 9.875C17.76 9.855 17.75 9.835 17.73 9.825C17.72 9.815 17.7 9.805 17.69 9.795C17.66 9.775 17.64 9.755 17.61 9.745L14.88 8.675H14.87C14.85 8.665 14.83 8.665 14.81 8.665C14.79 8.665 14.76 8.655 14.74 8.655C14.74 8.655 14.74 8.655 14.73 8.655C14.7 8.655 14.67 8.665 14.64 8.675C14.62 8.675 14.6 8.685 14.58 8.685C14.56 8.695 14.55 8.705 14.53 8.715C14.51 8.735 14.48 8.745 14.46 8.765C14.45 8.775 14.44 8.785 14.44 8.805C14.42 8.835 14.4 8.855 14.38 8.895C14.37 8.915 14.37 8.945 14.37 8.965C14.37 8.985 14.36 9.005 14.36 9.035V12.885L12.66 12.215V4.515L19.27 7.095V14.795ZM11.91 4.495V12.195L5.3 14.795V14.625C5.3 14.615 5.3 14.615 5.3 14.605V7.075L11.91 4.495ZM5.3 17.395V16.105L12.14 18.785C12.18 18.805 12.23 18.815 12.28 18.815C12.33 18.815 12.37 18.805 12.42 18.785L12.79 18.645L12.3 19.135V19.495L13.38 18.415L14.08 18.145L12.3 19.925V20.105L5.3 17.395ZM18.71 17.615L19.27 17.055V17.395L18.71 17.615Z"
        fill="#A3A3A3"
      />
    </svg>
  )
}

export default AreaIcon
