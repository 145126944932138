import { Flex, retriever } from '@retriever-ui/react'
import Dinero from 'dinero.js'
import { DineroObject } from 'dinero.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CLEANING_TAX_REGEX, CONDO_REGEX, IPTU_REGEX } from 'src/constants'
import { convertToMoney } from 'src/utils/monetary'

import { BookingContext } from '../../../booking.context'
import PackHousiModal from './modal'

type FeesType = {
  amount: DineroObject
  name: { pt_BR: string } | string
}

export const PackHousiValues = ({ isModal }: { isModal?: boolean }) => {
  const { t } = useTranslation('property-detail/booking-resume')
  const { price } = React.useContext(BookingContext)

  const feesObject = React.useMemo(() => {
    const fees = price?.monthly?.fees as FeesType[]

    return fees.reduce(
      (previousFee, currentFee) => {
        const feeKey = Object.values(currentFee.name).join().toLowerCase()

        const isIptu = IPTU_REGEX.test(feeKey)
        const isCondo = CONDO_REGEX.test(feeKey)
        const isCleaning = CLEANING_TAX_REGEX.test(feeKey)
        // if isn't condo and input return the previous value
        if (!isCondo && !isIptu && !isCleaning)
          return previousFee as DineroObject

        // if dont have previous value return the current
        if (typeof previousFee.amount === 'undefined') {
          return currentFee.amount
        }

        // get previous fee and sum with current fee
        // previousFee + currentFee
        return Dinero({ ...previousFee, currency: 'BRL' })
          .add(Dinero(currentFee.amount))
          .toObject()
      },
      {
        amount: 0,
        currency: 'BRL',
        precision: 2,
      }
    )
  }, [price])

  // get three values, gross amount, net amount and sum of iptu and condo
  const packHousiAmount = React.useMemo(() => {
    const gross = price?.monthly?.grossAmount as DineroObject
    const net = price?.monthly?.netAmount as DineroObject
    // sum of iptu and condo
    const fee = feesObject as DineroObject

    // if is initial value dont realize the math
    if (Object.is(gross, net)) return '-'

    // correspond to gross - (net - fee) calc
    const packAmount = Dinero(gross)
      .subtract(Dinero(net))
      .subtract(Dinero(fee))
      .toObject()

    const isUnavailable = packAmount.amount === 0 || packAmount.amount === 100
    // if the price is returned R$ 0 or R$ 1 we dont show to user.
    if (isUnavailable) return '-'

    return convertToMoney(packAmount)
  }, [price])

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <retriever.p
        fontSize={isModal ? 16 : 14}
        color="black.500"
        fontWeight={500}
      >
        {t('property-detail/booking-resume:PACK_HOUSI')}
      </retriever.p>
      <retriever.p
        fontSize={isModal ? 16 : 14}
        color="black.500"
        fontWeight={500}
      >
        {packHousiAmount}
      </retriever.p>
    </Flex>
  )
}

const PackHousi = () => {
  return (
    <div style={{ width: '100%' }}>
      <PackHousiValues />
      <PackHousiModal />
    </div>
  )
}

export default PackHousi
