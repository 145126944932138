const HousiLogoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="122"
    height="12"
    viewBox="0 0 122 12"
    fill="none"
  >
    <path
      d="M12.4494 4.71893L2.49169 4.71836L2.49182 0.0044292H0V11.9952H2.49182L2.49671 7.26389H12.449L12.4493 11.9946L14.9388 11.9952L14.9368 0.0044292L12.4493 0.00390625L12.4494 4.71893Z"
      fill="#474747"
    />
    <path
      d="M101.013 4.72269L93.1813 4.7171C92.5967 4.7171 92.1229 4.23255 92.123 3.63489C92.1231 3.03753 92.5967 2.55326 93.1809 2.55306L104.571 2.5503V0L93.1818 0.00707206C91.2214 0.00767853 89.6324 1.63271 89.6323 3.63714C89.6322 5.64215 91.2219 7.26759 93.1828 7.26759L101.012 7.26406C101.603 7.26406 102.082 7.75362 102.082 8.35786C102.083 8.96279 101.603 9.45347 101.012 9.45352L89.634 9.45439L89.6323 11.9963L101.013 12C102.978 11.9999 104.571 10.3708 104.571 8.36126C104.571 6.35172 102.978 4.72269 101.013 4.72269Z"
      fill="#474747"
    />
    <path d="M122 0.00439453H119.51V11.9951H122V0.00439453Z" fill="#474747" />
    <path
      d="M72.2039 0.00798921L72.2018 7.73042C72.2018 8.67828 71.4477 9.44941 70.5207 9.44941L63.928 9.44582C63.001 9.44582 62.2467 8.67469 62.2467 7.72683L62.2487 0.120296L62.2447 0.00439463H59.7549L59.7675 7.97925C59.7675 8.52991 59.8707 9.04992 60.0744 9.52473C60.2776 9.99931 60.5668 10.4244 60.934 10.7883C61.1096 10.9623 61.3036 11.1244 61.5104 11.2702C61.7185 11.4166 61.9408 11.5436 62.1706 11.6474C62.4019 11.7522 62.6464 11.8344 62.8974 11.8917C63.149 11.9491 63.4118 11.9783 63.6787 11.9783H70.7824C71.0492 11.9783 71.3121 11.9491 71.5638 11.8917C71.8149 11.8343 72.0593 11.7521 72.2903 11.6475C72.5204 11.5435 72.7425 11.4166 72.9507 11.2702C73.1574 11.1245 73.3514 10.9623 73.5273 10.7883C73.8943 10.4243 74.1835 9.99922 74.3868 9.52478C74.5905 9.04958 74.6937 8.52962 74.6937 7.97925V0.00439453L72.2039 0.00798921Z"
      fill="#474747"
    />
    <path
      d="M44.5099 2.45789C44.3066 1.98345 44.0174 1.55832 43.6503 1.1944C43.4744 1.02032 43.2805 0.858198 43.0738 0.712506C42.8656 0.566068 42.6435 0.439157 42.4134 0.335215C42.1824 0.230526 41.9379 0.148348 41.6869 0.0910005C41.4352 0.0335235 41.1723 0.00439453 40.9054 0.00439453H33.8018C33.5349 0.00439453 33.272 0.0335235 33.0204 0.0910005C32.7694 0.148307 32.5249 0.230485 32.2937 0.335256C32.0638 0.439116 31.8416 0.566027 31.6335 0.712424C31.4266 0.858239 31.2327 1.02036 31.0571 1.19436C30.6899 1.55824 30.4006 1.98337 30.1974 2.45793C29.9938 2.93275 29.8905 3.45276 29.8905 4.00342L29.8869 5.11246H29.8779L29.883 6.2811L29.8779 7.84083H29.8899L29.8905 7.98767C29.8905 8.53833 29.9938 9.05834 30.1974 9.53316C30.4006 10.0077 30.6899 10.4329 31.0571 10.7967C31.2327 10.9707 31.4266 11.1329 31.6335 11.2787C31.8416 11.4251 32.0638 11.552 32.2937 11.6558C32.5249 11.7606 32.7694 11.8428 33.0204 11.9001C33.272 11.9576 33.5349 11.9867 33.8018 11.9867H40.9054C41.1723 11.9867 41.4352 11.9576 41.6869 11.9001C41.9379 11.8427 42.1824 11.7606 42.4134 11.6559C42.6435 11.5519 42.8656 11.425 43.0738 11.2786C43.2805 11.1329 43.4744 10.9708 43.6503 10.7967C44.0174 10.4328 44.3066 10.0076 44.5099 9.5332C44.7135 9.05801 44.8167 8.53804 44.8167 7.98767V4.00342C44.8167 3.45305 44.7135 2.93308 44.5099 2.45789ZM40.6438 9.45783L34.051 9.45424C33.1347 9.45424 32.3884 8.70039 32.3714 7.7675L32.3708 6.47808L32.3718 5.22837L32.3702 5.18267L32.3698 4.25584C32.3698 3.30798 33.124 2.53685 34.051 2.53685L40.6438 2.53326C41.5708 2.53326 42.3249 3.30438 42.3249 4.25225L42.326 6.26576L42.3249 7.73884C42.3249 8.68671 41.5708 9.45783 40.6438 9.45783Z"
      fill="#474747"
    />
  </svg>
)

export default HousiLogoIcon
