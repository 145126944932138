import React from 'react'

export type MessageType<T> = {
  data?: T
  category: string
}

const useMessages = <T,>(eventName?: string) => {
  const [data, setData] = React.useState<MessageType<T>>()

  React.useEffect(() => {
    const handleEvent = (event: CustomEvent | Event) => {
      const { detail } = event as CustomEvent
      setData(detail)
    }
    if (!eventName) return

    document.addEventListener(eventName, handleEvent, false)

    return () => {
      document.removeEventListener(eventName, handleEvent, false)
    }
  }, [eventName, document])

  const emit = React.useCallback(
    (eventName: string, data?: MessageType<T>) => {
      const event = new CustomEvent(eventName, { detail: data })
      document.dispatchEvent(event)
    },
    [document]
  )

  return { value: data, emit }
}

export default useMessages
